import React, { useContext, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import FormControl from '@mui/material/FormControl';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import elementValidation from '../Component/validation.js';
import Button from './Button';
import  { ReactComponent as Dawnlode }   from "../images/Table/download.svg";
import {
  makeStyles,
} from '@material-ui/core/styles';
const commonStyles = makeStyles((theme) => ({
    root: {
      "MuiFormHelperText-root": {
        color: "red !important"
      }
    },

    inputError: {
      "& .MuiOutlinedInput-root.Mui-error": {
        color: "orange !important"
      },
      "& .MuiInput-underline.Mui-error:after": {
        borderBottomColor: "orange !important"
      },
      "& .MuiFormHelperText-root": {
        color: "red !important"
      }
    }
  }));
export default function InputWithButton(props) {
  const classes = useStyles();
  const required = null;
    let { field_type, field_id, field_label,field_btn_label,field_disabled, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    { 
      classes.input = classes.input.replace(" input-error ", " ");
      classes.input = classes.input.replace("  ", " ");
      classes.input +=" input-error ";   
    }
    if(field_type==="number"){ 
      field_value = parseInt(field_value)
    }
    const elementField = {field_type, field_id, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
    function handleBlur(){
      console.log(field_value)
      elementValidation(elementField)
    }

  return (
    <div className={classes.inputroot}>
   <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
      <FormControl  variant="outlined" className={classes.iwb}>
     
      <OutlinedInput className={classes.input}
             id = {field_id} 
             type={field_value_type}
             value={field_value}
             disabled={field_disabled}
             placeholder={field_placeholder ? field_placeholder : ''} 
             onChange={event => handleChange(field_id, event)}
             error
             onBlur={handleBlur}  
        />
     <Button className={classes.btn1} 
      ButtonName={field_btn_label}
      // field_disabled={field_disabled} 
      onClick={(event)=>{event.preventDefault();}}
     />
 
     <Button className={classes.btn2}
     ButtonName={<Dawnlode/>}
      field_disabled={field_disabled} 
      onClick={(event)=>{event.preventDefault();}}
     />
    
     
        <FormHelperText>{field_helperText||elementField.field_helperText}</FormHelperText>
        </FormControl>
        
      </div>   
      );
}
