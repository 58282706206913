
import {
  makeStyles,
} from '@material-ui/core/styles';
import '../css/App.css'
const drawerWidth = 256;
const leftGap = 408;

const useStyles = makeStyles((theme) => ({
  main: {
    position: 'relative',
    marginBottom: '30px',
    padding: '20px',
    // marginLeft: 'auto',
    // marginRight: 'auto',
    // width: '96%',
    // minWidth:'700px',
    '& .btn-margin-success .closebtn': {
      display: 'none'
    },
    '& .MuiSnackbar-root': {
      '& .MuiPaper-root': {
        height: "100%",
        overflow: 'auto',

      },
    },
    '& .MuiTypography-h6': {
      color: '#344256',
      fontSize: '20px',
      ['@media (max-width: 1280px)']: {
        fontSize: '18px',
      },
    },
    '& .MuiSelect-select .MuiBox-root': {
      alignItems: 'center',
      height: '24px',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    '& .MuiChip-root': {
      fontSize: '12px',
      height: '24px',
      backgroundColor: 'rgb(15 15 15 / 8%)',
      borderRadius: '16px',
    },
    '& .MuiPaper-root': {
      height: 'calc(100vh - 330px)',
      overflow: 'auto',
      overflowY: 'auto',
      // position:'relative',
      '& .MuiTable-root': {

        '& .MuiTableBody-root .MuiTableCell-root': {
          fontSize: '14px',
          fontWeight: '600',
          ['@media (max-width: 1440px)']: {
            fontSize: '13px',
            fontWeight: '600',
            // color:'#616467',
          },
        },
        '& .MuiTableHead-root': {
          width: '100%',
          maxWidth: '100%',
          height: '53px',
          top: 0,
          '& .MuiTableCell-root': {
            lineHeight: '18px',
          },
        },
      },
    },
  },
  tableBox: {
    border: '1px solid #d9dfe3',
    borderRadius: '4px',
    padding: '24px 15px',
    minWidth: '700px',
    background: '#f5f9fa',
  },
  Brdcrb: {
    '& span:first-of-type': {
      marginRight: '3px',
    },
    '& span': {
      padding: 0,
      color: '#8a8d8f',
    },
    '& svg': {
      marginRight: 0
    },
    '& a:hover': {
      color: '#2da0da',
      textDecoration: 'none',
    },
  },
  formioClass: {
    '& div': {
      maxWidth: '100%',
    },
  },

  stickImg: {

  },
  breadCrmb: {
    '& li:last-of-type': {
      '& span': {
        color: '#2da0da',
      }
    }


  },
  //formSection
  marginChange: {
    // marginTop: theme.spacing(30),
    fontSize: '24px',
    fontWeight: '600',
    color: '#475F7B',
    marginBottom: '20px',
  },

  //header 
  appBar: {
    // fontSize:'1.6rem',
    top: 0,
    display: 'flex',
    // minWidth: '740px',
    width: '100%',
    padding: '8px 20px 8px 21px',
    position: 'absolute',
    // height: '4.0rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: theme.spacing(8),
    backgroundColor: '#fff',
    borderBottom: '0.3px solid #C5CFD3',
    // boxShadow: '0px 2px 4px rgba(50, 55, 56, 0.2)',
    boxShadow: '0 0 3px #dbdbdb',
    zIndex: '999',
    '& .MuiGrid-container': {
      justifyContent: 'space-between',
    }
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    flex: 1,
    // '&:hover': {
    //   backgroundColor:' #F0F8FA',
    // },
    marginRight: theme.spacing(2),
    marginLeft: '30px',
    width: '100%',
    minWidth: '220px',
    maxWidth: '700px',
    border: '1px solid #C8D8DE',
    borderRadius: theme.spacing(5),
    backgroundColor: '#fff',
    // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  },

  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: theme.spacing(2),
    '& svg': {
      fill: '#697381',
    },
  },
  logoSvg: {
    color: '#052656',
    width: '120px',
    '& h2': {
      marginTop: '0px',
      paddingLeft: '15px',
      fontFamily: " Knife Princess",
      fontSize: '26px',
    },
    '& img': {
      width: '100%',
      height: '28px',
    }

  },
  inputRoot: {
    width: '100%',
    height: '48px',
  },
  inputInput: {
    fontSize: '14px',
    padding: theme.spacing(1.5, 8.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    '&::placeholder': {
      color: '#445062',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  avatar: {
    width: '2.1rem',
    height: '2.1rem',
    borderRadius: '50%',
    border: '1px solid #237DA7',
    backgroundColor: '#fff',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
    },
    '& h2': {
      fontFamily: 'Georgia, serif',
    margin:0,
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    color:'#237DA7',
    }
  },
  avatarBtn: {
    padding: theme.spacing(1, 1, 1, 1),
    marginTop: '-6px',
    borderRadius: theme.spacing(.5),
  },
  btnGrp: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    '& svg': {
      fill: '#303F53'
    },
    '& .MuiIconButton-root': {
      marginLeft: theme.spacing(2)
    },
  },
  avatarName: {
    fontSize: '14px',
    marginLeft: theme.spacing(1),
  },
  //policy image
  svgimg: {
    width: '375px',
    maxWidth: '375px',
    position: 'fixed',
    marginTop: '75px',
    // marginLeft:drawerWidth,
    textAlign: 'center',
    // paddingTop:'25px',
    // paddingLeft:'30px',
    ['@media (max-width: 1600px)']: {
      width: '286px',
      marginTop: '24px'
    },
    ['@media (max-width: 1280px)']: {
      display: 'none',
    },
    '& img': {
      opacity: ".4",
      ['@media (max-width: 1600px)']: {
        width: '75%',
      },
    },
    '& h6': {
      paddingTop: '16px ',
      fontSize: '16px ',
      color: '#65707F',
      margin: "0px",
      ['@media (max-width: 1600px)']: {
        fontSize: '15px',
      },
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#949DA7',
      paddingTop: '8px',
      paddingLeft: '18px',
      margin: "0px",
      ['@media (max-width: 1600px)']: {
        fontSize: '13px',
      },
    },
  },

  sideMenuInactive: {
    width: '0px',
    position: 'fixed',
    '& .MuiDrawer-paper': {
      width: '0px',
    }
  },

  //SideMenu
  sideMenu: {
    display: 'flex',
    flexDirection: 'column',
    position: 'fixed',
    zIndex: '998',
    // paddingRight: '2px',
    left: '-3px',
    right: '0px',
    top: '60px',
    width: '256px',
    backgroundColor: '#fff',
    borderRight: '1px solid #e3e0e0',
    // boxShadow: '4px 4px 6px rgba(0, 0, 0, 0.041)',
    height: 'calc(100vh - 60px)',
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  header: {

    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: 'calc(100vh - 180px)',
    '& .MuiPaper-root': {
      width: '252px',
      backgroundColor: 'transparent',
      // marginLeft: '12px',
      borderRight: 'none',
    }
  },
  drawerPaper: {
    width: '226px',
    backgroundColor: 'transparent',
    marginLeft: '12px',
    borderRight: 'none',
    // height:'calc(100vh - 272px)' ,
    // overflowY: 'scroll',
  },
  drawerActive: {
    color: '#2da0da',
  },
  drawerContainer: {
    // overflow: 'auto',
    '& .MuiListItemIcon-root': {
      minWidth: '20px',
      '& svg': {
        width: '12px',
      }
    },
    '& .MuiListItem-gutters': {
      padding: '4px 10px 4px 25px',
      marginBottom: '10px',
    },
    '& .MuiTypography-body1': {
      fontSize: '16px',
      // fontWeight: '500',
      //   '&:hover ':{
      //   color:'#2da0da',
      // } 
    },

    // '& .MuiButtonBase-root':{
    //   '&:hover .MuiTypography-body1':{
    //     color:'#2da0da',
    //   }, 
    //   '&:hover div svg':{

    //       fill:'#2da0da',

    //   } ,
    //   '&:hover':{
    //     borderLeft:"4px solid #2da0da",
    //     transition:"0.2s"
    //   } 
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menuHead: {
    padding: '27px 0px 5px 24px',
  },
  ftrlgo: {
    '& img': {
      width: '220px',
    }
  },
  drawerFooter: {
    fontSize: '10px',
    color: '#8796a5',
    padding: theme.spacing(1, 0, 1, 0),
  },
  menuFont: {
    fontSize: 5,
    fontWeight: 200,
    color: '#445062',
    // backgroundColor:'green'
    '& .MuiListItem-button:hover': {
      background: '#fafafa',
    }
  },
  activeMenu: {
    // backgroundColor:'red',
    '& .MuiTypography-body1': {
      color: '#0055d4',
    },
    '& div svg': {
      fill: '#0055d4',
    },
    borderRight: "4px solid #0055d4 !important",
    transition: "0.2s",
    background: '#fafafa !important',
    paddingLeft: '21px !important',
  },
  //Subheader
  appSubBar: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '150px',
    backgroundColor: '#fff',
    borderBottom: '2px solid #f4f5f7',
    // boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    zIndex: '997',

  },
  shh: {
    paddingTop: '16px',
    // flex:'0 0 100%',
    minWidth: '700px',
  },
  topSp: {
    paddingTop: '72px',
    width: 'calc(100% - 274px)',
    marginLeft: 'auto',
    '& > .MuiGrid-root > .MuiGrid-root': {
      minWidth: '700px',
    },
    '& > .MuiGrid-container': {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: 'auto',
      paddingLeft: '20px',
      paddingRight: '20px',
      '& ol': {
        flex: '0 0 100%',
        maxWidth: '100%',
        justifyContent: 'flex-start',
      },
      '& > .MuiGrid-container': {
        paddingTop: theme.spacing(3),
        alignItems: 'center',
        flex: '0 0 100%',
        maxWidth: '100%',
        flexWrap: 'nowrap',
        minWidth: '700px',
      },
    },
  },
  rightMr: {
    marginRight: theme.spacing(5),
  },
  menuList: {
    margigRight: '8px',
    color: '#75ADC7',
  },
  heading: {
    fontSize: '24px',
    color: '#344256',
    fontWeight: '500',
    marginBottom: 0,
    ['@media (max-width: 1280px)']: {
      fontSize: '24px',
    },
  },
  flex: {
    marginLeft: drawerWidth,
    display: 'flex',
  },
  flowHeader:{
    display:'flex',
    justifyContent:'space-between',
    marginBottom:'10px',
    '& h2':{
      color:'#363636',
      fontSize: '1.5rem'
    }
  },
  filtersearch:{
    width:'300px',
    position:'relative',
    marginBottom:'20px',
  },
  searchbtn:{
    color: '#fff',
    padding: '10px 12px',
    fontSize: '14px',
    position: 'absolute',
    top: 0,
    right: 0,
    background: '#0055d4',
    border: '1px solid #0055d4',
    borderRadius: '0 4px 4px 0',
  },
  searchInput:{
    fontSize: '14px',
    padding: '10px 12px',
    // boxShadow: '2px 2px 0 #f5f5f5',
    border: '1px solid #dbdbdb',
    borderRadius:' 4px',
   
    width:'100%',
    '&:focus':{
      outline: '1px solid #0055d4'
    }
  },
  subBtn: {
    backgroundColor: '#0055d4',
    color: '#fff',
    height: '36px',
    fontSize: '14px',
    padding: '7px 20px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    border: '2px solid #0055d4',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    transition: 'all .3s',
    // '&:hover': {
    //   border: '2px solid #0055d4',
    //   color: '#0055d4',
    //   backgroundColor: '#e6effc',
    //   transform: 'translateY(-1px)'
    // },
    '& svg': {
      marginRight: '10px',
      fontSize: '22px',
    },
    '& span':{
      fontSize:'18px',
      fontWeight:'600',
      marginRight:'14px'
    }
  },
  center: {
    //  marginLeft:theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  btn: {
    backgroundColor: '#26AD8D',
    color: '#F2F8FA',
    fontSize: '14px',
    padding: '3px 24px',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #187e66',
    cursor: 'pointer',
  },

  errorpopup: {

    '& .MuiPaper-root': {
      width: '400px',
      padding: '6px 16px',
      backgroundColor: 'rgb(253, 237, 237)',
    }
  },
  //Tabes
  formtab: {
    borderBottom: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.12)',

    borderBottom: '1px solid #A0AFB2',
  },
  topSpace: {
    // marginTop:theme.spacing(2),
    // padding: '0 0 24px 0',
    '& .css-19kzrtu': {
      padding: '0px',
    },
    '& .MuiTab-root': {
      padding: '0px',
      marginRight: '6%',
      width: ' 14%',
      maxWidth: '14%'

    },
    '& .MuiTab-root:last-of-type': {
      marginRight: '0',
    },
    '& [class *="MuiButtonBase-root"].Mui-selected': {
      paddingLeft: '0px',
      fontWeight: 'bold',
      color: '#000',
    },
    '& .MuiTabs-indicator': {
      left: '15px',
      backgroundColor: '#26ad8d',
      width: '130px',
    },
    '& .containers': {
      padding: 0,
      width: '100%',
      margin: '0',
      justifyContent: 'space-between',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0 20px',
      // display: 'grid',
      // gridTemplateColumns: 'auto auto',
      // ['@media (max-width: 1320px)'] : {
      //   display: 'block',
      //   },
      // ['@media (min-width: 1920px)'] : {
      //   display: 'grid',
      //   gridTemplateColumns: 'auto auto auto',
      //   },
    }
  },

  editHead: {

  },
    formAline:{
      marginTop:'20px',
    },
  steps: {
    width: '100%',
    marginTop: '30px',
    "& .MuiStep-root": {
      transform: 'translateX(-21%)',

    },
    '& .MuiStepper-root': {
      marginBottom: '30px',
    },
    '& .MuiStepConnector-root span': {
      borderTopWidth: '2px',
      borderColor: '#e1e5e6',
    },
    '& .MuiStepLabel-iconContainer svg': {
      height: '32px',
      width: '32px',
      fill: '#d7dbe4',
      stroke: '1px red',
      marginTop: '-4px',
      '&.Mui-active': {
        fill: '#c79f2f',
      },
      '&.Mui-completed': {
        fill: '#26ad8d',
      },
    },
    '& .MuiStepLabel-labelContainer': {
      maxWidth: '112px',
      '& .MuiStepLabel-label': {
        fontWeight: '600',
        '& span': {
          fontSize: '12px',
          color: '#05152D',
          opacity: '.6',
          display: 'block',
          textAlign: 'center',
        },
      }
    },
  },
  //MainSection
  MainSection: {
    width: `calc(100% - 252px)`,
    marginTop: '70px',
    marginLeft: 'auto',
  },

  popupCreate: {
    '& div .MuiPaper-root': {
      margin: 'auto',
      borderRadius: '10px',
      padding: '20px',
      width: '800px',
      height: '100%',
      maxHeight: 'calc( 100% - 10%)',
      minWidth: '800px',
    },
    '& .alertError .MuiPaper-root':{
      minWidth:'400px',
      maxWidth:'400px',
      padding:'10px 20px',
    }
  },
  popupEdit: {
    '& div .MuiPaper-root': {
      margin: 'auto',
      borderRadius: '10px',
      padding: '0 20px 20px 20px',
      // padding: '0px 6%',
      width: '800px',
      height: '100%',
      maxHeight: 'calc( 100% - 10%)',
      minWidth: '800px',
    },
    '& .alertError .MuiPaper-root':{
      minWidth:'400px',
      maxWidth:'400px',
      padding:'10px 20px',
    }
  },
  popupcrt: {
    '& div .MuiPaper-root': {
      margin: 'auto',
      borderRadius: '10px',
      // padding: '20px',
      // padding: '0px 6%',
      width: '400px',
      height: '100%',
      maxHeight: 'calc( 100% - 50%)',
      minWidth: '400px',
    },
  },
  poup: {
    ' & .MuiPaper-root': {

      maxHeight: 'calc(100% - 10%)',
      minWidth: '450px',
      margin: 'auto',
      borderRadius: '10px',
      padding: '0 20px 20px',
      width: 'auto',
    },
  },
  war: {
    '& p': {
      fontSize: '15px',
      color: '#ff0000',
      fontWeight: '500',
    },
  },
  loader: {
    width: '48px',
    height: '48px',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto'
  },
  loading: {
    textAlign: 'center',
    paddingTop: '24px',
    '& img': {
      width: '48px',
      height: '48px',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto'
    },
    '& .MuiSvgIcon-root': {
      right: '20px',
      position: 'absolute',
      color: '#d12469',
      fontSize: '32px',
      top: '10px',
      '&:hover': {
        boxShadow: '0px 6px 14px rgba(50, 55, 56, 0.4)',
        borderRadius: '25%'
      }
    },
  },
  bdr: {
    '& h4': {
      fontWeight: '400',
      color: '#344256'
    },
    borderBottom: '2px solid #e7ebf1',
    marginBottom: '20px',
  },
  dellabel: {
    '& .makeStyles-inputroot': {
      marginBottom: '40px',
    },
    display: 'flex',
    border: '1px solid #A0AFB2',
    padding: '10px 5px',
    borderRadius: '25px',
    backgroundColor: '#F2F8FA',
    marginBottom: '25px',
  },
  flx: {
    flex: '1',
    paddingLeft: '30px',
    borderRight: '1px solid #A0AFB2',
  },
  flx2: {
    flex: '1',
    paddingLeft: '30px',
  },
  tps: {
    padding: '20px 22% 0px 22%',
    '& Button': {
      marginLeft: '10px',
      border: '0',
      borderRadius: '4px',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '10px',
      color: '#fff',
    },
    '& Button:not(:first-of-type)': {
      marginLeft: '10px',
    }
  },
  tp: {
    padding: '20px 22% 0px 22%',
    '& Button': {
      color: "#fff",
      border: '0',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '10px',
      borderRadius: '4px',
      border: '1px solid #98A3A3',
      boxShadow: 'none',
      backgroundColor: '#98A3A3',
      '&:hover': {
        backgroundColor: '#98A3A3',
        boxShadow: 'none',
      }
    },
    '& Button:not(:first-of-type)': {
      marginLeft: '10px',
      backgroundColor: '#D12469',
      minWidth: '116px',
      '&:hover': {
        backgroundColor: '#D12469',
      }
    }
  },
  tpbp: {
    padding: '20px 22% 6% 6%',
    '& Button': {
      color: "#fff",
      border: '0',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '10px',
      borderRadius: '4px',
      border: '1px solid #6c757d',
      boxShadow: 'none',
      backgroundColor: '#98A3A3',
      '&:hover': {
        backgroundColor: '#98A3A3',
        boxShadow: 'none',
      }
    },
    '& Button:nth-of-type(2)': {
      marginLeft: '10px',
      backgroundColor: '#d12469',
      '&:hover': {
        backgroundColor: '#d12469',
      }
    },
    '& Button:last-of-type': {
      marginLeft: '10px',
      backgroundColor: '#26AD8D',
      '&:hover': {
        backgroundColor: '#26AD8D',
      }
    }
  },
  tpbps: {
    padding: '20px 22% 6% 6%',
    '& Button': {
      color: "#fff",
      border: '0',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '10px',
      borderRadius: '4px',
      border: '1px solid #6c757d',
      boxShadow: 'none',
      backgroundColor: '#98A3A3',
      '&:hover': {
        backgroundColor: '#98A3A3',
        boxShadow: 'none',
      }
    }
  },
  cbtp: {
    margin: '10% 31% 0% 33%',
    '& Button': {
      border: '1px solid #6c757d',
      boxShadow: 'none',
      backgroundColor: '#98A3A3',
      '&:hover': {
        backgroundColor: '#98A3A3',
        boxShadow: 'none',
      }
    },
    '& Button:not(:first-of-type)': {
      marginLeft: '10px',
      backgroundColor: '#84AABC',
      '&:hover': {
        backgroundColor: '#84AABC',
      }
    },
    '& Button:last-child': {
      marginLeft: '10px',
      backgroundColor: '#1E6DAA',
      '&:hover': {
        backgroundColor: '#1E6DAA',
      }
    }
  },
  btnAline: {
    // padding:'20px 22% 0px 21%', 
    '& Button': {
      border: '1px solid #6c757d',
      boxShadow: 'none',
      backgroundColor: '#98A3A3',
      '&:hover': {
        backgroundColor: '#98A3A3',
        boxShadow: 'none',
      }
    },
    '& Button:not(:first-of-type)': {
      marginLeft: '100px',
      backgroundColor: '#1E6DAA',
      '&:hover': {
        backgroundColor: '#1E6DAA',
      }
    }
  },

  btnRset: {
    backgroundColor: '#8AA6A5',
    borderColor: '#8AA6A5',
    '&:hover': {
      backgroundColor: '#8AA6A5',
      borderColor: '#8AA6A5',
    }
  },
  btnDflt: {
    backgroundColor: '#1E6DAA',
    borderColor: '#1E6DAA',
    '&:active': {
      backgroundColor: '#195685',
      borderColor: '#195685',
    }
  },
  btnBck: {
    backgroundColor: '#8AA6A5',
    borderColor: '#8AA6A5',
    '&:active': {
      backgroundColor: '#6A8281',
      borderColor: '#6A8281',
    }
  },
  btnSnD: {
    backgroundColor: '#82A9BB',
    borderColor: '#82A9BB',
    '&:active': {
      backgroundColor: '#6C29A3',
      borderColor: '#6C29A3',
    }
  },
  btnSmt: {
    backgroundColor: '#2a6fd7',
    borderColor: '#2a6fd7',
    '&:hover': {
      backgroundColor: '',
      borderColor: '',
    }
  },
  btnRej: {
    backgroundColor: '#d12469',
    borderColor: '#d12469',
    '&:hover': {
      backgroundColor: '',
      borderColor: '',
    }
  },
  btnApp: {
    backgroundColor: '#26AD8D',
    borderColor: '#26AD8D',
    '&:hover': {
      backgroundColor: '',
      borderColor: '',
    }
  },

  ctaNxtPrv: {
    // padding:'0 0 6% 0',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    // minWidth:'544px',
    maxWidth: '100%',
    // marginLeft:'48px',
    '& Button': {
      marginLeft: '10px',
      border: '0',
      borderRadius: '4px',
      padding: '6px 14px',
      fontSize: '14px',
      fontWeight: '600',
      marginLeft: '10px',
      color: '#fff'
    }
  },

  flex3: {
    display: 'flex',
    listStyleType: 'none',
    padding: '0px',
    '& li': {
      paddingRight: '180px',
      fontSize: '14px',
      fontWeight: '600',
      color: '#05152D',
    },
    '& li:nth-child(even)': {
      fontSize: '14px',
      fontWeight: '1000',
      color: 'black',
    }
  },
  preview: {
    '& .MuiChip-root': {
      fontSize: '12px',
      height: '24px',
    },
    '& .MuiSelect-select .MuiBox-root': {
      alignItems: 'center',
      height: '24px',
      overflow: 'hidden',
      overflowY: 'auto',
    },
    '& .btn-margin-success .viewbtn': {
      display: 'none'
    },
    '& .css-19kzrtu': {
      padding: '5px 0px 0px',
    },
    '& [class *="MuiButtonBase-root"].Mui-selected': {
      paddingLeft: '0px',
      fontWeight: 'bold',
      color: '#000',
    },
    '& .MuiTabs-indicator': {
      left: '15px',
      backgroundColor: '#26ad8d',
      width: '130px',
    },
    '& .MuiTab-root': {
      padding: '0px',
      marginRight: '6%',
      width: ' 14%',
      maxWidth: '14%',

    },
    '& [class ^="makeStyles-formtab"]': {
      // '& + div':{
      //   paddingTop:'64px',
      // },

      position: 'fixed',
      background: '#fff',
      zIndex: 4,
      minWidth: '700px',

    },
    '& form': {
      marginLeft: "0px",
    },
    '& .steps': {
      marginLeft: "0px",
    },
    // padding: '34px 10% 0',
    maxWidth: '1280px',
    zIndex: '1',
    backgroundColor: '#fff',
    '& h4': {
      fontWeight: '400',
      color: '#344256',

    },
  },
  mainEdit: {
    paddingTop: '100px',
    '& .containers': {
      padding: 0,
      width: '100%',
      margin: '0',
      justifyContent: 'space-between',
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '0 20px',
    }
  },

  warningSvg: {
    display: 'flex',
    marginBottom: '32px',
    alignItems:'center',
    '& svg': {
      color: '#d12469',
      fontSize: '24px',
      marginRight: '6px',
    },
    '& p': {
      fontSize: '10px',
      color: '#6e747d',
      margin:0,
    },
  },
  close: {
    width: '796px',
    padding: '34px 0 20px 20px',
    zIndex: '3',
    position: 'fixed',
    backgroundColor: '#fff',
    right: '0',
    left: '0',
    margin: '0 auto',
    height: '80px',
    transform: 'translateY(0px)',
    borderRadius: '8px',
    '& h4':{
      fontSize: '24px',
      fontWeight: '600',
      color: '#475F7B',
      marginBottom: '0px',
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      right: '15px',
      top: '15px',
      color: '#d12469',
      fontSize: '32px',
      '&:hover': {
        boxShadow: '0px 6px 14px rgba(50, 55, 56, 0.4)',
        borderRadius: '25%'
      }
    }
  },
  closeup: {
   display: 'flex',
   justifyContent: 'space-between',
   padding: '34px 0 0',
   borderBottom: '2px solid #e7ebf1',
   marginBottom: '20px',
    '& .MuiSvgIcon-root': {
      right: '15px',
      top: '15px',
      color: '#d12469',
      fontSize: '32px',
      '&:hover': {
        boxShadow: '0px 6px 14px rgba(50, 55, 56, 0.4)',
        borderRadius: '25%'
      }
    }
  },
  prvw: {
    padding: '80px 20px 20px',
    '& ul': {
      maxWidth: '960px',
    },
    '& ul li': {
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #e5e5e5',
      padding: '24px 0% 0',
    },
    '& ul li span': {
      flex: '1 0 50%',
      fontSize: '16px',
      paddingBottom: '12px',
    },
    '& ul li span:not(:first-of-type)': {
      fontWeight: '500',
      flex: '0 0 50%',
    }
  },
  formData: {
    '& ul li': {
      alignItems: 'center',
      borderBottom: '0px solid #e5e5e5',
      padding: '0',
    },
  },
  // tableLoader: {
  //   borderTop: '2px solid #e5e5e5',
  //   background: '#e5e5e5',
  //   display: 'flex',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: '100%',
  //   padding: '10px',
  //   borderRadius: '4px',
  //   '& h5':{
  //     margin: '0',
  //   }
  // },
  tableLoader:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
},
  table: {
    '& td[value~="Process"]': {
      color: '#8ead26',
    },
    '& td[value~="Assinged"]': {
      color: '#dfb435'
    },
    '& td[value~="Inactive"]': {
      color: '#dfb435'
    },
    '& td[value~="REJECTED"]': {
      color: '#d12969',
    },
    '& td[value~="Failed"]': {
      color: '#d12969',
    },
    '& td[value~="Suspended"]': {
      color: '#d12969',
    },
    '& td[value~="PENDING"]': {
      color: '#fff',
      '& div': {
        backgroundColor: '#e4921e',
        width: '100px',
        padding: '8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
      }
    },
    '& td[value~="COMPLETED"]': {
      color: '#366daa',
    },
    '& td[value~="New"]': {
      color: '#366daa',
    },
    '& td[value~="APPROVED"]': {
      color: '#54ae8e',
    },
    '& td[value~="Success"]': {
      color: '#54ae8e',
    },
    '& td[value~="Active"]': {
      color: '#54ae8e',
    },

    '& td[value~="Low"]': {
      color: '#fff',
      '& .priority': {
        backgroundColor: '#008000',
        width: '85px',
        padding: '4px 8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: 'rgb(0 128 0 / 45%) 0px 8px 24px',
      }
    },
    '& td[value~="Medium"]': {
      color: '#fff',
      '& .priority': {
        backgroundColor: '#c0c00c',
        width: '85px',
        padding: '4px 8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: 'rgb(192 192 12 / 45%) 0px 8px 24px',
      }
    },
    '& td[value~="High"]': {
      color: '#fff',
      '& .priority': {
        backgroundColor: '#FFA500',
        width: '85px',
        padding: '4px 8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: 'rgb(255 165 0 / 45%) 0px 8px 24px',
      }
    },
    '& td[value~="Urgent"]': {
      color: '#fff',
      '& .priority': {
        backgroundColor: '#FF0000',
        width: '85px',
        padding: '4px 8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: 'rgb(255 0 0 / 45%) 0px 8px 24px',
      }
    },
    '& td[value~="Alert"]': {
      color: '#fff',
      '& .priority': {
        backgroundColor: '#ff0000',
        width: '85px',
        padding: '4px 8px',
        borderRadius: '25px',
        textAlign: 'center',
        cursor: 'pointer',
        animation: 'blink 1s infinite',
        boxShadow: 'rgb(255 0 0 / 45%) 0px 8px 24px',
      }
    },
    '& td': {
      color: '#1e1e1e',
      maxWidth: '136px',
      wordBreak: 'break-word',
      textAlign: 'left',
      padding: '15px 10px',
      fontSize: '13px',
      fontWeight: '500',
      '& button': {
        width: '40px',
        height: '40px',
        padding: '0px',
        '& svg path': {
          fill: '#0055d4'
        },
      },
      '& .MuiIconButton-root:hover': {
        backgroundColor: 'rgb(32 106 217 / 25%)',
        borderRadius: '6px'
      },
      '& .MuiIconButton-root.Mui-disabled': {
        '& svg path': {
          fill: '#888484'
        },
      }
    },
    '& th': {
      textAlign: 'left',
      padding: '8px',
      // border: " 1px solid #C5CFD3",

    },
    '& table': {
      // border: " 1px solid #C5CFD3",
    },
    '& td:last-of-type': {
      minWidth: '140px',
    },
    '& .MuiTableCell-root.MuiTableCell-head': {
      backgroundColor: '#fafafa',
      color: '#363636',
      fontSize: '12px',
      fontWeight: '600',
      padding: '15px 10px',
      borderColor: '#eaeaea',
    },

    '& tr:hover': {
      backgroundColor: '#fafafa',
    }
  },
  pagination: {
    '& p': {
      margin: 0,
    },
    '&  .MuiToolbar-root': {
      padding: '10px 8px 10px 16px',
      '& .MuiInputBase-root': {
        borderRadius: '4px',
        border: '1px solid #7e7b7b;'
      },
    }
  },
  Actbtn: {
    width: '80px',
    padding: '5px 10px',
    border: '1px solid #115a92a6',
    color: '#115a92',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    position: 'relative',
    '& span': {
      fontSize: '24px',
      paddingLeft: '15px',
      position: 'absolute',
      top: '-6px',
    }
  },
  remActbtn: {
    width: '123px',
    padding: '5px 10px',
    border: '1px solid #e80060',
    color: '#e80060',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '12px',
    position: 'relative',
  },
  // QrCodePopup:{
  //   position:'relative',
  // },
  qrclose: {
    display: 'flex',
    '& svg': {
      position: 'absolute',
      right: '5px',
      top: '5px',
      color: '#d12469',
      fontSize: '32px',
      '&:hover': {
        boxShadow: '0px 6px 14px rgba(50, 55, 56, 0.4)',
        borderRadius: '25%',
      }
    },
    '& img': {
      margin: '20px',
    },
  },
  previewBox: {
    // padding: '24px',
    width: '300px',
    // border: '1px solid #e5e5e5',
  }
}));
export default useStyles;
