import React from "react";
// //imports
import { Typography } from "@material-ui/core";
import Drawer from '@material-ui/core/Drawer';
import Header from "../Component/Header";
import Toolbar from '@material-ui/core/Toolbar';
import useStyles from "./ComponentCss";
import MenuRender from "./Sidebar/MenuRender";
import { SideMenuJson } from "../modules/menu"
import GtsLogo from "../images/decidd/gtslogo.png";
//icons imports
import { ReactComponent as DrawerFooterSVG } from "../images/svgicon/personal_setting.svg";

export default function SideMenu() {
  const classes = useStyles();
  return (
    <>
      <Header />
      <div className={classes.sideMenu}>
        <div className={classes.root}>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            // classes={{
            //   paper: classes.drawerPaper,
            // }}
          >
            <Toolbar />
            <div className={classes.drawerContainer}>

              <MenuRender menuJson={SideMenuJson} />

            </div>
          </Drawer>
          <div className={classes.ftrlgo}>
            {/* <DrawerFooterSVG /> */}
            <img src={GtsLogo} alt="GTS Logo" className={classes.drawerFooterLogo} />
            <Typography className={classes.drawerFooter}>© 2023 Globe teleservices, All rights reserved.</Typography>
          </div>
        </div>
      </div></>
  );
};


