import React from "react";
import useStyles from "../../../Component/ComponentCss";
import CreateUser from "../../../images/decidd/CreateUser.png";
import update from "../../../images/decidd/update.png";
import passwordChange from "../../../images/decidd/passwordChange.png";
import pending from "../../../images/card/pending.png";
import { useNavigate } from 'react-router-dom';


function Create() {
  const classes = useStyles();
  let navigate = useNavigate()

  function handleClick(page, link) {
    console.log(page);
    if (link && link !== '') {
      navigate(link);
    }
  };

  function Boxes(props) {
    const page = props.page1;
    const link = props.link1;
    const icon1 = props.icon;
    const src1 = props.src1;
    const status = props.status;
    const newpolicydisplay = props.newpolicydisplay;
    const newpolicynumber = props.newpolicynumber;
    const newpolicybton = props.newpolicybton;
    const newpolicyimgsrc = props.newpolicyimgsrc;
    //const newpolicybton= "bton2";
    //const newpolicyimgsrc= assign;
    return (

      <div className="flex">
        <div className="recipe-card" onClick={() => handleClick(page, link)}>
          <div className="active">
            <div className={icon1} ><span></span>{status}</div></div>
          <aside>
            <img src={src1} alt="policy" />
            <div className="button" style={{ display: (newpolicydisplay) }} > <img src={newpolicyimgsrc} alt="policy" /> </div>
          </aside>
          <article>
            <h3>{props.label}</h3>
            <div></div>
            <p>{props.text}</p>
          </article>
        </div>
      </div>
    )
  }
  const createTemplate = [
    {
      label: 'Create User',
      text: "Create User",
      page1: 1,
      link: "/Admin/UserManagement/ViewUser",
      icon: "active-icon",
      src1: CreateUser,
      status: "active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    // {
    //   label: 'Update User Details',
    //   text: "User Update",
    //   page1: 2,
    //   link: "/Admin/UserManagement/UpdateUser",
    //   icon: "active-icon",
    //   src1: update,
    //   status: "active",
    //   newpolicydisplay: "",
    //   newpolicynumber: ' ',
    //   newpolicybton: "bton",
    //   newpolicyimgsrc: pending
    // },
    {
      label: 'Change User Password',
      text: "User Passwprd Change",
      page1: 3,
      link: "/Admin/UserManagement/ChangeUserPassword",
      icon: "active-icon",
      src1: passwordChange,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Update User Status',
      text: "User Status Update",
      page1: 4,
      link: "/Admin/UserManagement/UpdateUserStatus",
      icon: "active-icon",
      src1: update,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    }
  ]

  return (
    <div className="main">
      {createTemplate.map((data, key) => {
        return (
          <Boxes
            text={data.text}
            label={data.label}
            page1={data.page1}
            link1={data.link}
            icon={data.icon}
            src1={data.src1}
            status={data.status}
            newpolicydisplay={data.newpolicydisplay}
            newpolicynumber={data.newpolicynumber}
            newpolicybton={data.newpolicybton}
            newpolicyimgsrc={data.newpolicyimgsrc}
          />
        );
      })
      }

    </div>
  );
}

export default Create;
