import React, {useEffect, useRef, useState} from "react";

import { AppBar, Toolbar, Grid, IconButton, Badge, InputBase, Typography } from "@material-ui/core";
import Decidd from "../images/decidd/Decidd2.png";
// import AVATAR from '../images/avatar01.jpg'
import AVATAR from '../images/decidd/Admin.webp'
import { ReactComponent as Bell } from "../images/svgicon/bell.svg";
import { ReactComponent as AlertTriangle } from "../images/svgicon/alert-triangle.svg";
import { ReactComponent as SearchIcon } from "../images/svgicon/search.svg";
import useStyles from './ComponentCss';
import Profile from '../Component/Profile';

export default function Header(props) {
  const classes = useStyles();
  const [inactive, setInactive] = useState(false);
  const ref = useRef();
  let userName = localStorage.getItem('userName');
  let name = localStorage.getItem('name');
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setInactive(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // function capitalizeFirstLetter(str) {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // }
  function capitalizeFirstLetter(str) {
    if(str){
    var parts = str.split(/[ .]/); 
    if (parts.length > 0) {
      return parts[0].charAt(0).toUpperCase() + parts[0].slice(1);
    } else {
      return str;
    }
  }
}
  function capitalizeFirstLetterOnly(str) {
    if(str){
      return str.charAt(0).toUpperCase();
    }
  }
  var userLeter = capitalizeFirstLetterOnly(name);
  return (

    <div className={classes.appBar} ref={ref}>

      <div className={classes.logoSvg}>
        {/* <h2>DECIDD</h2>             */}
        <img src={Decidd} alt="DECIDD" />
      </div>

      {/* <div className={classes.search} >
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
        />

      </div> */}
      <div className={classes.btnGrp}>
        {/* <IconButton aria-label="show 6 new alert">
          <Badge badgeContent={2} color="secondary">
            <AlertTriangle />
          </Badge>
        </IconButton>
        <IconButton aria-label="show 4 new notification">
          <Badge badgeContent={2} color="primary">
            <Bell />
          </Badge>
        </IconButton> */}
          <IconButton className={classes.avatarBtn} onClick={() => setInactive(!inactive)}>
            <div className={classes.avatar}>
            {userName==='admin'?<img src={AVATAR} alt="avatar"/>:<h2>{userLeter}</h2>}
            </div>
            <Typography className={classes.avatarName}>{userName!=='admin'?capitalizeFirstLetter(name):capitalizeFirstLetter(userName)}</Typography>
          </IconButton>
          <Profile inactive={inactive}
          />
      </div>
    </div>

  )
}