import * as React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from '../App/App';

const qrCodeDomain = "3.225.19.144:4277";
const ApiDomain = "149.20.187.163:8877";
// const ApiDomain = "10.206.0.59:8877";

const rootpath = "//" + ApiDomain + "/api/decidd/decidd/";
const authPath = "//" + ApiDomain + "/api/decidd/decidd/"; // for authenicated api Path


//API Login 
async function APILOGIN(data, path) {
  const apiUrl = `//${ApiDomain}/api/auth/${path}`;
  let response = await axios.post(apiUrl, data)
    .then(res => {
      console.log(res);
      return res
    })
  // console.log(response);
  return response.data;
}
//Api Resetpassword

async function APIREST(data, path, token) {
  const apiUrl = `//${ApiDomain}/api/auth/${path}`;

  // let token = localStorage.getItem("token");

  // Set the headers with the token
  const headers = {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  };

  try {
    const response = await axios.post(apiUrl, data, { headers });
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}


//get
async function APIGET(path) {
  let response = await APIAUTHGET(path);
  return response;/** */
}

//Auth Get
let responseHttp;
async function APIAUTHGET(path) {
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;
  let authorise = false;
  var config = {
    method: 'get',
    url: rootpath + path,
    headers: {
      'token': token,
      'Authorization': _authorization,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "http://localhost:3000,*",
    }
  };
  responseHttp = await axios(config)
    .then(function (_responseHttp) {
      console.log(_responseHttp);
      // console.log(JSON.stringify(_responseHttp.data));
      if (_responseHttp.data) {
        responseHttp = _responseHttp.data;
        authorise = true;
      } else {
        alert('Token Expired')
      }
      return responseHttp;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response.data) {
        if (error.response.data.code === "INVALID_TOKEN" || error.response.data.code === "EXPIRED_TOKEN" || error.response.data.code === "EMPTY_TOKEN") {
          localStorage.removeItem('token');
          ReactDOM.render(<App />, document.getElementById('root'));
        }
      }

    });
  // if (localStorage.getItem('token')) {
  // } else { window.location.href("/") }
  return responseHttp;
}

//post
function APIPOST(path, data, contextJson, callback, setErrorMsg) {

  let response = APIAUTHPOST(path, data, contextJson, callback, setErrorMsg);
  return response;
}

//Auth Post 
function APIAUTHPOST(path, _data, contextJson, callback, setErrorMsg) {
  // let {token} = React.useContext(MasterContext);
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;
  _data = JSON.stringify(_data);
  var config = {
    method: 'POST',
    url: authPath + path,
    headers: {
      'token': token,
      'authorization': _authorization,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "http://localhost:3000,*",
    },
    data: _data,
  }
  axios(config)
    .then(function (res) {
      let successPageJSON = {}
      let _name = "";
      Object.keys(contextJson).forEach((key, index) => {
        // let _object = contextJson[key];
        if (index === 0) {
          _name = String(contextJson[key].fields[0].field_value);
          console.log(contextJson[key].fields[0])
        }
        //_object.fields.forEach((field, index) => {
        if (contextJson.xioFEConfig.actionName) {
          successPageJSON = Object.assign({
            // "policyName":res.data.data.id,
            "policyName": _name,
            "actionName": "Created",
            "createLink": contextJson.xioFEConfig.createLink,
            "viewLink": contextJson.xioFEConfig.viewLink,
            "createButtonText": contextJson.xioFEConfig.createButtonText,
            "viewButtonText": contextJson.xioFEConfig.viewButtonText,
            "paragraphText": contextJson.xioFEConfig.paragraphText,
            "dataRecevied": res.data

          })
        }
        // callback(successPageJSON);
      })
      console.log(res.data);
      if (res.data && res.data.statusCode === "Success" || res.data.statusCode === "SUCCESS") {
        callback(successPageJSON);
      } else if (res.data.message === "400") {
        setErrorMsg(res.data)
      } else {
        // alert("Recevied status code "+res.status+` & Status Text is ${res.statusText}`+res.data.message+"with Code Status Code "+res.data.statusCode);
        setErrorMsg("Something went wrong " + res.data.statusCode);
      }
      return res;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response.data) {
        if (error.response.data.code === "INVALID_TOKEN" || error.response.data.code === "EXPIRED_TOKEN" || error.response.data.code === "EMPTY_TOKEN") {
          localStorage.removeItem('token');
          ReactDOM.render(<App />, document.getElementById('root'));
        }
      }
    });
}

//patch
function APIPATCH(path, data, contextJson, callback, setErrorMsg) {

  let response = APIAUTHPATCH(path, data, contextJson, callback, setErrorMsg);
  return response;

}


//Auth Patch 
function APIAUTHPATCH(path, _data, contextJson, callback, setErrorMsg) {
  // let {token} = React.useContext(MasterContext);
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;
  // _data = JSON.stringify(_data);
  var config = {
    method: 'PATCH',
    url: authPath + path,
    headers: {
      'token': token,
      'authorization': _authorization,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "http://localhost:3000,*",
    },
    data: _data,
  }
  axios(config)
    .then(function (res) {
      let successPageJSON = {}
      let _name = "";
      Object.keys(contextJson).forEach((key, index) => {
        // let _object = contextJson[key];
        if (index === 0) {
          _name = String(contextJson[key].fields[0].field_value);
          console.log(contextJson[key].fields[0])
        }
        //_object.fields.forEach((field, index) => {
        if (contextJson.xioFEConfig.actionName) {
          successPageJSON = Object.assign({
            // "policyName":res.data.data.id,
            "policyName": _name,
            "actionName": "Edited",
            "createLink": contextJson.xioFEConfig.createLink,
            "viewLink": contextJson.xioFEConfig.viewLink,
            "createButtonText": contextJson.xioFEConfig.createButtonText,
            "viewButtonText": contextJson.xioFEConfig.viewButtonText,
            "paragraphText": contextJson.xioFEConfig.paragraphText,
            "dataRecevied": res.data

          })
        }
        // callback(successPageJSON);
      })
      console.log(res.data);
      if (res.data && res.data.statusCode === "Success" || res.data.statusCode === "SUCCESS") {
        callback(successPageJSON);
      } else if (res.data.message === "400") {
        setErrorMsg(res.data)
      } else {
        setErrorMsg("Something went wrong " + res.data.statusCode);
      }
      return res;
    })
    .catch(function (error) {
      console.log(error);
    });
}

//del
function APIDEL(path, data, contextJson, callback) {
  console.log(path, contextJson, callback);
  let response = APIAUTHDEL(path, data, contextJson, callback);
  return response;
}

function APIAUTHDEL(path, _data, contextJson, callback) {
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;
  // _data = JSON.stringify(_data);
  var config = {
    method: 'DELETE',
    url: authPath + path,
    headers: {
      'token': token,
      'authorization': _authorization,
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "http://localhost:3000,*",
    },
    data: _data,
  }
  axios(config)
    .then(function (res) {
      let successPageJSON = {}
      let _name = "";
      Object.keys(contextJson).forEach((key, index) => {
        // let _object = contextJson[key];
        if (index === 0) {
          _name = String(contextJson[key].fields[0].field_value);
          console.log(contextJson[key].fields[0])
        }
        //_object.fields.forEach((field, index) => {
        if (contextJson.xioFEConfig.actionName) {
          successPageJSON = Object.assign({
            // "policyName":res.data.data.id,
            "policyName": _name,
            "actionName": "Deleted",
            "createLink": contextJson.xioFEConfig.createLink,
            "viewLink": contextJson.xioFEConfig.viewLink,
            "createButtonText": contextJson.xioFEConfig.createButtonText,
            "viewButtonText": contextJson.xioFEConfig.viewButtonText,
            "paragraphText": contextJson.xioFEConfig.paragraphText
          })
        }
        // callback(successPageJSON);
      })
      console.log(res.data);
      if (res.data && res.data.statusCode === "Success") {
        callback(successPageJSON);
      } else {
        alert("Something went wrong");
      }
      return res;
    })
    .catch(function (error) {
      console.log(error);
    });
}


function getMasterData() {
  //peId = 'peId';
  var masterDataJSON = [
    { 'path': 'department', 'businessObject': 'deparment' },
    { 'path': 'productCategory', 'businessObject': 'productCategory' },
    { 'path': 'product', 'businessObject': 'product' }
  ];
  if (masterDataJSON) {
    if (localStorage.getItem('masterData')) {
      localStorage.removeItem('masterData');
    }
    let data = {};
    masterDataJSON.map((obj) => {
      async function settingData() {
        return data[obj.path] = (await APIGET(obj.path + "/all").then(data => data.data))
      }
      settingData();
    })
    setTimeout(() => {
      // if(res){
      console.log(data);
      localStorage.setItem('masterData', JSON.stringify(data));
      // localStorage.setItem(obj.businessObject,res);
      // }
    }, 1500)
  }
  return localStorage.getItem('masterData');

}

//upload file

//Auth Post 
function APIUPLOAD(path, _data, setUploadPercentage, callback) {
  // let {token} = React.useContext(MasterContext);
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;
  // _data = JSON.stringify(_data);
  var config = {
    method: 'POST',
    url: authPath + path,
    headers: {
      // 'Content-Type': 'multipart/form-data',
      'token': token,
      'authorization': _authorization,
      "Access-Control-Allow-Origin": "http://localhost:3000,*",
    },
    onUploadProgress: progressEvent => {
      setUploadPercentage(
        parseInt(
          Math.round((progressEvent.loaded * 100) / progressEvent.total)
        )
      );
    },
    data: _data,
  }
  axios(config)
    .then(function (res) {
      console.log(res.data);
      if (res.data.data) {
        callback(res.data.data);
      } else {
        alert("Something went wrong");
      }
      // setTimeout(() => setUploadPercentage(), 2000);
      return res;
    })
    .catch(function (error) {
      console.log(error);
      // setUploadPercentage()
    });
}


//

async function UNIQUECHACK(path) {
  let token = localStorage.getItem("token");
  let _authorization = `Bearer ${token}`;

  let authorise = false;

  var config = {
    method: 'get',
    url: rootpath + path,
    headers: {
      'token': token,
      'Authorization': _authorization,
      'Content-Type': 'application/json'
    }
  };
  responseHttp = await axios(config)
    .then(function (_responseHttp) {
      console.log(_responseHttp);
      // console.log(JSON.stringify(_responseHttp.data));
      if (_responseHttp.data) {
        responseHttp = _responseHttp.data;
        authorise = true;
      } else {
        alert('Token Expired')
      }
      return responseHttp;
    })
    .catch(function (error) {
      console.log(error);
      if (error.response.data.code === "INVALID_TOKEN" || error.response.data.code === "EXPIRED_TOKEN") {
        if (localStorage.getItem('token')) {
          localStorage.removeItem('token');
          // window.history.pushState({},"", "/");   
          ReactDOM.render(<App />, document.getElementById('root'));
          // navigate("/");

        }
      }

    });
  if (localStorage.getItem('token')) {
  } else { window.location.href("/") }
  return responseHttp;
}
export { APIGET, APIPOST, APIDEL, APIPATCH, APIAUTHPOST, APILOGIN, APIREST, APIAUTHGET, APIUPLOAD, UNIQUECHACK, qrCodeDomain, ApiDomain, rootpath }