import React from "react";
import Switch from "@material-ui/core/Switch";
import useStyles from "./FormControlsCss";
export default function SwitchLabels(props) {
  const classes=useStyles();
  const { name, color, onChange,disabled, checked } = props;
 
  return (
    <div className={classes.switch}>
      <Switch 
        // color={color}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        name={name}
      />
    </div>
  );
}
