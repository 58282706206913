import React, { useContext,useEffect } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import {InputLabel} from '@material-ui/core';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import  useStyles  from './FormControlsCss';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import elementValidation from '../Component/validation.js';
import FormHelperText from '@mui/material/FormHelperText';
import copy from "copy-to-clipboard";
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import Button from './Button';
export default function PasswordInput(props) {
  const classes = useStyles();
  const { field_type, field_mandatory,field_id, field_label,field_password_length, field_password_pattern, field_SpecialCharacter, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq  } = props;
  const { handleChange } = useContext(FormContext)
  const [elements,setElements] = React.useState(field_value);
  const [showPassword,setshowPassword] = React.useState(false);
  const _length =field_password_length;
  const _chars =field_password_pattern;
   console.log(_length)


   const elementField = {field_type, field_mandatory,field_id, field_label,field_password_length, field_password_pattern, field_SpecialCharacter, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq  };
    
   function handleBlur(){
     elementValidation(elementField)
   }

   useEffect(() => {
    handleChange(field_id,{},elements);
  },[elements,field_id,handleChange]);

function passwordGenrate(){
  // e.defaultprevented;
  
 setElements(randomString(_length,_chars))
}

function handlechangepasswordGenerator(value){
    setElements(value);
    handleChange(field_id,{},value);
}
function handleClickShowPassword(){
  setshowPassword(current => !current);
}
const copyToClipboard = () => {
  copy(elements);
}
function randomString(length,chars ) {
  var mask = '';
  if (chars.indexOf('a') > -1) mask += 'abcdefghijklmnopqrstuvwxyz';
  if (chars.indexOf('A') > -1) mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  if (chars.indexOf('#') > -1) mask += '0123456789';
  if (chars.indexOf('!') > -1) mask += '~`!@#$%^&*()_+-={}[]:";\'<>?,./|\\';
  var result = '';
  for (var i = length; i > 0; --i){
    result += mask[Math.round(Math.random() * (mask.length - 1))];
  }
  return result;
} 
console.log(elements);

  return (

    <div className={classes.inputroot} >
    <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
      {/* <FormControl className={classes.password}> */}
      <OutlinedInput className={classes.input}
            //  type='password' 
            id = {field_id}
             type={showPassword===true ? 'text' : 'password'}
             value={elements}
             placeholder={field_placeholder ? field_placeholder : ''} 
             onChange={event => handlechangepasswordGenerator(event.target.value)}
             onBlur={()=>(handleBlur())}
             endAdornment={
              <InputAdornment position="end" className={classes.pbtn}>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword===true ? <Visibility /> : <VisibilityOff />}
                </IconButton>
                {elements ? <IconButton
                  aria-label="toggle password visibility"
                  onClick={copyToClipboard}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                 <ContentCopyOutlinedIcon /> 
                </IconButton>: ""}
                <Button className={classes.btn1} 
                    // ButtonName={field_btn_label}
                    ButtonName="Generate"
                  // field_disabled={field_disabled} 
                  onClick={(event)=>{event.preventDefault();passwordGenrate()}}
                />
              </InputAdornment>
            }
        />
        <FormHelperText>{field_helperText||elementField.field_helperText}</FormHelperText>
        {/* </FormControl> */}
      </div>   
      );
}

