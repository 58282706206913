import React, { useEffect, useState, useRef } from 'react';
import '../css/newFile.css'
import loader from '../images/decidd/loader.gif'
import { APIGET, APIDEL, APIPOST } from '../services/APIServices';
import { MasterContext } from '../services/MasterContext';
import { GoKebabHorizontal } from "react-icons/go";
const TData = [
  {
    id: '1',
    tabTitle: "All",
    tabContent: []
  },
  {
    id: '2',
    tabTitle: "Comments",
  },
  {
    id: '3',
    tabTitle: "History",
    tabContent: [
      {
        tabTitle: " changed the ParentMay 18, 2023 at 5:51 PM",
        tabContent: " HIGH_SEVERITY  VAPT"
      },
      {
        tabTitle: " changed the ParentMay 18, 2023 at 5:51 PM",
        tabContent: "HIGH_SEVERITY  VAPT"
      },
      {
        tabTitle: " changed the ParentMay 18, 2023 at 5:51 PM",
        tabContent: "HIGH_SEVERITY  VAPT"
      }
    ]
  }
]

const data = {
  "active": "Y",
  "approvalStatus": "PENDING",
  "createById": 1,
  "updateById": 1,
  "enterpriseId": 1,
  "insrtSessionId": 1,
  "status": "active",
};

function getTimeDifference(timestamp) {
  const now = new Date();
  // Convert the timestamp to a valid format (YYYY-MM-DDTHH:mm:ss)
  const parts = timestamp.split(" ");
  const datePart = parts[0];
  const timePart = parts[1];
  const [day, month, year] = datePart.split("-");
  const [hour, minute, second] = timePart.split(":");
  const inputDate = new Date(year, month - 1, day, hour, minute, second);

  const timeDifference = now - inputDate;

  // Calculate the number of milliseconds in different time units
  const millisecond = 1;
  const millisecondsPerSecond = millisecond * 1000;
  const millisecondsPerMinute = millisecondsPerSecond * 60;
  const millisecondsPerHour = millisecondsPerMinute * 60;
  const millisecondsPerDay = millisecondsPerHour * 24;
  const millisecondsPerWeek = millisecondsPerDay * 7;
  const millisecondsPerMonth = millisecondsPerDay * 30; // Approximation for a month
  const millisecondsPerYear = millisecondsPerDay * 365; // Approximation for a year

  if (timeDifference < millisecondsPerMinute) {
    const seconds = Math.floor(timeDifference / millisecondsPerSecond);
    return `${seconds}s`;
  } else if (timeDifference < millisecondsPerHour) {
    const minutes = Math.floor(timeDifference / millisecondsPerMinute);
    return `${minutes}m`;
  } else if (timeDifference < millisecondsPerDay) {
    const hours = Math.floor(timeDifference / millisecondsPerHour);
    return `${hours}h`;
  } else if (timeDifference < millisecondsPerWeek) {
    const days = Math.floor(timeDifference / millisecondsPerDay);
    return `${days}d`;
  } else if (timeDifference < millisecondsPerMonth) {
    const weeks = Math.floor(timeDifference / millisecondsPerWeek);
    return `${weeks}w`;
  } else if (timeDifference < millisecondsPerYear) {
    const months = Math.floor(timeDifference / millisecondsPerMonth);
    return `${months}mo`;
  } else {
    const years = Math.floor(timeDifference / millisecondsPerYear);
    return `${years}y`;
  }
}

// const timestamp = "2023-09-05T17:21:32"; // Valid timestamp format
// const timeDifferenceString = getTimeDifference(timestamp);
// console.log(timeDifferenceString); // Output: e.g., "5h" or "30s" (hours or seconds ago)



function Tab(props) {
  let { id, scroll, field_apiContextHistory, field_apiContextComments, field_flowid, field_unique, field_disabled_Edit, field_type, field_id, field_apipath, field_disabled, field_label, field_placeholder, field_value, field_options, field_helperText, field_minlength, field_maxlength, field_maxvalue, field_value_type, field_minvalue, field_error_msg_required, field_error_msg_minlength, field_error_msg_maxlength, field_error_msg_minvalue, field_error_msg_maxvalue, field_error_msg_uq, field_mandatory } = props;
  let { masterData } = React.useContext(MasterContext);
  const [comment, setComment] = useState('');
  const [minHight, setMinHight] = useState(46);
  const [open, setOpen] = useState(false);
  const [deleteResponse, setDeleteResponse] = useState();
  const [menu, setMenu] = useState(false);
  const [index, setIndex] = useState();
  const [cmOpen, setCmOpen] = useState();
  // const [fdata,setData] = useState(data);
  const [dataSource, setDataSource] = useState([]);
  const [list, setList] = useState([]);
  const apiContextComments = field_apiContextComments;
  const apiContextHistory = field_apiContextHistory;
  let userName = localStorage.getItem('userName');
  let userId = localStorage.getItem('userId')
  let name = localStorage.getItem('name');
  const ref = useRef();
  function capitalizeFirstLetterOnly(str) {
    return str.charAt(0).toUpperCase();
  }
  var userLeter = capitalizeFirstLetterOnly(name);

  const [tabData, setTabData] = useState([
    {
      id: '1',
      tabTitle: "All",
      tabContent: []
    },
    {
      id: '2',
      tabTitle: "Comments",
    },
    {
      id: '3',
      tabTitle: "History",
      tabContent: []
    }
  ]);
  const [visibleTab, setVisibleTab] = React.useState(tabData[1].id)


  // The empty dependency array ensures this effect runs only once on mount


  const updateTabContent = (response) => {
    console.log(response);
    const updatedTabData = [...tabData]; // Create a copy of tabData array
    updatedTabData[0].tabContent = response; // Update the tabContent in the copy
    setTabData(updatedTabData); // Update the state with the new tabData
  };
  // async function settingData(apiContext) {
  //     return await APIGET(apiContext + `/all`).then(data => {
  //         if (data) {
  //             setDataSource(data.data);
  //         }
  //     })
  // }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setMenu(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function settingData(apiContext) {
    return APIGET(apiContext + `/filter?${field_flowid}=${id}`).then(data => {
      if (data) {
        setDataSource(data);
        return data.data; // Return the response
      }

    });
  }
  async function postingData(data) {
    // masterData[`${apiContextVar}`] = {}
    try {
      const response = await APIPOST(apiContextComments + '/save', data);
      // setPostResponse(response.data);
      if (response) {
        // setPostResponse(response.data);
        return response.data
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
  function handledeleteResponse(res) {
    setDeleteResponse(res)
  }

  async function deleteComment(id) {
    setMenu(false)
    setIndex(id)
    try {
      const response = await APIDEL(apiContextComments + `/${id}`, {}, "null", handledeleteResponse);
      if (response) {
       
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    if (deleteResponse && deleteResponse.dataRecevied.statusCode === "Success") {
      settingData(apiContextComments)
        .then(response => {
          updateTabContent(response);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, [deleteResponse])

  const handleCommentChange = (e) => {
    setComment(e.target.value);
    data[`${apiContextComments}Label`] = e.target.value;
    // setData([...fdata, { commentLabel: e.target.value }]);
  };

  const handleSave = (event) => {
    event.preventDefault();
    masterData[`${apiContextComments}`] = {}
    data[field_flowid] = id
    data['userId'] = userId
    data['userName'] = name
    console.log(data)
    postingData(data);
    setComment('');
    data[`${apiContextComments}Label`] = ''
  };

  const handleCancel = (event) => {
    event.preventDefault();
    // Perform cancel logic here
    setComment('');
    setMinHight(46);
    setOpen(false);
  };
  const handleClick = (id) => {
    setVisibleTab(id)
    if (id === "3") {
      settingData(apiContextHistory);
    } else if (id === "1") {

      settingData(apiContextComments)
        .then(response => {
          updateTabContent(response);
        })
        .catch(error => {
          console.error(error);
        });

    }
  };


  const listTitles = tabData.map((item) =>
    <li key={item.id} onClick={() => handleClick(item.id)} className={visibleTab === item.id ? "tab-title tab-title--active" : "tab-title"}>{item.tabTitle}</li>
  )

  // const listContent = props.data.map((item) => 

  //     {visibleTab === item.id && item.tabContent.map((list)=>{
  //         console.log(list.tabTitle);
  //         <li>{list.tabTitle}</li>
  //     })}
  // )

  console.log(scroll)
  const handleOpen = () => {
    setOpen(true);
    setMinHight(120);

    setTimeout(() => {
      scroll();
    }, 100);
  };

  const listContent = tabData.map((item, index) => {
    console.log(item);
    if (visibleTab === item.id && visibleTab !== "2" && item.id !== "2") {
      return item.tabContent ? item.tabContent.map((list, _index) => (
        <div className='content' key={list.tabTitle}>
          <div className='icon-box'><span className='icon-txt icon-bg'>{capitalizeFirstLetterOnly(list['userName'])}</span></div>
          <ul>
            <li><div><span>{list['userName']}</span>.<span>{getTimeDifference(list['instdttm'])}</span></div><div className='cmtMenuIcon' onClick={() => { setCmOpen(_index); setMenu(!menu) }}><GoKebabHorizontal /></div></li>
            <li>{list[`${apiContextComments}Label`]}</li>
            {/* <li></li> */}
            <div className={cmOpen === _index && menu ? 'cmtMenuAtv' : 'cmtMenu'} onClick={() => { deleteComment(dataSource.data[_index].id) }}>
              <span>Delete</span>
            </div>
          </ul>

        </div>
      )) : null;
    } else if (item.id === "2" && visibleTab === "2") {
      return (
        <div className='content' key={item.id}>
          <div className='icon-box'><span className='icon-txt icon-bg-1'>{userLeter}</span></div>
          <div className='wd'>
            <textarea
              className={open ? 'comment-box comment-box-min ' : 'comment-box'}
              value={comment}
              onChange={handleCommentChange}
              placeholder="Enter your comment..."
              onClick={handleOpen}
              style={{ height: `${minHight}px` }}
              required
            />
            {open && (
              <div className='btn-grp'>
                <button className='btn-1' onClick={handleSave} disabled={!comment}>Save</button>
                <button className='btn-2' onClick={handleCancel}>Cancel</button>
              </div>
            )}
          </div>
        </div>
      );
    }
    return null;
  });

  // console.log(listContent)
  return (
    <div className="Tbcon">
      <p>Activity</p>
      <div className="tabs">
        <ul className="tabs-titles">
          {listTitles}
        </ul>
        <div className="tab-content">
          {/* {dataSource.length!==0&&visibleTab === "2"?listContent:<div class="horizontal-bar-wrap"><div class="bar1 bar"></div></div>} */}
          {listContent.every((item, i) => item === null) ? (

            <div class="horizontal-bar-wrap"><div class="bar1 bar"></div></div>
          ) : (
            listContent
          )}
        </div>
      </div>
    </div>
  )
}
export default Tab;