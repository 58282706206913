import React, { useContext } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import OutlinedInput from '@mui/material/OutlinedInput';
import {InputLabel} from '@material-ui/core';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import  useStyles  from './FormControlsCss';

//validation 
import elementValidation from '../Component/validation';

export default function TimePicker(props) {
  const {field_type, field_id, field_label, field_placeholder,field_mandatory, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq } = props;
  const { handleChange } = useContext(FormContext)
  const classes = useStyles(); 

  const elementField = {field_type, field_id, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
  function handleBlur(){
    // console.log(field_value)
    elementValidation(elementField)
  }
  return (

    <div className={classes.inputroot}>
    <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
     <FormControl className={classes.border}>
      <OutlinedInput className={classes.input}
        id={field_id}
        // type={field_type}
        type='time'
        defaultValue=""
        value={field_value}
        onBlur={handleBlur}
        onChange={event => handleChange(field_id,{},event.target.value)}
      />
      {/* <span>(HH/MM)</span> */}
       <FormHelperText>{field_helperText||elementField.field_helperText}</FormHelperText>
      </FormControl>
    </div>
  );
}
