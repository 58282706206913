import React,{useState,useEffect} from 'react'
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import Selects from '@mui/material/Select';
import { FormContext } from '../Component/Forms/FormContext';
import elementValidate from '../Component/validation';
import  { useContext } from  'react';
import { APIGET } from '../services/APIServices';

// import Checkbox from '@mui/material/Checkbox';
// import FormHelperText from '@mui/material/FormHelperText';

//Import master Data Context
import { MasterContext } from '../services/MasterContext'; 
 
export default function Select(props) {
  const classes = useStyles();
  const [attr,setAttr] = React.useState();
  const {id, field_type, field_disabled_Edit, field_id, field_label, field_placeholder,field_disabled, field_value,field_option_src, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
  let {masterData} = React.useContext(MasterContext);
  const { handleChange } = useContext(FormContext);
  const [fieldDisable, setFieldDisable]= useState(field_disabled);
    const [fieldOptions,setFieldOptions] = React.useState(field_options);
    async function getOptions(props) {
      try{
        if(props.field_option_src_context){
          console.log(masterData);
            if(props.field_option_check_local_storage==="true"&&(masterData[`${props.field_option_src_context}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`].length!==0)){
                      setFieldOptions(masterData[`${props.field_option_src_context}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`]);
                      console.log(masterData);
                    }
            else{
                let APIRES = await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
                (APIRES)=>{
                    // console.log(APIRES);
                    let responseArray =new Array();
                    let _i =0;
                    if(props.field_select_all_label){
                        let _obj = new Object();
                        _obj.option_label = props.field_select_all_label;
                        _obj.value = props.field_select_all_value;
                        responseArray[_i] = _obj;
                        _i++;  
                    }
                    Object.keys(APIRES.data).forEach( key => {
                        let _obj = new Object();
                        _obj.option_label = APIRES.data[key][props.field_option_label];
                        _obj.value = APIRES.data[key][props.field_option_value];
                        _obj['attributes'] = {};
                        if(field_option_src.field_option_attributes){
                          field_option_src.field_option_attributes.map((att)=>{
                            _obj['attributes'][`data_${att}`]=APIRES.data[key][att];
                          });
                        }
                        responseArray[_i] = _obj;
                        _i++; 
                    })
                    console.log(responseArray)
                    setFieldOptions(responseArray);
                    if(masterData[`${props.field_option_src_context}`&&`${props.field_option_src_context}`]){
                      if(masterData[`${props.field_option_src_context}`][`${field_id}`]){
                        masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                      }
                      else{
                        masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                        masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                      }
                    }
                    else{
                      masterData[`${props.field_option_src_context}`]={}
                      masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                      masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                    }
                    // masterData[`${field_id}_${props.field_option_src_context}_${props.field_option_src_critieria}`] = responseArray;
                }
                );            
              }                  
        }
      } catch(err){/** TODO Do Nothing */}
    }
    useEffect(()=>{
        getOptions(field_option_src);
    },[field_option_src])
    const field = {
        field_id:field_id,
        field_type:field_type,
        field_disabled:field_disabled,
        field_value:field_value,
        field_helperText:field_helperText,
        field_minlength:field_minlength,
        field_maxlength:field_maxlength,
        field_minvalue:field_minvalue,
        field_maxvalue:field_maxvalue,
        field_value_type:field_value_type,
        field_mandatory:field_mandatory,
        field_options: (fieldOptions) ? fieldOptions : field_options ,
        field_label:field_label
      }
      function handleBlur(){ 
        elementValidate(field)
      }

      function handlingChange(_id,event,value){
        // let x = event.target;
        fieldOptions.map((option)=>{
          if(option.value === value && option.attributes){
            setAttr(option.attributes);
          }
        })
        handleChange(_id,{},value);
      }

useEffect(()=>{
  if(typeof id==='number' && field_disabled_Edit==='yes' && field_value){
    setFieldDisable(true)
  }else{
    setFieldDisable(field_disabled)
  }
},[field_disabled]);

    return (
        <div className={classes.inputroot}>
            {/* <FormControl > */}
        <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
      
            
            <Selects className={classes.select}
             
                // name={name}
                id={field_id}
                {...attr}
                value={field_value}
                displayEmpty
                onChange={onChange || (event => handlingChange(field_id,event,event.target.value))}
                placeholder={field_placeholder}
                disabled={fieldDisable}
                onBlur={handleBlur}
                >
                {/* <MenuItem value="">{field_placeholder}</MenuItem> */}
                {field_placeholder?<MenuItem value="">{field_placeholder}</MenuItem>:null}

                {
                    fieldOptions.map(
                        (option,i) => ( 
                        (option.value)?
                        <MenuItem 
                                key={i} 
                                id={`${field_id}_op_${option.value}`}
                                {...option.attributes} 
                                value={option.value}
                        >
                            {option.option_label}
                        </MenuItem>
                        :
                        <MenuItem 
                            {...option.attributes}  
                            key={i}
                            value={option.option_label}
                        >
                            {option.option_label}
                        </MenuItem >
                        )
                    )
                }
            </Selects>
            <FormHelperText>{field_helperText}</FormHelperText>  
        {/* </FormControl> */}
        </div>
    )
}
