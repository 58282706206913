import * as React from 'react';
import useStyles from '../ComponentCss';
import Button from '../../FormsControls/Button';
import Element from '../../FormsControls/Element';
import { FormContext } from '../Forms/FormContext';
import CloseIcon from '@mui/icons-material/Close';
import { IoWarningOutline } from 'react-icons/io5';
import elementValidation from '../validation.js';
import delJSON from './DeleteJson.json';
import Loading from '../../images/Table/Loading.gif';
import { APIGET, APIDEL } from '../../services/APIServices';
import SuccessPage from '../SuccessPage';


export default function Delete(props) {
  console.log('Delete Componet Rerender.');
  const [delResponse, setDelResponse] = React.useState();
  const [data, setData] = React.useState();
  const [elements, setElements] = React.useState(null);
  // const delJSON = delJSON;
  const apiPath = props.apiContext.toString();
  const contextHeading = props.contextHeading;
  const [successRender, setSuccessRender] = React.useState(undefined);
  const [successPageData, setSuccessPageData] = React.useState(undefined);
  const classes = useStyles();

  React.useEffect(() => {
    setElements(delJSON.fields)

    delJSON.fields.map((field, i) => {
      (field.field_value = "")
    })
  }, [])

  //delete==>    
  const { index, dataArr } = props;

  React.useEffect(() => {
    let APIRES = APIGET(apiPath + `/${dataArr[index].id}`).then(res => {
      if (res) {
        setData(res.data)
      }
    })
  }, [])

  const { fields } = elements ?? {}

  //handle change==>
  const handleChange = (id, event, valueStr) => {
    const newElements = { ...elements }
    delJSON.fields.forEach(field => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          case 'checkbox':
            field['field_value'] = event.target.checked;
            break;

          default:
            field['field_value'] = valueStr;
            break;
        }


      }
      setElements(newElements);
    });
  }

  function deleteSuccessPage(props) {
    setSuccessPageData(props)
    setSuccessRender(1);
    console.log('Success Rander is Rendering..')
  }

  //Handle Deletion ===>
  const handleDeletion = () => {
    let validData = true;

    delJSON.fields.map((field, index) => {
      if (!elementValidation(field)) {
        validData = false;
      }
      // validData = elementValidation(field);
    });
    if ((delJSON.fields[1].field_value === "Delete" || delJSON.fields[1].field_value === "delete") && validData) {
      props.onDelete(delJSON.fields[0].field_value, deleteSuccessPage);
    }
    else {
      delJSON.fields[1].field_helperText = "You have enter 'Delete'";
      setDelResponse(delJSON.fields);
    }
    // delJSON.fields[0].field_helperText=delJSON.fields[0].field_error_msg_uq;
  }
  const handleCancel = () => {
    // delJSON.fields[0].field_value=undefined;
    // delJSON.fields[1].field_value=undefined;
    delJSON.fields.map((field, index) => {
      delJSON.fields[index].field_helperText = undefined;
      delJSON.fields[index].field_value = undefined;
    });

    props.close();
  }
  console.log(data)
  if (data) {
    return (
      <>
        <div className={classes.closeup}>
            <h4>Permanently Delete</h4>
          <CloseIcon onClick={() => (handleCancel())} />
        </div>
        {(successRender && successPageData) ?
          <SuccessPage
            flowName={contextHeading}
            actionName={successPageData.actionName}
            createButtonText={successPageData.createButtonText}
            viewButtonText={successPageData.viewButtonText}
            paragraphText={successPageData.paragraphText}
          />
          :
          <div className={classes.preview}>
            <div className={classes.warningSvg}>
              <IoWarningOutline />
              <p>Warning:You cannot undo the action. The following will be delete forever</p>
            </div>
            <FormContext.Provider value={{ handleChange }}>
              <div className='App containers'>
                {delJSON.fields ? delJSON.fields.map((field, i) => <Element key={i} field={field} />) : null}
              </div>
            </FormContext.Provider>
            <div className={classes.ctaNxtPrv}>
              <Button className={classes.btnRset} ButtonName="Cancel" onClick={() => (handleCancel())} />
              <Button className={classes.btnRej} ButtonName="Delete" onClick={() => (handleDeletion())} />
            </div>
          </div>
        }
      </>
    )
  } else {
    return <div className={classes.loading}><img src={Loading} alt="Loading..." /><CloseIcon onClick={() => (handleCancel())} /></div>
  }
}

