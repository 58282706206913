// import React from "react";
// import "../../css/Login.css";
import logo from "../../images/decidd/Decidd.png";
import gtslogo from "../../images/decidd/gtslogo.png";
// // import login from "../../images/decidd/login.png";
// import login from "../../images/decidd/logingts.png";
// //import master context
// import Snackbar from '@mui/material/Snackbar';
// import Alert from '@mui/material/Alert';
// import {
//     Routes,
//     Route,
//     useNavigate
// } from "react-router-dom";
// import SignUpPage from "../../modules/Login/SignUpPage";
// import ResetPassword from "../../modules/Login/ResetPassword";
// import ForgetPassword from "../../modules/Login/ForgetPassword";
// import { APILOGIN ,APIGET} from "../../services/APIServices";

// const LoginPageRoutes = (props) => {

//     return (
//         <Routes>
//             <Route path="/" element={<LoginPageComponent funcLogin={props.funcLogin} />} />
//             <Route path="/forgetPassword" element={<ForgetPassword funcLogin={props.funcLogin} />} />
//             <Route path="/login" element={<LoginPageComponent funcLogin={props.funcLogin} />} />
//             <Route path="/signUp" element={<SignUpPage funcLogin={props.funcLogin} />} />
//             <Route path="/home" element={<LoginPageComponent funcLogin={props.funcLogin} />} />
//             <Route path="*" element={<LoginPageComponent funcLogin={props.funcLogin} />} />
//             <Route path="/api/auth/resetPassword" element={<ResetPassword funcLogin={props.funcLogin} />} />
//         </Routes>
//     );
// }

// function LoginPageComponent(props) {
//     let navigate = useNavigate();


//     const forgetPasswordRoute = () => {
//         navigate("/forgetPassword");
//     }
//     const loginRoute = () => {
//         navigate("/login");
//     }
//     // const signUpRoute = () => {
//     //     navigate("/signUp");
//     // }

//     const [username, setUsername] = React.useState('');
//     const [password, setPassword] = React.useState('');
//     const [remember, setRemember] = React.useState(false);
//     let lData = {};

//     React.useEffect(() => {
//         const usernameR = localStorage.getItem('usernameR');
//         const passwordR = localStorage.getItem('passwordR');
//         const remember = localStorage.getItem('remember');
//         if (remember === 'true') {
//             setUsername(usernameR);
//             setPassword(passwordR);
//             setRemember(true);
//         }
//     },[]);

//     React.useEffect(() => {
//         lData = { username, password, remember };
//     }, [username, password, remember]);

//     const handleUserChange = event => {
//         setUsername(event.target.value);
//     };

//     const handlePassChange = event => {
//         setPassword(event.target.value);
//     };

//     const handleChange = event => {
//         setRemember(current => !current);
//     };
//     return (
//         <><div className="login-container">
//             <div className="df jcc ais">
//                 <div className="imageLeft">
//                     <div className="toplogo">
//                         <img src={logo} alt="logo" className="logoAnimate" />
//                     </div>

//                     {/* <h5>Consent &amp; Preference Management Platform</h5> */}
//                   <div className="imgBox">
//                         <img className="loginGtsImg" src={gtslogo} alt="GTS" />
//                         <img className="loginImg" src={login} alt="decidd" />
//                         </div>

//                 </div>
//                 <div className="formRight ">
//                     <div className="login-tabs">
//                         <div className="buttons">
//                             <button className="tab-1 active" onClick={loginRoute}>
//                                 Login
//                             </button>
//                             {/* <button className="tab-2" onClick={signUpRoute}>
//                                 SignUp
//                             </button> */}
//                         </div>
//                     </div>
//                     <div className="wrap jcc df">
//                         <div className="form_wrapper content-1 active">
//                             <form method="post">
//                                 <div className="login_input">
//                                     <label htmlFor="">User Name/Email Id</label>
//                                     <input type="email" id="username" name="username" autoComplete="off" value={username} defaultValue={username} onChange={handleUserChange} />
//                                 </div>
//                                 <div className="login_input">
//                                     <label htmlFor="">Password</label>
//                                     <input type="password" id="password" name="password" value={password} defaultValue={password} autoComplete="new-password" onChange={handlePassChange} />
//                                 </div>
//                                 <div className="login_chx">
//                                     <input type="checkbox" id="Remember" className="checkbx" name="Remember" defaultValue="" onChange={handleChange} checked={remember} />
//                                     <span>Remember me?</span>
//                                 </div>
//                                 <div className="btn_wrap">
//                                     <button className="login_btn" onClick={(e) => {
//                                         e.preventDefault();
//                                         props.funcLogin(lData)
//                                     }}>Login</button>
//                                     <p className="forget_pswd">
//                                         <button
//                                             name="forgetPassword"
//                                             onClick={forgetPasswordRoute}
//                                         >
//                                             Forget Password?
//                                         </button>
//                                     </p>

//                                 </div>
//                             </form>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//         </div></>
//     );
// }


// function LoginPage(props) {
//     const [loginMsg, setLoginMsg] = React.useState();
//     const [state, setState] = React.useState({
//         open: false,
//         vertical: 'top',
//         horizontal: 'center',
//         msgType: ''
//     });
//     const ApiContext = "login"
//     const { vertical, horizontal, open, msgType } = state;

//     const handleClick = (newState) => {
//         setState({ open: true, ...newState });
//     };

//     const handleClose = () => {
//         setState({ ...state, open: false });
//     };
//     async function funcLogin(data) {
//         // localStorage.setItem("token", data.password);
//         // props.setToken(() => (data.password))
//         //remember username and password 
//         if (data.remember === true) {

//             localStorage.setItem("usernameR", data.username);
//             localStorage.setItem("passwordR", data.password);
//             localStorage.setItem("remember", data.remember);
//         } else {
//             localStorage.removeItem("usernameR");
//             localStorage.removeItem("passwordR");
//             localStorage.removeItem("remember");
//         }

//         // APILOGIN();

//         // let response;
//         let resData = await APILOGIN(data, ApiContext)
//             .then(res => {
//                 // response = res;
//                 // console.log(res);
//                 return res
//             })
//             .catch(err => {
//                 setLoginMsg("Login Failed ! Please Re Check Your creadentials.");
               
//                 handleClick({
//                     vertical: 'top',
//                     horizontal: 'center',
//                     msgType: 'error'
//                 });
//             })

//         if (resData) {
//             handleClick({
//                 vertical: 'top',
//                 horizontal: 'center',
//                 msgType: 'success'
//             });
//             setLoginMsg("Logged In");
//             if (localStorage.getItem('token')) {
//                 localStorage.removeItem('token');
//                 localStorage.setItem("token", resData.data.token);
//                 localStorage.setItem("userName", resData.data.username);
//                 if(resData.data.username==="admin"){
//                     localStorage.setItem("name", resData.data.username);
//                 }else{
//                     localStorage.setItem("name", resData.data.name);
//                 }
//                 localStorage.setItem("userId", resData.data.id);
//             }
//             else {
//                 localStorage.setItem("token", resData.data.token);
//                 localStorage.setItem("userName", resData.data.username);
//                 if(resData.data.username==="admin"){
//                     localStorage.setItem("name", resData.data.username);
//                 }else{
//                     localStorage.setItem("name", resData.data.name);
//                 }
//                 localStorage.setItem("userId", resData.data.id);
//             }
//             props.setToken(() => (resData.data.token))
//             // fetchData(resData.data.username);
//         }
//         // funcSetToken(resData.data.token);    
             
//     }

//         async function fetchData(userName) {
//           try {
//             const response = await APIGET(`userDetails/filter?userDetailsLabel=${userName}`);
//             if (response) {
//             //   setUserId(response.id);
//               localStorage.setItem("userId", response.data[0].id)
//               console.log(response.data[0].id)
//               // Handle other data as needed
//             }
//           } catch (error) {
//             // Handle errors
//             console.error("Error fetching data:", error);
//           }
//         }
    
//     return (
//         <React.Fragment>
//             <Snackbar
//                 anchorOrigin={{ vertical, horizontal }}
//                 open={open}
//                 onClose={() => { handleClose() }}
//                 autoHideDuration={4000}
//                 // message={loginMsg}
//                 key={vertical + horizontal}
//             >
//                 <Alert severity={msgType}>{loginMsg}</Alert>
//             </Snackbar>
//             <LoginPageRoutes funcLogin={funcLogin} />
//         </React.Fragment>
//     );

// }

// export default LoginPage;

import React, { useState, useEffect } from "react";
import "../../css/Login.css";
import login from "../../images/decidd/logingts.png";
import {
    Routes,
    Route,
    useNavigate
} from "react-router-dom";
import SignUpPage from "../../modules/Login/SignUpPage";
import ResetPassword from "../../modules/Login/ResetPassword";
import ForgetPassword from "../../modules/Login/ForgetPassword";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { APILOGIN } from "../../services/APIServices";
import { encrypt, decrypt } from "../../services/Encrypt&Decrypt";

const LoginPage = (props) => {
    const [loginMsg, setLoginMsg] = useState();
    const [state, setState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        msgType: ''
    });
    const ApiContext = "login"
    const { vertical, horizontal, open, msgType } = state;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [remember, setRemember] = useState(false);
    let lData = {};
    const navigate = useNavigate();

    useEffect(() => {
        const usernameR = localStorage.getItem('usernameR');
        const passwordR = localStorage.getItem('passwordR');
        const remember = localStorage.getItem('remember');
        if (remember === 'true') {
            setUsername(usernameR);
            setPassword(passwordR);
            // setPassword(decrypt(passwordR));
            setRemember(true);
        } 
    }, []);

    const handleUserChange = event => {
        setUsername(event.target.value);
    };

    const handlePassChange = event => {
        setPassword(event.target.value);
    };

    const handleChange = event => {
        setRemember(current => !current);
    };

    const forgetPasswordRoute = () => {
        navigate("/forgetPassword");
    }

    const loginRoute = () => {
        navigate("/login");
    }

    const handleClick = (newState) => {
        setState({ open: true, ...newState });
    };

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    async function funcLogin() {
        //remember username and password 
       const data = { username, password, remember }
        if (data.remember === true) {
            localStorage.setItem("usernameR", data.username);
            localStorage.setItem("passwordR", data.password);
            // localStorage.setItem("passwordR", encrypt(data.password));
            localStorage.setItem("remember", data.remember);
        } else {
            localStorage.removeItem("usernameR");
            localStorage.removeItem("passwordR");
            localStorage.removeItem("remember");
        }

        // APILOGIN();

        // let response;
        let resData = await APILOGIN(data, ApiContext)
            .then(res => {
                // response = res;
                // console.log(res);
                return res
            })
            .catch(err => {
                setLoginMsg("Login Failed ! Please Re Check Your credentials.");

                handleClick({
                    vertical: 'top',
                    horizontal: 'center',
                    msgType: 'error'
                });
            })

        if (resData) {
            handleClick({
                vertical: 'top',
                horizontal: 'center',
                msgType: 'success'
            });
            setLoginMsg("Logged In");
            if (localStorage.getItem('token')) {
                localStorage.removeItem('token');
                localStorage.setItem("token", resData.data.token);
                localStorage.setItem("userName", resData.data.username);
                if(resData.data.username==="admin"){
                    localStorage.setItem("name", resData.data.username);
                }else{
                    localStorage.setItem("name", resData.data.name);
                }
                localStorage.setItem("userId", resData.data.id);
            }
            else {
                localStorage.setItem("token", resData.data.token);
                localStorage.setItem("userName", resData.data.username);
                if(resData.data.username==="admin"){
                    localStorage.setItem("name", resData.data.username);
                }else{
                    localStorage.setItem("name", resData.data.name);
                }
                localStorage.setItem("userId", resData.data.id);
            }
            props.setToken(() => (resData.data.token))
        }       
    }

    return (
        <React.Fragment>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                onClose={() => { handleClose() }}
                autoHideDuration={4000}
            >
                <Alert severity={msgType}>{loginMsg}</Alert>
            </Snackbar>
            <div className="login-container">
            <div className="df jcc ais">
                <div className="imageLeft">
                    <div className="toplogo">
                        <img src={logo} alt="logo" className="logoAnimate" />
                    </div>

                    {/* <h5>Consent &amp; Preference Management Platform</h5> */}
                  <div className="imgBox">
                        <img className="loginGtsImg" src={gtslogo} alt="GTS" />
                        <img className="loginImg" src={login} alt="decidd" />
                        </div>

                </div>
                <div className="formRight ">
                    <div className="login-tabs">
                        <div className="buttons">
                            <button className="tab-1 active" onClick={loginRoute}>
                                Login
                            </button>
                            {/* <button className="tab-2" onClick={signUpRoute}>
                                SignUp
                            </button> */}
                        </div>
                    </div>
                    <div className="wrap jcc df">
                        <div className="form_wrapper content-1 active">
                            <form method="post">
                                <div className="login_input">
                                    <label htmlFor="">User Name/Email Id</label>
                                    <input type="email" id="username" name="username" autoComplete="off" value={username} defaultValue={username} onChange={handleUserChange} />
                                </div>
                                <div className="login_input">
                                    <label htmlFor="">Password</label>
                                    <input type="password" id="password" name="password" value={password} defaultValue={password} autoComplete="new-password" onChange={handlePassChange} />
                                </div>
                                <div className="login_chx">
                                    <input type="checkbox" id="Remember" className="checkbx" name="Remember" defaultValue="" onChange={handleChange} checked={remember} />
                                    <span>Remember me?</span>
                                </div>
                                <div className="btn_wrap">
                                    <button className="login_btn" onClick={(e) => {
                                        e.preventDefault();
                                        funcLogin()
                                    }}>Login</button>
                                    <p className="forget_pswd">
                                        <button
                                            name="forgetPassword"
                                            onClick={forgetPasswordRoute}
                                        >
                                            Forget Password?
                                        </button>
                                    </p>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </React.Fragment>
    );
}

// export default LoginPage;

const LoginPageRoutes = (props) => {
    return (
        <Routes>
            <Route path="/" element={<LoginPage setToken={props.setToken}/>} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/login" element={<LoginPage setToken={props.setToken}/>} />
            <Route path="/signUp" element={<SignUpPage />} />
            <Route path="/home" element={<LoginPage setToken={props.setToken}/>} />
            <Route path="*" element={<LoginPage setToken={props.setToken}/>} />
            <Route path="/api/auth/resetPassword" element={<ResetPassword />} />
        </Routes>
    );
}

export default LoginPageRoutes;