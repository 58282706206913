import React from "react";
import SideMenu from "../../Component/SideMenu";
import Subheader from "../../Component/Subheader";
import Header from "../../Component/Header";
import MainSection from "../MainSection";
import                   '../../css/card.css';
import { createTheme, ThemeProvider } from "@material-ui/core";
import { Suspense } from "react";

const theme = createTheme({
  palette:{
    primary:{
      main:'#385C8C',
      light:'#237DA7',
    },
    secondary:{
      main:'#D12469',
      light:'#E7679A',
    },
    text:{
      primary: 'rgba(5, 21, 45, 0.87)',
      secondary: 'rgba(5, 21, 45, 0.54)',
      disabled: 'rgba(5, 21, 45, 0.38)',
      hint: 'rgba(5, 21, 45, 0.38)',
      divider: 'rgba(5, 21, 45, 0.12)',

    },

  },
  typography:{
    fontFamily: '"Poppins", sans-serif',
    fontSize: 12.25,
    h1:{
      fontFamily: '"Poppins", sans-serif',
    },
    h2:{
      fontFamily: '"Poppins", sans-serif',
    },
    h3:{
      fontFamily: '"Poppins", sans-serif',
      fontSize:16,
      fontWeight:600,
    },
    h4:{
      fontFamily: '"Poppins", sans-serif',
    },
    h5:{
      fontFamily: '"Poppins", sans-serif',
    },
    h6:{
      fontFamily: '"Poppins", sans-serif',
    },
  }

})

const styles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: 'calc(100% - 256px)',
  marginLeft: '256px',
  position: 'fixed',
  top: 0,
  left: 0,
  // backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: '#a9b6b8',
  fontSize: '2rem',
};

function HomePage() {
    return (
        <ThemeProvider theme={theme}>
            {/* <Header/> */}
            <SideMenu></SideMenu>
             {/* <Subheader></Subheader> */}
           
                <MainSection/>
                 {/* <Suspense fallback={<div style={styles}>Loading....</div>}>
                  
                    <MainSection/>

                  </Suspense> */}
        </ThemeProvider>
    );
}


export default HomePage;