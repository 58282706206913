
import * as React from 'react';
import TextField from '@mui/material/TextField';
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import  useStyles  from './FormControlsCss';
import { FormContext } from '../Component/Forms/FormContext';
import  { useContext } from  'react';
export default function SearchableSelectBox(props) {

  const {field_type,field_mandatory, field_id, field_label, field_placeholder, field_value,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq ,field_options,onChange} = props;
  const { handleChange } = useContext(FormContext)
  const classes = useStyles();
  return (
    <div className={classes.inputroot}> 
    <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
    <Autocomplete  className={classes.input}
                disablePortal
                id="sp"
                options= {field_options.map((option) =>option.option_label)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField placeholder="Search..." value={field_value} {...params} />}
            />
            <FormHelperText>{field_helperText}</FormHelperText>
    </div>
  );
}
