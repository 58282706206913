const CryptoJS = require('crypto-js');
const passphrase = 'd6afa53-ravi-4c0f6fbf';

// Function to encrypt a plaintext with a passphrase
function encrypt(plaintext) {
  const encrypted = CryptoJS.AES.encrypt(plaintext, passphrase);
  return encrypted.toString();
}

// Function to decrypt ciphertext with the same passphrase
function decrypt(ciphertext) {
  const decrypted = CryptoJS.AES.decrypt(ciphertext, passphrase);
  if (decrypted) {
    return decrypted.toString(CryptoJS.enc.Utf8);
  } else {
    return null;
  }
}

module.exports = {
  encrypt,
  decrypt,
  passphrase,
};
