import React, { useContext } from 'react'
import TimePicker from './Time'

import  useStyles  from './FormControlsCss';

export default function TimeStartToEnd() {
  const classes = useStyles();
  return (

    <div className={classes.timeInputGrp}>
     <TimePicker className={classes.timeInput} field_label='Starting Time'/>
     <h2>To</h2>
     <TimePicker className={classes.timeInput} field_label='End Time'/>
    </div>
  );
}
