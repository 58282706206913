import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/decidd/Decidd.png";
// import login from "../../images/decidd/login.png";
import gtslogo from "../../images/decidd/gtslogo.png";
import login from "../../images/decidd/logingts.png";
import { APILOGIN,APIREST } from "../../services/APIServices";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { MdOutlineVisibilityOff,MdOutlineVisibility } from "react-icons/md";
import "../../css/Login.css";
import { passwordStrength } from 'check-password-strength'


function ResetPassword() {
  const [loginMsg, setLoginMsg] = React.useState();
  const [succesMsg, setSuccesMsg] = useState("");
  const [data, setData] = React.useState({});
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMsgCfm, setErrorMsgCfm] = useState("");
  const [open, setOpen] = React.useState(false);
  const [showPassword,setshowPassword] = React.useState(false);
  const [restToken,setRestToken] = React.useState(null);
  const [showCfmPassword,setshowCfmPassword] = React.useState(false);
  let navigate = useNavigate();
console.log(Boolean(restToken))
  // window.history.back(window.history.length-2);
// console.log(window.history)
  const loginRoute = () => {
    navigate("/");
  };
 
  useEffect(()=>{
    const currentURL = new URL(window.location.href);
    const token = currentURL.searchParams.get("token");
    setRestToken(token);     
    console.log(token); 
      
  },[])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  function handleClickShowPassword(){
    setshowPassword(current => !current);
  }
  function handleClickShowCfmPassword(){
    setshowCfmPassword(current => !current);
  }

  function validatePassword(password, setErrorMsg, setIsValid) {

    if(password){
      setErrorMsg("Password strength is "+passwordStrength(password).value);
      setIsValid(false);

      if (!/[A-Z]/.test(password)) {
        setErrorMsg("Password should contain at least one uppercase letter.");
        setIsValid(false);
        return;
      }
    
      if (!/[a-z]/.test(password)) {
        setErrorMsg("Password should contain at least one lowercase letter.");
        setIsValid(false);
        return;
      }
      if (!/[!@#$%^&*]/.test(password)) {
        setErrorMsg("Password should have at least one special symbol.");
        setIsValid(false);
        return;
      }
      if (!/[0-9]/.test(password)) {
        setErrorMsg("Password should contain at least one digit.");
        setIsValid(false);
        return;
      }
      if (password.length < 8) {
        setErrorMsg("Password should be at least 8 characters long.");
        setIsValid(false);
        return;
      }
      if(passwordConfirm){
        if (password !== passwordConfirm) {
          setErrorMsgCfm("Password do not match.");
          setIsValid(false);
          return;
        }else{
          setErrorMsgCfm("");
          setIsValid(true);
         }
       }
    }else{
      // setErrorMsg("");
      // setIsValid(false);
      setErrorMsg("Password is required.");
      setIsValid(false);
      return;
    }
   
    // if (passwordStrength(password).value === "Strong" || passwordStrength(password).value === "Medium") {
    //   setErrorMsg("Password strength is "+passwordStrength(password).value);
    //   setIsValid(true);
    // }
  }
  
    function validationPasswordConfiram(password,passwordConfirm,setIsValid ){
       if(passwordConfirm){
        if (password !== passwordConfirm) {
          setErrorMsgCfm("Password do not match.");
          setIsValid(false);
          return;
        }else{
          setErrorMsgCfm("");
          setIsValid(false);
         }
       }
    if (passwordStrength(password).value === "Strong" || passwordStrength(password).value === "Medium") {
      setErrorMsgCfm("");
      setIsValid(true);
    }
    
    }
  

  function handlePasswordChange(e, field) {
    setPassword(e.target.value);
    setData(prevState => ({ ...prevState, [field]: e.target.value }));
    // setIsValid(true)
    validatePassword(e.target.value, setErrorMsg, setIsValid)
    
  }

  function handlePasswordConfirmChange(e, field) {
    setPasswordConfirm(e.target.value);
    // setData(prevState => ({ ...prevState, [field]: e.target.value }));
    validationPasswordConfiram(password,e.target.value, setIsValid)
  
  }

  async function funcResetPassword(data) {
    if (data.password && data.password === passwordConfirm) {
      setIsValid(true)
      const ApiContext = "resetPassword";
      let resData = await APIREST(data, ApiContext, restToken)
        .then(res => {
          return res;
        })
        .catch(err => {
          setLoginMsg("Failed to reset the password. Please try again later.");
          setOpen(true)
        });

      if (resData) {
        // Password reset successful, redirect to the login page   
        setPassword("")
        setPasswordConfirm("")
        setErrorMsg("")
        setSuccesMsg("Password has been reset successfully.");
        setTimeout(loginRoute, 3000);
      
      }
    } else {
      setIsValid(false)
      if (!data.password) {
        setErrorMsg('Please enter a password.')
      } else if (!passwordConfirm) {
        setErrorMsgCfm('Please enter a confirm password.')
      } else if (data.password !== passwordConfirm) {
        setErrorMsgCfm("Passwords do not match.");
      }
    }
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => { handleClose(); }}
        // message={loginMsg}
        autoHideDuration={4000}
        severity="error"
      >
      <Alert severity="error">{loginMsg}</Alert>
       </Snackbar> 

      <div className="login-container">
        <div className="df jcc ais">
          <div className="imageLeft">
            {/* <h2>CONSENTICA</h2> */}
            <div className="toplogo">
                        <img src={logo} alt="logo" className="logoAnimate" />
                    </div>
            {/* <h5>Consent &amp; Preference Management Platform</h5> */}
            <div className="imgBox">
                        <img className="loginGtsImg" src={gtslogo} alt="GTS" />
                        <img className="loginImg" src={login} alt="decidd" />
                        </div>
          </div>
          <div className="formRight ">
            <div className="login-tabs">
              <div className="buttons">
                <button className="tab-1 active">
                  Reset Password
                </button>
                {/* <button className="tab-2" onClick={signUpRoute}>
                                SignUp
                            </button> */}
              </div>
            </div>
            <div className="wrap jcc df">
              <div className="form_wrapper content-1 active">
                <form method="post">
                {Boolean(succesMsg)?<p className="termsg">{succesMsg}</p>:<p className="terms">Enter your new password below, we're just being extra safe</p>}
                  <div className="login_input">
                    <label htmlFor="password">New Password</label>
                    <input type={showPassword===true ? 'text' : 'password'} id="password" name="password" value={password} onChange={(event) => handlePasswordChange(event, 'password')} placeholder="Password"/>
                    <div className="pass" onClick={handleClickShowPassword}>{showPassword===true ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}</div>
                    {Boolean(errorMsg) && <p className={passwordStrength(password).value==="Strong"? "strMsg" : passwordStrength(password).value === "Medium" ? "medMsg" : "errorMsg"}>{errorMsg}</p>}
                  </div>
                  <div className="login_input">
                    <label htmlFor="password">Confirm Password</label>
                    <input type={showCfmPassword===true ? 'text' : 'password'} id="confirmPassword" name="confirmPassword" value={passwordConfirm} onChange={(event) => handlePasswordConfirmChange(event, 'passwordConfirm')} placeholder="Confirm Password"/>
                    <div className="pass" onClick={handleClickShowCfmPassword}>{showCfmPassword===true ? <MdOutlineVisibility /> : <MdOutlineVisibilityOff />}</div>
                    {!isValid && <p className="errorMsg">{errorMsgCfm}</p>}
                  </div>
                  <div className="btn_wrap">
                    <button className="login_btn" onClick={(e) => {
                      e.preventDefault();
                      funcResetPassword(data)
                    }} disabled={!isValid}>Reset Password</button>
                  </div>
                </form>
              </div>
            </div>

          </div>

        </div>

      </div></>


  );
}
export default ResetPassword;