import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import useStyles from './FormControlsCss';
import Chip from '@mui/material/Chip';
import { FormContext } from '../Component/Forms/FormContext';
import { FormControl, FormHelperText, InputLabel, MenuItem } from '@material-ui/core';
import elementValidate from '../Component/validation';
import { APIGET } from '../services/APIServices';
import { IoCloseOutline } from 'react-icons/io5';

//Import master Data Context
import { MasterContext } from '../services/MasterContext';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// const names = [
//   'Oliver Hansen',
//   'Van Henry',
//   'April Tucker',
//   'Ralph Hubbard',
//   'Omar Alexander',
//   'Carlos Abbott',
//   'Miriam Wagner',
//   'Bradley Wilkerson',
//   'Virginia Andrews',
//   'Kelly Snyder',
// ];

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

export default function MultipleSelectChip(props) {
  const classes = useStyles();
  let { field_type, field_id, field_label, field_placeholder, field_disabled, field_value, field_options, field_option_src, field_helperText, field_minlength, field_maxlength, field_maxvalue, field_value_type, field_minvalue, field_error_msg_required, field_error_msg_minlength, field_error_msg_maxlength, field_error_msg_minvalue, field_error_msg_maxvalue, field_error_msg_uq, field_mandatory } = props;
  let filedValueArray = new Array();
  let { masterData } = React.useContext(MasterContext);
  const [fieldOptions, setFieldOptions] = React.useState(field_options);
  const [close, setClose] = React.useState(false);
  const [placeholder, setPlaceholder] = React.useState('Multiple Select');
  const [fieldLabel, setFieldLabel] = React.useState(props.field_label);
  async function getOptions(props) {
    try {
      if (props.field_option_src_context) {
        // if(false){
        if (props.field_option_check_local_storage === "true" && (masterData[`${props.field_option_src_context}`] && masterData[`${props.field_option_src_context}`][`${field_id}`] && masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`])) {
          setFieldOptions(masterData[`${props.field_option_src_context}`] && masterData[`${props.field_option_src_context}`][`${field_id}`] && masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`]);
          console.log(masterData[`${props.field_option_src_context}`]);
        }
        else {
          let src_criteria = "";
          if (props.field_option_src_critieria === "filter?ids=All") {
            src_criteria = "all";
          } else {
            src_criteria = props.field_option_src_critieria;
          }
          let APIRES = await APIGET(props.field_option_src_context + `/` + src_criteria).then(
            (APIRES) => {
              console.log(APIRES);
              let responseArray = new Array();
              let _i = 0;
              if (props.field_select_all_label) {
                let _obj = new Object();
                _obj.option_label = props.field_select_all_label;
                _obj.value = props.field_select_all_value;
                responseArray[_i] = _obj;
                _i++;
              }
              Object.keys(APIRES.data).forEach(key => {
                let _obj = new Object();
                _obj.option_label = APIRES.data[key][props.field_option_label];
                _obj.value = APIRES.data[key][props.field_option_value];
                responseArray[_i] = _obj;
                _i++;
              })
              //fieldOptions = responseArray; 
              setFieldOptions(responseArray);
              if (masterData[`${props.field_option_src_context}` && `${props.field_option_src_context}`]) {
                if (masterData[`${props.field_option_src_context}`][`${field_id}`]) {
                  masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                }
                else {
                  masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                  masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                }
              }
              else {
                masterData[`${props.field_option_src_context}`] = {}
                masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
              }
            }
          );
        }
      }
    } catch (err) {/** TODO Do Nothing */ }
  }
  useEffect(() => {
    getOptions(field_option_src);
  }, [props])
  console.log(fieldOptions);
  // if(typeof(field_value)==="String"){
  //   console.log(`Field Value type id ${typeof(field_value)}`)
  //   field_value = field_value.Split(",")
  // }
  //filedValueArray = field_value.split(",");


  ///------->unComment This.
  // function getSelect(elementString){
  //   return elementString === "Select";
  // }
  // let inputArr =  field_value;
  // if(typeof(field_value) === "string")
  //   inputArr = field_value.split(",");
  // else{
  //   inputArr = field_value;
  // }
  // let tempArr = new Array();
  // inputArr.forEach(element => {
  //   if(!(tempArr.length > 0) ){
  //     tempArr.push(element)
  //   }else{
  //     let index = tempArr.indexOf(element)
  //     if(index === -1)
  //       tempArr.push(element)
  //     else
  //       tempArr.splice(tempArr.indexOf(element), 1);
  //   }
  // });
  // field_value =  Array.from(new Set(tempArr)).join(",");

  // if(field_value  && typeof(field_value)==="string" && (field_value.indexOf('All')>=0 || (field_option_src && field_option_src.field_select_all_label && field_value.indexOf(field_option_src.field_select_all_value) >= 0 ) )){
  //   if((field_option_src && field_option_src.field_select_all_label && field_value.indexOf(field_option_src.field_select_all_value) >= 0 )){
  //     field_value = field_option_src.field_select_all_value;
  //   }else{
  //     field_value = "All";
  //   }
  //   filedValueArray = field_value.split(",");
  //   if(filedValueArray.length > 0 )
  //     //alert("Please unselect All for other selection");
  //     field_label = field_label + ":: Please Unselect "+(field_option_src.field_select_all_label?field_option_src.field_select_all_label:"All")+" for other selection";

  // } else if(field_value  && typeof(field_value)!=="string"){
  //   field_value = field_value.toString();
  // }else if(field_value===""||field_value==="Select"){
  //   field_value = "Select";
  // }else if(typeof(field_value) === "undefined"){
  //   field_value = "Select";
  // }else if(field_value  && typeof(field_value)==="string" ){
  //   filedValueArray = field_value.split(",");
  //   let selectIndexes;

  //   if(filedValueArray.length > 0){
  //      selectIndexes = filedValueArray.findIndex(getSelect);
  //     if(selectIndexes > -1)
  //       filedValueArray.splice(filedValueArray.findIndex(getSelect), 1);
  //       field_value = filedValueArray.join(",")
  //   }
  // }

  const field = {
    field_id: field_id,
    field_type: field_type,
    field_value: field_value,
    field_helperText: field_helperText,
    field_minlength: field_minlength,
    field_maxlength: field_maxlength,
    field_minvalue: field_minvalue,
    field_maxvalue: field_maxvalue,
    field_value_type: field_value_type,
    field_mandatory: field_mandatory,
    field_label: field_label
  }



  function handleBlur() {
    elementValidate(field);
  }

  const { handleChange } = React.useContext(FormContext)
  const onChange = (event) => {
    // console.log(typeof(field_value))
    // console.log(filedValueArray);
    const {
      target: { value },
    } = event;
    handleChange(field_id, event, filedValueArray)
  };

  // const handleClickSelect = () =>{
  //   console.log(`Handle Click is triggred and ${field_value} is the field Value.`);
  //   props.field_value = "Select";
  // }

  // const handleClickAll = ()=>{
  //   console.log(`Handle Click All is Clicked and field Value id ${field_value}`);
  //   props.field_value = "All";
  // }
  const setLabel = (str) => {
    setFieldLabel(str);
  }
  useEffect(() => {
    if (field_value) {
      setPlaceholder('')
    } else {
      setPlaceholder('Multiple Select')
    }
  }, [field_value]);

  const handleClickMenuItem = (event, value) => {
    setPlaceholder('')
    let _tempValue = String(value);
    // console.log(event.currentTarget.attributes);
    // console.log(event.target);
    if (_tempValue) {
      let _tempArr = field_value.split(",");
      // alert(_tempArr.indexOf("All"))
      // alert(_tempArr.indexOf(_tempValue))

      if (_tempArr.indexOf(_tempValue) >= 0) {
        _tempArr.splice(_tempArr.indexOf(_tempValue), 1);
        
      } else {
        if (_tempValue === "All" || _tempValue === "Global") {
              _tempArr = [String(_tempValue)];
        }else{
          if(_tempArr.includes("All")){
            _tempArr = [String(_tempValue)];
          }else{
            _tempArr.push(String(_tempValue));
          }
        }
      }
  
      // if ((_tempArr.indexOf("All") >= 0 || _tempArr.indexOf("Global") >= 0)) {
      //   if (_tempValue === "All" || _tempValue === "Global") {
      //     _tempArr = [String(_tempValue)];
      //     alert("1")
      //     setLabel(props.field_label + ":: Please Unselect " + (field_option_src.field_select_all_label ? field_option_src.field_select_all_label : "All") + " for other selection");
      //   } else {
      //     if (_tempArr.indexOf(_tempValue) >= 0) {
      //       alert("2")
      //       _tempArr.splice(_tempArr.indexOf(_tempValue), 1);
      
      //     } else {
      //       _tempArr.push(String(_tempValue));
      //       alert("3")
      //     }
      //     setLabel(props.field_label);
      //   }
      // } else { 
      //   if (_tempArr.indexOf(_tempValue) >= 0) {
      //     _tempArr.splice(_tempArr.indexOf(_tempValue), 1);
      //     setLabel(props.field_label);
      //   }
      // }


      if (_tempArr.indexOf('Select') >= 0 && _tempArr.indexOf('') >= 0) {
        _tempArr.indexOf('') >= 0 ? _tempArr.splice(_tempArr.indexOf(''), 1) : _tempArr.splice(_tempArr.indexOf('Select'), 1);
      }
      field_value = _tempArr.join(',');
      filedValueArray = field_value.split(",");
      handleChange(field_id, event, filedValueArray)
    }
    return null;
  }

  if (field_value && field_value != null && field_value.length > 0) {
    filedValueArray = field_value.split(",");
    // if(filedValueArray.indexOf('Select')>=0&&filedValueArray.indexOf('')>=0&&filedValueArray.indexOf(undefined)>=0){
    //   filedValueArray.indexOf('')||filedValueArray.indexOf(undefined)>=0?filedValueArray=[]:filedValueArray.splice(filedValueArray.indexOf('Select'), 1);
    // }
  }

  const handleDelete = (event, value) => {
    handleClickMenuItem(event, value)
  };

  return (
    <div className={classes.inputroot} >

      <div style={{ display: "flex" }}>
        <InputLabel className={classes.label}>{fieldLabel}</InputLabel>{field_mandatory === "yes" ? <span
          style={{
            fontSize: "18px",
            color: "#d71729",
            fontWeight: "400",
            lineHeight: "20px",
            transform: "translateY(-2px)"
          }}>*</span> : null}
      </div>
      <FormControl variant="outlined" className={classes.border}>
        <span style={{ position: "absolute", top: '12px', left: '17px', opacity: '0.6' }}>{placeholder}</span>
        <Select className={classes.input}
          labelId="demo-multiple-chip-label"
          id={field_id}
          // native={true}
          // onClick={()=>{setClose(true)}}
          onOpen={() => setClose(true)}
          onClose={() => setClose(false)}
          open={close}
          multiple
          value={filedValueArray}
          onChange={(event) => { onChange(event) }}
          onBlur={handleBlur}
          disabled={field_disabled}
          // input={<OutlinedInput id="select-multiple-chip" label="Chip" />} 
          renderValue={(selected) => (
            <>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  fieldOptions.map((name) => (
                    String(name.value) === value ? <Chip key={value} value={value} label={name.option_label} onClick={(event) => { handleDelete(event, name.value) }} /> : ""
                  ))
                ))}
              </Box>

            </>
          )}
        // MenuProps={MenuProps}
        >
          {/* <MenuItem value="0">MultipleSelect</MenuItem> */}
          {fieldOptions.map((name) => (
            <MenuItem
              key={name.option_label}
              value={name.value}
              tabInd
              onClick={(event) => { handleClickMenuItem(event, name.value) }}
            // style={getStyles(name, personName, theme)}
            // selected={filedValueArray.indexOf(name.value)>=0}
            >
              {name.option_label}

            </MenuItem>

          ))}
        </Select>


        <FormHelperText>{field_helperText}</FormHelperText>
      </FormControl>
      {close === true ? <div className={classes.closeMultiSelcet} onClick={() => { setClose(current => !current) }}><IoCloseOutline /></div> : null}
    </div>
  );
}

