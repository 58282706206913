import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/decidd/Decidd.png";
// import login from "../../images/decidd/login.png";
import gtslogo from "../../images/decidd/gtslogo.png";
import login from "../../images/decidd/logingts.png";
import mail from "../../images/mail.png";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import "../../css/Login.css";
import { APILOGIN } from "../../services/APIServices";

function ForgetPassword(props) {
  const [loginMsg, setLoginMsg] = React.useState();
  const [data, setData] = React.useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [succesMsg, setSuccesMsg] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [open, setOpen] = React.useState(false);

  let navigate = useNavigate();
  const loginRoute = () => {
    navigate("/login");
  };

  const resetpasswordRoute = () => {
    navigate("/resetPassword");
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleUserChange = event => {
    setData({ emailId: event.target.value });
    const emailInput = event.target.value;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(regex.test(emailInput));
    setErrorMsg('Please enter a valid email address.')
  };

  async function funcForgotPassword(data) {

    if (data.emailId) {
      // let response;
      const ApiContext = "forgotPassword";
      let resData = await APILOGIN(data, ApiContext)
        .then(res => {
          // response = res;
          // console.log(res);
          return res;
        })
        .catch(err => {
          setData({ emailId:""});
          setLoginMsg("Failed to request password reset. Please try again later.");
          setOpen(true)
        });

      if (resData) {
        if(resData.code==="NOT_FOUND"){
          setOpen(true)
          setLoginMsg("Your Email Id not Registered.");
        }else if(resData.code==="SUCCESS"){
          setSuccesMsg("Password Reset Link Send Successfully in Your Email.");
        }
        // Password reset successful, redirect to the login page   
        setData({ emailId:""});
        // resetpasswordRoute()
      }
    } else {
      setIsValidEmail(!true)
      setErrorMsg('Please enter a email address.')
    }

  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={() => { handleClose(); }}
        // message={loginMsg}
        // key={vertical + horizontal}
        autoHideDuration={4000}
      >
        <Alert severity="error">{loginMsg}</Alert>
       </Snackbar> 
      <div className="login-container">
        <div className="df jcc ais">
          <div className="imageLeft">
          <div className="toplogo">
                        <img src={logo} alt="logo" className="logoAnimate" />
                    </div>
            {/* <h5>Consent &amp; Preference Management Platform</h5> */}
            <div className="imgBox">
                        <img className="loginGtsImg" src={gtslogo} alt="GTS" />
                        <img className="loginImg" src={login} alt="decidd" />
                        </div>
          </div>
          <hr />
          <div className="formRight">
            <div className="login-tabs">
              <div className="buttons">
                <button className="tab-1 active">Forget Password</button>
              </div>
            </div>
            <div className="wrap jcc df">
              <div className="form_wrapper content-1 active">
                {Boolean(succesMsg)?<p className="termsg">{succesMsg}</p>:<p className="terms">Enter your email below to your password reset instructions</p>}
                <form>
                  <div className="login_input pr ">
                    <label htmlFor="email">Email Id</label>
                    <input type="text" id="mail" name="mail" value={data.emailId} onChange={handleUserChange} placeholder="Enter Registered Email Id"/>
                    <img src={mail} alt="mail" className="mail" />
                    {!isValidEmail && <p className="errorMsg">{errorMsg}</p>}
                  </div>

                  <div className="btn_wrap">
                    <button className="login_btn" type="action" onClick={(e) => {
                      e.preventDefault();
                      funcForgotPassword(data)
                    }} disabled={!isValidEmail}>Send</button>
                  </div>
                </form>
                <div className="btn_wrap">
                  {/* <img src={arrowleft} alt="" className="" /> */}
                  <span className="back_btn forget_pswd" >
                    <button onClick={loginRoute}>Back to Login Page</button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div></>
  );
}
export default ForgetPassword;