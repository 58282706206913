import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
import DatePicker from "./DatePicker";
import Button from "./Button";
import SwitchLabels from "./Switchlabel";
import MultilineText from "./MultilineText";
import PasswordInput from "./PasswordInput";
import MultipleSelect from "./MultipleSelectBox";
import SearchableSelectBox from "./SearchableSelectBox";
// import Custom from "./CustomField/CustomField";
import TimePicker from "./Time";
import TimeStartToEnd from "./TimeStartToEnd";
import DateToDate from "./DateToDate";
import TextContent from "./TextContent";
import QRCode from './QRCode';
import QRCodeUpload from './QRCodeUpload';
import ImageUpload from './ImageUpload';
import InputWithButton from './InputWithButton';
import MultilineTextWithButton from './MultilineTextWithButton';
import Formio from '../FormsControls/Formio/FormioApp';
import PreviewBox from "./Formio/PreviewBox";
import Confirmation from './Confirmation';
import FormBrack from './FormBrack';
import RequiredMsg from './RequiredMsg';
import Heading from './Heading';
import DragFileUpload from './DragFileUpload';
import Customer360Search from './Customer360Search';
import Customer360Select from './Customer360Select';
import CheckBoxList from "./CheckBoxList";
import UploadFile from "./UploadFile";
import UploadFileExcel from "./UploadFileExcel";
import DragDrapSelect from "./DragDropSelect/DragDropSelect";
import ScrubRule from "./ScrubRule";
import ButtonSelect from "./ButtonSelect";
import BtnGroup from "./ButtonGroup";
import ActivitySection from "./ActivitySection";
const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    DatePicker,
    Button,
    SwitchLabels,
    MultilineText,
    PasswordInput,
    MultipleSelect,
    SearchableSelectBox,
    // Custom,
    TimePicker,
    TimeStartToEnd,
    TextContent,
    QRCode,
    QRCodeUpload,
    ImageUpload,
    InputWithButton,
    MultilineTextWithButton,
    Formio,
    PreviewBox,
    DateToDate,
    Confirmation,
    FormBrack,
    DragFileUpload,
    Customer360Search,
    Customer360Select,
    CheckBoxList,
    UploadFile,
    DragDrapSelect,
    Heading,
    ScrubRule,
    ButtonSelect,
    BtnGroup,
    UploadFileExcel,
    RequiredMsg,
    ActivitySection
}

export default Controls;