import React, { useEffect, useState } from "react";

import { FormJSONCreateContext } from "../FormJSONCreateContext";
//import contextJson from './smsPolicy.json'; 

import PolicyImg    from "../PolicyImg";
import useStyles    from '../ComponentCss';
import FormSection  from "../../FormsControls/FormSection";
import { useNavigate } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import FormScopeDefiner from "./FormScopeDefiner";
import {APIGET} from '../../services/APIServices';
import Loading from '../../images/Table/Loading.gif';

function EditForm(params) {
    const apiContextVar = params.apiContextVar;
    const contextJson = params.contextJson;
    const contextHeading = params.contextHeading;
    const formFieldsJson = params.formFieldsJson;
    const contextClasses = "";
    const contextObjective = "update";
    const close = params.close;
    const apiPath = params.apiPath;
    const contextIndex = params.index;
    const [contextDataArr,setContextDataArr] = React.useState(params.dataArr);
    const [editData,setEditData] = useState();
    const [editFOpen, setEditFOpen] = useState(false);
    const classes=useStyles();
   const [successRender,setSuccessRender] = React.useState(undefined);
    const [successPageData,setSuccessPageData] = React.useState(undefined);
  
    //for navigation 
    let navigate = useNavigate();
  console.log(contextDataArr)
  React.useEffect(()=>{
    async function settingData(){
      return await APIGET(apiContextVar+`/${params.dataArr[contextIndex].id}`).then(data=>{
        if (data) {
          setEditData(data.data)
          setEditFOpen(true)
        }
      })
      }
    settingData();
  },[])

  
  //Opening Edit Diloge Page====> 
  useEffect(()=>{
    console.log(formFieldsJson);
    if(contextIndex!==undefined){
        console.log(editData);
        if(editData){
            Object.keys(formFieldsJson).forEach((key,index) =>{
              //let _object = formFieldsJson[key];
              if(formFieldsJson[key].fields)
              formFieldsJson[key].fields.map((field) => 
                { 
                  field.field_value= editData[field.field_id];
                  field.id = editData['id'];
                  if(field.id){
                    field.id = editData['id'];
                    console.log(field.id)
                  }
                })
            })
        }
    }
},[contextIndex,editData])

  //On Success Create Policy....>
    function renderSuccessPage(props){
      setSuccessPageData(props);
      setSuccessRender(1);
      console.log('Success Render is Rendering...')
  
    }
  
   return (
    <>
     <div className={classes.close}>
      <CloseIcon onClick={params.close}/>
      <h4>Edit {contextHeading}</h4>
      </div>
     <div  className={classes.preview}>
     {editFOpen?<div className={classes.mainEdit}>
            <FormScopeDefiner value={{renderSuccessPage,editData,close,contextJson,apiContextVar,contextHeading,contextClasses,contextObjective,contextIndex,contextDataArr}}></FormScopeDefiner>
        </div>:<div className={classes.loader}><img src={Loading} alt="Loading..." /></div>}
      </div>
    </>
    );
  }
  
  export default EditForm;
  