import React, {useState, useEffect} from 'react';
import {Form} from '@formio/react';
import { makeStyles } from '@material-ui/core/styles';
import RetreiveForm from './Components/RetriveFormdata';
import {  Link } from "react-router-dom";
import FormioButton from './FormioButton';

import { FormJSONCreateContext } from '../../Component/FormJSONCreateContext';

import { createTheme, ThemeProvider  } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {      
      main: '#1E6DAA',
    },
    secondary: {
      light: '#8EAD26',
      main: '#8EAD26',
      contrastText: '#fff',
    },
    warning: {
      light: '#E49217',
      main: '#E49217',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
    },
    info: {
      main: '#8AA6A5',
	  contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});

const aa = makeStyles({
  previewContainer:{
    width:'100%',
    maxWidth:'1140px',
    margin:'0 auto 40px',
  },
  ctaGroup:{
    width:'100%',
    display: 'flex',
    alignItems:'center', 
    justifyContent:'center',
    marginTop:'32px',
    '& button':{
      minWidth:104,
      margin:'0 8px',
      borderRadius:2,
    }
  },
  previewBox :{
    display: 'flex',
    flex:'0 0 60%',
    flexWrap:'wrap',
    maxWidth: '60%',
    backgroundColor: '#fff',
    borderRight:'1px solid #d4e0eb',
    padding:'24px',
    '& > div:first-of-type':{
        width:'100%',
    },
    '& .form-group.formio-component':{
      marginBottom:16
    },
    '& .form-group.formio-component .form-control':{
      maxWidth:'400px'
    },
    
  },
  testFormBtn:{
    display:'flex',
    justifyContent:'center',
    flex:'0 0 100%',
    maxWidth:'100%',
    marginTop:'32px',
    '& button':{
      backgroundColor: 'transparent',
      border:'1px solid #98A3A3',
      color:'#05152D',
      borderRadius:24,
      minWidth:'96px',
      margin:' 0 8px',
      padding: '4px 8px 2px',
      boxShadow:'none',
      fontSize: '13px',
      '&:hover':{
        color:'#05152D',
        backgroundColor: 'transparent',
        textDecoration:'none',  
      },
    },
    '& > button:first-of-type':{
      borderColor:'#d12469',
      color:'#d12469',
      '&:hover':{
        backgroundColor:'#d12469', 
        color:'#fff', 
        
      },
    },
    '& > button:last-of-type':{
      borderColor:'#2A9F45',
      color:'#2A9F45',
      '&:hover':{
        backgroundColor:'#2A9F45', 
        color:'#fff',  
      },
    },
  },
  formDisplayGrp:{
    display:'flex',    
    backgroundColor: '#fff',
    alignItems:'flex-start',    
    border:'1px solid #bfc6c9',
    borderTop:'none',    
    borderRadius:'0 0 8px 8px',
    overflow: 'hidden',

  },
  formValueSubmit:{
    flex:'0 0 40%',
    maxWidth:'40%',
    padding:'24px',
    '& > div':{
      display:'flex',
      flexWrap:'wrap',
      alignItems: 'flex-start',
      flexDirection: 'column',
      border: '1px solid #c8d3d9',
      padding: '8px 12px',
      borderRadius: '4px',
      marginBottom: '16px'
    } ,
    '& h6':{
      color: '#4B8ABB',
      fontWeight: '300',
      marginBottom: '4px',
    }, 
    '& p':{
      marginBottom: '0px',
    width: '100%',
    backgroundColor: '#ebf0f1',
    border: '1px solid #d7e2e6',
    padding: '2px 8px',
    minHeight: '32px',
    borderRadius: '2px',
    wordBreak:'break-all'
    },
  },
  formDisplayHeaderGrp:{
    display:'flex', 
    padding:'8px 24px',
    borderRadius:'8px 8px 0 0',
    marginTop:'24px',
    border:'1px solid #bfc6c9',
    borderBottom:'none',
    backgroundColor:'#bfc6c9',
    '& > div:first-of-type':{      
      flex:'0 0 60%',
      maxWidth:'60%',
    },
    '& > div:last-of-type':{
      flex:'0 0 40%',
      maxWidth:'40%',
      padding:'0 24px',
    },
  },
 
  
})

let fieldIndex =undefined;
let fieldkey = undefined;
var fieldkey1 ;
var fieldIndex1 ;

export default function PreviewBox( {FormData ,setPrvBoxHandler}) {
	const {contextJson} = React.useContext(FormJSONCreateContext);
  const [formData, setFormData] = useState({});
  const [formInputData, setFormInputData] = useState({});



  useEffect(() => {

    Object.keys(contextJson).forEach((key)=>{
			if(key!=="xioFEConfig"){
        contextJson[key].fields.forEach((field,index)=>{
          if(field.field_type==="formIO"){
            fieldkey = key;
            fieldIndex = index;
          }else if(field.field_type==="previewBox"){
            fieldkey1 = key
            fieldIndex1 = index
          }
        })
      }})

    const fetchCurrentForm = () => {
        let listData = localStorage.getItem(contextJson[fieldkey].fields[fieldIndex].field_id);
        listData = listData?JSON.parse(listData):{}
        let inputObj = {};
        console.log(listData)
        listData.components.map((item,index)=>{
          inputObj[`${item.key}`]=""; 
        })
        setFormInputData(inputObj);
        setFormData(listData);
			  contextJson[fieldkey].fields[fieldIndex].field_value = listData;
        contextJson[fieldkey1].fields[fieldIndex1].field_value = listData;
    }

    fetchCurrentForm();
  }, []);
    const classes = aa();
  return (

    <ThemeProvider theme={theme}>
    <div className={classes.previewContainer}>
    <div className={classes.formDisplayHeaderGrp}> 
      <div className={classes.formViewHeader}>
          <h6>Form View</h6>
      </div>
      <div className={classes.formValueHeader}>
        <h6>Value Submitted</h6>
      </div>
    </div>
    <div className={classes.formDisplayGrp}> 

      <div className={classes.previewBox}>
          
        <Form form={formData} onChange={(value)=>{
          
          let newFormInputData = {...value.data}
          setFormInputData(newFormInputData)     
          console.log(formData)   
        }}
        />
        
        <div className={classes.testFormBtn}>
            <FormioButton 
            color="info" 
            label="Cancel" 					
            handleClick={ () => {
              localStorage.setItem('currentForm', JSON.stringify(formData));
            }}/>
           
          <RetreiveForm className={classes.testFormBtnSubmit} formData={formData} setFormData={()=>{}} setGuid={()=>{}}/>
        </div>
         
      </div>
       
      <div className={classes.formValueSubmit}>
          {formInputData&&Object.keys(formInputData).map((item,index)=>{
            return (
              <div  key={"value-"+index}>
              <h6 >{item}</h6> <p>{formInputData[item]}</p>
              
              </div>
              
            )
          })}
        </div>    
          
    </div>
    {/* <div className={classes.ctaGroup}>
    <Link to="/" className={classes.prevButton}>
      <FormioButton color="info" label="Back"/>	
    </Link>
    <Link to="/" className={classes.prevButton}>
        <FormioButton 
        color="primary" 
        label="NEXT" 					
       />
      </Link>	
    <Link to="/LocalStoreageList">      					
      <FormioButton color="success" label="View List" />
    </Link>
    </div>*/}
 

    </div>
    </ThemeProvider>
  );
}