import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ToggleButton from '@mui/material/ToggleButton';
import  useStyles  from './FormControlsCss';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const options = ['Policy Parameter', 'Preference Parameter', 'Consent Validity Stage','Consent Collection'];


export default function ButtonWithSelectList(props) {
  const classes = useStyles();
  let { id,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
  const { handleChange } = useContext(FormContext)
// const count=0;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [disabledIndex, setDisabledIndex] = React.useState([]);

  const handleClick = () => {
    // console.info(`You clicked ${options[selectedIndex]}`)
      props.addParameter(options[selectedIndex])
      setSelectedIndex();
      setDisabledIndex([...disabledIndex,selectedIndex])
  };

  const handleMenuItemClick = (event,index) => {
    setSelectedIndex(index);
    // setDisabledIndex([...disabledIndex,index])
    setOpen(false);
  };
// console.log(disabledIndex[0])
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    // setOpen(current => !current);
  };

  const handleClose = () => {
    setOpen(false);
  };
// console.log(options[selectedIndex])
// console.log(selectedIndex)
  return (
    <div className={classes.btnwslt}>
      <ButtonGroup variant="">
        <Button
          size="small"
          // aria-controls={open ? 'split-button-menu' : undefined}
          // aria-expanded={open ? 'true' : undefined}
          // aria-label="select merge strategy"
          // aria-haspopup="menu"
          onClick={handleClick}
        >
        <AddOutlinedIcon/>
        </Button>
        <Button onClick={handleToggle}><div>{options[selectedIndex]}</div><ArrowDropDownIcon /></Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 100001,
        }}
        open={open}
        // anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {/* {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          > */}
            <Paper >
              <div onClick={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      disabled={index === disabledIndex[0] || index=== disabledIndex[1] || index=== disabledIndex[2] || index=== disabledIndex[3]}
                      // selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </div>
            </Paper>
          {/* </Grow>
        )} */}
      </Popper>
    </div>
  );
}

