import React from 'react'
// import Checkbox from './Checkbox';
// import Input from './Input';
// import Select from './Select';

// import Controls from '../../FormsControls/Control'
import {ApiDomain,rootpath} from '../services/APIServices';

let i = 0;
let j = 0;

function isNum(str)
{
	var string = str + '';
	var flag=1;
	for (var i=0;i < string.length;i++)
	{
		if (((string.substring(i,i+1) < '0') || (string.substring(i,i+1) > '9')) && (string.substring(i,i+1) != "."))
		return false;
	}
	return true;
}

function isInt(string)
{
	var flag=1;
	for (var i=0;i < string.length;i++)
	{
		if ((string.substring(i,i+1) < '0') || (string.substring(i,i+1) > '9'))
		{
			return false;
		}
	}
	return true;
}
function isAlphanum(string)
{
	var invalidchar = new Array('#','\'','"','\\','/','!','@','$','%','^','&','*','?','.',':','~','`','(',')','-','_','+','=','{','}','[',']','|','<','>',',',';');
	return validate_characters(string,invalidchar);
}
function isDescription(string)
{
	var invalidchar = new Array('\'','"','\\','!','$','%','^','&','*',':','~','`','(',')','=','{','}','[',']','|','<','>',';');
	return validate_characters(string,invalidchar);
}
function isName(string)
{	var invalidchar = new Array('#','"','\\','!','@','$','%','^','&','*','?',':','~','`','(',')','_','+','=','{','}','[',']','|','<','>',';','1','2','3','4','5','6','7','8','9','0');
return validate_characters(string,invalidchar);
}
function isSpecialCharacter(string)
{
    var invalidchar = new Array('#','"','\\','!','@','$','%','^','&','*','?',':','~','`','+','=','{','}','[',']','|','<','>',';');
return validate_characters(string,invalidchar);
}
function isCompanyName(string)
{
	var invalidchar = new Array('#','"','!','@','$','%','^','&','*','?',':','~','`','_','+','=','{','}','[',']','|','<','>',';','\'');
	return validate_characters(string,invalidchar);
}
function isPropertyName(string)
{
	var invalidchar = new Array('#','\\','!','@','$','%','^','*','?',':','~','`','_','+','=','{','}','[',']','|','<','>');
	return validate_characters(string,invalidchar);
}
function isNumText(string)
{
	var invalidchar = new Array('\'','"','\\','!','@','$','%','^','&','*','?',':','~','`','(',')','_','+','=','{','}','[',']','|','<','>');
	return validate_characters(string,invalidchar);
}
function isUsername(username)
{
	var invalidchar = new Array('#','\'','"','\\','/',' ','!','$','%','^','&','*','?','@');
	return validate_characters(username,invalidchar);
}
function isAddress(string)
{
	var invalidchar = new Array('\\','!','@','$','%','^','*','?','~','`','_','+','=','{','}','[',']','|','<','>');
		return validate_characters(string,invalidchar);
}
function isCompany_Url(string)
{
	string=string.toLowerCase();
	if((string.substr(0,4))!= "http")
	string="http://"+string;
	var RegExp=/^(((ht|f)tp(s?))\:\/\/)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?$/
	//	var RegExp = /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(([0-9]{1,5})?\/.*)?$/
	var str = RegExp.test(string);
	if(RegExp.test(string)){
		return true;
	}
	else
	return false;
}

function isEmail(str)
{
	var regex = /^[-_.a-z0-9]+@(([-_a-z0-9]+\.)+(ad|ae|aero|af|ag|ai|al|am|an|ao|aq|ar|arpa|as|at|au|aw|az|ba|bb|bd|be|bf|bg|bh|bi|biz|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|com|coop|cr|cs|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|edu|ee|eg|eh|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gh|gi|gl|gm|gn|gov|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|in|info|int|io|iq|ir|is|it|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|mg|mh|mil|mk|ml|mm|mn|mo|mobi|mp|mq|mr|ms|mt|mu|museum|mv|mw|mx|my|mz|na|name|nc|ne|net|nf|ng|ni|nl|no|np|nr|nt|nu|nz|om|org|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|pro|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)|(([0-9][0-9]?|[0-1][0-9][0-9]|[2][0-4][0-9]|[2][5][0-5])\.){3}([0-9][0-9]?|[0-1][0-9][0-9]|[2][0-4][0-9]|[2][5][0-5]))$/i;
	return regex.test(str);
}
function checkemail(emailadd)
{
	if (emailadd.length>50)
	return false;
	else if (emailadd.length<8)
	return false;
	else
	return isEmail(trim(emailadd));
}
function trim(inputString)
{

	if (typeof inputString != "string") { return inputString; }
	var retValue = inputString;
	var ch = retValue.substring(0, 1);
	while (ch == " ")
	{
		retValue = retValue.substring(1, retValue.length);
		ch = retValue.substring(0, 1);
	}
	ch = retValue.substring(retValue.length-1, retValue.length);
	while (ch == " ")
	{
		retValue = retValue.substring(0, retValue.length-1);
		ch = retValue.substring(retValue.length-1, retValue.length);
	}
	while (retValue.indexOf("  ") != -1)
	{
		retValue = retValue.substring(0, retValue.indexOf("  ")) + retValue.substring(retValue.indexOf("  ")+1, retValue.length);
	}
	return retValue;
}
function strlen(str)
{
	return str.length;
}
/* string : the string to be validated
invalidchar : array of invalid characters
*/
function validate_characters(string,invalidchar)
{	var invalid=true;
    if(string.length > 0 && ! Array.isArray(string)){
        for(i=0;i<string.length;i++)
        {
            for(j=0;j<invalidchar.length;j++)
            {
                if (string.charAt(i) === invalidchar[j])
                {
                    invalid=false;
                    break;
                }
            }
        }

        if(string.charAt(0)=='.' || string.charAt(0)=='\'')
        invalid=false;
    }
    return invalid;
}
 




///---------------------->
function checkForMinLength(field_value,field_minlength){
    let length= strlen(field_value)
    if(length >= field_minlength){
        return true
    }
    else{
        return false
    }
}

function checkForMaxLength(field_value,field_maxlength){
    let length = strlen(field_value);
    if (length <= field_maxlength){
        return true
    }else{
        return false
    }
}

function checkForMaxValue(field_value,field_maxValue){
    if(parseInt(field_value)<=parseInt(field_maxValue)){
        return true
    }else{
        return false
    }
}

function checkForMinValue(field_value,field_minValue){
    if(parseInt(field_value)>=parseInt(field_minValue)){
        return true
    }else{
        return false
    }
}
function validateMobileNumber(number) {
    const re = /^[0-9]{10}$/;
    return re.test(number);
  }

function checkForValueType(field_value,field_value_type){
    switch (field_value_type){
        case "Email":
            return (checkemail(field_value))
        case "Mobile":
            return (validateMobileNumber(field_value))
        case "Interger":
            return (isInt(field_value))
        case "Number":
            return (isNum(field_value))
        case "AlphaNum":
            return (isAlphanum(field_value))
        case "Name":
            return (isName(field_value))
        case "SpecialCharacter":
            return (isSpecialCharacter(field_value))
        case "CompanyName":
            return (isCompanyName(field_value))
        case "Description":
            return (isDescription(field_value))
        case "PropertyName":
            return (isPropertyName(field_value))
        case "NumText":
            return (isNumText(field_value))
        case "UserName":
            return (isUsername(field_value))
        case "Address":
            return (isAddress(field_value))
        case "Url":
            return (isCompany_Url(field_value))
        default:
            return true;  
    }
}


export default function elementValidation  (field){
    // const [dataSource, setDataSource]= React.useState();
    // const ApiDomain = "52.7.232.133:8877";
    // const rootpath = "//"+ApiDomain+"/api/policy/cerf/"
    field['field_helperText']= "";
    var return_val = true;
    let field_value=field.field_value;
    let field_apipath=field.field_apipath;
    // const field_mandatory =field.field_mandatory
    const field_type =field.field_type
    const field_label = field.field_label
    const field_minlength = field.field_minlength;
    const field_maxlength = field.field_maxlength;
    const field_minValue = field.field_minvalue;
    const field_maxValue = field.field_maxvalue;
    const field_value_type = field.field_value_type;
    let setDataSource
    // function mandatoryValidation
    const mandatoryValidation=()=>{
        if(!(field_value)){
            if(field.field_error_msg_required !== undefined && field.field_error_msg_required !== "" ){
                field['field_helperText']=field.field_error_msg_required;
                return_val=false;
            }
            else{
                field['field_helperText']=`Please provide ${field.field_label}`
                return_val=false;
            }
        }
    }
   
    function UNIQUECHACK(path) {
        const token = localStorage.getItem('token');
        const _authorization = `Bearer ${token}`;
        const xhr = new XMLHttpRequest();
        xhr.open('GET', rootpath + path, false); // set third parameter to false for synchronous request
        xhr.setRequestHeader('token', token);
        xhr.setRequestHeader('Authorization', _authorization);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Accept','application/json');
        xhr.send();
      
        if (xhr.status === 200) {
          const data = JSON.parse(xhr.responseText);
          return data;
        } else if (xhr.status === 401 || xhr.status === 403) {
          // handle unauthorized or forbidden requests
          if (localStorage.getItem('token')) {
            localStorage.removeItem('token');
            window.location.reload();
          }
        } else {
          throw new Error(`Request failed with status ${xhr.status}`);
        }
      }
    
    function  myCheckUnique(_field){

        const res = checkUnique(_field)
            // return_val=res;
            if (res) {
                return_val=true;   
            } else {
                field['field_helperText'] = _field.field_helperText
                return_val=false;
            }
    
         }

     function checkUnique() {
        let fieldValue = field_value;
        let apiUrl = field_apipath;
      
        if (field.id) {
          apiUrl += `${fieldValue}/${field.id}`;
        } else {
          apiUrl += field_value;
        }
      
        try {
          const response = UNIQUECHACK(apiUrl);
          if (response.statusCode === "NOT_FOUND") {
            // Field value is unique
            return true;
          } else {
            // Field value already exists
            const errorMessage = field.field_error_msg_uq || `This ${field.field_label} already exists`;
            field.field_helperText = errorMessage;
            return false;
          }
        } catch (error) {
          // Handle error
          console.error(error);
          return false;
        }
      }
      

    //function min-max Validation 
    const validationMinMaxLength=()=>{
        if(field_minlength){
            if(!checkForMinLength(field_value,field_minlength)){
                if(field.field_error_msg_minlength.length > 0){
                    field['field_helperText'] = field.field_error_msg_minlength;
                    // console.log()
                    return_val=false;
                }else{
                    field['field_helperText'] = `too short for ${field.field_label}`;
                    return_val=false;
                    
                }
            }
        }
        if(return_val && field_maxlength){
            if(!checkForMaxLength(field_value,field_maxlength)){
                if(field.field_error_msg_maxlength.length>0){
                    field['field_helperText'] = field.field_error_msg_maxlength;
                    return_val=false;
                }else {
                    field['field_helperText'] = `Limit of Maximum value length required is ${field_maxlength} .`;
                    return_val=false;
                }
            }
        }
    }

    //function min-max_value
    const validtionMinMaxValue=()=>{
        if(field_maxValue){
            if(!checkForMaxValue(field_value,field_maxValue)){
                if(field.field_error_msg_maxvalue>0){
                    field['field_helperText'] = field.field_error_msg_maxvalue;
                    return_val=false;
                }else{
                    field['field_helperText'] = `Maximum value is set to ${field_maxValue}`;
                    return_val=false;
                }
            }
        }
        if( return_val && (field_minValue||field_minValue==0)){
            if(!checkForMinValue(field_value,field_minValue)){
                if(field.field_error_msg_minvalue){
                    field['field_helperText']=field.field_error_msg_minvalue;
                    return_val=false;
                }else{
                    field['field_helperText'] = `Minimum value is set to ${field_minValue}`
                    return_val=false;
                }
            }
        }
    }

    //Validation for Date and Time.
    const dateMinMaxValidation =()=>{
        var dd_mm_yyy_format = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
        var yyyy_mmm_dd_format = /^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/;
        if(!yyyy_mmm_dd_format.test(field_value)){
            field_value = field_value.split('-').reverse().join('-');
        }
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        today = yyyy + '-' + mm + '-' + dd;
        let date = new Date(field_value);
        var minmumDate;
        if(field_minValue==="disablePast"){
            minmumDate = new Date(today);
        }else{
            minmumDate = new Date(field_minValue);
        }
        if(field_minValue){
                if(date<minmumDate){
                    if(field.field_error_msg_minvalue){
                        field['field_helperText'] = field.field_error_msg_minvalue;
                        return_val=false;
                    }else{
                        field['field_helperText'] = `Minimum Date value is set to ${today}`;
                        return_val=false;
                    }
                }
        }
        // if(field_maxValue){
        //     if(date>today){
        //             if(field.field_error_msg_maxvalue){
        //                 field['field_helperText'] = field.field_error_msg_maxvalue;
        //                 return_val = false;
        //             }else{  
        //                 field['field_helperText'] = `Maximum Date  value is set to ${today}`;
        //                 return_val=false;
        //             }
        // }
    }
    switch(field_type){
        case "text":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value){
                //min-max-length-validation
                if((field_minlength||field_maxlength)){
                    validationMinMaxLength();
                }
                //value Type Validtion
                if(!checkForValueType(field_value,field_value_type)){
                    field['field_helperText']=`Please Enter a valid ${field.field_label}`
                    return_val=false;
                }
                if(field.field_unique&&field.field_unique.toLowerCase() === "yes"){
                    myCheckUnique(field);
                }
            }
            break;
        case "customer360Search":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                if(!field.field_SearchValue){
                    field['field_helperText']=`Please Select a valid Option`
                    return_val=false;
                }else{
                    if(!checkForValueType(field_value,field_value_type)){
                    
                        field['field_helperText']=`Please Enter a valid ${field.field_SearchValue}`
                        return_val=false;
                    }
                }
              
            }
            break;
        case "number":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                //min-max-validation
                if(return_val&&(field_maxValue||field_minValue)){
                    validtionMinMaxValue();
                }
            }
            break;
        case "email":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
           if(field_value){
                //value Type Validtion
                if(!checkForValueType(field_value,field_value_type)){
                    field['field_helperText']=`Please Enter a valid ${field.field_label}`
                    return_val=false;
                }
            }
            break;
        case "password":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            break;
        case "uploadFile":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            break;
        case "date":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                dateMinMaxValidation();
            }
            break; 
        case "qRCode":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                
            }
            break;       
        case "multiSelect":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                
            }
            break;
        case "select":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                
            }
            break;
        case "multilineText":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            if(field_value&&return_val){
                //min-max-length-validation
                if((field_minlength||field_maxlength)){
                    validationMinMaxLength();
                }
                //value Type Validtion
                if(!checkForValueType(field_value,field_value_type)){
                    // field['field_helperText']=`Please Enter a valid ${field.field_label}`
                    field['field_helperText']=`Please don't use these characters in description box '" \ ! $ % ^ & * () = {} [] | <>;`
                    return_val=false;
                }
            }
            break;
        case "time":
            field_value = field.field_value;
            //mandatoryValidation
            if(field.field_mandatory&&field.field_mandatory.toLowerCase() === "yes"){
                mandatoryValidation();
            }
            // if(field_value&&return_val){
                
            // }
            break;
        case "custom":
            function StrtoJSON(strObj){
                if(typeof(strObj)!=="string"){
                  return {strObj}
                }
                else{
                    return StrtoJSON(JSON.parse(strObj));
                }
            }
            field_value = StrtoJSON(field.field_value).strObj;

            console.log(field_value);
            // if(field_value !== undefined)
            if(field_value.fields !== undefined)
            field_value.fields.map((field)=>{
                return_val = elementValidation(field);
            })
            break;
        default:
            break;
    }
    if (return_val){
        field['field_helperText']="";
        return_val=true;
    }
    //console.log(field);
    let _te = document.getElementById(field.field_id);
    if(_te !== null)
    try{
        if(field.field_type==="date")
        {
            _te.parentNode.parentNode.nextSibling.textContent = field['field_helperText'];
        }
        else if(field.field_type==="customer360Search")
        {
            _te.parentNode.parentNode.parentNode.nextSibling.textContent = field['field_helperText'];
        }
        else{
            _te.parentNode.nextSibling.textContent = field['field_helperText'];
        }
    }catch(ex){
        console.log(_te.parentNode.nextSibling);
        console.log(ex.message);
    }
    console.log(field['field_helperText'])
    return return_val;
}
