import React, { useContext, useState,useRef } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import useStyles from "./FormControlsCss";
import Button from './Button'
import { InputLabel } from '@material-ui/core';
import QrScanner from 'qr-scanner';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function QRCodeUplode(props) {
  const classes = useStyles();
  const aRef = useRef(null);
  let { field_type, field_qrCodeDoamin, field_id, field_label, field_keyValue, field_placeholder, field_value, field_options, field_helperText, field_minlength, field_maxlength, field_maxvalue, field_value_type, field_minvalue, field_error_msg_required, field_error_msg_minlength, field_error_msg_maxlength, field_error_msg_minvalue, field_error_msg_maxvalue, field_error_msg_uq, field_mandatory } = props;
  const { handleChange } = useContext(FormContext)
  const [value, setValue] = useState();
  // let uniqueId = props.id;

  const [errorMsg, setErrorMsg] = React.useState();
  const [img, setImg] = useState()
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };


  const handleScan = (event) => {
    const file = event.target.files[0];
    setValue(file)
    if (!file) {
      console.error('No file selected.');
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      const imageData = reader.result;
      QrScanner.scanImage(imageData)
        .then((UploadQrURL) => {
          console.log(UploadQrURL);
          setImg(file)
          handleChange(field_id, {}, UploadQrURL);
        })
        .catch((error) => {
          console.error(error);
          setImg()
          setOpen(true)
          setErrorMsg(`Error scanning file. Reason: ${error}`)
        });
    };

    reader.readAsDataURL(file);
  };


  const CancelUpload = (e) => {
    e.preventDefault();
    aRef.current.value = null;
    setImg()
    handleChange(field_id, {}, "");
  }

  const TestQRCode = (e) => {
    e.preventDefault();
    window.open(props.field_value, '_blank').focus();
  }


  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={errorMsg}
        key={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>

      <div className={classes.inputroot}>
        <InputLabel className={classes.label}>Upload QR Code</InputLabel>
        <div className={classes.upldbox}>
          <div className={classes.upldbtn}>
            <Button ButtonName='Upload' onClick={(event) => { event.preventDefault(); }} />
            <input type="file"
              draggable={false}
              // id="qr-input-file"
              id={props.field_id} 
              ref={aRef}
              // onChange={(event) => handleClick(event)}
              onChange={handleScan}
              accept="image/*" >
            </input>
            {(props.field_value !== "") ?
              <>
                <div>
                  <Button ButtonName='Remove' onClick={(e) => { CancelUpload(e) }} />
                </div>
                <div>
                  <Button ButtonName='View' onClick={(e) => { TestQRCode(e) }} />
                </div>
              </>
              : null}
          </div>
          <div className={classes.QRcodebox} > {img&& <img src={URL.createObjectURL(img)} alt="" />}</div>
        </div>
      </div>
      </>
      );
}