
import React, { useContext,useEffect } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import FormControl from '@mui/material/FormControl';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';

import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
// import elementValidate from '../Component/ElementValidation';
import { APIGET } from '../services/APIServices';
import elementValidation from '../Component/validation.js';


export default function MultilineText(props) {
  const classes = useStyles();
  const required = null;
    let { field_type, field_id, field_label, field_placeholder, field_value,field_option_src, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [fieldOptions,setFieldOptions] = React.useState(field_value);
 
    const elementField = {field_type, field_id, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
    function handleBlur(){
      // elementValidate(elementField)
      console.log(field_value)
      elementValidation(elementField)
    }

    async function getOptions(props) {
      try{
        if(props.field_option_src_context){
          // if(false){
                let APIRES = await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
                (APIRES)=>{
                    console.log(APIRES);
                    let responseArray =new Array();
                    let _obj = new Object();
                    let _i =0;
              
                    Object.keys(APIRES.data).forEach( key => {
                        // let _obj = new Object();
                        _obj.option_label = APIRES.data[key][props.field_option_label];
                        _obj.value = APIRES.data[key][props.field_option_value];
                        _obj['attributes'] = {};
                    })
                    //fieldOptions = responseArray; 
                    setFieldOptions(_obj.option_label);
                    console.log(_obj.option_label);
                    // masterData[`${field_id}_${props.field_option_src_context}_${props.field_option_src_critieria}`] = responseArray;
                }
                );            
                              
        }
      } catch(err){/** TODO Do Nothing */}
    }
    // useEffect(()=>{
    //     getOptions(field_option_src);
    // },[field_option_src])

    function handleChangeLine(value){
      setFieldOptions(value);
      handleChange(field_id,{},value);
  }

  return (
    <div className={classes.inputroot}>
    <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
    {/* <FormControl  variant="outlined" className={classes.width}> */}
      <OutlinedInput className={classes.multiInput}
             id = {field_id}
             type={field_type}
            //  value={fieldOptions}
             value={field_value}
             placeholder={field_placeholder ? field_placeholder : ''} 
             onChange={event => handleChangeLine(event.target.value)}
             multiline
             rows={3}
             error
             onBlur={handleBlur}
        />
        <FormHelperText>{field_helperText||elementField.field_helperText}</FormHelperText>
        {/* </FormControl> */}
      </div>   
      );
}
