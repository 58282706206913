import React, { useEffect, useState } from 'react';
import {Formio,FormBuilder} from '@formio/react';
import 'formiojs/dist/formio.full.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FormioApp.css';
import FormioButton from './FormioButton';
import PreviewBox from './PreviewBox';
import Axios from 'axios';
// import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
// import RetreiveForm from './Components/RetriveFormdata';
import {  Link } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { createTheme, ThemeProvider  } from '@mui/material/styles';
import { FormJSONCreateContext } from '../../Component/FormJSONCreateContext';
// import { LeftOutlined } from '@ant-design/icons';

// Get the HTMLComponent from the components listing.
const FieldComponent = Formio.Components.components.field;

console.log(Formio.Components.components);
/**
 * Create a Header compoennt and extend from the HTMLComponent.
 */
class DayOfWeek extends FieldComponent {
  constructor(component, options, data) {
    super(component, options, data);
    this.daysButtons = {};
  }
  
  static schema(...extend) {
    return FieldComponent.schema({
      label: 'Day of Week',
      type: 'dayofweek',
	  days: ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
    }, ...extend);
  }
  
  static get builderInfo() {
    return {
      title: 'Day of Week',
      group: 'basic',
      icon: 'calendar',
      weight: 2,
      documentation: '/userguide/#html-element-component',
      schema: DayOfWeek.schema()
    };
  }
  
  renderButton(day) {
    return this.renderTemplate('input', {
      input: {
        type: 'input',
        ref: `${this.component.key}-${day}`,
        attr: {
          id: `${this.component.key}-${day}`,
          class: 'form-control day-of-week-button',
          type: 'button',
          value: day
        }
      }
    });
  }
  
  render(children) {
    let table = '<table class="table table-compressed"><tbody>';
    	table += '<tr>';
      for (let j = 0; j < this.component.days.length; j++) {
      	table += '<td>';
        table += this.renderButton(this.component.days[j]);
        table += '</td>';
      }
      table += '</tr>';
    table += '</tbody></table>';
    return super.render(table);
  }
  
  attach(element) {
    const refs = {};

    for (let i = 0; i < this.component.days.length; i++) {
    	const day=this.component.days[i];
      refs[`${this.component.key}-${day}`] = 'single';
    }

    this.loadRefs(element, refs);
    this.daysButtons = {};
    for (let i = 0; i < this.component.days.length; i++) {
    	const day=this.component.days[i];
      const control=this.refs[`${this.component.key}-${day}`];
    	this.daysButtons[day]=control;
    	this.addEventListener(control, 'click', () => {
      	if(control.getAttribute("class").indexOf("day-of-week-button_pressed")==-1) {
        	control.setAttribute("class", "form-control day-of-week-button_pressed");
        } else {
        	control.setAttribute("class", "form-control day-of-week-button");
        }
        this.updateValue();
      });
    }
    
    return super.attach(element);
  }
  
  getValue() {
  	let values=[];
    for(let day in this.daysButtons) {
    	let dayButton=this.daysButtons[day];
      if(dayButton.getAttribute("class").indexOf("day-of-week-button_pressed")!=-1) {
      	values.push(day);
      }
    }
    return values;
  }
  
  setValue(value) {
    if (!value) {
      return;
    }
    for(let day of value) {
    	this.daysButtons[day].setAttribute("class", "form-control day-of-week-button_pressed");
    }
  }
}

Formio.use({
  components: {
    dayofweek: DayOfWeek
  }
});


Formio.Components.addComponent('dayofweek', DayOfWeek);

const theme = createTheme({
  palette: {
    primary: {      
      main: '#1E6DAA',
    },
    secondary: {
      light: '#8EAD26',
      main: '#8EAD26',
      contrastText: '#fff',
    },
    warning: {
      light: '#E49217',
      main: '#E49217',
      contrastText: '#fff',
    },
    success: {
      main: '#2e7d32',
    },
    info: {
      main: '#8AA6A5',
	  contrastText: '#fff',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
const useStyle = makeStyles({
	prevButton:{
		'& button':{
			display:'flex',
			alignItems:'center',
			justifyContent:'center',
			textTransform:'capitalize',
			cursor:'pointer',
			'& span':{
				margin:0,
			},
			'& svg':{
				marginRight:8,
			}
		}		
	},
	formDataDis:{
		display:'flex',
		padding:40,
		alignItems:'center',
		justifyContent:'center',
		marginBottom:64,
	},
	buttonGrp:{
		width:'70%',
		display:'flex',
		alignItems:'center',
		justifyContent:'center',
		marginLeft:'auto',
		marginTop:'20px',
		'& button':{
			minWidth:'104px',
			margin:'8px',
			fontSize:'12px',
			borderRadius:'2px',
			height:40,
		}
	},
})
function FormioInterface() {
	const {contextJson} = React.useContext(FormJSONCreateContext);
	const classes = useStyle();
	const [ formData, setFormData ] = useState ({display: 'form', page: 0, description: 'Test form', name: 'Test'});
	const [ openBox, setOpenBox ] = useState (false);
	const [guid, setGuid]= useState('');
	const [apiFormData, setApiFormData] = useState({});
	const [isEdit, setEdit] = useState(false);
	const [isExistingId, setEditId] = useState(null)

	let fieldIndex =undefined;
	let fieldkey = undefined;
	var fieldkey1 ;
	var fieldIndex1 ;
    React.useEffect(() => {

		Object.keys(contextJson).forEach((key)=>{
			if(key!=="xioFEConfig"){
			contextJson[key].fields.forEach((field,index)=>{
				if(field.field_type==="formIO"){
					fieldkey = key;
					fieldIndex = index;
				}else if(field.field_type==="previewBox"){
					fieldkey1 = key
					fieldIndex1 = index
				}
			})
		}})
        
        receiveForms();
		
        //console.log("Loaded data", apiFormData)
    }, [])
	useEffect(() => {
		const params = new URLSearchParams(window.location.search) // id=123
        var id=false; // 123 
		const fetchCurrentForm = () => {
			let listData = localStorage.getItem(contextJson[fieldkey].fields[fieldIndex].field_id);
			listData = listData?JSON.parse(listData):{}
			setFormData(listData);
			contextJson[fieldkey].fields[fieldIndex].field_value = listData;
			contextJson[fieldkey1].fields[fieldIndex1].field_value = listData;

		}
		const receiveForms = async () => {	
            // const receivedata = await Axios.get('http://localhost:8080/api/v1/form-def/'+ id +"/FORM_IO");
			if(contextJson[fieldkey].fields[fieldIndex].field_value){
				var receivedata = JSON.parse(contextJson[fieldkey].fields[fieldIndex].field_value);
			}
            //console.log(receivedata)
			if(receivedata!=="{}"){
				// id = true;
			}
            setApiFormData(receivedata);
        };
		if(id){
			setEditId(id)
			receiveForms();
			setEdit(true)
		}
		fetchCurrentForm();
	  }, []);
	
	//console.log(formData);
	const receiveForms = async () => {	
		// var id=false;
		// const receivedata = await Axios.get('http://localhost:8080/api/v1/form-def/'+ guid +"/FORM_IO");
		if(contextJson[fieldkey].fields[fieldIndex].field_value){
			var receivedata = JSON.parse(JSON.stringify(contextJson[fieldkey].fields[fieldIndex].field_value));
		}
		//console.log('I am the Receive -> ' , receivedata);
		if(receivedata!=="{}"){
			// id = true;
		}
		return receivedata;
	};
	useEffect(()=> {
		// const getForms = async () => {
		// 	const allForms = await reteriveForms();

		// }
		// getForms();
	}, []);
    return (
		<ThemeProvider theme={theme}>
		<div className='container-fluid py-2'>
			
			
			<FormBuilder  form={isEdit?apiFormData:formData} onChange={(schema) => {setFormData(schema);(localStorage.setItem(contextJson[fieldkey].fields[fieldIndex].field_id, JSON.stringify(schema)));
}} options={{
				builder: {
					basic: {
						components: {
							button: false,
							password: false,
							dayofweek: true
						}
					},
					resource: false,
					advanced: {
						components: {
							tags: false,
							currency:false,
							survey:false,
							// address:false,
							signature:false,
							datetime:false,
							header: true
						}
					},
					layout: {
						components: {
							content: false,
							fieldset:false,
							well:false,
						}
					},
					// layout: false,
					data: false,
					premium: false,
				},
				noDefaultSubmitButton: true
			}}  />
	
			

			<div className={classes.buttonGrp}>
				
				{/*<FormioButton color="info" label="Back"/>
				{/* <FormioButton color="success" label="Save" handleClick={()=>{localStorage.setItem(contextJson[fieldkey].fields[fieldIndex].field_id,JSON.stringify({})); setFormData({})}}/> */}
				{/* <Link to="/PreviewBox" className={classes.prevButton}> 
					<FormioButton 
					color="primary" 
					label="NEXT" 					
					handleClick={ () => {
						localStorage.setItem(contextJson[fieldkey].fields[fieldIndex].field_id, JSON.stringify(formData));
					}}/>
				{/* </Link> */}
				
				
			
			</div>
		
			{openBox && <PreviewBox FormData={formData} isExistingId={isExistingId} setPrvBoxHandler={ () => {setOpenBox(false)}}/>}
		</div>

		</ThemeProvider>
    );
}

export default FormioInterface;