import React, {useState} from "react";
import {Typography }    from '@material-ui/core';
import useStyles        from '../../../../Component/ComponentCss';
//JSON
import fieldsData       from './viewTableColumn.json';
import masterJSON       from './masterJson.json';

import DataTable from "../../../../Component/DataTable/GenerateTable";
//import context 
import {FormJSONCreateContext} from '../../../../Component/FormJSONCreateContext';
// import FilterTabs from "../../../Component/DataTable/FiltersTab";
import CloseIcon from '@mui/icons-material/Close';
//import Success Page  
import SuccessPage      from '../../../../Component/SuccessPage.js';
import { BsSearch } from "react-icons/bs";
//import for Navigation 
import {useNavigate}    from 'react-router-dom';
// import FilterUI from '../../Component/DataTable/FilterUi/FilterUI';
import Create from "./Create";
import Dialog from '@mui/material/Dialog';


const apiContextVar = "trafficType"; 
const contextHeading = "Traffic Type";
const policyLabelOnDelete="";

function View() {
  const classes = useStyles();
  const [successRender,setSuccessRender] = React.useState(undefined);
  const [successPageData,setSuccessPageData] = React.useState(undefined);
  const [filterTabPath, setFilterTabPath] = React.useState("");
const [filterUiPath, setFilterUiPath] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = useState();

  //for navigation 
  let navigate = useNavigate();
  
  //On Success Create Policy----> 
  function renderSuccessPage(props){
    setSuccessPageData(props)
    setSuccessRender(1);
    console.log('Success Rander is Rendering..')
  }
  function navigateToCreate(){
    navigate(successPageData.createLink)
    // handleClose();
    setSuccessRender(undefined);
    return null;
  }
  function navigateToView(){
    console.log('Navigating to View Policy...')
    navigate(successPageData.viewLink);
    setSuccessRender(undefined);
  }

   

  const authentication = {
    "actions": {
      "action":true,
      "disable": {
        "edit": false,
        "delete": false,
        "view": false,
        "download": true
      },
      "hide": {
        "edit": false,
        "delete": false,
        "view": false,
        "download": true
      }
    },
    "analytics": {
      "analytic": false
    }
  }

const FilterJSON = fieldsData.FilterJSON;
const [apiPath,setApiPath] = React.useState(apiContextVar.toString());
const [updateAll,setUpdateAll] = React.useState(false);

React.useEffect(() => {
  if (filterUiPath || filterTabPath) {
    const filterParams = [];
    if (filterUiPath) {
      filterParams.push(filterUiPath);
    }
    if (filterTabPath) {
      filterParams.push(filterTabPath);
    }
    const queryString = filterParams.join("&");
    setApiPath(`${apiContextVar.toString()}/filter?${queryString}&`);
  } else {
    setApiPath(apiContextVar.toString());
  }
}, [ filterTabPath, filterUiPath]);

 

const handleClose = () => {
  setOpen(false);
  setUpdateAll(true)
};
  return (
  <FormJSONCreateContext.Provider value={{renderSuccessPage,masterJSON}}>
    <div className={classes.main}> 
      {(successRender&&successPageData)?
          <SuccessPage
          flowName ={contextHeading}
          actionName = {successPageData.actionName}
          createPolicyLink = {navigateToCreate}
          viewPolicyLink = {navigateToView}
          createButtonText = {successPageData.createButtonText}
          viewButtonText = {successPageData.viewButtonText}
          paragraphText = {successPageData.paragraphText}
        />
      :

       <>
            <Dialog className={classes.popupEdit}
              // fullScreen={fullScreen}
              open={open}
             // onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
               <div className={classes.close}>
              <CloseIcon onClick={handleClose}/>
              <h4>{contextHeading}</h4>
              </div>
             <Create close={handleClose}/>
            </Dialog>

        <div className={classes.flowHeader}>
          <h2>{contextHeading}</h2>
          <button className={classes.subBtn} onClick={()=>setOpen(true)}>Add New</button>
        </div>
       
        <DataTable  columns={fieldsData.columns} updateAll={updateAll} apiContext={apiPath} policyLabelOnDelete={policyLabelOnDelete} apiContextVar={apiContextVar.toString()} contextHeading={contextHeading} createJson={masterJSON}  authentication={authentication}  />
     
       </>
              
      }
  </div>
</FormJSONCreateContext.Provider>
  );
}

export default View;
