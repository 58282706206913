import * as React from 'react';
import useStyles from './FormControlsCss';
import { FormJSONCreateContext } from '../Component/FormJSONCreateContext';


function Confirmation(props){
   const classes=useStyles();
   let { field_type, field_id, field_label, field_placeholder, field_value, field_dataId,field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
   const {contextJson} = React.useContext(FormJSONCreateContext);
    let arr = field_dataId.split(',');
    let localFData = {}
    Object.keys(contextJson).map((key)=>{
        if(key!=="xioFEConfig"){
        contextJson[key].fields.map((field,_index)=>{
            if(arr.indexOf(String(field.field_id))>=0){
                // localFData =Object.assign(localFData,field.field_id:field.field_value)
                localFData[field.field_id] = {"value":field.field_value,"label":field.field_label}
            }
        })
    }
    })
    return(
    <div className={classes.clist}>
    <ul >
        {Object.keys(localFData).map((key)=>{return (<li><span>{localFData[key].label}</span> : <span>{localFData[key].value}</span></li>)})}
        {/* <li><span>Pull Flow Name:</span><span></span></li>
        <li><span>Description:</span><span></span></li>
        <li><span>Flow Type:</span><span></span></li>
        <li><span>Black List Count:</span><span></span></li>
        <li><span>Validity Type:</span><span></span></li>
        <li><span>Start Date:</span><span></span></li>*/}
        <li><span>is ready to be created</span><span></span></li>
        <li><span>The same will go for approval to Mr.XXX Designation:</span><span></span></li>
        <li><span>Do you want to continue</span><span></span></li> 
    </ul>
    </div>
);
}

export default Confirmation; 



// import * as React from 'react';
// import useStyles from '../Component/ComponentCss';
// // import Button from '../../FormsControls/Button';
// import { FormJSONCreateContext } from '../Component/FormJSONCreateContext';
// function Confirmation(props){
//     const classes = useStyles();
//     const dataArr = props.dataArr;
//     const contextHeading = props.contextHeading;
//     const {contextJson} = React.useContext(FormJSONCreateContext);
//     const close = props.close;
//     const index = props.index;
//     let dataRow ={};
//     const getLabels = (_id)=>{ 
//       Object.keys(contextJson).forEach((key)=>{
//         if(key!=="xioFEConfig"){ 
//         let _object = contextJson[key];
//         _object.fields.forEach((field,index)=>{
//           const {field_id,field_label} = field;
//           if(field_id===_id){
//             dataRow[field_id] = {label:field_label,value:dataArr[field_id]}
//             return field_label;
//           } 
//         })
//     }})
//     }
//    if(dataArr){(Object.keys(dataArr).forEach((_id)=>(getLabels(_id))))}
//    const getFields = ()=>{

//    }
//     return(<div>
//         {/* {props.dataArr[props.index]} */}
//     <React.Fragment>
      
//       <div  className={classes.preview}>
//          <h4>Confirmation {contextHeading}</h4>
//          <div className={classes.prvw}>
//             <ul>
//             {(dataRow)?Object.values(dataRow).map((value)=>{
//                     if(value.label!=="Custom Fields"){return(<div><li><span>{value.label}</span><span>{value.value}</span></li></div>)}
//                     else{return null}
//               })
//               :null}
//               </ul>
//             {console.log(dataRow)}
//         </div>
//           {/* <div className={classes.flx2}> 
//                 <Button ButtonName="Cancel" onClick={props.close}/>
//                 <Button ButtonName="Delete"/><Button ButtonName="Save"/>
//           </div> */}
         
//         </div>
//         {/* <div className={classes.tpbp}> 
//                 <Button ButtonName="Close" onClick={props.close}/>
//                 <Button ButtonName="Delete"/>
//           </div> */}
//       </React.Fragment>
//     </div>);
// }

// export default Confirmation;