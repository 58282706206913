import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import  useStyles  from './FormControlsCss';


export default function ButtonSelect(props) {
    const classes = useStyles();
    let { id,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
  return (
    <div className={classes.btnslt}>
        <ToggleButton value="" label="" >
            <div>=</div>
            <ArrowDropDownIcon />
        </ToggleButton>
    </div>
     
  );
}
