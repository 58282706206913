import React, {useEffect} from 'react';
//importing mui stepper.....
import Stepper from '@mui/material/Stepper';
//importing other required mui compoent for stepper....

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button    from './Button';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import {Form}    from '../Component/Forms/formElement';

//json context
import { FormJSONCreateContext } from '../Component/FormJSONCreateContext';
//Form Renderer 
import FormRenderer from '../Component/Forms/FormRenderer'
//style-->
import useStyles from '../Component/ComponentCss';
//connector
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
//validation
import elementValidation    from '../Component/validation.js';
//APISERVICES
import {APIPOST,APIPATCH}            from '../services/APIServices';
import { MasterContext } from '../services/MasterContext';

export default function TabStepper(props) {
  const {renderSuccessPage} = React.useContext(FormJSONCreateContext);
  const {contextJson,apiContextVar,contextObjective,editData,contextDataArr,contextIndex} = React.useContext(FormJSONCreateContext);
  const {masterData} = React.useContext(MasterContext);
  // useEffect(() => {
  //   // 👇 add class to body element
  //   document.body.classList.add('newclass');
  // },[]);
  //css->
  const classes = useStyles();
  //step connector 
  const StepConnector =()=>{
    return(
      <div 
      style={{border:"1px",
              color:"#ff3333",
              height:"0px",
            }}
      >
      </div>
    )
  }

  //State--->
  const [activeStep,setActiveStep] =React.useState(0);
  //Post Response
  const [postResponse,setPostResponse] = React.useState();
  const [disableBtn, setDesable] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [reset, setReset] = React.useState(false)
  //variables --->
  let label = new Array();
 

 
  function hendleErrorMsg(msg) {
    if (msg.message === "400") {
      if (msg.serverMessages) {
        const keys = Object.keys(msg.serverMessages);
        Object.keys(contextJson).forEach((key, _i) => {
          if (key !== "xioFEConfig") {
            // isFormValid = (validateThisForm(_object));
            contextJson[key].fields.forEach((field) => {
              keys.forEach((fieldKey) => {
                if (field.field_id === fieldKey) {
                  field.field_helperText = msg.serverMessages[fieldKey];
                }
              });
            });
          }
        });
        setOpen(true)
        setErrorMsg(msg.serverMsg)
        setDesable(false)
      } else {
        setOpen(true)
        setErrorMsg(msg.serverMsg)
        setDesable(false)
      }
    } else {
      setOpen(true)
      setErrorMsg(msg)
      setDesable(false)
    }
  }

console.log(postResponse)
React.useMemo(() => {

  const resetFields = () => {
    Object.keys(contextJson).forEach((key, index) => {
      if (key !== "xioFEConfig") {
        contextJson[key].fields.forEach((field) => {
          if (contextObjective === "create") {
            if (field.field_type === "custom") {
              field.field_value = "{}"
            }else if (field.field_type !== "hidden" && field.field_default_value) {
              field.field_value = field.field_default_value
              field.id = ""
            } else {
              field.field_value = ""
              field.id = ""
            }
          } else if (contextObjective === "search" || contextObjective === "remove") {
            if (field.field_type !== "hidden" && field.field_default_value) {
              field.field_value = field.field_default_value
              field.id = ""
            } else {
              field.field_value = ""
              field.field_SearchValue = ""
              field.id = ""
            }
          } else if (contextObjective === "update") {
            field.field_value = editData[field.field_id]
          }

          let _te = document.getElementById(field.field_id);
          if (_te !== null)
            try {
              _te.parentNode.nextSibling.textContent = '';
            } catch (ex) {
              console.log(_te.parentNode.nextSibling);
              console.log(ex.message);
            }
        })
      }
    })
  }

  if (reset) {
    resetFields()
    setReset(false)
  }
}, [reset, contextObjective, contextDataArr, contextIndex, contextJson])

  Object.keys(contextJson).forEach((key,index)=>{
    label.push(contextJson[key].pagelabel)
  })
  //Steps 
  const Steps =()=>{
      const {contextJson} = React.useContext(FormJSONCreateContext);
      if(Object.keys(contextJson).length>1){
        let objData = new Array();
        Object.keys(contextJson).forEach((key,index)=>{
          if(key!=="xioFEConfig"){
          let _id = "simplet-tab"+index;
          let _label = contextJson[key].page_label;
          let _subLabel = contextJson[key].sub_label;
          console.log('this is stepper label ---> '+ _label);
          let stepProps = {}
          let labelProps = {}
          //isCompleted--->
            if(activeStep===index){
              stepProps.completed =false;
            }
          objData[index] = (
                            <Step key={_label} id={_id} {...stepProps}>
                                  <StepLabel {...labelProps}><span>{_subLabel}</span>{_label}</StepLabel>
                            </Step>
                            );
      }})
        return (<Stepper activeStep={activeStep} alternativeLabel  >{objData}</Stepper>);
      }
  }

//Step Panels--->
function StepPanels(){
    const {cotextJson} = React.useContext(FormJSONCreateContext);
    let objData = new Array();
    // let index=0;
    let Count = Object.keys(contextJson).length-1;
    Object.keys(contextJson).forEach((key,index)=>{
     if(activeStep===index&&key!=="xioFEConfig"){
      objData[index] =  (getStepPanels(contextJson[key],index,(Count-1)));
     }
    })
    return objData;
}

function getStepPanels(jsonContext,_index,count){
    return (
    <div>
        <FormRenderer formJSON = {jsonContext} />
        <div className={classes.ctaNxtPrv}>
              {(_index === 0 ?  <Button  ButtonStyle={{"background-color":'#8AA6A5',
                 "border-color":'#8AA6A5'}}   
                    ButtonName="Reset"
                    onClick={(event) => {setReset(true)}}
                    /> :null
                  )}
              {(_index === 0 ? null :
              <Button  ButtonStyle={{"background-color":'#8AA6A5',
                 "border-color":'#8AA6A5'}}    
                ButtonName="Back"
                onClick={handleBack}
                />
              )}
              {/* {(count===_index ? <Button  ButtonStyle={{"background-color":'#82A9BB',
                 "border-color":'#82A9BB'}}  
                    ButtonName="Draft" onClick={(event)=>{event.preventDefault();}}/> :
                   <Button  ButtonStyle={{"background-color":'#82A9BB',
                 "border-color":'#82A9BB'}} 
                    ButtonName="Draft" onClick={(event)=>{event.preventDefault();}}/>
                  )} */}
              {(count===_index? null :
              <Button ButtonStyle={{"background-color":'#1E6DAA',
                 "border-color":'#1E6DAA'}}  
                ButtonName="Next"
                onClick={() => localValidate(window.event,this,(_index+1),jsonContext)} 
                />
              )}
                {(count===_index ?   <Button field_disabled={disableBtn} ButtonStyle={{"background-color":'#26AD8D',
                 "border-color":'#26AD8D'}}
                    ButtonName="Submit"
                    /> :null
                  )}
        </div>
    </div>);
}

//validation---->
function validateThisForm(jsonContext){
  let return_error = true;
  jsonContext.fields.forEach(field => {
      if(!elementValidation(field)){
          return_error = false;
          console.log(field);
          return false;
      }
  });
  return return_error;
}

function localValidate(event,obj,_index,jsonContext){
  event.preventDefault();
  window.scrollTo(0,0);
  if(validateThisForm(jsonContext)){
      setActiveStep((prevActiveStep)=>prevActiveStep+1)
      return true;
  }else{
      return false;
  }
  //return 
}

//chandle click,change,blur etc...
const handleNext=()=>{

}
const handleBack=()=>{
    if(activeStep!==0){
      setActiveStep((prevActiveStep)=>prevActiveStep-1)
    }
}

//handle SUbmit-->
async function handleSubmit(event) {
  setDesable(true)
  event.preventDefault();
  window.scrollTo(0,0);
  console.log("Submit Cliked")
  //console.log(`${validatePolicyValidateForm()}${validatePolicyParameterForm()}`)
  //if(validatePolicyValidateForm()&&validatePolicyParameterForm()){
    let isFormValid = true;
    console.log("POST Should Run")
    event.preventDefault();
    window.scrollTo(0,0);
    
    let formFieldvalue;
    let data = {"active":"Y","genratedQrurl":"","additionalFields":"\{\}","approvalStatus":"PENDING","createById":1,"updateById":1,"enterpriseId":1,
    "insrtSessionId":1,"status":"NEW","endDate":"01-01-2099","enforcementDate":"01-01-2099","policyCategory":apiContextVar,"policyType":"","policyStatus":"NEW","consentFormStatus":"NEW",}
    Object.keys(contextJson).forEach( key => {
      if(key!=="xioFEConfig"){
      let _object = contextJson[key];
      if(isFormValid){
        isFormValid = (validateThisForm(_object));
      }else{
        setDesable(false) 
      }
      _object.fields.forEach(thisField => {
        if(thisField.field_type!=="multiSelect"&&thisField.field_type!=="date"&&thisField.field_type!=="qRCode"&&thisField.field_id!=="formJsonData"&&thisField.field_id!=="formJsonData1"){
          data[thisField.field_id] = thisField.field_value;
        }else if(thisField.field_type==="qRCode"){
          data[thisField.field_id] = thisField.field_value;
          data[thisField.field_keyValue] = thisField.url;
        }
        else if(thisField.field_type==="date"){
          var data_regex_format2 = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
          if(thisField.field_value){
            if(!data_regex_format2.test(thisField.field_value)){
              data[thisField.field_id] = thisField.field_value.split('-').reverse().join('-');
            }
            else{
              data[thisField.field_id] = thisField.field_value;
            }
          }
        }else if(thisField.field_type==="formIO"){
          data[thisField.field_id] = JSON.stringify(thisField.field_value);
          formFieldvalue = data[thisField.field_id]
        }else if(thisField.field_type==="previeBox"){
          data[thisField.field_id] = formFieldvalue;
        }else{
          data[thisField.field_id] = String(thisField.field_value);
        }
      });
    }});

  
    
    // async function postingData(contextJson){
    //   masterData[`${apiContextVar}`] = {}
    //   setPostResponse(await APIPOST(apiContextVar+`/save`,data,contextJson,renderSuccessPage,hendleErrorMsg).then(data=>data))
    //   if(postResponse){
    //     return postResponse
    //   }
    // }

    async function postingData(contextJson) {
      masterData[`${apiContextVar}`] = {};
      
      try {
        const response = await APIPOST(apiContextVar + '/save', data, contextJson, renderSuccessPage, hendleErrorMsg);
        setPostResponse(response);
      } catch (error) {
        // Handle any error that occurred during the APIPOST call
        console.error(error);
      }
    }
    
    // async function updateData(contextJson){
    //   masterData[`${apiContextVar}`] = {}   
    //   setPostResponse(await APIPATCH(apiContextVar+'/'+contextDataArr[contextIndex].id,data,contextJson,renderSuccessPage,hendleErrorMsg).then(
    //     data=>(console.log(data))
    //     ))
    //   if(postResponse){
    //     return postResponse
    //   }
    // }

    async function updateData(contextJson) {
      masterData[`${apiContextVar}`] = {};
      
      try {
        const response = await APIPATCH(apiContextVar + '/' + contextDataArr[contextIndex].id, data, contextJson, renderSuccessPage, hendleErrorMsg);
        setPostResponse(response);
    
      } catch (error) {
        // Handle any error that occurred during the APIPATCH call
        console.error(error);
      }
    }
    

    if(isFormValid){
      if(contextObjective === "create"){
        postingData(contextJson);
      }else if(contextObjective === "update"){
        updateData(contextJson)
      }
    }else{
      var msg='Please Check Form for errors!';
      hendleErrorMsg(msg)
    }
    return false;

}

const contextClass = props.contextClass;
let ShowTopTabs = false;
if(Object.keys(contextJson).length>1){
  ShowTopTabs=true;
}

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};

return (
  <>
  <Snackbar className="alertError" anchorOrigin={{ vertical:'top', horizontal:'center' }} open={open} autoHideDuration={4000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="error" sx={{ width: '100%'}}>
        {errorMsg}
      </Alert>
  </Snackbar>
      <Box  >
    <Form onSubmit={handleSubmit} className={classes.steps}>
    {ShowTopTabs?
    <React.Fragment>
    <Steps/>
    <Box className={contextClass}>
        <StepPanels />
    </Box>
    </React.Fragment>
    :
    <Box className={contextClass}>
        <StepPanels />
    </Box>
    }
    </Form>
</Box>
  </>
);
}