import React, { useEffect }  from "react";
import success from "../images/success.svg";
import { MdOutlineFileUpload, MdOutlineDone} from "react-icons/md";
import { RiErrorWarningLine } from "react-icons/ri";
import "../css/Success.css";

 function ScrubSuccessPage(props){
    const [counter, setCounter] = React.useState(11);
     const {open,successPageData,dataRecevied,policyName,actionName,createPolicyLink,viewPolicyLink,createButtonText,viewButtonText,paragraphText,close} = props;
     useEffect(()=>{
        let intervalid=null;
          if(open){
              intervalid=setInterval(()=>{
                setCounter(pre=>pre-=1);
              },1000)
          }else{
              clearInterval(intervalid)
          }
      },[open])
  console.log(dataRecevied)
     return(
        <div className="content-area df">
            <div className="success-blocks">
            <div className="success-icon">
                {/* <img src={success} alt="success"/> */}
                <MdOutlineDone />
                </div>
                <h2 className="sub-head">Success!</h2>
                <div>
                <h3 className="sub-heading">your scrubbing request is submitted</h3>
                {/* <p>{`${paragraphText}`}</p> */}
                </div>
                <div className="success-data">
                <table>
                    <tr>
                        <th>Scrub Name</th>
                        <th>Consent Policy</th>
                        <th>Prefrence Policy</th>
                        <th>File Uploaded</th>
                        <th>File Format</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td>{dataRecevied.data.scrubLabel}</td>
                        <td>{dataRecevied.data.consentPolicyId}</td>
                        <td>{dataRecevied.data.preferencePolicyId}</td>
                        <td>{dataRecevied.data.scrubFileUpload}</td>
                        <td>{dataRecevied.data.fileFormat}</td>
                        <td>{dataRecevied.data.scrubStatus}</td>
                        {/* <td>in Process</td> */}
                    </tr>
                </table>
                <div className="sltext"><span><RiErrorWarningLine/></span><p>Visit the scrub results page to download <b>Scrub Results</b></p></div>
                </div>
                <div className="df jcc btn-margin-success">
                 {/* <div className="btn-submit closebtn btn-basic-policy click-effect"
                    onClick={close}
                >
                    {`${viewButtonText}`}
                </div> */}
                <div className="btn-submit viewbtn btn-basic-policy click-effect"
                    onClick={viewPolicyLink}
                >
                    {`${viewButtonText}`}
                </div>
                <div className="btn-submit btn-basic click-effect"
                    onClick={close}
                >
                {`${createButtonText}`}
                </div>
                
                </div>
                <div className="df counter"><p>Automatically closes in&nbsp;</p><span>{counter}s</span><p>&nbsp;and starts new scrub request</p></div>
            </div>
        </div>

     );
 }
 export default ScrubSuccessPage;