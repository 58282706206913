import React from "react";
import useStyles from "../../../Component/ComponentCss";
import team from "../../../images/decidd/team.png";
import network from "../../../images/decidd/network.png";
import country from "../../../images/decidd/country.png";
import region from "../../../images/decidd/region.png";
import comments from "../../../images/decidd/comments.png";
import department from "../../../images/decidd/Department.png";
import carrier from "../../../images/decidd/carrier.png";
import reportingManger from "../../../images/decidd/ReportingManger.png";
import accountManager from "../../../images/decidd/accountManager.png";

import pending from "../../../images/card/pending.png";
import { useNavigate } from 'react-router-dom';
import { Typography } from '@material-ui/core';

function Create() {
  const classes = useStyles();
  let navigate = useNavigate()

  function handleClick(page, link) {
    console.log(page);
    if (link && link !== '') {
      navigate(link);
    }
  };

  function Boxes(props) {
    const page = props.page1;
    const link = props.link1;
    const icon1 = props.icon;
    const src1 = props.src1;
    const status = props.status;
    const newpolicydisplay = props.newpolicydisplay;
    const newpolicynumber = props.newpolicynumber;
    const newpolicybton = props.newpolicybton;
    const newpolicyimgsrc = props.newpolicyimgsrc;
    //const newpolicybton= "bton2";
    //const newpolicyimgsrc= assign;
    return (

      <div className="flex">
        <div className="recipe-card" onClick={() => handleClick(page, link)}>
          <div className="active">
            <div className={icon1} ><span></span>{status}</div></div>
          <aside>
            <img src={src1} alt="policy" />
            <div className="button" style={{ display: (newpolicydisplay) }} > <img src={newpolicyimgsrc} alt="policy" /></div>
          </aside>
          <article>
            <h3>{props.label}</h3>
            <div></div>
            <p>{props.text}</p>
          </article>
        </div>
      </div>
    )
  }
  const createTemplate = [
    {
      label: 'Create Team',
      text: "Create Team",
      page1: 1,
      link: "/Admin/MasterData/CreateTeam",
      icon: "active-icon",
      src1: team,
      status: "active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Create Region',
      text: "Create Region",
      page1: 2,
      link: "/Admin/MasterData/CreateRegion",
      icon: "active-icon",
      src1: region,
      status: "active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Create Country',
      text: "Create Country",
      page1: 3,
      link: "/Admin/MasterData/CreateCountry",
      icon: "active-icon",
      src1: country,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Create Network',
      text: "Create Network",
      page1: 4,
      link: "/Admin/MasterData/CreateNetwork",
      icon: "active-icon",
      src1: network,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Traffic Profile',
      text: "Traffic Profile",
      page1: 4,
      link: "/Admin/MasterData/TrafficProfile",
      icon: "active-icon",
      src1: comments,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Traffic Type',
      text: "Traffic Type",
      page1: 4,
      link: "/Admin/MasterData/TrafficType",
      icon: "active-icon",
      src1: comments,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Department',
      text: "Department",
      page1: 4,
      link: "/Admin/MasterData/Department",
      icon: "active-icon",
      src1: department,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Carrier',
      text: "Carrier",
      page1: 4,
      link: "/Admin/MasterData/Carrier",
      icon: "active-icon",
      src1: carrier,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Designation',
      text: "Designation",
      page1: 4,
      link: "/Admin/MasterData/Designation",
      icon: "active-icon",
      src1: comments,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Reporting Manager',
      text: "Reporting Manager",
      page1: 4,
      link: "/Admin/MasterData/ReportingManager",
      icon: "active-icon",
      src1: reportingManger,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Account Manager',
      text: "Account Manager",
      page1: 4,
      link: "/Admin/MasterData/AccountManager",
      icon: "active-icon",
      src1: accountManager,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Potential Customer Prospect',
      text: "Potential Customer Prospect",
      page1: 4,
      link: "/Admin/MasterData/Customer",
      icon: "active-icon",
      src1: accountManager,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Potential Volume',
      text: "Potential Volume",
      page1: 4,
      link: "/Admin/MasterData/PotentialVolume",
      icon: "active-icon",
      src1: accountManager,
      status: "Active",
      newpolicydisplay: "",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    }
  ]

  return (
    <div className="main">
      {createTemplate.map((data, key) => {
        return (
          <Boxes
            text={data.text}
            label={data.label}
            page1={data.page1}
            link1={data.link}
            icon={data.icon}
            src1={data.src1}
            status={data.status}
            newpolicydisplay={data.newpolicydisplay}
            newpolicynumber={data.newpolicynumber}
            newpolicybton={data.newpolicybton}
            newpolicyimgsrc={data.newpolicyimgsrc}
          />
        );
      })
      }

    </div>
  );
}

export default Create;
