import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import FormControl from '@mui/material/FormControl';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';

import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
// import elementValidate from '../Component/ElementValidation';
// import elementValidate from '../Component/ElementValidation'
import elementValidation from '../Component/validation.js';

import {UNIQUECHACK} from '../services/APIServices';


export default function Input(props) {
  const classes = useStyles();

  const required = null;
    let { id,field_unique,field_disabled_Edit,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [fieldDisable, setFieldDisable]= useState(field_disabled);
    // { 
    //   classes.input = classes.input.replace(" input-error ", " ");
    //   classes.input = classes.input.replace("  ", " ");
    //   classes.input +=" input-error ";   
    // }
    if(field_type==="number"){ 
      field_value = parseInt(field_value)
    }
    const elementField = {id,field_unique,field_disabled_Edit,field_type,field_disabled, field_id,field_apipath, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
    
    function handleBlur(){
      elementValidation(elementField)
    }

    useEffect(()=>{
      if(typeof id==='number'&& field_disabled_Edit==='yes' && field_value){
        setFieldDisable(true)
      }else{
        setFieldDisable(false)
      }
    },[]);

  return (
    <div className={props.field_parent_class?classes[props.field_parent_class]:classes.inputroot} style={props.field_parent_style?props.field_parent_style:null}>
    <div style={{display:"flex"}}>
    <InputLabel style={props.field_label_style?props.field_label_style:null} className={props.field_label_class?classes[props.field_label_class]:classes.label}  >{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
      {/* <FormControl > */}
        <OutlinedInput className={classes.input}
             id = {field_id}
             type={field_type}
             value={field_value}
             placeholder={field_placeholder ? field_placeholder : ''} 
             onChange={event => handleChange(field_id,{},event.target.value)}
            //  error
             disabled={fieldDisable}
             inputProps={{  min:field_minvalue, max:field_maxvalue, minLength:field_minlength, maxLength: field_maxlength}}
             onBlur={()=>(handleBlur())}
        />

        <FormHelperText>{field_helperText}</FormHelperText>
      {/* </FormControl> */}
        
      </div>   
      );
}
