
import React,{useState,useEffect} from 'react'
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import Selects from '@mui/material/Select';
import { FormContext } from '../Component/Forms/FormContext';
import elementValidate from '../Component/validation';
import  { useContext } from  'react';
import { APIGET } from '../services/APIServices';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
 
export default function Customer360Select(props) {
  const classes = useStyles();
    var getOps = 'id';
    const {field_type, field_id, field_label,field_SearchValue, field_placeholder,field_disabled, field_value,field_option_src,field_option, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [fieldOptions,setFieldOptions] = useState(field_options);
    const [fieldType,setFieldType]= useState(field_SearchValue);
   

    useEffect(()=>{
      if(fieldType==="policyLabel"){
        getOps = props.field_option_src.field_option_label;
        getOptions(field_option_src);
    }else if(fieldType==="policyId"){
        getOps = props.field_option_src.field_option_id;
        getOptions(field_option_src);
    }
    props.field_option_src.field_option_srh_context = fieldType;
  },[fieldType]);

    async function getOptions(props) {
    try{
    if(props.field_option_src_context){
    if(props.field_option_check_local_storage){
    //todo check in localstorage
    console.log("check in localstorage for "+props.field_option_src_context);
    }

    let APIRES =await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
    (APIRES)=>{
    console.log(APIRES);
    let responseArray =new Array();
    let _i =0;

    if(props.field_select_all_label){
    let _obj = new Object();
    _obj.option_label = props.field_select_all_label;
    _obj.value = props.field_select_all_value;
    responseArray[_i] = _obj;
    _i++;  
    }
    Object.keys(APIRES.data).forEach( key => {
    let _obj = new Object();
    _obj.option_label = APIRES.data[key][getOps];
    _obj.value = APIRES.data[key][props.field_option_value];
    responseArray[_i] = _obj;
    _i++; 
    })
    //fieldOptions = responseArray; 
    setFieldOptions(responseArray);
    }
    );                           
    }
    }catch(err){/** TODO Do Nothing */}
    }

    const field = {
        field_id:field_id,
        field_type:field_type,
        field_disabled:field_disabled,
        field_value:field_value,
        field_helperText:field_helperText,
        field_minlength:field_minlength,
        field_maxlength:field_maxlength,
        field_minvalue:field_minvalue,
        field_maxvalue:field_maxvalue,
        field_value_type:field_value_type,
        field_mandatory:field_mandatory,
        field_options: (fieldOptions) ? fieldOptions : field_options ,
        field_label:field_label
      }
      function handleBlur(){ 
        elementValidate(field)
      }
      const handletype=(event)=>{
        const getvalue=event.target.value;
        handleChange(field_id,getvalue,"")
        setFieldType(getvalue);
      }
      
    return (
        <div className={classes.inputroot}>
        <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
        <FormControl variant="outlined" className={classes.mw} 
        // {...(error && {error:true})}>
        >
         <div className={classes.css}>
          <div className={classes.selectm}> 
            <Selects className={classes.input}
             
                name="select"
                // id="search" 
                value={field_SearchValue}
                displayEmpty
                // onChange={(event)=>{setFieldType((prev)=>{return (event.target.value)});}}
                onChange={(e)=>handletype(e)}
                placeholder="{field_placeholder}"
                disabled={field_disabled}
                onBlur={handleBlur}
                >
                {/* <MenuItem className={classes.select} value="">None</MenuItem> */}
                <MenuItem value="">{field_placeholder}</MenuItem>

                {
                  field_option.map(
                        (option,i) => ( 
                        (option.value)?<MenuItem key={i} value={option.value}>{option.option_label}</MenuItem>://comment this line when field_options[0].field_value is defined for every select field.
                          <MenuItem key={i} value={option.option_label}>{option.option_label}</MenuItem>
                        )
                    )
                }
            </Selects>
            </div>
            <div className={classes.flexDiv}>
            <Selects className={classes.input}
             
             name="SelectsResult"
             id={field_id} 
             value={field_value}
             displayEmpty
             onChange={(event) => (handleChange(field_id,fieldType,event.target.value))}
             placeholder={field_placeholder}
             disabled={field_disabled}
             onBlur={handleBlur}
             >
           
             <MenuItem value="">{field_placeholder}</MenuItem>

             {
                 fieldOptions.map(
                     (options,i) => ( 
                     (options.value)?<MenuItem key={i} value={options.value}>{options.option_label}</MenuItem>://comment this line when field_options[0].field_value is defined for every select field.
                       <MenuItem key={i} value={options.option_label}>{options.option_label}</MenuItem>
                     )
                 )
             }
            </Selects>
            {/* <Autocomplete  className={classes.multiInput}
                disablePortal
                id="sp"
                options= {fieldOptions.map((option) =>option.option_label)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField placeholder="Search..." {...params} />}
            /> */}
         </div>
         </div>
            <FormHelperText>{field_helperText}</FormHelperText>
        </FormControl>
        </div>
    )
}

 

// import React, { useContext, useEffect, useState } from 'react'
// import { FormContext } from '../Component/Forms/FormContext';
// import {InputLabel} from '@material-ui/core';
// import FormControl from '@mui/material/FormControl';

// import Element from '../FormsControls/Element';

// import FormHelperText from '@mui/material/FormHelperText';
// import Select from '@mui/material/Select';
// import MenuItem from '@mui/material/MenuItem';

// import  useStyles  from './FormControlsCss';
// import OutlinedInput from '@mui/material/OutlinedInput';



// export default function Customer360Search(props) {
//     const classes = useStyles();
//     const { field_type,field_SearchValue, field_id, field_label, field_placeholder, field_value,field_option_src, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
//     const { handleChange } = useContext(FormContext)
  
//    return (
//      <div  className={classes.inputroot}>
//       <InputLabel className={classes.label}>{field_label}</InputLabel>
//      <FormControl className={classes.mw}>
//      <div className={classes.CustomInputGrp}>
//      <div className={classes.selectm}>
//      <Select  className={classes.input} 
//       id={field_id} 
//         value={field_value}
//         displayEmpty
//         onChange={onChange || (event => handleChange(field_id, event))}
        
//         // onBlur={handleBlur}
//      >
//              {field_options.map((option) => (
//                 <MenuItem key={option.option_label} value={option.value}>
//                     {option.option_label}
//                 </MenuItem>
//            ))}
//      </Select>
//        </div>
//      <div className={classes.flexDiv}>
//        <OutlinedInput className={classes.input}
//              id = {field_id}
//              type={field_type}
//             //  value={field_SearchValue}
//              placeholder={field_placeholder ? field_placeholder : ''} 
//              onChange={event => handleChange(field_id, event)}
//              error
//             //  disabled={field_disabled}
//              inputProps={{  min:field_minvalue, max:field_maxvalue}}
//             //  onBlur={handleBlur}
//          />
//        </div>
//      </div>
//      <FormHelperText
//         error
//      ></FormHelperText>
//      {/* </FormContext.Provider>  */}
//      </FormControl>
//      </div>  
//    );
// }
