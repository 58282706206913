import { APIPOST } from '../../services/APIServices';
import elementValidation from '../validation.js';

function validateThisForm(jsonContext) {
    let return_error = true;
    jsonContext.fields.forEach(field => {
        console.log(!elementValidation(field))
      if (!elementValidation(field)) {
        return_error = false;
        console.log(field);
        return false;
      }
    });
    return return_error;
  }

export function handleChange(masterJsn, setMasterJson, fid, valueStr) {
    const updatedJson = { ...masterJsn };
  
    Object.keys(updatedJson).forEach((key) => {
      if (key !== "xioFEConfig") {
        const _object = updatedJson[key];
  
        _object.fields.forEach((field) => {
          const { field_type, field_id, id } = field;
          if (fid === field_id) {
            switch (field_type) {
              case "checkbox":
              case "qRCode":
              case "uploadFileExcel":
                field.field_value = valueStr;
                break;
              // Add additional cases for other field types if needed
              default:
                // Handle unrecognized field types
                field.field_value = valueStr;
                // field.id=id;
                break;
            }
          }
        });
      }
    });
  
    setMasterJson(updatedJson);
  }

export async function handleSubmit(masterJson, apiContextVar, setSuccessPage, hendleErrorMsg) {
  let isFormValid = true;
  const data = {
    "active": "Y",
    "approvalStatus": "PENDING",
    "createById": 1,
    "updateById": 1,
     "enterpriseId": 1,
     "insrtSessionId": 1,
     "status": "New",
  };
// console.log(masterJson);
  Object.keys(masterJson).forEach((key, _i) => {
    if (key !== "xioFEConfig") {
      let _object = masterJson[key];
      if (isFormValid) {
        isFormValid = validateThisForm(_object);
        console.log(_object)
        if (isFormValid) {
          // alert(isFormValid);
          _object.fields.forEach(thisField => {
            const { field_type, field_id, field_value, field_keyValue } = thisField;

            switch (field_type) {
              case "date":
                data[field_id] = String(field_value);
                break;
              default:
                data[field_id] = String(field_value);
                break;
            }
          });
          console.log(data);
          postingData(apiContextVar, data, masterJson, setSuccessPage, hendleErrorMsg);
        } else {
          hendleErrorMsg("Please fill all the required fields");
        }
      }
    }
  });
}

async function postingData(apiContextVar, data, masterJson, setSuccessPage, hendleErrorMsg) {
    // masterData[`${apiContextVar}`] = {}
  try {
    const response = await APIPOST(apiContextVar + '/save', data, masterJson, setSuccessPage, hendleErrorMsg);
    if (response) {
      return response.data;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
