import React,{useEffect,useState} from "react";
import LoginPage from "../modules/Login/LoginPage";
import HomePage from "../modules/Home/HomePage";
// 
import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Switch } from 'react-router-dom';
import { MasterContext } from '../services/MasterContext';
import * as APIServices from '../services/APIServices';
// import { DataSaverOnOutlined } from "@mui/icons-material";


function Greeting(props) {
  const isLoggedIn = props.isLoggedIn;
  console.log("Greeting is getting render");
  console.log(MasterContext);
  {return((isLoggedIn)?( <Router><HomePage/></Router>):(<Router><LoginPage setToken={props.setToken}/></Router>))}
  // {
  //   return ((isLoggedIn) ? (<Router>
  //     <Suspense fallback={<div>Loading....</div>}>
  //       {/* <Switch> */}
  //       <HomePage />
  //       {/* </Switch> */}
  //     </Suspense>
  //   </Router>) : (<Router><LoginPage settingT={props.settingT} /></Router>))
  // }
}


// function App() {
//   const [masterDataResponse, setMasterDataResponse] = React.useState(false);
//   const [token, setToken] = React.useState();

//   React.useEffect(() => {
//     const fetchData = async () => {
//       try {
//         if (localStorage.getItem('token')) {
//           const response = await APIServices.APIGET('policy/1');
//           setToken(localStorage.getItem('token'));
//         } else {
//           setToken(undefined);
//         }
//       } catch (error) {

//       }
//     };

//     fetchData();
//   });
//   let masterData = { "eXMAPLE": "tHIS IS AN EXMPLE " };
//   const funcSetToken = (_token) => {
//     setMasterDataResponse(_token);
//   }

//   setTimeout(() => {
//     if (localStorage.getItem('masterData')) {
//       masterData = localStorage.getItem('masterData');
//     }
//   }, 1000);

//   return (
//     <MasterContext.Provider value={{ masterData, token, funcSetToken }}>
//       <Greeting
//         //  isLoggedIn={true} 
//         isLoggedIn={token}
//         setToken={setToken} />
//     </MasterContext.Provider>
//   );
// }

// export default App;  


function App() {
  const [masterData, setMasterData] = useState({});
  const storedToken = localStorage.getItem('token');
  const [token, setToken] = useState(storedToken?storedToken:null);

  useEffect(() => {
    const fetchData = () => {
      try {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
          setToken(storedToken);
          const response = APIServices.APIGET('carrier/1');
          // do something with response
        } else {
          setToken(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  });

  useEffect(() => {
    const storedMasterData = localStorage.getItem('masterData');
    if (storedMasterData) {
      setMasterData(JSON.parse(storedMasterData));
    }
  }, []);

  const handleSetToken = (newToken) => {
    setToken(newToken);
  };

  return (
    <MasterContext.Provider value={{ masterData, token, handleSetToken }}>
      <Greeting isLoggedIn={Boolean(token)} setToken={handleSetToken} />
    </MasterContext.Provider>
  );
}

export default App;