import React from "react";
import FormioButton from "../FormioButton";
import Axios from 'axios'; 


const Index= ({formData, setFormData, setGuid})=>{
	const reteriveForms = async () => {		
		const formKeys = {
			name: formData.name,
			description: formData.description,
			def:{components:formData.components},
			display: formData.display,
			page:formData.page}
		const response = await Axios.post('http://localhost:8080/api/v1/form-def/FORM_IO', formKeys);
		console.log('I am the Response -> ' , response.data.data.id);
		setGuid(response.data.data.id);
        //save array of guid and name in local storage.
        var currentList = localStorage.getItem('listItems');
        currentList = currentList?JSON.parse(currentList):[];
        let item = {
            "guid": response.data.data.id,
            "name": `Form No - ${currentList.length+1}`
        }
        
        var newList = [...currentList,item]
        localStorage.setItem('listItems', JSON.stringify(newList))
        // setFormData({})
	};

return <FormioButton color="success" label="Save" handleClick={reteriveForms}/>


}

export default Index;