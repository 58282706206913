import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import FormControl from '@mui/material/FormControl';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';

import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import elementValidation from '../Component/validation.js';
import Element          from './Element';
import {UNIQUECHACK} from '../services/APIServices';
import BtnGroup from './ButtonGroup';
import ButtonToggle from './ButtonToggle';
import ButtonWithSelectList from './ButtonWithSelectList'
const ScrubRuleJson = {
    "fields": [
      {
        "field_id":"policyperameter",
        "field_label":"Policy Parameter",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"consenttype",
        "field_label":"Consent Type",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"button",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"buttonSelect", 
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"policyID",
        "field_label":"Policy ID",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"policy",
          "field_option_src_critieria":"all",
          "field_option_label":"policyLabel",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Loading....",
             "value":"0"
         }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"",
        "field_type":"", 
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       }
    ]
};
const ScrubRuleJson2 = {
    "fields": [
      {
        "field_id":"Prefrenceperameter",
        "field_label":"Prefrence Parameter",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"Category",
        "field_label":"Category",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"button",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"buttonSelect", 
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"products",
        "field_label":"Products",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"product",
          "field_option_src_critieria":"all",
          "field_option_label":"productLabel",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Loading....",
             "value":"0"
         }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"",
        "field_type":"", 
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       }
    ]
};
const ScrubRuleJson3 = {
    "fields": [
      {
        "field_id":"Audience",
        "field_label":"Audience Stage",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Audience",
             "value":"Audience"
         },
         {
          "option_label": "Audience",
          "value":"Audience"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"ConsentGiven",
        "field_label":"Consent Given",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Consent",
             "value":"Consent"
         },
         {
          "option_label": "Consent",
          "value":"Consent"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"button",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"buttonSelect", 
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"Duration",
        "field_label":"Duration",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Loading....",
             "value":"0"
         }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"",
        "field_type":"", 
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       }
    ]
};
const ScrubRuleJson4 = {
    "fields": [
      {
        "field_id":"collectionFlow",
        "field_label":"Consent Collection Flow",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Collection",
             "value":"Collection"
         },
         {
          "option_label": "Collection",
          "value":"Collection"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"pullsms",
        "field_label":"Pull SMS",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Pull SMS",
             "value":"Pull"
         },
         {
          "option_label": "Pull SMS",
          "value":"Pull"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"button",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"buttonSelect", 
         "field_options": [
         {
             "option_label": "PNG",
             "value":"png"
         },
         {
          "option_label": "JPG",
          "value":"jpg"
          }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"duration",
        "field_label":"Duration",
        "field_mandatory":"",
        "field_placeholder":"Select",
        "field_type":"select", 
        "field_option_src":{ 
          "field_option_check_local_storage":"true",  
          "field_option_src_context":"",
          "field_option_src_critieria":"all",
          "field_option_label":"",
          "field_option_value":"id" ,
          "field_select_all_label":"", 
          "field_select_all_value":""   
        },
         "field_options": [
         {
             "option_label": "Loading....",
             "value":"0"
         }
        ],
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"Please Select the Asset Type",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       },
       {
        "field_id":"",
        "field_label":"",
        "field_mandatory":"",
        "field_placeholder":"",
        "field_type":"", 
        "field_value":"",
        "field_minlength":"",
        "field_maxlength":"",
        "field_minvalue":"",
        "field_maxvalue":"",
        "field_value_type":"",
        "field_on_blur":"",
        "field_error_msg_required":"",
        "field_error_msg_minlength":"",
        "field_error_msg_maxlength":"",
        "field_error_msg_minvalue":"",
        "field_error_msg_maxvalue":"",
        "field_error_msg_uq":" Name already exist"
       }
    ]
};


export default function ScrubRule(props) {
  const classes = useStyles();
  const [dataSource, setDataSource]= useState([]);
  const [jsonSource, setJsonSource]= useState([]);
  const [elements,setElements] = React.useState(null);
  const required = null;
  const added=[];
    let { id,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    // const { handleChange } = useContext(FormContext)
    React.useEffect(()=>{
        setElements(ScrubRuleJson.fields)
    },[])
    const addParameterJson =
        {
            "label":"Policy Parameter",
            "json":ScrubRuleJson,
        }
    function addFieldflow(value){
    //    alert("wait")
       setJsonSource([...jsonSource,ScrubRuleJson])
    }
    function removeFieldflow(index){
   const values=[...jsonSource];
   values.splice(index, 1);
       setJsonSource(values)
    }
    // console.log(jsonSource);
    function addParameter(value){
        switch(value) {
            case "Policy Parameter":
            addParameterJson.label=value;
            addParameterJson.json=ScrubRuleJson;
              break;
            case "Preference Parameter":
                addParameterJson.label=value;
                addParameterJson.json=ScrubRuleJson2;
              break;
            case "Consent Validity Stage":
                addParameterJson.label=value;
                addParameterJson.json=ScrubRuleJson3;
              break;
            case "Consent Collection":
                addParameterJson.label=value;
                addParameterJson.json=ScrubRuleJson4;
            break;
            default:
             alert('Select Value')
          }
        // console.log(value)
       if(value){
        setDataSource([...dataSource,addParameterJson]) 
       } 
    }

    const handleChange = (id,event,valueStr) => {
        const newElements = { ...elements }
        ScrubRuleJson.fields.forEach(field => {
          const { field_type, field_id } = field;
          if (id === field_id) {
            switch (field_type) {
              case 'checkbox':
                field['field_value'] = event.target.checked;
                break;
                case 'multiSelect':
                    // console.log("This is multiselect")
                    let _tempArr = [];
                    if(typeof(valueStr)!=="string"){
                      valueStr.map((item,index)=>{
                        if(_tempArr.length>0&&item){
                          _tempArr.push(item)
                        }else{
                          if(item){
                            (_tempArr[0]=item)
                          }
                        }
                      })
                    }
                    field['field_value'] = String(_tempArr);
              default:
                field['field_value'] = valueStr;
                break;
            }
          }
          setElements(newElements);
        });
      }

  return (
    <React.Fragment>
    {dataSource?<div>{
    dataSource.map((data,index)=>{ 
        console.log(data)
      return(
      <React.Fragment>
    <div className={classes.mb}>
    <div className={classes.srh}>
    <div><h5>{data.label}</h5></div>
    <div className={classes.chkctra}>
    <input type="checkbox" id="" className="" name="" defaultValue="" />
    <label htmlFor="">Include Criteria</label>
    </div>
    </div>
      <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {data.json.fields?data.json.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup addflow={addFieldflow} value={data.label}/>
    </div>
    </div>
    {jsonSource?<div>{
    jsonSource.map((data,index)=>{ 
        console.log(data)
      return(
        <React.Fragment>
                 <ButtonToggle/>  
            <div className={classes.srb}>
                <div className={classes.scrubRule}>
                    <FormContext.Provider value={{handleChange}}>
                    {ScrubRuleJson.fields?ScrubRuleJson.fields.map((field,i)=><Element key={i} field={field}/>):null}
                    </FormContext.Provider>
                    <BtnGroup addflow={addFieldflow} removeflow={removeFieldflow}/>
                </div>
            </div> 
         </React.Fragment>
     );
    })}
            </div>:""}
    </div>
    </React.Fragment>
    );
    })}
    </div>:""}
    {/* Selecte value and add this pera meter */}
    <ButtonWithSelectList  addParameter={addParameter}/>
    {/* Preference Parameter */}
    {/* <div className={classes.mb}>
    <div className={classes.srh}>
    <div><h5>Preference Parameter</h5></div>
    <div className={classes.chkctra}>
    <input type="checkbox" id="" className="" name="" defaultValue="" />
    <label htmlFor="">Include Criteria</label>
    </div>
    </div>
      <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {ScrubRuleJson2.fields?ScrubRuleJson2.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup/>
    </div>
    </div>
    <ButtonToggle/>  
    <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {ScrubRuleJson2.fields?ScrubRuleJson2.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup/>
    </div>
    </div>
    </div> */}
    {/* Selecte value and add this pera meter */}
    {/* <ButtonWithSelectList/> */}
    {/* Consent Validity Stage */}
    {/* <div className={classes.mb}>
    <div className={classes.srh}>
    <div><h5>Consent Validity Stage</h5></div>
    <div className={classes.chkctra}>
    <input type="checkbox" id="" className="" name="" defaultValue="" />
    <label htmlFor="">Include Criteria</label>
    </div>
    </div>
    <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {ScrubRuleJson3.fields?ScrubRuleJson3.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup/>
    </div>
    </div>
    </div> */}
    {/* Selecte value and add this pera meter */}
    {/* <ButtonWithSelectList/> */}
     {/* Consent Collection */}
     {/* <div className={classes.mb}>
    <div className={classes.srh}>
    <div><h5>Consent Collection</h5></div>
    <div className={classes.chkctra}>
    <input type="checkbox" id="" className="" name="" defaultValue="" />
    <label htmlFor="">Include Criteria</label>
    </div>
    </div>
      <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {ScrubRuleJson4.fields?ScrubRuleJson4.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup/>
    </div>
    </div>
    <ButtonToggle/>  
    <div className={classes.srb}>
    <div className={classes.scrubRule}>
    <FormContext.Provider value={{handleChange}}>
     {ScrubRuleJson4.fields?ScrubRuleJson4.fields.map((field,i)=><Element key={i} field={field}/>):null}
    </FormContext.Provider>
    <BtnGroup/>
    </div>
    </div>
    </div> */}
    </React.Fragment>
    
      );
}
