
import React from "react";
import elementValidation from '../validation';
import { FormJSONCreateContext } from '../FormJSONCreateContext';
import FormRenderer from '../Forms/FormRenderer';
import useStyles from '../ComponentCss';
import { Form } from '../Forms/formElement';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { MasterContext } from '../../services/MasterContext';
import { APIPOST, APIPATCH, APIGET } from '../../services/APIServices';
import ProcurementCoustom from '../../modules/Procurement/CoustomCreate';
import QualityCheckCoustom from '../../modules/QualityCheck/CoustomCreate';
import LeadCoustom from '../../modules/Lead/CoustomCreate';
import UserCoustom from '../../modules/Admin/UserManagement/CreateUser/CoustomCreate';


function CustomForm(props) {
    const classes = useStyles();
    const [postResponse, setPostResponse] = React.useState();
    const { contextJson, renderSuccessPage } = React.useContext(FormJSONCreateContext);
    const { apiContextVar, contextObjective, editData, contextDataArr, contextIndex, createClose, Close } = React.useContext(FormJSONCreateContext);
    const { masterData } = React.useContext(MasterContext);
    const [disableBtn, setDesable] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');

    function TabPanels() {
        // Define your variables here

        let componentToRender;

        if (apiContextVar === 'procurement') {
            componentToRender = (
                <ProcurementCoustom/>
            );
        } else if (apiContextVar === 'qualityCheck') {
            componentToRender = (
                <QualityCheckCoustom/>
            );
        } else if (apiContextVar === 'leads') {
            componentToRender = (
                <LeadCoustom/>
            );
        } else if (apiContextVar === 'userDetails') {
            componentToRender = (
                <UserCoustom/>
            );
        } else {
            // Render the default component when none of the cases match
        }

        return <>{componentToRender}</>;
    }

    function hendleErrorMsg(msg) {
        console.log(msg)
    }
    function validateThisForm(jsonContext) {
        let return_error = true;
        jsonContext.fields.forEach(field => {
            if (!elementValidation(field)) {
                return_error = false;
                console.log(field);
                return false;
            }
        });
        return return_error;
    }

    async function handleSubmit(event) {

        event.preventDefault();
        window.scrollTo(0, 0);
        console.log("Submit Cliked")
        let isFormValid = true;
        window.scrollTo(0, 0);

        const data = {
            "active": "Y",
            "approvalStatus": "PENDING",
            "createById": 1,
            "updateById": 1,
            "enterpriseId": 1,
            "insrtSessionId": 1,
        };
        Object.keys(contextJson).forEach((key, _i) => {
            if (key !== "xioFEConfig") {
                let _object = contextJson[key];
                if (isFormValid) {
                    isFormValid = (validateThisForm(_object));
                    if (isFormValid) {
                        _object.fields.forEach(thisField => {
                            if (thisField.field_type !== "multiSelect" && thisField.field_type !== "date" && thisField.field_type !== "qRCode" && thisField.field_type !== "formJsonData") {
                                data[thisField.field_id] = thisField.field_value;
                            } else if (thisField.field_type === "qRCode") {
                                data[thisField.field_id] = thisField.field_value;
                                data[thisField.field_keyValue] = thisField.url;
                            } else if (thisField.field_type === "date") {
                                var data_regex_format2 = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
                                if (thisField.field_value) {
                                    if (!data_regex_format2.test(thisField.field_value)) {
                                        data[thisField.field_id] = thisField.field_value.split('-').reverse().join('-');
                                    }
                                    else {
                                        data[thisField.field_id] = thisField.field_value;
                                    }
                                }
                            } else if (thisField.field_value === "formJsonData") {
                                data[thisField.field_id] = JSON.stringify(thisField.field_value);
                            }
                            else {
                                data[thisField.field_id] = String(thisField.field_value);
                            }
                        });
                        // setDesable(true)
                    }
                    else {
                        setDesable(false)
                    }
                }
            }
        }
        );

        console.log(data);


        // console.log(getResponse);
        async function postingData(contextJson) {
            masterData[`${apiContextVar}`] = {}
            try {
                const response = await APIPOST(apiContextVar + '/save', data, contextJson, renderSuccessPage, hendleErrorMsg);
                // setPostResponse(response.data);
                if (response) {
                    // setPostResponse(response.data);
                    return response.data
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        async function updateData(contextJson) {
            masterData[`${apiContextVar}`] = {}
            try {
                const response = await APIPATCH(apiContextVar + '/' + contextDataArr[contextIndex].id, data, contextJson, renderSuccessPage, hendleErrorMsg);
                if (response) {
                    setPostResponse(response.data);
                    return response.data
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }


        if (isFormValid) {
            if (contextObjective === "create") {
                postingData(contextJson);
            } else if (contextObjective === "update") {
                updateData(contextJson)
            }
        } else {
            var msg = 'Please Check Form for errors!';
            hendleErrorMsg(msg)
        }
        return false;
    }

    function hendleErrorMsg(msg) {
        setOpen(true)
        setErrorMsg(msg);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        if(contextObjective==="update"){
           Close()
        }else{
            createClose();
        }
    }
    const handleClose = () => {
        setOpen(false);
      };

    return (
        <>
          <Snackbar className="alertError" anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
            <Form onSubmit={handleSubmit}>
                <TabPanels />
                <div className={classes.ctaNxtPrv}>
                    <button  className={classes.btnRset} onClick={handleCancel}>Cancel</button>
                    <button className={classes.btnSmt} type="submit" onClick={handleSubmit}>Submit</button>
                </div>
            </Form>


        </>
    );
}

export default CustomForm;
