import React from 'react'
import Controls from './Control'
const Element =   ({ field: {id,field_default_value,field_unique,field_disabled_Edit,required_column,field_password_length,field_password_pattern,field_SpecialCharacter,url,preview,field_apipath,field_aline,acceptfile,field_import_css,field_script_src,field_parent_class,field_self_style,field_self_class,field_label_style,field_label_class, field_parent_style,field_type,field_style,field_dataId,field_action,field_btn_label,field_btn_label_2, field_id, field_label,
                            field_check_label, field_keyValue,field_placeholder,field_disabled,field_SearchValue, field_value,field_qrCodeDoamin,field_option,field_options,
                            field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,
                            field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,
                field_error_msg_minvalue,field_pastDisable,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory,field_option_src,field_apiContextHistory,field_apiContextComments,field_flowid
                          } }) => {
    switch (field_type) {
        case 'button':
            return (<Controls.Button 
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                ButtonName={field_label}
                ButtonStyle={field_style}
                type={field_action}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                
                />)
        case 'dragFileUpload':
            return (<Controls.DragFileUpload
                    field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                    field_pastDisable={field_pastDisable}
                    field_disabled={field_disabled}
                    ButtonName={field_label}
                    ButtonStyle={field_style}
                    type={field_action}
                    field_label={field_label}
                    field_type={field_type}
                    field_placeholder={field_placeholder}
                    field_value={field_value}
                    field_helperText={field_helperText}
                    field_minlength = {field_minlength}
                    field_maxlength = {field_maxlength}
                    field_maxvalue = {field_maxvalue}
                    field_minvalue ={field_minvalue}
                    field_value_type = {field_value_type}
                    field_error_msg_maxlength={field_error_msg_maxlength}
                    field_error_msg_minvalue={field_error_msg_minvalue}
                    field_error_msg_maxvalue={field_error_msg_maxvalue}
                    field_error_msg_minlength={field_error_msg_minlength}
                    field_error_msg_required={field_error_msg_required}
                    field_error_msg_uq={field_error_msg_uq}
                    field_mandatory={field_mandatory}
                    />)
         case 'uploadFile':
            return (<Controls.UploadFile
                    field_id={field_id}
                    field_parent_class={field_parent_class}
                    field_parent_style={field_parent_style}
                    field_label_class={field_label_class}
                    field_label_style={field_label_style}
                    field_self_class={field_self_class}
                    field_self_style={field_self_style}
                    field_pastDisable={field_pastDisable}
                    field_disabled={field_disabled}
                    ButtonName={field_label}
                    ButtonStyle={field_style}
                    type={field_action}
                    acceptfile={acceptfile}
                    url={url}
                    preview={preview}
                    field_label={field_label}
                    field_type={field_type}
                    field_placeholder={field_placeholder}
                    field_value={field_value}
                    field_helperText={field_helperText}
                    field_minlength = {field_minlength}
                    field_maxlength = {field_maxlength}
                    field_maxvalue = {field_maxvalue}
                    field_minvalue ={field_minvalue}
                    field_value_type = {field_value_type}
                    field_error_msg_maxlength={field_error_msg_maxlength}
                    field_error_msg_minvalue={field_error_msg_minvalue}
                    field_error_msg_maxvalue={field_error_msg_maxvalue}
                    field_error_msg_minlength={field_error_msg_minlength}
                    field_error_msg_required={field_error_msg_required}
                    field_error_msg_uq={field_error_msg_uq}
                    field_mandatory={field_mandatory}
                    />)
         case 'uploadFileExcel':
            return (<Controls.UploadFileExcel
                    field_id={field_id}
                    field_parent_class={field_parent_class}
                    field_parent_style={field_parent_style}
                    field_label_class={field_label_class}
                    field_label_style={field_label_style}
                    field_self_class={field_self_class}
                    field_self_style={field_self_style}
                    field_pastDisable={field_pastDisable}
                    field_disabled={field_disabled}
                    ButtonName={field_label}
                    ButtonStyle={field_style}
                    type={field_action}
                    acceptfile={acceptfile}
                    required_column={required_column}
                    url={url}
                    preview={preview}
                    field_label={field_label}
                    field_type={field_type}
                    field_placeholder={field_placeholder}
                    field_value={field_value}
                    field_helperText={field_helperText}
                    field_minlength = {field_minlength}
                    field_maxlength = {field_maxlength}
                    field_maxvalue = {field_maxvalue}
                    field_minvalue ={field_minvalue}
                    field_value_type = {field_value_type}
                    field_error_msg_maxlength={field_error_msg_maxlength}
                    field_error_msg_minvalue={field_error_msg_minvalue}
                    field_error_msg_maxvalue={field_error_msg_maxvalue}
                    field_error_msg_minlength={field_error_msg_minlength}
                    field_error_msg_required={field_error_msg_required}
                    field_error_msg_uq={field_error_msg_uq}
                    field_mandatory={field_mandatory}
                    />)
        case 'text':
            return (<Controls.Input
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                id={id}
                field_unique={field_unique}
                field_disabled_Edit={field_disabled_Edit}
                field_apipath={field_apipath}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                />)
        case 'email':
            return (<Controls.Input
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                id={id}
                field_unique={field_unique}
                field_disabled_Edit={field_disabled_Edit}
                field_apipath={field_apipath}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                />)
        case 'inputWithButton':
            return (<Controls.InputWithButton
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_btn_label={field_btn_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                />)        
        case 'textContent':
            return(<Controls.TextContent
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
            />)
        case 'number':
        return (<Controls.Input
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                id={id}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
        />)
        case 'password':
        return (<Controls.PasswordInput
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_password_length = {field_password_length}
                field_password_pattern = {field_password_pattern}
                field_SpecialCharacter = {field_SpecialCharacter}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                />)
        case 'multilineText':
            return (<Controls.MultilineText
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                field_option_src = {field_option_src}
            />) 
         case 'multilineTextWithButton':
            return (<Controls.MultilineTextWithButton
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_btn_label={field_btn_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                field_option_src = {field_option_src}
            />)           
        case 'select':
            return (<Controls.Select
                field_id={field_id}
                id={id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_disabled_Edit={field_disabled_Edit}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                 field_options={field_options}
                field_option_src = {field_option_src}
                // onBlur = {handleBlur}
            />)
         case 'checkBoxList':
            return (<Controls.CheckBoxList
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                 field_options={field_options}
                field_option_src = {field_option_src}
                // onBlur = {handleBlur}
            />)
        case 'checkbox':
            return (<Controls.Checkbox
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_check_label={field_check_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}
                // onBlur = {handleBlur}
            />)
        case 'radio':
            return (<Controls.RadioGroup
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_aline={field_aline}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}  
                field_options={field_options} 
                field_option_src = {field_option_src}
                // onBlur = {handleBlur}
                />)
        case 'custom':
            return (<Controls.Custom
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                

                />)
        case 'date':
            return (<Controls.DatePicker
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_default_value={field_default_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                

                />)
        case 'multiSelect':
            return(<Controls.MultipleSelect
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}

                    // onBlur = {handleBlur}
                />)
        case 'time':
            return (<Controls.TimePicker
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}           
                 />)
        case 'timeStartToEnd':
            return (<Controls.TimeStartToEnd
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}        

                />) 
        case 'dateRange':
            return (<Controls.DateToDate
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}        

                />) 
         case 'searchableSelect':
            return (<Controls.SearchableSelectBox
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                field_options={field_options}
                field_option_src = {field_option_src}

            />)
      /*  case 'multipleSelect':
            return (<Controls.MultipleSelect
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}

                    // onBlur = {handleBlur}
                />)
                */
        case 'qRCode':
            return (<Controls.QRCode
               field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_qrCodeDoamin={field_qrCodeDoamin}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_keyValue ={field_keyValue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                field_options={field_options}
                field_option_src = {field_option_src} 
                url={url}
            />) 
        case 'qRCodeUpload':
            return (<Controls.QRCodeUpload
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_qrCodeDoamin={field_qrCodeDoamin}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_keyValue ={field_keyValue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                field_options={field_options}
                field_option_src = {field_option_src} 
            />)     
        case 'imageUpload':
            return (<Controls.ImageUpload
                   
            />)  
        case 'formIO':
             return (<Controls.Formio
                
        />)      
        case 'previewBox':
            return (<Controls.PreviewBox
                
            />)
        case 'confirmation':
             return (<Controls.Confirmation
              field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_check_label={field_check_label}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_dataId={field_dataId}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}   
             />)
              case 'formBreakRow':
                return (<Controls.FormBrack 
                    field_label={field_label}
            />)
              case 'requiredMsg':
                return (<Controls.RequiredMsg 
                    field_label={field_label}
                    field_btn_label={field_btn_label}
                    field_btn_label_2={field_btn_label_2}
            />)
            case 'heading':
                return (<Controls.Heading 
                    field_label={field_label}
            />)
             case 'customer360Search':
                return (<Controls.Customer360Search
                id={id}
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_type={field_type}
                field_label={field_label}
                field_placeholder={field_placeholder}
                field_SearchValue={field_SearchValue}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}               
                 field_options={field_options}
                 field_option={field_option}
                field_option_src = {field_option_src}            
    
                    />)
                     case 'customer360Select':
                        return (<Controls.Customer360Select
                        id={id}
                        field_id={field_id}
                        field_parent_class={field_parent_class}
                        field_parent_style={field_parent_style}
                        field_label_class={field_label_class}
                        field_label_style={field_label_style}
                        field_self_class={field_self_class}
                        field_self_style={field_self_style}
                        field_pastDisable={field_pastDisable}
                        field_disabled={field_disabled}
                        field_type={field_type}
                        field_label={field_label}
                        field_placeholder={field_placeholder}
                        field_SearchValue={field_SearchValue}
                        field_value={field_value}
                        field_helperText={field_helperText}
                        field_minlength = {field_minlength}
                        field_maxlength = {field_maxlength}
                        field_maxvalue = {field_maxvalue}
                        field_minvalue ={field_minvalue}
                        field_value_type = {field_value_type}
                        field_error_msg_maxlength={field_error_msg_maxlength}
                        field_error_msg_minvalue={field_error_msg_minvalue}
                        field_error_msg_maxvalue={field_error_msg_maxvalue}
                        field_error_msg_minlength={field_error_msg_minlength}
                        field_error_msg_required={field_error_msg_required}
                        field_error_msg_uq={field_error_msg_uq}
                        field_mandatory={field_mandatory}               
                         field_options={field_options}
                         field_option={field_option}
                        field_option_src = {field_option_src}            
            
                            />)
        
        case 'dragDropSelect':
            return(<Controls.DragDrapSelect
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}

                    // onBlur = {handleBlur}
                />)
        case 'scrubRule':
            return(<Controls.ScrubRule
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}

                    // onBlur = {handleBlur}
                />)
        case 'buttonSelect':
            return(<Controls.ButtonSelect
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}
                />)
        case 'btnGroup':
            return(<Controls.BtnGroup
                field_id={field_id}
                field_parent_class={field_parent_class}
                field_parent_style={field_parent_style}
                field_label_class={field_label_class}
                field_label_style={field_label_style}
                field_self_class={field_self_class}
                field_self_style={field_self_style}
                field_pastDisable={field_pastDisable}
                field_disabled={field_disabled}
                field_label={field_label}
                field_type={field_type}
                field_placeholder={field_placeholder}
                field_value={field_value}
                field_helperText={field_helperText}
                field_minlength = {field_minlength}
                field_maxlength = {field_maxlength}
                field_maxvalue = {field_maxvalue}
                field_minvalue ={field_minvalue}
                field_value_type = {field_value_type}
                field_error_msg_maxlength={field_error_msg_maxlength}
                field_error_msg_minvalue={field_error_msg_minvalue}
                field_error_msg_maxvalue={field_error_msg_maxvalue}
                field_error_msg_minlength={field_error_msg_minlength}
                field_error_msg_required={field_error_msg_required}
                field_error_msg_uq={field_error_msg_uq}
                field_mandatory={field_mandatory}                    
                field_options={field_options}
                field_option_src = {field_option_src}
                />)
                case 'ActivitySection':
                    return (<Controls.ActivitySection 
                        field_id={field_id}
                        id={id}
                        field_parent_class={field_parent_class}
                        field_parent_style={field_parent_style}
                        field_label_class={field_label_class}
                        field_label_style={field_label_style}
                        field_self_class={field_self_class}
                        field_self_style={field_self_style}
                        field_pastDisable={field_pastDisable}
                        field_disabled={field_disabled}
                        field_flowid={field_flowid}
                        field_apiContextComments={field_apiContextComments}
                        field_apiContextHistory={field_apiContextHistory}
                        ButtonName={field_label}
                        ButtonStyle={field_style}
                        type={field_action}
                        field_label={field_label}
                        field_type={field_type}
                        field_placeholder={field_placeholder}
                        field_value={field_value}
                        field_helperText={field_helperText}
                        field_minlength = {field_minlength}
                        field_maxlength = {field_maxlength}
                        field_maxvalue = {field_maxvalue}
                        field_minvalue ={field_minvalue}
                        field_value_type = {field_value_type}
                        field_error_msg_maxlength={field_error_msg_maxlength}
                        field_error_msg_minvalue={field_error_msg_minvalue}
                        field_error_msg_maxvalue={field_error_msg_maxvalue}
                        field_error_msg_minlength={field_error_msg_minlength}
                        field_error_msg_required={field_error_msg_required}
                        field_error_msg_uq={field_error_msg_uq}
                        field_mandatory={field_mandatory}
                        
                        />)
        default:
            return null;
    }


}

export default Element
