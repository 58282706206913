import React from "react";
import {Typography } from '@material-ui/core';
import FormScopeDefiner from "../../../../Component/Forms/FormScopeDefiner";
import PolicyImg    from "../../../../Component/PolicyImg";
import smsPolicyImg from '../../../../images/smspolicy.svg'
import useStyles    from '../../../../Component/ComponentCss';
import contextJson from './masterJson.json';
const apiContextVar = "reportingManager";
const contextHeading = "Add Reporting Manager";

function Create() {
    // const [successRender,setSuccessRender] = React.useState(undefined);
    // const [successPageData,setSuccessPageData] = React.useState(undefined);
    let sideImageData={};
    const classes=useStyles();
    const contextClasses = classes.topSpace;
    const contextObjective = "create";

    Object.keys(contextJson).forEach((key,index) =>{
      if(key!=="xioFEConfig"){
        contextJson[key].fields.map((field)=>{
          if(field.field_type==="custom"){
            field.field_value = "{}";
          } else if(field.field_type !== "hidden"&&field.field_default_value){
            field.field_value = field.field_default_value
            field.id = ""
          } else{
            field.field_value = "";
            field.field_helperText = "";
            field.id = "";
          }
          return field; // <-- add this return statement
        });
        
        // =---sideIMage---dataAssing--->
        if(contextJson['xioFEConfig']&&contextJson['xioFEConfig'].sideImageData){
          sideImageData=contextJson['xioFEConfig'].sideImageData;
        }
      }
    });


    return (
      <div className={classes.main}>
          {/* <PolicyImg Image={smsPolicyImg} data={sideImageData}/>  */}
          <div className={classes.formAline}>
            <Typography variant="h6" className={classes.marginChange}>
              {contextHeading}
            </Typography>
          <FormScopeDefiner value={{contextJson,apiContextVar,contextHeading,contextClasses,contextObjective}}>
          </FormScopeDefiner> 
          </div>
      </div>
    )
  }

  export default Create;
  