// import formJSON from './formElement.json';
import * as React from 'react';
import { useReducer,useState, useEffect } from 'react';

import { FormContext }      from './FormContext'
import Element              from '../../FormsControls/Element';
import elementValidation from '../validation';

import { FormJSONCreateContext } from '../FormJSONCreateContext';
import { AltRoute } from '@mui/icons-material';


function FormRenderer(props) {
  const {contextJson} = React.useContext(FormJSONCreateContext);
  const {formJSON}=props;
  const [elements, setElements] = useState(null);
  const [validateType,setValidateType] = useState(false);
  // const [,forceUpdate] = useReducer(x => x + 1, 0);
  const [state, updateState] = React.useState();
  const forceUpdate = React.useCallback((args) => setElements(args), []);

  useEffect(() => {
    forceUpdate(props.formJSON)
  })/**/

  function valueExchange(field_id_1,field_id_2,change_to_1,value_from_1,key_to_change_2){
      /**
       * field_id_1 and field_id_2 are mandatory
       * provide one from change_to_1 , value_From_1
       * key_to_change is also manadatory.
       */
      function getField(_field_id){

        Object.keys(contextJson).forEach((key,index) =>{
          if(key!=="xioFEConfig"){
          let _object = contextJson[key];
          if(contextJson[key])
          return contextJson[key].fields.map((thisField,_index)=> {
            if(thisField.field_id === _field_id){
              return contextJson[key].fields[_index];
            }
          })}
        })
      }
      if(value_from_1 && !(change_to_1)){
        getField(field_id_2)[key_to_change_2] = value_from_1;
      }
      else if(field_id_1&&field_id_2){
         getField(field_id_2)[key_to_change_2] = getField(field_id_1)[change_to_1];
      }
      else{
        if(field_id_1){
          return console.error(`Provide  field_id_1`)
        }
        else if(field_id_2){
          return console.error(`Provide field_id_2`);
        }
        else{
          return console.error(`Please provide all the required arguments. `);
        }
    }
  }
  const { fields,} = elements ?? {}

  const handleChange = (id, event,valueStr) => {
    const newElements = { ...elements }
    //console.log(contextJson.PolicyParameter.fields)
    Object.keys(contextJson).forEach((key,index) =>{
      if(key!=="xioFEConfig"){
      let _object = contextJson[key];
      if(contextJson[key])
      contextJson[key].fields.forEach(thisField => {
        console.log(thisField);
        if(thisField.field_runtime_ops){
          thisField.field_runtime_ops.forEach(field => {
            if(field.condition)
              var resultEval ;
              var stmt = "resultEval = thisField."+field.condition.field+" "+field.condition.operator+" \""+field.condition.checkVal+"\""
              eval(stmt);
              console.log(stmt);
              console.log(resultEval);
              if(resultEval){
                console.log(field.ops);
                eval(field.ops);
              }
          });
        }
      })}
    })
    Object.keys(contextJson).forEach((key,index) =>{
      if(key!=="xioFEConfig"){
      let _object = contextJson[key];
      contextJson[key].fields.forEach((field, index) => {
        const { field_type, field_id } = field;
        console.log(field_id,id,field_id===id);
        if (id === field_id) {
          switch (field_type) {
            case 'checkbox':
              contextJson[key].fields[index]['field_value'] = valueStr;
              // if(event.target.checked.toString()===true.toString()){
              //   contextJson[key].fields[index]['field_value'] = event.target.checked.toString();
              // }else
              // {
              //   contextJson[key].fields[index]['field_value'] = "";
              // }
              //props.formJSON.fields[index]['field_value']=event.target.checked;
              //status of cheack box should be here.
              break;
            case 'qRCode':
              contextJson[key].fields[index]['field_value']=valueStr;
              contextJson[key].fields[index]['url']=event;

              break;
            case 'uploadFileExcel':
              contextJson[key].fields[index]['field_value']=valueStr;
              contextJson[key].fields[index]['field_file_format']=event;

              break;
            case 'customer360Search':
              contextJson[key].fields[index]['field_value']=valueStr;
              contextJson[key].fields[index]['field_SearchValue']=event;
            break;
            case 'customer360Select':
              contextJson[key].fields[index]['field_value']=valueStr;
              contextJson[key].fields[index]['field_SearchValue']=event;
            break;
            case 'multiSelect':
              console.log("This is multiselect")
              let _tempArr = [];
              if(typeof(valueStr)!=="string"){
                valueStr.map((item,index)=>{
                  if(_tempArr.length>0&&item){
                    _tempArr.push(item)
                  }else{
                    if(item){
                      (_tempArr[0]=item)
                    }
                  }
                })
              }
              contextJson[key].fields[index]['field_value'] = String(_tempArr);
              //console.log(typeof(contextJson.PolicyParameter.fields[index]['field_value']))
              break;
              case 'checkBoxList':
                console.log("This is check list",valueStr)
                contextJson[key].fields[index]['field_value'] = valueStr;
                //console.log(typeof(contextJson.PolicyParameter.fields[index]['field_value']))
                break;
            case 'custom':
              contextJson[key].fields[index]['field_value']=JSON.stringify(JSON.stringify(valueStr))
              console.log(contextJson[key].fields[index]['field_value']);
              break;
            case 'formio':

            break;
            case 'qRCodeUpload':
              contextJson[key].fields[index]['field_value']=valueStr;
              break;
            case 'date':
              contextJson[key].fields[index]['field_value'] = valueStr;
              break;
              
            default:
             // console.log(contextJson.PolicyParameter.fields[index]['field_value'])
              contextJson[key].fields[index]['field_value'] = valueStr;
              //props.formJSON.fields[index]['field_value']=event.target.value;
            break;
          }
        }
        setTimeout(forceUpdate(newElements),0);
      })}
      let customJsonContext;
      function StrtoJSON(strObj){
        if(typeof(strObj)!=="string"){
          return {strObj}
        }
        else{
          if(strObj != ""){
            return StrtoJSON(JSON.parse(strObj));
          }else{

          }
        }
      }  
      Object.keys(contextJson).forEach((key,index) =>{
        if(key!=="xioFEConfig"){
        let _object = contextJson[key];
        contextJson[key].fields.forEach((field, index) => {
          const { field_type,field_value} = field;
          if (field_type === 'custom' && field_value !== "") {
            let valueStrCustom = StrtoJSON(field_value);
            customJsonContext = valueStrCustom.strObj;
            if(customJsonContext)
            Object.keys(customJsonContext).forEach((_key,_index) =>{
              let __object = customJsonContext[_key]
              __object.forEach((_field,__index)=>{
                const {field_id} = _field;
                if (id === field_id) {
                 customJsonContext[_key][__index].field_value = valueStr;
                   contextJson[key].fields[index]['field_value']=JSON.stringify(JSON.stringify(customJsonContext))
               }
              })
              
            })
          }        
          setTimeout(forceUpdate(newElements),0);

        })}
      });
    
    });
    // console.log(elements)
    // forceUpdate();
}
useEffect(()=>{
  Object.keys(contextJson).forEach((key,index) =>{
    if(key!=="xioFEConfig"){
    let _object = contextJson[key];
    if(contextJson[key].fields)
    contextJson[key].fields.forEach(thisField => {
      console.log(thisField);
      if(thisField.field_runtime_ops){
        thisField.field_runtime_ops.forEach(field => {
          if(field.condition)
            var resultEval ;
           
              var stmt = "resultEval = thisField."+field.condition.field+" "+field.condition.operator+" \""+field.condition.checkVal+"\""
           
            eval(stmt);
            console.log(stmt);
            console.log(resultEval);
            if(resultEval){
              console.log(eval(field.ops));
              eval(field.ops);
              setTimeout(forceUpdate(props.formJSON),0);
              // forceUpdate();

            }
        });
      }
    })}
  })
})

//console.log(contextJson.PolicyParameter.fields)
return (
  <React.StrictMode>
  <FormContext.Provider value={{ handleChange }}>
    <div className="App containers">
        {fields ? fields.map((field, i) =>{ 
          return  (<Element key={i} field={field}/>)}) :
           null}
    </div>
  </FormContext.Provider>
  </React.StrictMode>
);
}

export default FormRenderer;
