import React, { useContext } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import { FormControl, InputLabel, RadioGroup as MuiRadioGroup, FormControlLabel, Radio } from '@material-ui/core';

import  useStyles  from './FormControlsCss';
export default function RadioGroup(props) {
    const classes = useStyles();
    // const { name, label, value, onChange, items } = props;
    const { field_type, field_id, field_label, field_placeholder,field_disabled,field_aline, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)

    return (
        <div className={classes.inputroot}>
           <div style={{display:"flex"}}>
        <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
        style={{fontSize: "18px",
        color: "#d71729",
        fontWeight: "400",
        lineHeight: "20px",
        transform: "translateY(-2px)"}}>*</span>:null}
        </div>
            <FormControl className={field_aline==="vertical"?null:`${classes.rbdb}`}>
            <MuiRadioGroup 
                // name={name}
                id = {field_id}
                type={field_type}
                disabled={field_disabled}
                onChange={onChange || (event => handleChange(field_id, {},event.target.value))}
                defaultValue={field_value}
                value={field_value}
               >
                {
                    field_options.map(
                        (option,i) => ((option.value)?
                            <FormControlLabel key={i} value={option.value} control={<Radio />} label={option.option_label} />:
                            <FormControlLabel key={i} value={option.option_label} control={<Radio />} label={option.option_label} />
                        )
                    )
                }
            </MuiRadioGroup>
        </FormControl>
        </div>
    )
}
