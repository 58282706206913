import React from 'react';
import { 
  emphasize,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
// import HomeIcon from '@material-ui/icons/Home';
import { ReactComponent as HomeIcon } from '../images/svgicon/home.svg';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './ComponentCss';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {useLocation} from 'react-router-dom';

import { useNavigate } from 'react-router-dom';

//dynamic path 
// import {DynamicPath} from '../services/DynamicPath';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    
    backgroundColor:'transparent',
    // backgroundColor: theme.palette.grey[100],
    height: theme.spacing(0),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },

}))(Chip);

// function handleClick(event) {
//   event.preventDefault();
// }



export default function BreadcrumbsGrp() {
  const classes = useStyles();
  const path = useLocation();
  let pathString = String(path.pathname);
  var _tmpArray = pathString.replace(/([A-Z])/g, ' $1').split(" ");
  // console.log(_tmpArray);
  var  _pathString = _tmpArray.join(" ");
  // console.log(_pathString);
  let arr = _pathString.split('/');
  // console.log(arr);

  let arry = pathString.split('/');
  let _link=arry.join(','+'/');
  let _arry = _link.split(',');

  // console.log(_arry);
  // console.log(arry);

  let navigate = useNavigate()
  const [open, setOpen] = React.useState();
  const [forec, setForce] = React.useState(0);
  
  // const handleClick = () => {
  //   let path = ""
  //   for (let i = 0; i <= open; i++) {
  //     path = path+_arry[i];
  //   }
  //   navigate(path);

  // };

  React.useEffect(()=>{
    if(open>=0){
      let path = ""
      for (let i = 0; i <= open; i++) {
        path = path+_arry[i];
        console.log(path);
      }
      navigate(path);
    }else{
      setOpen(0)
    }
},[forec])
// console.log('click link',open)
// console.log('click forec link',forec)
  return (
   
    <ThemeProvider>
      <Breadcrumbs
       aria-label="breadcrumb"
        className={classes.breadCrmb}
        // separator={<NavigateNextIcon fontSize="small" />}
        separator="»"
        >
            {arr.map((item,index)=>{
              let homeIcon = null;
              if(item!==" " && item!=="  "){
                if(item==="" && index===0){
                  homeIcon = <StyledBreadcrumb  label="Home"/>
                }
                return(
                <div key={index} className={classes.Brdcrb}>
                  {/* <h1>{item}</h1> */}
                  
                  <StyledBreadcrumb
                    component="a"
                    label={String(item)}
                    icon={homeIcon}
                    // href={item}
                    // onClick={console.log('\nbutton cliked '+ item)}
                    // onClick= {handleClick(),setOpen(index) }
                    onClick={()=>{setOpen(index);setForce(forec+1)}}
                  />
                  </div>
                )
              }
              return null;
            })}
      </Breadcrumbs>
    </ThemeProvider>
   
  );
}
