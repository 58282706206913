import React from 'react'
// import {Button as MuiButton} from "@material-ui/core";
import useStyles from './FormControlsCss';

export default function Button(props) {
 
    let {ButtonName,type,id, onClick,field_disabled,color,reltar, icon} = props
    
    const classes = useStyles();

    return ( 
        // <div className={props.field_parent_class?classes[props.field_parent_class]:null} style={props.field_parent_style?props.field_parent_style:null}>
        <button type={type} id={id} disabled={field_disabled} icon={icon} reltar={reltar} onClick={onClick} color={color} className={props.className} style={props.ButtonStyle} >{ButtonName}</button>
        // </div>
    )
        
        
}
