
import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import copy from "copy-to-clipboard";  
import axios from 'axios';
import Button from './Button';
import FormControl from '@mui/material/FormControl';
import logo from "../images/customerconsent.png";
import {APIUPLOAD}            from '../services/APIServices';
import { MdOutlineFileUpload, MdOutlineDone} from "react-icons/md";
import *as XLSX from 'xlsx';
import FormHelperText from '@mui/material/FormHelperText';



function UploadFileExcel(props) {
  const classes = useStyles();
  const [file, setFile] = useState()
  const [uploadPercentage, setUploadPercentage] = useState();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [headers, setheader] = useState(null);
  const [row, setRow] = useState(null);
  const [disable, setDisable] = useState(true);
  const [filename, setFilename] = useState(null);
  const [fileFormat, setFileFormat] = useState('');
  const [copyText, setCopyText] = useState('');
  const [error, seterror] = useState('');
  
  let { id,field_type, field_id,field_apipath,field_disabled,preview,field_label,acceptfile,required_column, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
  const { handleChange } = useContext(FormContext)
  const apipath= "uploadFiles/uploadFile";
  const fileType=acceptfile;
console.log(acceptfile)

  function getFileExtension(filename) {
    const Extension = filename.split('.').pop();
    setFileFormat(Extension)
  }

  const handleChangeFile = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
      const efile=event.target.files[0];
      console.log(efile.name)
      const data= await efile.arrayBuffer(efile);
      const excelFile=XLSX.read(data,{type:'buffer'});
      const excelSheet=excelFile.Sheets[excelFile.SheetNames[0]];
      const exceljson=XLSX.utils.sheet_to_json(excelSheet);
      setRow(exceljson.length)
      setFilename(efile.name)
      setUploadedFile(null);
     const fileCol = Object.keys(exceljson[0]);
      getFileExtension(efile.name)
    if(efile&&fileType.includes(efile.type)){
      if(fileCol.find(element => element === 'MSISDN' || element === 'msisdn') || fileCol.find(element => element === 'EMAIL' || element === 'Email' || element === 'email')){
        setheader(Object.keys(exceljson[0]))
        setDisable(false)
        seterror('')
        // alert('ok file')
      }else{
        setDisable(true)
        setheader(Object.keys(exceljson[0]))
        seterror('The header fields must have following name: MSISDN, Email')
      }
    }else{
          setDisable(true)
          setheader(null)
          seterror('Please select following files:.csv, .xlsx')
      } 
    }
  }
//   const onSubmit = async e => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('file', file);
//     let token = localStorage.getItem("token");
//     let _authorization = `Bearer ${token}`;
//     try {
//       // const res = await UploadFile('uploadFiles/uploadFile', formData, {
//       const res = await axios.post(apipath, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'token':token,
//           'authorization':_authorization,
//           "Access-Control-Allow-Origin": "http://localhost:3000,*",
//         },
//         onUploadProgress: progressEvent => {
//           setUploadPercentage(
//             parseInt(
//               Math.round((progressEvent.loaded * 100) / progressEvent.total)
//             )
//           );
//         }
//       });
//       console.log(uploadPercentage);
//      // Clear percentage
//      setTimeout(() => setUploadPercentage(), 1000);

//      const { fileName,uploadFilesLabel,filePath } = res.data.data;
//     // alert(uploadFilesLabel);
//     handleChange(field_id,fileFormat,uploadFilesLabel);
//      setUploadedFile(uploadFilesLabel);
//      setCopyText(filePath);
//     //  setMessage('File Uploaded');
//    } catch (err) {
//      if (err.response.status === 500) {
//       alert('There was a problem with the server');
//       //  setMessage('There was a problem with the server');
//      } else {
//       //  setMessage(err.response.data.msg);
//      }
//      setUploadPercentage()
//    }
//  };

 function setFileData(data){
  console.log(data)
  const { fileName,uploadFilesLabel,filePath } = data;
  handleChange(field_id,fileFormat,uploadFilesLabel);
   setUploadedFile({ fileName, filePath });
   setCopyText(filePath);
}


 const onSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await APIUPLOAD(apipath, formData, setUploadPercentage, setFileData);
    console.log('Upload successful!', response);
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};

 const copyToClipboard = () => {
  copy(copyText);
  alert(`You have copied "${copyText}"`);
}
  return (
<div className={classes.fileroot}>
<div style={{display:"flex"}}>
        <InputLabel style={props.field_label_style?props.field_label_style:null} className={props.field_label_class?classes[props.field_label_class]:classes.label}  >{field_label}</InputLabel>{field_mandatory==="yes"?<span 
          style={{fontSize: "18px",
          color: "#d71729",
          fontWeight: "400",
          lineHeight: "20px",
          transform: "translateY(-2px)"}}>*</span>:null}
      </div>
    <div className={classes.fubox}>
         <InputLabel className={classes.label}>Upload</InputLabel>
         <div className={classes.alinfilebtn}>
         <div  variant="outlined" className={classes.upldfl}>
         {filename?<label for="files">{filename}</label>:<label for="files">Upload file only in .xlxs, .csv</label>}
            <input type="file" 
            id="files"
            onChange={handleChangeFile} 
            accept={acceptfile}
            />
            {/* <button type="button" onClick={copyToClipboard}>Copy Url</button>: */}

          {uploadPercentage && (<div className={classes.progress}>
          <div
            className={classes.progressBar}
            role='progressbar'
            style={{ width: `${uploadPercentage}%` }}
          >
              {uploadPercentage}%
            </div>
          </div>)}
          </div>
          <button type="submit"  onClick={onSubmit} disabled={disable}><MdOutlineFileUpload /></button>
         </div>

      {headers && (<div className={classes.fileheader}>
      <InputLabel className={classes.label}>Preview</InputLabel>
      <table className={classes.headerview}>
        <thead>
          <tr>
            {headers.map((item, index) => {
          return (
            <th key={index}>{item}</th>
            )})}
          </tr>
        </thead>
      </table>
      </div>)}
        {error&&<FormHelperText>{error}</FormHelperText>}
        {uploadedFile&&(<div class={classes.done}><span><MdOutlineDone /></span><p>{row} Rows has been successfully Upload</p></div>)}
  
    </div>
      
  </div>
  );
}

export default UploadFileExcel;

// import React, { useState } from 'react';
// import * as XLSX from 'xlsx';

// const UploadFileExcel = () => {
//   const [fileName, setFileName] = useState('');
//   const [sheetData, setSheetData] = useState([]);
//   const [sql, setSql] = useState('');

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     setFileName(file.name);
//     readExcelFile(file);
//   };

//   const readExcelFile = async (file) => {
//     try {
//       const arrayBuffer = await readFileAsArrayBuffer(file);
//       const workbook = XLSX.read(arrayBuffer, { type: 'array' });
//       const sheetName = workbook.SheetNames[0];
//       const sheet = workbook.Sheets[sheetName];
//       const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
//       setSheetData(data);
//       convertExcelDataToSqlInsertQuery(data);
//     } catch (error) {
//       console.error('Error reading Excel file:', error);
//     }
//   };

//   const convertExcelDataToSqlInsertQuery = (data) => {
//     if (data.length === 0) {
//       setSql('');
//       return;
//     }

//     const columns = data[0];
//     const rows = data.slice(1); // Exclude the header row

//     let sqlQuery = `INSERT INTO my_table (${columns.join(', ')}) VALUES\n`;

//     for (let i = 0; i < rows.length; i++) {
//       const row = rows[i];
//       const values = row.map((value) => `'${value}'`).join(', ');
//       sqlQuery += `(${values})`;

//       if (i < rows.length - 1) {
//         sqlQuery += ',\n';
//       }
//     }

//     setSql(sqlQuery);
//   };

//   const readFileAsArrayBuffer = (file) => {
//     return new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.onload = (event) => {
//         resolve(event.target.result);
//       };
//       reader.onerror = (event) => {
//         reject(event.target.error);
//       };
//       reader.readAsArrayBuffer(file);
//     });
//   };

//   return (
//     <div>
//       <input type="file" name="file" onChange={handleFileChange} />
//       <button onClick={readExcelFile}>Read Excel File</button>
//       <pre>{sql}</pre>
//     </div>
//   );
// };

// export default UploadFileExcel;
