import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from '../Component/ComponentCss';
import { Form } from '../Component/Forms/formElement';
import Button from './Button';
import FormRenderer from '../Component/Forms/FormRenderer'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
//validation 
import elementValidation from '../Component/validation.js';
//json context
import { FormJSONCreateContext } from '../Component/FormJSONCreateContext';
//APISERVICES
import { APIPOST, APIPATCH, APIGET } from '../services/APIServices';
//Importing the navigation purposes.. 
import { useNavigate } from 'react-router-dom';
import { MasterContext } from '../services/MasterContext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

// function a11yProps(index) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// };
// function registerDomEvent() {
//   document.addEventListener("click", this.a11yProps(this.attr('tabIndex')));
// }

export default function BasicTabs(props) {
  const { renderSuccessPage } = React.useContext(FormJSONCreateContext);
  const { contextJson, apiContextVar, contextObjective, editData, contextDataArr, contextIndex } = React.useContext(FormJSONCreateContext);
  const { masterData } = React.useContext(MasterContext);

  const classes = useStyles();
  let navigate = useNavigate()
  const [postResponse, setPostResponse] = React.useState();
  const [getResponse, setGetResponse] = React.useState();
  // const [removeResponse, setremoveResponse] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [reset, setReset] = React.useState(false)
  //Tab index
  const [value, setValue] = React.useState(0);
  const [disableBtn, setDesable] = React.useState(false);
  var objData = [];


  React.useMemo(() => {

    const resetFields = () => {
      Object.keys(contextJson).forEach((key, index) => {
        if (key !== "xioFEConfig") {
          contextJson[key].fields.forEach((field) => {
            if (contextObjective === "create") {
              if (field.field_type === "custom") {
                field.field_value = "{}"
              } else if (field.field_type !== "hidden" && field.field_default_value) {
                field.field_value = field.field_default_value
                field.id = ""
              } else {
                field.field_value = ""
                field.id = ""
              }
            } else if (contextObjective === "search" || contextObjective === "remove") {
              if (field.field_type !== "hidden" && field.field_default_value) {
                field.field_value = field.field_default_value
                field.id = ""
              } else {
                field.field_value = ""
                field.field_SearchValue = ""
                field.id = ""
              }
            } else if (contextObjective === "update") {
              field.field_value = editData[field.field_id]
            }

            let _te = document.getElementById(field.field_id);
            if (_te !== null)
              try {
                _te.parentNode.nextSibling.textContent = '';
              } catch (ex) {
                console.log(_te.parentNode.nextSibling);
                console.log(ex.message);
              }
          })
        }
      })
    }

    if (reset) {
      resetFields()
      setReset(false)
    }
  }, [reset, contextObjective, contextDataArr, contextIndex, contextJson])


  function hendleErrorMsg(msg) {
    console.log(msg)
    if (msg.error === true) {
      if (msg.serverMessages) {
        const keys = Object.keys(msg.serverMessages);
        Object.keys(contextJson).forEach((key, _i) => {
          if (key !== "xioFEConfig") {
            // isFormValid = (validateThisForm(_object));
            contextJson[key].fields.forEach((field) => {
              keys.forEach((fieldKey) => {
                if (field.field_id === fieldKey) {
                  field.field_helperText = msg.serverMessages[fieldKey];
                }
              });
            });
            console.log(_i)
            handleTabChange(window.event, 0);
          }
        });
        setOpen(true)
        setErrorMsg(msg.serverMsg)
        setDesable(false)
      } else {
        setOpen(true)
        setErrorMsg(msg.serverMsg)
        setDesable(false)
        handleTabChange(window.event, 0);
      }
    } else {
      setOpen(true)
      setErrorMsg(msg)
      setDesable(false)
    }
  }

  const links = "/Customer360/RemoveConsent/RemoveConsentlList";
  const link = "/Customer360/CheckConsent/SearchDetailList";
  const url = "/Customer360/CheckConsent/ConsentLfeCycle";
  let _data = "";

  React.useEffect(() => {
    function handleClick(link) {
      if (link && link !== '' && getResponse) {
        if (getResponse.length === 1) {
          getResponse.map((arr, index) => {
            _data = getResponse[String(index)];
            // console.log("helllo",_data);
          });
          navigate(url, { state: _data });
        } else if (getResponse.length > 1) {
          navigate(link, { state: getResponse });
        } else {
          var msg = "The Consent not found!";
          hendleErrorMsg(msg);
        }
      }
    }

    if (contextObjective === "search") {
      handleClick(link);
    } else if (contextObjective === "remove") {
      handleRemove(links);
    }
  }, [getResponse, contextObjective, link, links, handleRemove, hendleErrorMsg]);

  // function hendleErrorMsg(msg) {
  //   setOpen(true);
  //   setErrorMsg(msg);
  //   setDesable(false);
  // }

  function handleRemove(links) {
    if (links && links !== '' && getResponse) {
      if (getResponse.length > 0) {
        navigate(links, { state: getResponse });
      } else {
        var msg = "The Consent not found!";
        hendleErrorMsg(msg);
      }
    }
  }


  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    window.scrollTo(0, 0);
    // console.warn(newValue)
    setValue(newValue);
  };
  const c = (event) => {
    event.preventDefault();
    if (value > 0) {
      setValue(value - 1);
    }
  }
  function TabsTops(props) {
    const { contextJson } = React.useContext(FormJSONCreateContext);
    var __index = 0;
    if (Object.keys(contextJson).length > 1)
      Object.keys(contextJson).forEach((key, index) => {
        if (key !== "xioFEConfig")
          objData[index] = (getTopTabObject(contextJson[key].page_label, __index));
        __index++;
      });
    let retrunData;
    for (let _i = 0; _i < objData.length; ++_i) {

    }
    return Object.keys(objData).map((key) => {
      return objData[key];
    })

  }
  function getTopTabObject(_string, _index) {
    let _id = "simple-tab-" + _index;
    let ariaControls = "simple-tabpanel-" + _index;
    return (<Tab className={classes.hello} label={_string} onChange={() => handleTabChange(window.event, _index)} tabIndex={_index} aria-selected="true" id={_id} aria-controls={ariaControls} />);

  }
  function TabPanels() {
    const { contextJson } = React.useContext(FormJSONCreateContext);
    let objData = new Array();
    //objData.
    var index = 0;
    var Count = Object.keys(contextJson).length - 1;
    Object.keys(contextJson).forEach(key => {
      if (key !== "xioFEConfig") {
        objData[index] = (getTabPanels(contextJson[key], index, (Count - 1)));
        index++;
      }
    });
    return objData;
  }
  function localValidate(event, obj, _index, jsonContext) {
    event.preventDefault();
    window.scrollTo(0, 0);
    console.log(validateThisForm(jsonContext))
    if (validateThisForm(jsonContext)) {
      handleTabChange(window.event, _index);
      return true;
    } else {
      return false;
    }
    //return 
  }
  function getTabPanels(jsonContext, _index, count) {
    return (
      <TabPanel value={value} index={_index}>
        <FormRenderer formJSON={jsonContext} />
        <div className={classes.ctaNxtPrv}>
          {(_index === 0 ? <Button
            className={classes.btnRset}
            ButtonName="Reset" onClick={() => setReset(true)}
          /> : null
          )}
          {(_index === 0 ? null :
            <Button
              className={classes.btnBck}
              ButtonName="Back"
              onClick={() => handleTabChange(window.event, (_index - 1))}
            />
          )}
          {/* {(count===_index ? <Button ButtonStyle={{"background-color":'#82A9BB',
                 "border-color":'#82A9BB'}}
                    ButtonName="Draft" onClick={(event)=>{event.preventDefault();}}/> :
                   <Button  ButtonStyle={{"background-color":'#82A9BB',
                 "border-color":'#82A9BB'}}
                    ButtonName="Draft" onClick={(event)=>{event.preventDefault();}}/>
                  )} */}
          {(count === _index ? null :
            <Button
              className={classes.btnDflt}
              ButtonName="Next"
              onClick={() => localValidate(window.event, this, (_index + 1), jsonContext)}
            />
          )}
          {(count === _index ? <Button field_disabled={disableBtn}
            className={classes.btnSmt}
            ButtonName="Submit"
          /> : null
          )}
        </div>
      </TabPanel>);
  }

  function validateThisForm(jsonContext) {
    let return_error = true;
    jsonContext.fields.forEach(field => {
      if (!elementValidation(field)) {
        return_error = false;
        console.log(field);
        return false;
      }
    });
    return return_error;
  }

  //Validate PolicyParameter Form on validation===>

  /*  async function NextPolicyParameterForm(event){ 
  event.preventDefault();
  console.log(validatePolicyParameterForm())
  if(validatePolicyParameterForm()===true){
    event.preventDefault();
    setValue(value+1);
  }
}
//===>Setting Initial Values===>
async function SetValueInPOlicy(){
  masterJSON.PolicyParameter.fields.map((field)=>{
      field.field_value = ""
  })
  masterJSON.ValidatePolicy.fields.map((field)=>{
      field.field_value = ""
  })
  }




function validatePolicyValidateForm(){
  let return_error = true;
  masterUpdateJSON.ValidatePolicy.fields.forEach(field => {
  if(field.field_id!=="renewalPeriod"){
    if(!elementValidation(field)){
      return_error = false;
      return false;
    }
  }
  });
  return return_error;
}
async function NextPolicyValidateForm(event){
  event.preventDefault();
  console.log(validatePolicyValidateForm())
  if(validatePolicyValidateForm()===true){
    event.preventDefault();
    setValue(value+1);
  }
}

*/
  //handle submit ------------>

  async function handleSubmit(event) {

    event.preventDefault();
    window.scrollTo(0, 0);
    console.log("Submit Cliked")
    let isFormValid = true;
    console.log("POST Should Run")
    event.preventDefault();
    window.scrollTo(0, 0);
    // let data = {
    //   "active": "Y",
    //   "additionalFields": "\{\}",
    //   "approvalStatus": "PENDING",
    //   "scrubStatus": "In Process",
    //   "createById": 1,
    //   "updateById": 1,
    //   "enterpriseId": 1,
    //   // {remove after db change
    //   "policyLevel": "Departmental",
    //   "channel": "Null",
    //   "validityType": "Null",
    //   "endDate": "00-00-0000",
    //   "preferenceTimeEnd": "00:00:00",
    //   "preferenceTimeStart": "00:00:00",
    //   "renewalPeriod": 0,
    //   // remove after db change }
    //   "expiryType": "PREPETUAL",
    //   "insrtSessionId": 1,
    //   "status": "NEW",
    //   "enforcementDate": "00-00-0000",
    //   "policyCategory": apiContextVar,
    //   "policyStatus": "NEW",
    //   "smsTemplateStatus": "NEW",
    //   "smsConsentFormStatus": "NEW",
    //   "emailConsentFormStatus": "NEW",
    // }
    const data = {
      "active": "Y",
      "approvalStatus": "PENDING",
      "createById": 1,
      "updateById": 1,
       "enterpriseId": 1,
       "insrtSessionId": 1,
    };
    Object.keys(contextJson).forEach((key, _i) => {
      if (key !== "xioFEConfig") {
        let _object = contextJson[key];
        if (isFormValid) {
          isFormValid = (validateThisForm(_object));
          if (isFormValid) {
            _object.fields.forEach(thisField => {
              if (thisField.field_type !== "multiSelect" && thisField.field_type !== "date" && thisField.field_type !== "qRCode" && thisField.field_type !== "formJsonData") {
                data[thisField.field_id] = thisField.field_value;
              } else if (thisField.field_type === "qRCode") {
                data[thisField.field_id] = thisField.field_value;
                data[thisField.field_keyValue] = thisField.url;
              } else if (thisField.field_type === "date") {
                var data_regex_format2 = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
                if (thisField.field_value) {
                  if (!data_regex_format2.test(thisField.field_value)) {
                    data[thisField.field_id] = thisField.field_value.split('-').reverse().join('-');
                  }
                  else {
                    data[thisField.field_id] = thisField.field_value;
                  }
                }
              } else if (thisField.field_value === "formJsonData") {
                data[thisField.field_id] = JSON.stringify(thisField.field_value);
              }
              else {
                data[thisField.field_id] = String(thisField.field_value);
              }
            });
            // setDesable(true)
          }
          else {
            handleTabChange(window.event, _i);
            setDesable(false)
          }
        }
      }
    }
    );

    console.log(data);

    let value, policyid, searchValue, policy;
    var filter = 'filter?';

    Object.keys(contextJson).forEach((key) => {
      if (contextObjective === "remove" || contextObjective === "search") {
        if (key !== "xioFEConfig") {
          let _object = contextJson[key];
          _object.fields.forEach((thisField) => {
            if (thisField.field_type === "customer360Search") {
              value = thisField.field_value;
              searchValue = thisField.field_SearchValue;
              // searchValue = thisField.field_option_src.field_option_srh_context;
            } else if (
              thisField.field_type === "customer360Select" || thisField.field_type === "select"
            ) {
              policyid = thisField.field_value;
              policy = "policyId";
              // policy = thisField.field_SearchValue;
            }
          });
        }
      }
    });

    async function getData() {
      const response = await APIGET(apiContextVar + `/${filter}${searchValue}=${value}&${policy}=${policyid}`);
      const data = response.data;
      setGetResponse(data);
    }

    // console.log(getResponse);
    async function postingData(contextJson) {
      masterData[`${apiContextVar}`] = {}
      try {
        const response = await APIPOST(apiContextVar + '/save', data, contextJson, renderSuccessPage, hendleErrorMsg);
        // setPostResponse(response.data);
        if (response) {
          // setPostResponse(response.data);
          return response.data
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    async function updateData(contextJson) {
      masterData[`${apiContextVar}`] = {}
      try {
        const response = await APIPATCH(apiContextVar + '/' + contextDataArr[contextIndex].id, data, contextJson, renderSuccessPage, hendleErrorMsg);
        if (response) {
          setPostResponse(response.data);
          return response.data
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }


    if (isFormValid) {
      if (contextObjective === "create") {
        postingData(contextJson);
      } else if (contextObjective === "update") {
        updateData(contextJson)
      } else if (contextObjective === "search" || contextObjective === "remove") {
        if ((value && searchValue) || policyid) {
          getData();
        } else {
          var msg = 'Please Enter Value in Form for Consent Data!';
          hendleErrorMsg(msg)
        }
      }
    } else {
      // alert("Please check form for errors");
      var msg = 'Please Check Form for errors!';
      hendleErrorMsg(msg)
    }
    return false;
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //handle Next Submit ------------>


  /* */
  const contextClass = props.contextClass;
  let ShowTopTabs = false;
  if (Object.keys(contextJson).length > 2) {
    ShowTopTabs = true;
  }
  TabsTops();
  return (
    <React.Fragment >
      <Snackbar className="alertError" anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={4000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>
      <Form onSubmit={handleSubmit}>
        {ShowTopTabs === true ? <Box className={contextClass}>
          <Box className={classes.formtab} sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleTabChange} aria-label="">
              {/* <TabsTops /> */}
              {(Object.keys(contextJson).length > 1) ?
                Object.keys(contextJson).map((key, index) => {
                  if (key !== "xioFEConfig") {
                    // objData[index] =  (getTopTabObject(contextJson[key].page_label,index));
                    return (<Tab label={contextJson[key].page_label} onChange={() => handleTabChange(window.event, index)} tabIndex={index} aria-selected="true" id={"simple-tab-" + index} aria-controls={"simple-tabpanel-" + index} />)
                  }
                }) : null}
              {/* {Object.keys(objData).map((key, idx) => (
            <Tab/>
          ))} */}
            </Tabs>
          </Box>

          <TabPanels />
        </Box>
          : <Box className={contextClass}><TabPanels /> </Box>

        }
      </Form>
    </React.Fragment>
  )
}

