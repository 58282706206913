import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import  useStyles  from './FormControlsCss';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';


export default function BtnGroup(props) {
    const classes = useStyles();
    let { id,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    // const buttons = [
    //   ,
    // ];
    const handleClick = () => {
        props.addflow(props.value)
    };
    const handleClickRemove = (index) => {
        props.removeflow(index)
    };
    return (
      <Box
        sx={{
          display: 'flex',
          '& > *': {m: 1,},
           }}
           className={classes.btngup}
      >
        <ButtonGroup
          orientation="vertical"
          aria-label="vertical outlined button group"   
        >
          {/* {buttons} */}
          <Button style={{"background-color":'#4b9f46',"border-color":'#4b9f46',"color":'#ffffff'}} key="one" onClick={handleClick}><AddOutlinedIcon/></Button>
      <Button style={{"background-color":'#88929f',"border-color":'#88929f',"color":'#ffffff'}} key="two" ><CreateNewFolderOutlinedIcon/></Button>
      <Button style={{"background-color":'#9f2a2a',"border-color":'#9f2a2a',"color":'#ffffff'}} key="three" onClick={(index)=> handleClickRemove(index)}><DeleteOutlinedIcon/></Button>
        </ButtonGroup>
      </Box>
    );
  }
