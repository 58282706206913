import React, { useContext } from 'react'
import DatePicker from './DatePicker'

import  useStyles  from './FormControlsCss';

export default function DateToDate(props) {
  const classes = useStyles();
  const { field_label} = props;
  // const { handleChange } = useContext(FormContext)
  return (

    <div className={classes.timeInputGrp}>
     <DatePicker className={classes.timeInput} field_label={field_label}/>
     <h2>To</h2>
     <DatePicker className={classes.timeInput} field_label=" "/>
    </div>
  );
}
