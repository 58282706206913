import React, { useEffect }  from "react";

import { useNavigate } from 'react-router-dom';
// import TableSection from './TableSection';
//import PolicyStatusSort from './sms/view/TablePolicytStatusSorting'

import { FormJSONCreateContext } from "../FormJSONCreateContext";

import PolicyImg    from "../PolicyImg";
import useStyles    from '../ComponentCss';
import SuccessPage  from '../SuccessPage';
import Dialog from '@mui/material/Dialog'; 
import ScrubSuccessPage  from '../ScrubSuccessPage';
import FormSection  from "../../FormsControls/FormSection";
import ProcurementCoustom from '../../modules/Procurement/CoustomCreate';
import QualityCheckCoustom from '../../modules/QualityCheck/CoustomCreate';
import LeadCoustom from '../../modules/Lead/CoustomCreate';
import UserCoustom from '../../modules/Admin/UserManagement/CreateUser/CoustomCreate';

function FormScopeDefiner(obj) {
    const apiContextVar = obj.value.apiContextVar;
    const apiContext = obj.value.apiContext;
    const Close =obj.value.close;
    const createClose =obj.value.createClose;
    const editData = obj.value.editData;
    const searchListPage =  obj.value.searchListPage;
    const contextJson = obj.value.contextJson;
    const contextObjective = obj.value.contextObjective;
    const Heading = obj.value.contextHeading;
    const contextClasses = obj.value.contextClasses;
    const contextIndex = obj.value.contextIndex;
    const contextDataArr = obj.value.contextDataArr;
    const [successRender,setSuccessRender] = React.useState(undefined);
    const [successPageData,setSuccessPageData] = React.useState(undefined);
    const [open,setOpen] = React.useState(false);
    const classes=useStyles();
    // const { handleClose } = React.useContext(FormJSONCreateContext);
   //for navigation 
    let navigate = useNavigate();

  
  //On Success Create Policy....>
    function renderSuccessPage(props){
      setSuccessPageData(props);
      setSuccessRender(1);
      console.log('Success Render is Rendering...') 
  
    }
    function navigateToCreate(){
      navigate(successPageData.createLink)
      // handleClose();
      setSuccessRender(undefined);
      return null;
    }
    function navigateToView(){
      console.log('Navigating to View Policy...')
      navigate(successPageData.viewLink);
    }
    
    useEffect(()=>{
      Object.keys(contextJson).forEach((key,index)=>{
        if(key!=="xioFEConfig"){
         // Object.keys(contextJson[key]).forEach((_key,_index)=>{
            for(let i=0;i<(contextJson[key].fields).length;++i){
              if(contextJson[key].fields[i].field_type === "formIO"){
                if(localStorage.getItem(contextJson[key].fields[i].field_id)){
                  localStorage.removeItem(contextJson[key].fields[i].field_id);
                }
                if(typeof(contextJson[key].fields[i].field_value) === "string" && contextJson[key].fields[i].field_value!== "" ){
                  localStorage.setItem(contextJson[key].fields[i].field_id,JSON.stringify(JSON.parse(contextJson[key].fields[i].field_value)));
                }else{
                  localStorage.setItem(contextJson[key].fields[i].field_id, JSON.stringify(contextJson[key].fields[i].field_value) );

                }
              }
            }
        }
      })
    })

 
    return (
      <FormJSONCreateContext.Provider value={{renderSuccessPage,editData,searchListPage,Close,contextJson,apiContextVar,apiContext,contextClasses,contextObjective,contextIndex,Close,createClose,contextDataArr}}>

     
        {(successRender&&successPageData)?
          <SuccessPage
            flowName ={Heading}
            actionName = {successPageData.actionName}
            createPolicyLink = {navigateToCreate}
            viewPolicyLink = {navigateToView}
            close={Close}
            createButtonText = {successPageData.createButtonText}
            viewButtonText = {successPageData.viewButtonText}
            paragraphText = {successPageData.paragraphText}
          />
        :
          <FormSection Heading={Heading} contextClasses={contextClasses} contextObjective={contextObjective}/>
        }
      </FormJSONCreateContext.Provider>
    );
  }
  
  export default FormScopeDefiner;
  