import React, { useContext, useState } from "react";
import Controls from "../FormsControls/Control";
import { FormContext } from './Forms/FormContext';
import masterJson from '../modules/Procurement/masterJson.json'
import '../css/CustomFlow.css';
import { APIPOST, APIPATCH, APIGET } from '../services/APIServices';
import { MasterContext } from '../services/MasterContext';
import { FormJSONCreateContext } from './FormJSONCreateContext';
import ActivitySection from '../FormsControls/ActivitySection';
import elementValidation from './validation.js';
import { handleSubmit, handleChange } from "./Functions/fromFunction";

export default function CustomFlow() {
    const { Input, Select, DragFileUpload, MultilineText } = Controls;
    //    const { renderSuccessPage } = React.useContext(FormJSONCreateContext);
    const { masterData } = React.useContext(MasterContext);
    const apiContextVar = "policy";
    const [masterJsn, setMasterJson] = useState(masterJson);
    const [successPage, setSuccessPage] = useState();

    console.log(masterJson);

    // const handleChange = (id, event, valueStr) => {
    //     console.log("id", id);
    //     console.log("Value", valueStr);

    //     const updatedJson = { ...masterJsn }; // Create a copy of the masterJson object

    //     Object.keys(updatedJson).forEach((key) => {
    //         if (key !== "xioFEConfig") {
    //             const _object = updatedJson[key];

    //             _object.fields.forEach((field) => {
    //                 const { field_type, field_id } = field;

    //                 if (id === field_id) {
    //                     console.log("id matched");

    //                     switch (field_type) {
    //                         case "checkbox":
    //                         case "qRCode":
    //                         case "uploadFileExcel":
    //                             field.field_value = valueStr;
    //                             break;
    //                         // Add additional cases for other field types if needed
    //                         default:
    //                             // Handle unrecognized field types
    //                             field.field_value = valueStr;
    //                             break;
    //                     }
    //                 }
    //             });
    //         }
    //     });

    //     setMasterJson(updatedJson);
    // };


    // function validateThisForm(jsonContext) {
    //     let return_error = true;
    //     jsonContext.fields.forEach(field => {
    //         if (!elementValidation(field)) {
    //             return_error = false;
    //             console.log(field);
    //             return false;
    //         }
    //     });
    //     return return_error;
    // }

    function hendleErrorMsg(msg) {
        alert(msg)
    }

    async function postingData(masterJson, data) {
        masterData[`${apiContextVar}`] = {}
        try {
            const response = await APIPOST(apiContextVar + '/save', data, masterJson, setSuccessPage, hendleErrorMsg);
            // setPostResponse(response.data);
            if (response) {
                // setPostResponse(response.data);
                return response.data
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        handleSubmit(masterJson, apiContextVar, setSuccessPage, hendleErrorMsg);
      };

    // const handleSubmit = (event) => {
    //     event.preventDefault();
    //     let isFormValid = true;
    //     const data = {};

    //     Object.keys(masterJson).forEach((key, _i) => {
    //         if (key !== "xioFEConfig") {
    //             let _object = masterJson[key];
    //             if (isFormValid) {
    //                 isFormValid = validateThisForm(_object);
    //                 if (isFormValid) {
    //                     _object.fields.forEach(thisField => {
    //                         const { field_type, field_id, field_value, field_keyValue } = thisField;

    //                         switch (field_type) {
    //                             case "qRCode":
    //                                 data[field_id] = field_value;
    //                                 data[field_keyValue] = thisField.url;
    //                                 break;

    //                             case "date":
    //                                 const data_regex_format2 = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    //                                 if (field_value) {
    //                                     if (!data_regex_format2.test(field_value)) {
    //                                         data[field_id] = field_value.split('-').reverse().join('-');
    //                                     } else {
    //                                         data[field_id] = field_value;
    //                                     }
    //                                 }
    //                                 break;

    //                             case "formJsonData":
    //                                 data[field_id] = JSON.stringify(field_value);
    //                                 break;

    //                             case "multiSelect":
    //                                 // Handle multiSelect field type if needed
    //                                 break;

    //                             default:
    //                                 data[field_id] = String(field_value);
    //                                 break;
    //                         }
    //                     });
    //                     // setDesable(true);
    //                 } else {
    //                     // handleTabChange(_i);
    //                     // setDesable(false);
    //                     alert("Please fill all the required fields");
    //                 }
    //             }
    //         }
    //     });
    //     console.log(data)
    //     postingData(masterJson, data)
    // };
const handleCancel = (e) => {
        e.preventDefault();
}
    return (
        <form onSubmit={handleFormSubmit}>
            {/* <FormContext.Provider value={{ handleChange }}> */}
            <FormContext.Provider value={{ handleChange: (id, event, valueStr) => handleChange(masterJsn, setMasterJson, id, valueStr) }}>
                <div className="cntanr">
                    {/* <div className="from-hdr">
                        <h1>Custom Flow</h1>
                      
                    </div> */}

                    <div className="divForm pd">
                        <div className="left-form">
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "country") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "network") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "pcp") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else if (field.field_id === "senderId") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else if (field.field_id === "targetPrice") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );

                                            } else if (field.field_id === "potentialVolume") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else if (field.field_id === "content") {
                                                return (
                                                    <MultilineText
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            <div className="activity">
                                <ActivitySection />
                            </div>
                        </div>
                        <div className="right-form">
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "accountManager") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "team") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "region") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "trafficType") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "trafficProfile") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            <div>
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "attachmentList") {
                                                return (
                                                    <DragFileUpload
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        acceptfile={field.acceptfile}
                                                        url={field.url}
                                                        preview={field.preview}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }
                                        });
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <div className="pdlr">
                        {Object.keys(masterJson).map((key, index) => {
                            if (key !== "xioFEConfig") {
                                return masterJson[key].fields.map((field) => {
                                    if (field.field_id === "attachmentList") {
                                        return (
                                            <DragFileUpload
                                                field_id={field.field_id}
                                                field_label={field.field_label}
                                                field_value={field.field_value}
                                                field_default_value={field.field_default_value}
                                                field_placeholder={field.field_placeholder}
                                                field_mandatory={field.field_mandatory}
                                                acceptfile={field.acceptfile}
                                                url={field.url}
                                                preview={field.preview}
                                                field_type={field.field_type}
                                                field_helperText={field.field_helperText}
                                                field_minlength={field.field_minlength}
                                                field_maxlength={field.field_maxlength}
                                                field_minvalue={field.field_minvalue}
                                                field_maxvalue={field.field_maxvalue}
                                                field_value_type={field.field_value_type}
                                                field_on_blur={field.field_on_blur}
                                                field_error_msg_required={field.field_error_msg_required}
                                                field_error_msg_minlength={field.field_error_msg_minlength}
                                                field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                field_error_msg_uq={field.field_error_msg_uq}
                                            />

                                        );
                                    } else {
                                        // Handle other field types
                                        return null;
                                    }
                                });
                            }
                            return null;
                        })}
                    </div> */}
                    <div className="fromBtn">
                        <button className="btn-sub" type="submit">Save</button>
                        <button className="btn-cncl" onClick={handleCancel}>Cancel</button>
                    </div>
                </div>
            </FormContext.Provider>
        </form>
    );

}