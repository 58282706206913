import React, { useContext } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import { FormControl, FormControlLabel, Checkbox as MuiCheckbox } from '@material-ui/core';
import {InputLabel} from '@material-ui/core';
import FormHelperText from '@mui/material/FormHelperText';
import  useStyles  from './FormControlsCss';
export default function Checkbox(props) {
    const classes = useStyles();

    const {field_id, field_label, field_value,field_check_label,field_helperText,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [checked,setChecked]=React.useState(field_value==="true"?true:false);

    function handelChecked(value){
        setChecked(current => !current);
        const _value=value.toString();
        console.log(value)
        handleChange(field_id,{},_value)
    }
    return (
<div className={classes.inputroot}>
<div style={{display:"flex"}}>

    <InputLabel className={classes.label}>{field_label}</InputLabel>
        {field_mandatory==="yes"?<span 
        style={{fontSize: "18px",
        color: "#d71729",
        fontWeight: "400",
        lineHeight: "20px",
        transform: "translateY(-2px)"}}>{`*`}</span>:null}
    
    
    </div>
<FormControl> 
            <FormControlLabel
                control={<MuiCheckbox
                    id={field_id}
                    // name={name}
                    color="primary"
                    // value={value}
                    checked={checked}
                   onChange={event => handelChecked(event.target.checked)}
                />}
                label={field_check_label}
            />
            <FormHelperText>{field_helperText}</FormHelperText>
            
        </FormControl>
</div>
       
    )
}
