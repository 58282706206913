 
import React, { useContext,useEffect } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import {InputLabel} from '@material-ui/core';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import  useStyles  from './FormControlsCss';
import Button from './Button';  
import { APIGET } from '../services/APIServices';
// import FormHelperText from '@mui/material/FormHelperText';

export default function MultilineTextWithButton(props) {
  const classes = useStyles();
  const {field_type,field_mandatory, field_id, field_label,field_btn_label, field_placeholder,field_option_src, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq  } = props;
  const { handleChange } = useContext(FormContext) 
  const [fieldOptions,setFieldOptions] = React.useState();
  async function getOptions(props) {
    try{
      if(props.field_option_src_context){
        // if(false){
              let APIRES = await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
              (APIRES)=>{
                  console.log(APIRES);
                  let responseArray =new Array();
                  let _obj = new Object();
                  let _i =0;
                  // if(props.field_select_all_label){
                  //     let _obj = new Object();
                  //     _obj.option_label = props.field_select_all_label;
                  //     _obj.value = props.field_select_all_value;
                  //     responseArray[_i] = _obj;
                  //     _i++;  
                  // }
                  Object.keys(APIRES.data).forEach( key => {
                      // let _obj = new Object();
                      _obj.option_label = APIRES.data[key][props.field_option_label];
                      _obj.value = APIRES.data[key][props.field_option_value];
                      _obj['attributes'] = {};
                      // if(field_option_src.field_option_attributes){
                      //   field_option_src.field_option_attributes.map((att)=>{
                      //     _obj['attributes'][`data_${att}`]=APIRES.data[key][att];
                      //   });
                      // }
                      // console.log(_obj);
                      // responseArray[_i] = _obj;
                      // _i++; 
                  })
                  //fieldOptions = responseArray; 
                  setFieldOptions(_obj.option_label);
                  console.log(_obj.option_label);
                  // masterData[`${field_id}_${props.field_option_src_context}_${props.field_option_src_critieria}`] = responseArray;
              }
              );            
                            
      }
    } catch(err){/** TODO Do Nothing */}
  }
  useEffect(()=>{
      getOptions(field_option_src);
  },[field_option_src])





console.log(fieldOptions)
  return (

    <div className={classes.inputroot} >
   <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
    <FormControl  variant="outlined" className={classes.miwtb}>
    <OutlinedInput className={classes.multiInput}
              type="text"
            //  value={field_value}
             value={fieldOptions}
            //  placeholder={field_placeholder ? field_placeholder : ''} 
             onChange={event => handleChange(field_id,{}, event.target.value)}
             multiline
             rows={4} 
        /> 
    {/* <Button className={classes.btn1} ButtonName={field_btn_label}/> */}
    </FormControl>
      </div>   
      );
}