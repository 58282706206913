import * as React from 'react';
import useStyles from '../ComponentCss';
import Button from '../../FormsControls/Button';
import Element from '../../FormsControls/Element';
import { FormContext } from '../Forms/FormContext';
import SuccessPage from '../SuccessPage';

import elementValidation from '../validation.js';
//APIService 
import { APIGET, APIDEL, APIPATCH } from '../../services/APIServices';
const json = {
  "fields": [
    {
      "field_id": "approvalComments",
      "field_label": "Comments",
      "field_mandatory": "yes",
      "field_placeholder": "",
      "field_type": "multilineText",
      "field_value": "",
      "field_minlength": "",
      "field_maxlength": "",
      "field_minvalue": "",
      "field_maxvalue": "",
      "field_value_type": "",
      "field_on_blur": "",
      "field_error_msg_required": "",
      "field_error_msg_minlength": "",
      "field_error_msg_maxlength": "",
      "field_error_msg_minvalue": "",
      "field_error_msg_maxvalue": "",
      "field_error_msg_uq": ""
    }
  ]
};
export default function ApproveRejectComment(props) {
  // const [value,setValue] = React.useState("");
  const [data, setData] = React.useState();
  const [elements, setElements] = React.useState(json.fields);
  const [successRender, setSuccessRender] = React.useState(undefined);
  const [successPageData, setSuccessPageData] = React.useState(undefined);

  const apiPath = props.apiPath;
  const path = props.path;
  const classes = useStyles();
  const [actionName, setactionName] = React.useState(path === "/approve"?"Approve":"Reject");
  //==>    
  const { dataArr, contextJson } = props;

React.useEffect(()=>{
  json.fields.map((field)=>(
    field.field_value=""
  ))
},[])


  //handle change==>
  const handleChange = (id, event, valueStr) => {
    const newElements = [...elements]
    newElements.forEach(field => {
      const { field_type, field_id } = field;
      if (id === field_id) {
        switch (field_type) {
          case 'checkbox':
            field['field_value'] = event.target.checked;
            break;

          default:
            field['field_value'] = valueStr;
            console.log(valueStr)
            break;
        }
      }
      setElements(newElements);
    });
  }

  function approveSuccessPage(props) {
    setSuccessPageData(props)
    setSuccessRender(1);
    console.log('Success Rander is Rendering..')
  }

  //Handle Deletion ===>
  const createData = (dataObj) => {
    let tempData = dataObj;
    elements.forEach((field) => {
      tempData[field.field_id] = field.field_value;
    });
    return tempData;
  }

  const handleSubmit = async () => {
    try {
      const data = createData({});
      const response = await APIPATCH(apiPath + path + `/${dataArr.id}`, data, contextJson, approveSuccessPage);
      console.log(response.data);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  }

  const handleCancel = () => {
    props.close();
  }
  return (
    <div className={classes.prvw}>
      {(successRender && successPageData) ?
        <SuccessPage
          flowName={props.contextHeading}
          actionName={actionName}
          createButtonText={successPageData.createButtonText}
          viewButtonText={successPageData.viewButtonText}
          paragraphText={successPageData.paragraphText}
        /> : <>
          <FormContext.Provider value={{ handleChange }}>
            <div className='App containers'>
              {/* <DeleteLabel/> */}
              {elements ? elements.map((field, i) => <Element key={i} field={field} />) : null}

            </div>
          </FormContext.Provider>
          <div className={classes.ctaNxtPrv}>
            <Button className={classes.btnRset} ButtonName="Cancel" onClick={() => (handleCancel())} />
            <Button className={classes.btnSmt} ButtonName="Submit" onClick={() => (handleSubmit())} />
          </div>
        </>}
    </div>

  )
}

