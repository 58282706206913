import React from "react";
// import { useState } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from "@material-ui/core";
import useStyles from "../ComponentCss";

import { hasChildren } from "./utils";

//routing-imports
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'//importing for getting current location. 
// import { HvacOutlined } from "@mui/icons-material";

//Dynamic Path
// import {SetTitleSubHeader} from '../../services/DynamicPath';


export default function MenuRender(_obj) {
  const classes = useStyles();
 
  let returnObject =null;
  let MenuObj = _obj.menuJson
  return MenuObj.menus.map((obj, key) => { 
    let _thiJson = MenuObj.menus[key];
    return MenuTitle(_thiJson) ;
  })
  
 // return returnObject;
}

const MenuItem = ( {item,index} ) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} index={index} />;
};
const MenuTitle = (MenuItems)=>{
  const classes = useStyles();
  let navigate = useNavigate();
  //const { MenuItems: children } = item;
  const handleClickChild=()=> {
    navigate(MenuItems.path);
  }
  if(!MenuItems){return (<></>);}
  return (
    <><Typography variant="h3" className={classes.menuHead} color="primary">
        {MenuItems.MenuTitle} 
    </Typography>
    <div className={classes.menuFont}>
    {MenuItems.item.map((child, index) => (
      <MenuItem key={index} item={child} onClick={handleClickChild} />
    ))}
     
    </div></>
  )
}
const SingleLevel = ({ item,index}) => {
  const classes = useStyles();
  console.log(index);
  console.log(item);
  // const [re,setRe] = React.useState(false);
  //onclick
  //routing-state
  let navigate = useNavigate();
  const location = useLocation();

  //handle click . ...
  const handleClick = () => {
    navigate(item.path);
    // setRe(!(re))
    // setIsActive(index);
  };

  return (
    <ListItem button onClick={handleClick}  className={
      ((index===undefined))
      &&
      (String(location.pathname).match(String(item.path))) ?
       `${classes.activeMenu}`:
        ''} >
      <ListItemIcon>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} />
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const classes = useStyles();
  const { items: children } = item;

  //routing-stateT
  let navigate = useNavigate();
  const location = useLocation();///location---> current path.

  //onclick
  const handleClick = () => {
    // console.log(location.pathname);
    navigate(item.path);
  };


  const handleClickChild=()=> {
    navigate(item.path);
  }

  return (
        <React.Fragment >
          <ListItem button onClick={handleClick} className={(String(location.pathname).match(String(item.path))) ? `${classes.activeMenu}`: ''}>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
            {/* {item.path !== location.pathname ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
            {String(location.pathname).match(String(item.path)) ?  <ArrowRightIcon/>:<ArrowDropDownIcon />}
          </ListItem>
          <Collapse in={String(location.pathname).match(String(item.path))} timeout="auto" unmountOnExit >
            <List component="div" disablePadding >
              {children.map((child, index) => (
                <MenuItem key={index} item={child} index={index}/>
              ))}
            </List>
          </Collapse>
        </React.Fragment>

  );
};


