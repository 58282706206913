import react,{useState} from "react";
import axios from 'axios';
 function ImageUpload(){
const [image, setimage]=useState(" ")
const handleChange=(e)=>{
    console.log(e.target.files)
    setimage(e.target.files[0])
}
const handleApi=()=>{
    const url=''
    const formData=new formData()
    formData.append('image,image')
    axios.post(url,formData).then((res)=>{
    console.log(res);
})}
 return(
     <div>
         <input type='file' onChange={handleChange}></input>
         {/* <button onClick={handleApi}></button> */}
     </div>
 )
}
export default ImageUpload