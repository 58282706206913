import React,{useState,useEffect} from 'react'
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import  useStyles  from './../FormControlsCss';
import Selects from '@mui/material/Select';
import { FormContext } from '../../Component/Forms/FormContext';
import elementValidate from '../../Component/validation';
import { APIGET } from '../../services/APIServices';

//Context
import  { useContext } from  'react';
import { MasterContext } from '../../services/MasterContext'; 


export default function DragDrapSelect(props){
    const classes = useStyles();
    const [attr,setAttr] = React.useState();
    const { field_type, field_id, field_label, field_placeholder,field_disabled, field_value,field_option_src, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
    let {masterData} = React.useContext(MasterContext);
    const { handleChange } = useContext(FormContext);
    const [fieldOptions,setFieldOptions] = React.useState(field_options);
    async function getOptions(props) {
        try{
        if(props.field_option_src_context){
            // if(false){
            if(props.field_option_check_local_storage==="true"&&(masterData[`${props.field_option_src_context}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`])){
                        setFieldOptions(masterData[`${props.field_option_src_context}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`]&&masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`]);
                        console.log(masterData);
                    }
            else{
                let APIRES = await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
                (APIRES)=>{
                    console.log(APIRES);
                    let responseArray =new Array();
                    let _i =0;
                    if(props.field_select_all_label){
                        let _obj = new Object();
                        _obj.option_label = props.field_select_all_label;
                        _obj.value = props.field_select_all_value;
                        responseArray[_i] = _obj;
                        _i++;  
                    }
                    Object.keys(APIRES.data).forEach( key => {
                        let _obj = new Object();
                        _obj.option_label = APIRES.data[key][props.field_option_label];
                        _obj.value = APIRES.data[key][props.field_option_value];
                        responseArray[_i] = _obj;
                        _i++; 
                    })
                    //fieldOptions = responseArray; 
                    setFieldOptions(responseArray);
                    if(masterData[`${props.field_option_src_context}`&&`${props.field_option_src_context}`]){
                        if(masterData[`${props.field_option_src_context}`][`${field_id}`]){
                        masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                        }
                        else{
                        masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                        masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                        }
                    }
                    else{
                        masterData[`${props.field_option_src_context}`]={}
                        masterData[`${props.field_option_src_context}`][`${field_id}`] = {}
                        masterData[`${props.field_option_src_context}`][`${field_id}`][`${props.field_option_src_critieria}`] = responseArray;
                    }
                    // masterData[`${field_id}_${props.field_option_src_context}_${props.field_option_src_critieria}`] = responseArray;
                }
                );            
                }                  
        }
        } catch(err){/** TODO Do Nothing */}
    }
    useEffect(()=>{
        getOptions(field_option_src);
    },[field_option_src])
    



    //---->Variables
    const [selected,setSeleted]=React.useState([]); 
    const [selectedKeys,setSeletedKeys]=React.useState([]);
    const [r,setR] = React.useState(true);

    let currentDragging;
    let dragginOverLeft;
    function forceUpdate(){
        setR(!r);
    }


    function AlreadySelected(key){
        let index = selectedKeys.indexOf(key);
        if(index>=0){
            return true;
        }
        return false;
    }


    function InjectItem(_option){
            let _temp = selected;
            _temp.push(_option);
            setSeleted(_temp);
            _temp = selectedKeys;
            _temp.push(_option.option_label);
            setSeletedKeys(_temp);
            forceUpdate();
    }

    function RemoveItem(_option){
        let _temp = selected;
        _temp.splice(selectedKeys.indexOf(_option.option_label),1);
        setSeleted(_temp);
        _temp = selectedKeys;
        _temp.splice(selectedKeys.indexOf(_option.option_label),1);
        setSeletedKeys(_temp);
        forceUpdate();
    }


    function GetListItem(_args){
        // const styleItem = {
        //     "border":"1px solid black",
        //     "minHeight":"40px",
        //     "minWidth":"100px",
        //     "padding":"10px 10px",
        // }
        // const styleItemHover = {
        //     "border": "1px solid #2da0da",
        //     "boxShadow": "1px 1px 6px #2da0da",
        //     "transform": "translateY(-2px) scale(1.01)",
        //     "backgroundColor":"#D13128"
        // }
        // const rightSideItemButton={
        //     "float":"right",
        //     "border": "1px solid gray",
        //     "padding": "0px 8px",
        //     "backgroundColor":`${_args.itemSide===`left`?'rgb(38, 173, 141)':'#D12469'}`,
        //     "color":'#FFF',
            
        // }


        const clickAdd=(event,_option)=>{
            console.log(event)
            if(_args.itemSide==="left"&&!AlreadySelected(_option.option_label)){
                    InjectItem(_option);
            }
            else if(_args.itemSide==="right"){
                RemoveItem(_option);
            }
        }
        const handleDrag=(event)=>{
            if(!currentDragging){
                currentDragging=true;
            }
        }
        const handleDragStart=(event,_option)=>{
            // event.preventDefault();
            currentDragging = true;
            event.dataTransfer.clearData();
            event.dataTransfer.setData("text/plain",_option);
            console.log(event)
        }

        const handleDragEnd=(event,_option)=>{
            if(dragginOverLeft){
                if(!AlreadySelected(_option.option_label)){
                    InjectItem(_option);
                }
                else{
                    // RemoveItem(_option);
                }
            }else{
                if(AlreadySelected(_option.option_label)){
                    RemoveItem(_option);
                }
            }
            currentDragging = false;
            dragginOverLeft=false;
            event.dataTransfer.clearData();
        }
        return (
            <React.Fragment>
                <div
                    onDrag={(event)=>{handleDrag(event,_args.option)}}
                    onDragStart={(event)=>{handleDragStart(event,_args.option)}}
                    onDragEnd={(event)=>{handleDragEnd(event,_args.option)}}
                    draggable={true}
                >
                <div
                    key={_args.option.key} 
                    id={`${_args.option.id}_op_${_args.option.value}`}
                    value={_args.option.value}
                    // style={styleItem}
                    className={classes.dragDropstyleItem}
                >
                        {_args.option.option_label}
                        <span 
                            // style={rightSideItemButton}
                            onClick={(event)=>{clickAdd(event,_args.option)}}
                            className={_args.itemSide==="left"?classes.dragDropRightAddButton:classes.dragDropRightRemoveButton}
                        >{_args.itemSide==="left"?"+":"x"}</span>
                </div>
                </div>
            </React.Fragment>
        );
    }

    //---->Handle Drop and Drag for Right Left Parent. 
    const handleDragLeftEnter=(event)=>{
        event.preventDefault();
        dragginOverLeft=true;
    }

    const handleDragLeft=(event)=>{
        event.preventDefault();
        dragginOverLeft=true;
    }
    const handleDragLeftLeave=(event)=>{
        event.preventDefault();
        if(currentDragging){
            dragginOverLeft=false;
        }
    }

    const handleDragOverLeft=(event)=>{
        event.preventDefault();
    }


    //---->Style 
    const ParentDiv={
        "display":"flex",
        "flexDirection":"row",
        "minWidth":"700px",
        "width":"800px",
        justifyContent: 'space-between',
        
    }
    
    const ChildRight = {
        "display":"flex",
        "minWidth":"300px",
        "float":"right",
        backgroundColor: "white",
        "flexDirection":"column",
        "height":"200px",
        "overflow":"auto",
    }
    const ChildLeft = {
        "display":"flex",
        "minWidth":"300px",
        backgroundColor: "white",
        "float":"left",
        "flexDirection":"column",
        "height":"200px",
        "overflow":"auto",
    }
    //----->Render
    return (
        <React.Fragment>
            <div>
                <div className={classes.inputroot}>
                    <div style={{display:"flex"}}>
                        <InputLabel className={classes.label}>{field_label}
                        </InputLabel>
                                {
                                    field_mandatory==="yes"?
                                    <span 
                                        style={{fontSize: "18px",
                                        color: "#d71729",
                                        fontWeight: "400",
                                        lineHeight: "20px",
                                        transform: "translateY(-2px)"}}
                                    >
                                        *
                                    </span>
                                :null}
                    </div>
                    <div style={ParentDiv}>
                        {/* All List Items */}
                        <div 
                            style={ChildLeft}
                           
                            >
                            {fieldOptions.map((option,_i)=>{
                                           return <GetListItem 
                                                        itemSide="left"
                                                        option={option}
                                                    />
                                        }
                                    )
                            }
                        </div>
                        {/* Selected Items */}
                        <div 
                            onDrop={(event)=>{handleDragLeft(event)}}
                            onDragLeave={(event)=>{handleDragLeftLeave(event)}}
                            onDragEnter={(event)=>{handleDragLeftEnter(event)}}
                            onDrag={(event)=>{handleDragLeft(event)}}
                            onDragOver={(event)=>{handleDragLeft(event)}}
                            style={ChildRight}
                        >
                            {
                            selected.map((option,_i)=>{
                                        return <GetListItem 
                                                        itemSide="right"
                                                        option={option}
                                                />
                                    }
                                )
                            }
                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}