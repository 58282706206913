import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo-svg.svg";
import "../../css/Login.css";

function SignUpPage() {
    let navigate = useNavigate();
    // const forgetPasswordRoute = () => {
    //     navigate("/forgetPassword");
    // } 
    const loginRoute = () => {
        navigate("/login");
    } 
    const signUpRoute = () => {
        navigate("/signUp");
    }
    
    return (<div className="login-container">
           <div className="df jcc ais">
            <div className="imageLeft">
                        {/* <h2>CONSENTICA</h2> */}
                        <img src={logo} alt="logo" className="logoAnimate" />
                        <h5>Consent &amp; Preference Management Platform</h5>
                        <img src="/images/login.png" alt="" />
                </div>
                <div className="formRight">
                    <div className="login-tabs">
                        <div className="buttons">
                            <button className="tab-1 " onClick={loginRoute}> Login </button> 
                            <button className="tab-2 active" onClick={signUpRoute}> SignUp </button>
                        </div>
                    </div>
                    <div className="wrap jcc df">
                            <div className="form_wrapper content-2 active">
                                <form>
                                    <div className="login_input">
                                        <label htmlFor="">Full Name</label>
                                        <input type="text" id="fullName" name="fullName" />
                                    </div>
                                    <div className="login_input">
                                        <label htmlFor="">Email Id</label>
                                        <input type="email" id="email" name="email" />
                                    </div>
                                    <div className="login_input">
                                        <label htmlFor="">Mobile Number</label>
                                        <input type="text" id="mobileNumber" name="mobileNumber" />
                                    </div>
                                    <div className="login_input">
                                        <label htmlFor="">Enterprises Name</label>
                                        <input type="text" id="enterpriseName" name="enterpriseName" />
                                    </div>
                                    <div className="btn_wrap">
                                        <p className="terms">
                                            By creating an account to agree our term of service and
                                            <span> Privacy &amp; Cookies Statements</span>
                                        </p>
                                        <button className="login_btn">Create Account</button>
                                    </div>
                                </form>
                            </div>
                    </div>
                    
                </div>
                
            </div>
            
        </div>
    );

    
}


export default SignUpPage;