import * as React from 'react';
import Button from '@mui/material/Button';

export default function FormioButton( {label, color, handleClick,iconChange}) {
  return (
      <Button variant="contained" startIcon={iconChange} onClick={handleClick} color={color}>
       {label}
      </Button>
  );
}
