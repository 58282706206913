
import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import {InputLabel} from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import copy from "copy-to-clipboard";  
import axios from 'axios';
import Button from './Button';
import FormControl from '@mui/material/FormControl';
import logo from "../images/customerconsent.png";
import {APIUPLOAD}            from '../services/APIServices';
import FormHelperText from '@mui/material/FormHelperText';
import elementValidation from '../Component/validation.js';


function UploadFile(props) {
  const classes = useStyles();
  const [file, setFile] = useState()
  const [uploadPercentage, setUploadPercentage] = useState();
  const [uploadedFile, setUploadedFile] = useState({});
  const [copyText, setCopyText] = useState('');
  let { id,field_type, field_id,field_apipath,field_disabled,preview,field_label,acceptfile, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
  const { handleChange } = useContext(FormContext)
  const apipath= "uploadFiles/uploadFile";

  const elementField = {id,field_type,field_disabled, field_id,field_apipath, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
  function handleBlur(){
    elementValidation(elementField)
  }

  function handleChangeFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      setFile(event.target.files[0])
      const typ=(event.target.files[0])
      console.log(typ.type)
      setUploadedFile(null);
    }
  }
 
//   const onSubmit = async e => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('file', file);
//     let token = localStorage.getItem("token");
//     let _authorization = `Bearer ${token}`;
//     try {
//       // const res = await APIPOST('uploadFiles/uploadFile', formData, {
//       const res = await axios.post(apipath, formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           'token':token,
//           'authorization':_authorization,
//           "Access-Control-Allow-Origin": "http://localhost:3000,*",
//         },
//         onUploadProgress: progressEvent => {
//           setUploadPercentage(
//             parseInt(
//               Math.round((progressEvent.loaded * 100) / progressEvent.total)
//             )
//           );
//         }
//       });
//       console.log(uploadPercentage);
//      // Clear percentage
//      setTimeout(() => setUploadPercentage(), 1000);

//     const { fileName,uploadFilesLabel,filePath } = res.data.data;
//     handleChange(field_id,{},uploadFilesLabel);
//      setUploadedFile({ fileName, filePath });
//      setCopyText(filePath);
//     //  setMessage('File Uploaded');
//    } catch (err) {
//      if (err.response.status === 500) {
//       alert('There was a problem with the server');
//       //  setMessage('There was a problem with the server');
//      } else {
//       //  setMessage(err.response.data.msg);
//      }
//      setUploadPercentage()
//    }
//  };

function setFileData(data){
  console.log(data)
  const { fileName,uploadFilesLabel,filePath } = data;
  handleChange(field_id,{},uploadFilesLabel);
   setUploadedFile({ fileName, filePath });
   setCopyText(filePath);
}

const onSubmit = async (e) => {
  e.preventDefault();
  const formData = new FormData();
  formData.append('file', file);
console.log(formData)
console.log(file)
  try {
    const response = await APIUPLOAD(apipath, formData, setUploadPercentage, setFileData);
    console.log('Upload successful!', response);
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};
 
 const copyToClipboard = () => {
  copy(copyText);
  alert(`You have copied "${copyText}"`);
}

  return (
<div style={{display:"flex"}}>
    <div className={classes.fileroot}>
      <div style={{display:"flex"}}>
        <InputLabel style={props.field_label_style?props.field_label_style:null} className={props.field_label_class?classes[props.field_label_class]:classes.label}  >{field_label}</InputLabel>{field_mandatory==="yes"?<span 
          style={{fontSize: "18px",
          color: "#d71729",
          fontWeight: "400",
          lineHeight: "20px",
          transform: "translateY(-2px)"}}>*</span>:null}
      </div>
          <div  variant="outlined" className={classes.boxBoder}>
            <input 
            id={field_id}
            type="file" 
            onChange={handleChangeFile} 
            accept={acceptfile}
            // onBlur={()=>(handleBlur())}  
            />
            {/* <button type="button" onClick={copyToClipboard}>Copy Url</button>: */}
          {uploadedFile ? null:
          <button type="submit"  onClick={onSubmit}>Upload</button>
          }

          {uploadPercentage && (<div className={classes.progrs}>
          <div
            className={classes.progressBar}
            role='progressbar'
            style={{ width: `${uploadPercentage}%` }}
          >
              {uploadPercentage}%
            </div>
          </div>)}
          </div>
          {field_helperText?<FormHelperText>{field_helperText}</FormHelperText>:null}
          {/* {uploadedFile && ( <label  style={{marginTop: "20px",marginBottom: '0px'}}>{uploadedFile.filePath}</label> ) } */}
    </div>
      {file && (<div>{preview==='yes' && <div className={classes.previewImg}>
              <img src={URL.createObjectURL(file)} alt="" />
              {/* <span><h5>Preview</h5></span> */}
          </div>}
      </div>)}
  </div>
  );
}

export default UploadFile;

