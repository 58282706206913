


import React,{useState,useEffect} from 'react'
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import Selects from '@mui/material/Select';
import { FormContext } from '../Component/Forms/FormContext';
import elementValidate from '../Component/validation';
import  { useContext } from  'react';
import { APIGET } from '../services/APIServices';

import { MasterContext } from '../services/MasterContext';

function CheckBoxList(props) {
    const classes = useStyles();
  
  
  const { field_type, field_id, field_label, field_placeholder,field_disabled, field_value,field_option_src, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
  let {masterData} = React.useContext(MasterContext);
  const [fieldOptions,setFieldOptions] = React.useState(field_options);
  const { handleChange } = useContext(FormContext);
  const [list, setList] = useState([]);
  var str = field_value;
  var arr = str.split(",").map(Number);
  const [checkedItems, setCheckedItems] = useState(field_value?arr:[]);

  // const onHandleChange = (event) => {
  //   const { value, checked } = event.target;
  //   if (checked) {
  //     // push selected value in list
  //     let temp = list;
  //     temp.push(value); 
  //     setList(temp);
  //   } else {
  //     // remove unchecked value from the list
  //     let temp = list;
  //     temp = temp.filter(_item => _item !== value);
  //     setList(temp);
  //   }
  //   let _list = list.toString();
  //   console.log(list)
  //   const myArray = _list.split(',');
  //   const _List = myArray.join(', ')
  //   // let _List = list.join(', ');
  //   handleChange(field_id,{},_List);
  // }

    useEffect(()=>{
      var str = checkedItems.join(",");
      handleChange(field_id,{},str);
    },[checkedItems])

  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };
 
  async function getOptions(props) {
    try{
      if(props.field_option_src_context){
        if(false){
          // if(props.field_option_check_local_storage==="true"&&masterData&&masterData[props.field_option_src_context]){
              //todo check in localstorage
                console.log(masterData);
                setFieldOptions(masterData[props.field_option_src_context]);
              }
      else{
          let APIRES =await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria).then(
          (APIRES)=>{
              console.log(APIRES);
              let responseArray =new Array();
              let _i =0;
              if(props.field_select_all_label){
                  let _obj = new Object();
                  _obj.option_label = props.field_select_all_label;
                  _obj.value = props.field_select_all_value;
                  responseArray[_i] = _obj;
                  _i++;  
              }
              Object.keys(APIRES.data).forEach( key => {
                  let _obj = new Object();
                  _obj.option_label = APIRES.data[key][props.field_option_label];
                  _obj.value = APIRES.data[key][props.field_option_value];
                  responseArray[_i] = _obj;
                  _i++; 
              })
              //fieldOptions = responseArray; 
              setFieldOptions(responseArray);
              masterData[props.field_option_src_context] = responseArray;
          }
          );            
        }                  
      }
    } catch(err){/** TODO Do Nothing */}
  }
useEffect(()=>{
getOptions(field_option_src);
},[])

// if(field_value && field_value!= null && field_value.length > 0){
//  const myArray = field_value.split(',');
//  const arrOfNum = myArray.map(element => {
//   return Number(element);
// });
//   setList(arrOfNum);  
// }
// console.log(field_value) 
  return (
    <div className={classes.inputroot}>
        <div style={{display:"flex"}}>
            <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
            style={{fontSize: "18px",
            color: "#d71729",
            fontWeight: "400",
            lineHeight: "20px",
            transform: "translateY(-2px)"}}>*</span>:null}
        </div>
        <div className={classes.CheckBoxList} >

        {fieldOptions.map((option, i) => <label key={i}>
            <input
            type="checkbox"
            value={option.value}
            checked={checkedItems.includes(option.value)}
            // onChange={(e)=>onHandleChange(e)}
            onChange={() => handleCheckboxChange(option.value)}
            /> {option.option_label}
        </label>)}
        </div>
        {/* <div>Selected Value: {list.length ? list.join(', ') : null}</div> */}
        {/* {console.log(list)} */}
    </div>
  ); 
}
 
export default CheckBoxList;
