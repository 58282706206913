import * as React from 'react';

import { Typography }   from '@mui/material/Typography';
import useStyles        from '../ComponentCss';
import Button           from '../../FormsControls/Button';
import Input            from '../../FormsControls/Input';
import Element          from '../../FormsControls/Element';
// import useStyles from '../../../../../Component/Forms/formsCss';
import { FormContext }  from '../Forms/FormContext';
import CloseIcon from '@mui/icons-material/Close';
import {IoWarningOutline } from 'react-icons/io5';
import elementValidation from '../validation.js';
// import delJSON from './DeleteJson.json';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

//axios
import axios from 'axios';

//APIService 
import {APIGET,APIDEL} from '../../services/APIServices';

//Context
import { FormJSONCreateContext } from '../FormJSONCreateContext';

export default function QrCodePopup(props){ 
  const classes = useStyles();
   

      return(
        <div className={classes.qrclose}>
           <CloseIcon onClick={()=>(props.close())}/>
           <img src={props.qrLink} alt="Qr Code" width="200" height="200"></img>
        </div> 
      )
}

