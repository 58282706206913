import React from 'react'
import useStyles from './FormControlsCss';
import { RiErrorWarningLine } from "react-icons/ri";

export default function RequiredMsg(props) {
 
    let {field_label,field_btn_label,field_btn_label_2} = props
    
    const classes = useStyles();

    return ( 
        <div className={classes.inputroot}>
        <div className={classes.RequiredMsg}>
          <span>
            <RiErrorWarningLine />
          </span>
          <div className={classes.txtp}>
            <p>{field_label}</p>
            <div className={classes.RequiredMsgbtn}>
              <div>MSISDN</div>
              <div>Email</div>
            </div>
          </div>
        </div>
      </div>   
    );  
}
