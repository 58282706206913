//  import Formioimg from '../images/Formio.png';

import {makeStyles} from '@material-ui/core/styles';
import { margin } from '@mui/system';
 const useStyles = makeStyles((theme) => ({
  selectOptions:{
      background:'red',
  },
  fileroot:{
    marginBottom: "40px",
    width:'544px',
    '& .MuiFormHelperText-root':{
      // position: 'absolute',
      top: '100%',
      // left: '-13px',
      width: '100%',
      fontSize: '11px',
      color: "#D12469",
      maxWidth: '544px',
      overflow: 'hidden',
      textOverflow: "ellipsis",
      transform: 'translateY(2px)',

    },
    '& .MuiFormLabel-root':{
      fontSize: '14px',
      fontWeight: '600',
      color: '#65707F',
      marginBottom: '12px',
      display: 'block',
    },
    '& .Mui-disabled':{
      borderColor: '#A0AFB2',
      opacity: 1,
      "&:hover": {
        borderColor: '#A0AFB2',
      boxShadow: 'none', 
      
      },
    } 
  },
  inputroot:{
    marginBottom: "25px",
    position: 'relative',
    // marginRight: "10px",
    // minWidth:'300px',
    // width:'400px',
    // ['@media (min-width: 1920px)'] : {
    //   width:'400px',
    //   },
    //     ['@media (max-width: 850px)'] : {
    //        width:'100%',
    //     },
    '& h3':{
      color:'#344256',
    },
    '& .MuiInputBase-root':{
      padding:'0px',
    },
    '& .MuiFormHelperText-root':{
      position: 'absolute',
      margin: '0',
      // top: '100%',
      // left: '-13px',
      maxWidth: '100%',
      // minWidth: '100px',
      fontSize: '10px',
      color: "#D12469",
      overflow: 'hidden',
      textOverflow: "ellipsis",
      whiteSpace: 'nowrap',
    },
    '& .MuiFormLabel-root':{
      fontSize: '12px',
      fontWeight: '600',
      color: '#65707F',
      marginBottom: '8px',
      display: 'block',
    },
    '& .Mui-disabled':{
      borderColor: '#A0AFB2',
      opacity: 1,
      "&:hover": {
        borderColor: '#A0AFB2',
      boxShadow: 'none', 
      
      },
    } 
  },
  assetsinpt:{
    marginTop:'24px',
    '& .MuiFormLabel-root':{
      fontSize: '14px',
      fontWeight: '600',
      color: '#65707F',
      marginBottom: '12px',
      display: 'block',
    },
  },
  MuiError:{
    '& .MuiOutlinedInput-root':{
    borderColor: '#d12469',
    boxShadow: '0px 0px 5px rgba(147 31 31, 0.5)', 
    }
  },
  rbdb:{
    '& .MuiFormGroup-root':{
      display: 'block',
     },
  },
  closeMultiSelcet:{
    width: '26px',
    zIndex: '999999',
    position: 'relative',
    backgroundColor: '#fff',
    // border: '1px solid #f9a65f',
    color: '#8e8888',
    textAlign: 'center',
    height: '26px',
    left: '540px',
    bottom: '0px',
    cursor: 'pointer',
    '& svg':{
      fontSize:'24px',
    }
  },
  border:{
    width:'100%',
    maxWidth: '544px', 
    height: '50px',
    position:'relative',
    backgroundColor: '#fff',
    '& .MuiFormControl-root':{
      border:'1px solid #A0AFB2',
    }
  },
  previewImg:{
    position:'relative',
    border: '1px solid #b1b6b8',
    width: '140px',
    height: '110px',
    padding: '5px',
    marginLeft: '10px',
    borderRadius: '4px',
    "& img":{
      width: '100%',
      height: '100%',
    },
    "& span":{
      left: '0',
      width:'100%',
      position: 'absolute',
      bottom: 0,
      background: '#dee1e6',
      color:'#fff',
      textAlign: 'center',
      "& h5":{
        margin:'0',
      }
    }
  },
  progrs:{
    height: '10px',
    marginTop: '10px',
    fontSize: '8px',
    width: '544px',
    position: 'absolute',
    left: '0',
  },
  progress:{
    height: '10px',
    marginTop: '10px',
    fontSize: '8px',
    width: '427px',
    position: 'absolute',
    left: '0',
  },
  progressBar:{
    backgroundColor:'#26ad8d',
    backgroundImage:' linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent)',
    backgroundSize: '1rem 1rem',
    // display: 'flex',
    // flexDirection:' column',
    justifyContent: 'center',
    overflow: 'hidden',
    color: '#fff',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    /* background-color: #007bff; */
    transition:'width .6s ease',
  },
  fubox:{
    border: '1px solid #A0AFB2',
    borderRadius: '4px',
    padding: '20px',
    width: '544px',
    // marginBottom: '50px',
    '& .MuiFormLabel-root':{
      fontSize: '14px',
      fontWeight: '600',
      color: '#65707F',
      marginBottom: '12px',
      display: 'block',
    },
  },
  done:{
    display:'flex',
    alignItems: 'center',
    marginTop:'20px',
    height: '25px',
    '& span':{
      width: '25px',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #4b9f46',
      backgroundColor:'#fff',
      borderRadius: '40px',
      marginRight:'10px',
      '& svg':{
        fill:'#4b9f46'
      }
    },
    '& p':{
      margin:0,
      fontSize:'13px',
      color:'#76bf8a'
    }
  },
  boxBoder:{
    width:'100%',
    // maxWidth: '544px', 
    position: 'relative',
    height: '50px',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#344256',
    border:'1px solid #A0AFB2',
    backgroundColor: '#fff',
    boxSizing:'border-box',
    display: 'block',
    "& input": {
      boxSizing:'border-box',
      height: '48px',
      padding:'8px 16px',
      cursor:'pointer',
      width:'100%',
    },
    "& fieldset": {
      border:'none !important',
    },
    "&:hover": {
      borderColor: '#1e9176',
    boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)', 
    },
    "& input[type=file]::file-selector-button":{
      border: '1px solid #26AD8D',
      
      padding: '.2em .4em',
      borderRadius: '.2em',
      backgroundColor: '#26AD8D',
      transition: '1s',
      color:'#fff',
    },
    "& button":{
      border: '1px solid #26AD8D',
      padding: '.2em .4em',
      borderRadius: '.2em',
      backgroundColor: '#26AD8D',
      transition: '1s',
      position: 'absolute',
      right: 0,
      margin: '8px 16px',
      color:'#fff',
    },
    "& button:hover":{
      backgroundColor: '#26AD8',
      border: '1px solid #26AD8',
      boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
      transform: 'translateY(-1px)',
    },
    "& input[type=file]::file-selector-button:hover" :{
      backgroundColor: '#26AD8',
      border: '1px solid #26AD8',
      boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
      transform: 'translateY(-1px)',
    }
  },
  alinfilebtn:{
    display:'flex',
    "& button":{
      border: '1px solid #00b091',
      padding: '.2em .4em',
      borderRadius: '.2em',
      backgroundColor: '#00b091',
      transition: '1s',
      // position: 'absolute',
      height: '50px',
      marginLeft: '16px',
      color:'#fff',
    },
    "& button:disabled":{
      border: '1px solid #7b9393',
      padding: '.2em .4em',
      borderRadius: '.2em',
      backgroundColor: '#7b9393',
      transition: '1s',
      // position: 'absolute',
      height: '50px',
      marginLeft: '16px',
      color:'#fff',
    },
    "& button:hover":{
      // backgroundColor: '#7b9393',
      // border: '1px solid #26AD8',
      boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
      transform: 'translateY(-1px)',
    },
    '& svg':{
      width: '40px',
      height: '30px',
    },
  },
  upldfl:{
    width:'100%',
    // maxWidth: '544px',
    padding: '12px 16px',
    // marginBottom:'26px', 
    position: 'relative',
    height: '50px',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#344256',
    border:'1px solid #A0AFB2',
    backgroundColor: '#fff',
    boxSizing:'border-box',
    display: 'block',
    "& input": {
      boxSizing:'border-box',
      height: '48px',
      padding:'8px 16px',
      cursor:'pointer',
      width:'100%',
      position: 'absolute',
      left: '0',
      top: '0',
      opacity: 0,
    },
    "& fieldset": {
      border:'none !important',
    },
    "&:hover": {
      borderColor: '#1e9176',
    boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)', 
    },
    "& input[type=file]::file-selector-button":{
      border: '1px solid #26AD8D',
      
      padding: '.2em .4em',
      borderRadius: '.2em',
      backgroundColor: '#26AD8D',
      transition: '1s',
      color:'#fff',
    },
    "& input[type=file]::file-selector-button:hover" :{
      backgroundColor: '#26AD8',
      border: '1px solid #26AD8',
      boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
      transform: 'translateY(-1px)',
    }
  },
  fileheader:{
    marginTop:'26px',
  },
  headerview:{
    borderCollapse: 'collapse',
    width: '100%',
    '& th':{
      backgroundColor: '#e3e6e8',
      fontWeight: '600',
      border: '1px solid #c5cfd3',
      fontSize: '10px',
      padding: '8px',
      textAlign: 'left',
    }
  },
  width:{
    width:'100%',
    maxWidth: '544px', 
    '& .MuiOutlinedInput-root':{
      padding:'0',
    }
  },
  CheckBoxList:{
    border:'1px solid #A0AFB2',
    backgroundColor: '#fff',
    padding:'8px 16px',
    width:'100%',
    maxWidth: '544px', 
    borderRadius: '4px',
    height: '168px',
    overflow: 'auto',
    "& label":{
      display: "block",
      fontSize: '16px',
    },
    "&:hover": {
      borderColor: '#1e9176',
    boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)', 
    },
  },
  iwb:{
    display: 'flex',
    flexWrap: 'wrap',   
    justifyContent: 'center',
    width:'100%',   
    maxWidth: '356px', 
    height: '50px',
    '& .MuiInputBase-root':{
      backgroundColor:'#fff',
    },
    '& Button':{
      padding:'10px 20px',
      backgroundColor:'#d99d31',
      border:'1px solid #a0afb2',
      height:'48px',
      marginLeft:'10px',
      '&:hover':{ 
        backgroundColor: '#d99d31',
        boxShadow: '1px 1px 6px rgba(31, 147, 120, 0.8)',
      transform: 'translateY(-1px)'
      },
    },
    '& Button:not(:first-of-type)':{
      backgroundColor:'#7b9393',
      '&:hover':{
        backgroundColor:'#7b9393',
      }
    }
  },

  pbtn:{
    position: 'absolute',
    // top: '0.5px',
    right: '0px',
    '& Button:not(:first-of-type)':{
      marginLeft:"10px",
    },
    '& Button:last-of-type':{
      padding:'5px 10px',
      backgroundColor:'#4c9aff',
      color:'#fff',
      // transform: 'translate(1px, 1px)',
      border:'0px solid #a0afb2',
      borderRadius:'0 3px 3px 0',
      marginLeft:"15px",
      height:'30px',
      // position: 'absolute',
      right: 0,
      '&:hover':{ 
        backgroundColor: '#4c9aff',
        boxShadow: '1px 1px 6px rgb(76 154 255 / 67%)',
        transform: 'translate(0px, 0px)',
      },
    },
    '& .MuiInputAdornment-root':{
      height: '28px',
      maxHeight: '28px',
    }
  },
  miwtb:{
    display: 'flex',
    width:'100%',   
    maxWidth: '544px',
    flexDirection: 'row',
    '& .MuiOutlinedInput-root':{
      padding:'0',
    },
    '& Button':{
      marginLeft:'10px',
      // position: 'absolute',
      left: '440px',
      top: '80px',
      backgroundColor:'#d99d31',
      border:'1px solid #a0afb2',
      '&:hover':{ 
        backgroundColor: '#d99d31',
        boxShadow: '1px 1px 6px rgba(31, 147, 120, 0.8)',
      transform: 'translateY(-1px)'
      },
      '&:active':{ 
        backgroundColor: '#d99d31',
        boxShadow: '0px 0px 3px rgba(31, 147, 120, 0.8)',
      transform: 'translateY(1px)'
      },
    }
  },
  select:{
    width:'100%',
    height: '30px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
    border:'none',
    backgroundColor: '#f4f5f7',
    "& fieldset": {
      border:'none',
    },
    "& .MuiSelect-select":{
      fontSize: '12px',
      fontWeight: '500',
      padding:'0px 10px !important',
      height: '29px !important',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      '&:focus': {
        // outline: '2px solid #4c9aff',
        border:'1px solid #4c9aff',
        },
    },
  },
  input:{
    width:'100%',
    // maxWidth: '544px', 
    height: '30px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#344256',
    // border:'1px solid #A0AFB2',
    backgroundColor: '#f4f5f7',
    boxSizing:'border-box',
    display: 'block',
    "& .MuiSelect-select":{
      // width:'134px',
      padding:'5px 10px !important ',
      height: 'auto',
      // width: 'auto',
      fontSize: '12px',
      fontWeight: '500',
      '&:focus': {
        border:'1px solid #4c9aff',
        borderRadius: '4px',  
        },
    },
    "& input": {
      fontSize: '12px',
      boxSizing:'border-box',
      height: '30px',
      padding:'5px 10px',
      '&:focus': {
        // outline: 'none !important',
        // outline: '2px solid #000',
        border:'1px solid #4c9aff',
        borderRadius: '4px',    
        // borderColor: '#1e9176',
        },
      // border:'none',
    },
    "& .MuiInputBase-input:focus":{
        WebkitTextFillColor: 'rgb(0 0 0 / 87%)',
    },
    "& fieldset": {
      border:'none',
    },
    '& .MuiInputAdornment-root':{
      position: 'absolute',
      right: '0px',
      '& button':{
       marginRight:0,
       '& svg':{
        width: '18px',
       }
      }
     }
  },
  multiInput:{
    width:'100%',
    // maxWidth: '544px', 
    // minHeight: '50px',
    borderRadius: '4px',
    fontSize: '12px',
    fontWeight: '500',
    color: '#344256',
    // border:'1px solid #A0AFB2',
    backgroundColor: '#f4f5f7',
    boxSizing:'border-box',
    display: 'block',
    "& .MuiInputBase-input": {
      boxSizing:'border-box',
      // minHeight: '48px',
      padding:'5px 10px',
      fontSize: '12px',
      '&:focus': {
        border:'1px solid #4c9aff',
        borderRadius: '4px',    
        },
    },
    "& fieldset": {
      border:'none !important',
    }
  },
  

  switch:{
    border: '1px solid #a4bec5',
    height: '50px',
    padding: '4px',
    borderRadius: '5px',
    backgroundColor: '#cddde1',
    // marginTop: '26px',
    // marginLeft: '16px',
    '& .MuiSwitch-thumb':{
      backgroundColor:'#26AD8D',
    }
  },


  searchSelect:{
    padding:'8px 16px',
    // marginBottom:'32px',
    width:'100%',
    maxWidth: '544px',
    height: '50px',
    borderRadius: '4px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#344256',
    border:'1px solid #A0AFB2',
    backgroundColor: '#fff',
    boxSizing:'border-box',
    display: 'block',
    "& input": {
      boxSizing:'border-box',
      height: '48px',/////
      padding:'8px 16px',
      border:'none',
    },
    "& fieldset": {

      border:'none !important',
    },
    "&:hover": {
      borderColor: '#1e9176',
    boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)', 
    },
    '&:focus': {
      outline: 'none !important',
    border:'1px solid #A0AFB2',    
    borderColor: '#1e9176',
    boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
    },
  },

//////
  timeInputGrp:{
        width:'100%',
        maxWidth: '544px',
        alignItems:'center',
        display: 'flex',
  "& > div": {
        flex:'1',
          },        
  "& h2": {
        margin:'0 16px',
        fontSize: '18px',
        fontWeight: '600',
        color: '#65707F',
        display: 'block',
            },
      }, 
      ///////
      btn:{
        height: '42px',
        // marginLeft:'10px',
        backgroundColor: '#26AD8D',
        color: '#F2F8FA',
        fontSize: '15px',
        padding:'10px 30px',
        borderRadius: '4px',
        border:'1px solid #26AD8D',
        cursor: 'pointer',
        transition: 'all .3s',
        // '&:hover':{ 
        //   backgroundColor: '#26AD8D',
        //   boxShadow: '1px 1px 6px rgba(31, 147, 120, 0.8)',
        // transform: 'translateY(-1px)'
        // },
        '&:disabled':{ 
          backgroundColor: '#cccccc !important', 
          boxShadow: '1px 1px 6px #999999',
          transform: 'translateY(0px)',
          border:'1px solid #999999 !important'
        },
        '&:active':{ 
          backgroundColor: '#1e9176',
          boxShadow: '0px 0px 3px rgba(31, 147, 120, 0.8)',
        transform: 'translateY(1px)'
        },
      },   
    
CustomInputGrp:{
      // alignItems:'center',
      maxWidth:'544px',
      width:'100%',
      display: 'flex',        
      "& Button": {
        color:'#000',
        fontSize: '20px',
        width: '56px',
        height: '50px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cddde1',
        border: '1px solid #a4bec5',
        marginTop: '26px',
        marginLeft: '16px',
        transition:'all .2s',
       
         },
  },
  css:{
    // alignItems:'center',
    maxWidth:'544px',
    width:'100%',
    display: 'flex',
    "& .MuiAutocomplete-root .MuiOutlinedInput-root":{
      height:'48px',
    }        
},
  mw:{
    maxWidth:'544px',
    width:'100%',
  },
   flexDiv: {
       flex:'1',
       '& .MuiInputBase-root':{
        backgroundColor:'#fff'
      }
      },
      inputFlex:{
        flex: '0 0 60%',
        maxWidth: '60%',
      },
     selectSize:{
      marginLeft: '16px',
      flex:'1',
      '& .MuiInputBase-root':{
        backgroundColor:'#fff'
      }
     },
     selectm:{
      marginRight: '16px',
      flex: '0 0 40%',
      maxWidth: '40%',
      '& .MuiInputBase-root':{
        backgroundColor:'#fff'
      }
     },
     switchSize:{
      marginLeft: '16px',
      '& .MuiSwitch-root':{
        left:'25px',
      }
     },  
    CustomInput:{
      display: 'flex', 
      "& Button": {
        color:'#000',
        fontSize: '20px',
        width: '56px',
        height: '50px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#cddde1',
        border: '1px solid #a4bec5',
        marginTop: '26px',
        marginLeft: '16px',
        transition:'all .2s'
         },
    },
    CustomSize:{
      maxWidth:'445px',
      width:'100%',
    },
    error:{

      "& MuiFormHelperText-root":{
        color:'red',
      }
    },
    QRcode:{
      display:'flex',
      height:'200px',
    },
    qrbtn:{
      display:'flex',
      flexDirection:'column',
      '& Button':{
        margin:'10px',
        backgroundColor: 'transparent',
        color: 'green',
        width: '120px',
        height: '40px',
        padding:'10px',
        '&:hover':{
          backgroundColor: 'transparent',
        }
      }
    },
    upldbox:{
      display:'flex',
      height:'200px'
    },
    upldbtn:{
      // display: 'flex',
      position: 'relative',
      overflow: 'hidden',
      display: 'inline-block',
      '& Button':{
        margin:'10px',
        backgroundColor: 'transparent',
        color: 'green',
        width: '120px',
        height: '40px',
        padding:'10px',
        '&:hover':{
          backgroundColor: 'transparent',
        }
      },
      '& input[type=file]':{
        fontSize: '100px',
        position: 'absolute',
        margin: '10px',
        width: '120px',
        // maxWidth:'544px',
        // width:'100%',
        height: '40px',
        left: 0,
        top: 0,
        opacity: 0,
      },
    },
    qrotpt:{
      border:'1px solid red',
      // backgroundColor:'green',
      width :'150px',
      height:'150px',
    },
    QRcodebox:{
      border:'1px solid gray',
      borderRadius:'4px',
      backgroundColor:'#fff',
      height:'186px',
      width:'186px',
      padding:'2px',
      position:'relative',
      '& img':{
        height:'180px',
        width:'180px',
      },
      '& canvas':{
        position: 'absolute',
        bottom:'0px',
        height:'180px !important',
        width:'180px !important',
        overflow:'hidden'
      }
    },
    ".rightFloat":{
      float:'right'
    },".leftFloat":{
      float:'left'
    },

    fb:{
      '& .form-wrap.form-builder .stage-wrap.empty':{
        height: '560px',
        border: '3px solid #242f3d',
        borderRadius: '10px',
        width:'55%',
        marginLeft:'5%',
        // backgroundImage:"`url(${Formioimg})`",
      },
      '& .form-wrap.form-builder .stage-wrap':{
        width:'55%',
        marginLeft:'5%',
        overflow: 'hidden',
        overflowY: 'auto',
        // backgroundImage:"`url(${Formioimg})`",
      },
      '& .form-wrap.form-builder .frmb':{
        height: '560px',
        border: '3px solid #242f3d',
        borderRadius: '10px',
      },
      '& .form-wrap.form-builder .pull-left':{
        float:'right',
      },
      '& .form-wrap.form-builder .cb-wrap':{
        width:'40%'
      }
    },
    clist:{
      '& ul':{
        fontSize:'14px',
        fontWeight:'600',
        color:'#626e7d',
        padding:'0',
        '& li':{
          listStyle:'none',
        },
        '& li:not(:last-child)':{
          marginBottom:'8px',
        },
        '& li:last-child':{
          marginTop:'42px',
        }
      }
    },
    brack:{
      borderBottom: '1px solid #A0AFB2',
      width: '544px',
      marginBottom: '40px',
      marginTop: '72px',
    },
    RequiredMsg:{
      borderBottom: '1px solid #A0AFB2',
      borderTop: '1px solid #A0AFB2',
      width: '544px',
      display:'flex',
      marginBottom: '40px',
      marginTop: '40px',
      padding:'30px 0px 30px 0px',
      '& p':{
        color:'#394962',
        fontSize:'16px',
        // margin: 0,
      },
      '& span svg':{
        width:'30px',
        height:'30px',
        fill:'#e4921e',
        transform: 'rotate(180deg)',
      },
    },
 txtp:{
  padding: '3px 10px',
 },
    RequiredMsgbtn:{
      display: 'flex',
      '& div':{
        cursor: 'pointer',
        backgroundColor: '#fff',
        border: '1px solid #A0AFB2',
        padding: '5px 30px',
        color:'#394962',
        fontSize: '14px',
        borderRadius: '20px',
        fontWeight: '600',
      },
      '& div:first-of-type':{
      marginRight:'10px',
      },

    },
    h3color:{
      // color:'#fff',
      fontSize:'20px',
    },
    dragDropstyleItem:{
        "border":"1px solid black",
        "minHeight":"40px",
        "minWidth":"100px",
        "padding":"10px 10px",
        '& span':{
          "display":"none",
        },
        '&:hover':{
            "border": "1px solid #2da0da",
            "boxShadow": "1px 1px 6px #2da0da",
            "transform": "translateY(-1px) scale(1.01)",
            '& span':{
              "display":"block",
            },
        }
    },
    dragDropRightAddButton:{
          "float":"right",
          "border": "1px solid gray",
          "padding": "0px 8px",
          "borderRadius":"4px",
          "backgroundColor":'rgb(38, 173, 141)',
          "color":'#FFF',
    },
    dragDropRightRemoveButton:{
      "float":"right",
      "border": "1px solid gray",
      "padding": "0px 8px",
      "backgroundColor":'#D12469',
      "borderRadius":"4px",
      "color":'#FFF',
    },
    scrubRule:{
      display:'flex',
      // background:"red",
      '& [class *="makeStyles-inputroot"]':{
        marginBottom: '0px',
        // marginLeft:'8px',
        marginRight:'10px',
        width: '172px',
        '& .MuiFormLabel-root':{
          fontSize:'12px'
        },
      },
    },
    srb:{
      border: '1px solid #A0AFB2',
      padding: '15px 12px',
      width: '700px',
      backgroundColor:'#ffffff',
      borderRadius: '4px',
      marginTop: '20px',
    },
    srh:{
      border: '1px solid #A0AFB2',
      padding: '10px 12px',
      width: '700px',
      backgroundColor:'#f0f6fa',
      borderRadius: '4px',
      // marginBottom: '30px',
      display:'flex',
      justifyContent: 'space-between',
      '& h5':{
        marginBottom: '0px',
        fontWeight: 600,
        fontSize: '15px',
      }
    },
    mb:{
      border: '1px solid #A0AFB2',
      padding: '15px 14px',
      width: '730px',
      backgroundColor:'#f0f6fa',
      borderRadius: '4px',
      marginBottom: '20px',
      justifyContent: 'space-between',
    },
    btnslt:{
      height: '50px',
      width: '80px',
      marginTop: '24px',
      // marginLeft: '8px',
      marginRight: '10px',
      backgroundColor:'#ffffff',
      borderRadius: '4px',
      '& button':{
        width: '100%',
        height: '50px',
        border:'1px solid #A0AFB2',
        justifyContent: 'space-between',
        padding: '13px 7px 13px 16px',
        "&:hover": {
          borderColor: '#1e9176',
        boxShadow: '0px 0px 5px rgba(31, 147, 120, 0.5)',
        backgroundColor:'#ffffff', 
        },
      },
    },
    btngup:{
      '& .MuiButtonGroup-root':{
        margin: '0px',
        // marginBottom: '0px',
        '& .MuiButtonBase-root':{
          padding: '3px 0px',
        }
      }
    },
    btntogl:{
      marginBottom: '20px',
      marginTop: '20px',
      '& [class *="MuiButtonBase-root"].Mui-selected':{
        padding: '2px 10px',   
        fontWeight: 'bold',
        color:'#ffffff',
        backgroundColor:'#366daa',
        "&:hover": {
          backgroundColor:'#366daa', 
        },
       },
      '& .MuiButtonBase-root':{
        padding: '2px 10px',
        border:'1px solid #366daa',
        color:'#A0AFB2',
        // "&:hover": {
        //   backgroundColor:'#366daa', 
        // },
      }
    },
    chkctra:{
      height: '16px',
      fontSize: '13px',
      '& input':{
        width: '15px',
        height: '15px',
        accentColor: '#00a14d',
      }
    },
    btnwslt:{
      marginLeft: '20px',
      marginBottom: '20px',
      position: 'relative',
      '& .MuiButtonGroup-root':{
      boxShadow: 'none',
      '& button':{
        color:'#fff',
        padding:'7px',
        backgroundColor:'#4b9f46',
        "&:hover": {
          backgroundColor:'#4b9f46', 
        },
      },
      '& Button:not(:first-of-type)':{
        color:'#000',
        width: '210px',
        fontSize:"12px",
        border:'1px solid #4b9f46',
        backgroundColor:'transparent',
        justifyContent: 'space-between',
        "&:hover": {
          backgroundColor:'transparent', 
        },
      },
    },
      '& .MuiPopperUnstyled-root':{
        position: 'absolute !important',
        top: '41px !important',
        left: '41px !important',
        width:'210px',
        // height:"118px",
        '& [class *="MuiButtonBase-root"].Mui-selected':{
          padding: '7px 20px',   
          fontWeight: 'bold',
          color:'#ffffff',
          backgroundColor:'#85929f',
          "&:hover": {
            backgroundColor:'#85929f', 
          },
         },
         '& .MuiPaper-root':{
          height:"144px",
          '& .MuiButtonBase-root':{
            fontSize:'13px',
          }
        },
         }
    }

}));
export default useStyles;


