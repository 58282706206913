import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { BsSearch } from "react-icons/bs";
import copy from "copy-to-clipboard";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import useStyles from '../ComponentCss';
import { ReactComponent as Edit } from '../../images/Table/Edit.svg';
import { ReactComponent as Bin } from '../../images/Table/Bin.svg';
import { ReactComponent as View } from '../../images/Table/Eye.svg';
import { ReactComponent as FileIcons } from '../../images/svgicon/report.svg';
import { ReactComponent as QrCode } from '../../images/svgicon/qr-code-scan-9795.svg';
import { ReactComponent as Download } from '../../images/Table/download.svg';
import Loading from '../../images/Table/Loading.gif';
import { FormJSONCreateContext } from '../FormJSONCreateContext';

import DeleteTableRow from './Delete';
import QrCodePopup from './QrCodePopup';
import EditForm from '../Forms/EditForm';
import PreviewPolicy from "./Preview.js";
import ApproveReject from "./ApproveReject.js";
//dilog
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';


//testing
//import SuccessPage from '../SuccessPage'
//JSON CONTEXT

import demo from './demo.json';

//APISERVICES
import { APIGET, APIDEL} from '../../services/APIServices';
//import SuccessPage from '../SuccessPage';

//Navigation
import { useNavigate } from 'react-router-dom';
import { render } from '@testing-library/react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

// eslint-disable-next-line no-undef


export default function GenerateTable(obj) {
  const classes = useStyles();
  const [delResponse, setDelResponse] = React.useState(undefined);
  const _columns = obj.columns;
  // const setFilteredData = obj.setFilteredData;
  const { renderSuccessPage } = React.useContext(FormJSONCreateContext);
  const { contextJson } = React.useContext(FormJSONCreateContext);
  let navigate = useNavigate();
  const apiPath = obj.apiContext;
  const updateAll = obj.updateAll;
  const [dataSource, setDataSource] = useState([]);
  const DeleteTablerow = obj.DeleteTablerow;
  const Preview = obj.Preview;
  const Authn = obj.authentication;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [openQr, setOpenQr] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [openApprove, setApproveView] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [count, setCount] = useState(0);
  const [data, setData] = useState();
  const [editData, setEditData] = useState();
  const [editPosition, setEditPosition] = useState(undefined);
  const [delPosition, setDelPosition] = useState();
  const [qrLink, setQrLink] = useState();
  const [viewPosition, setViewPosition] = useState();
  const [approvePosition, setApprovePosition] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [errorOpen, setErrorOpen] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState('');
  // const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [value, setValue] = useState();

  const formFieldsJson = obj.createJson;
  //Setting_data for view table.===>

  function hendleErrorMsg(msg) {
    setErrorOpen(true)
    setErrorMsg(msg)
  }

  useEffect(() => {
    async function settingData() {
      return await APIGET(apiPath + `/all`).then(data => {
        if (data) {
          // setDataSource(data.data);
          setFilteredData(data.data);
          if (data.code === "EXPIRED_TOKEN") {
            hendleErrorMsg(data.message)
          } else if (!data.data) {
            hendleErrorMsg(data.message)
          }
        }
      })
      // return setDataSource(await APIGET(apiPath+`/all?page=${page}&pageSize=${rowsPerPage}`).then(data=>data.data))
    }
    settingData();


    // },[apiPath, page, rowsPerPage])
  }, [apiPath, updateAll])

  useEffect(() => {
    const startIndex = page * rowsPerPage;
    const endIndex = startIndex + rowsPerPage;
    if(filteredData){
      setDataSource(filteredData.slice(startIndex, endIndex))
    }
    },[filteredData, page, rowsPerPage])

 

 
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    console.log(parseInt(event.target.value, 10));
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  // handling delete==>
  async function deletingData(value,deleteSuccessPage) {

    let data = {"active": "D"}
    data['reasonForDeletion'] = value;

    Object.keys(formFieldsJson).forEach(key => {
      if (key !== "xioFEConfig") {
        let _object = formFieldsJson[key];
        if (true) {
          // isFormValid = (validateThisForm(_object));
        }
        _object.fields.forEach(thisField => {
          if (thisField.field_type !== "multiSelect" && thisField.field_type !== "date" && thisField.field_type !== "qRCode" && thisField.field_type !== "formJsonData") {
            data[thisField.field_id] = thisField.field_value;
          } else if (thisField.field_type === "qRCode") {
            data[thisField.field_id] = thisField.field_value;
            data[thisField.field_keyValue] = thisField.url;
          } else if (thisField.field_type === "date") {
            var data_regex_format2 = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
            if (thisField.field_value) {
              if (!data_regex_format2.test(thisField.field_value)) {
                data[thisField.field_id] = thisField.field_value.split('-').reverse().join('-');
              }
              else {
                data[thisField.field_id] = thisField.field_value;
              }
            }
          } else if (thisField.field_value === "formJsonData") {
            data[thisField.field_id] = JSON.stringify(thisField.field_value);
          }
          else {
            data[thisField.field_id] = String(thisField.field_value);
          }
        });
      }
    });
 


    const getLabels = () => {
      Object.keys(formFieldsJson).forEach((key) => {
        if (key !== "xioFEConfig") {
          let _object = formFieldsJson[key];
          _object.fields.forEach((field, index) => {
            const { field_id, field_label } = field;
            if (field_id) {
              formFieldsJson[key].fields[index].field_value = dataSource[delPosition][field_id];
              return field_label;
            }
          })
        }
      })
    }

    getLabels();

    // setDelResponse(await APIDEL(obj.apiContextVar+`/${dataSource[delPosition].id}`,data,formFieldsJson,renderSuccessPage).then(data=>data))

    try {
      const response = await APIDEL(obj.apiContextVar + '/' + dataSource[delPosition].id, data, formFieldsJson, deleteSuccessPage);
      console.log(response);
      if (response) {
        setDelResponse(response);
        return response;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }


  //Rendering Rows
  function RenderRow(props) {
    const index = props;
    if (index < dataSource.length) {
      let arr = dataSource[String(index)];
      let keys = _columns.map((column) => (column.dataIndex));
      // keys.map((key)=>(console.log(String(arr[key]))))
// console.log(keys)
      function pendingStatus() {
        //  alert("Approve/ Reject Policy")
        setApprovePosition(index)
        setApproveView(true)
      }
      return (
        <React.Fragment>
          <TableRow>
            {keys.map((key) => ((String(arr[key]) == 'PENDING')&&key==="approvalStatus" ? <StyledTableCell onClick={pendingStatus} value={String(arr[key])}><div dangerouslySetInnerHTML={{ __html: String(arr[key]) == null ? "" : arr[key] }}></div></StyledTableCell> :
            key==="priority" ? <StyledTableCell value={String(arr[key])}><div class="priority" dangerouslySetInnerHTML={{ __html: String(arr[key]) == null ? "" : arr[key] }}></div></StyledTableCell> :
              <StyledTableCell value={String(arr[key])}><div style={{ display: 'flex' }}><span></span><div dangerouslySetInnerHTML={{ __html: String(arr[key]) == null ? "" : arr[key] }}></div></div></StyledTableCell>
            ))}
            {(Authn.analytics.analytic) ? <StyledTableCell >

              <IconButton aria-label="expand row" size="small" >
                <FileIcons onClick={() => {
                  copy(arr.genratedQrurl);
                }}
                />
              </IconButton>
              <IconButton aria-label="expand row" size="small" >
                <QrCode onClick={() => {
                  // console.log(arr.genratedQrcode)
                  setOpenQr(true)
                  setQrLink(arr.genratedQrcode)
                  
                }}
                />
              </IconButton>
              <IconButton aria-label="expand row" size="small" >
                <Download onClick={() => {
                  window.open(arr.genratedQrcode, '_blank').focus();
                }}
                />
              </IconButton>
            </StyledTableCell> : null}

            {(Authn.actions.action) ? <StyledTableCell >

              {(Authn.actions.hide.edit) ? null : <IconButton disabled={Authn.actions.disable.edit} aria-label="expand row" size="small" >
                <Edit onClick={() => {
                  // async function settingData(){
                  //   return setEditData(await APIGET(obj.apiContextVar+`/${arr.id}`).then(data=>data.data))
                  //   }
                  // settingData();
                  setOpen(true)
                  setEditPosition(index)
                  // setTimeout(function(){
                  //   if(!editData){
                  //     //async function settingData(){
                  //     //  return setDataSource(await APIGET(apiPath+`/all`).then(data=>data.data))
                  //     //  }
                  //     // settingData();
                  //   }

                  //   console.log(editPosition)
                  // },1500);
                }}
                />
              </IconButton>}

              {(Authn.actions.hide.download) ? null : <IconButton disabled={arr.scrubStatus==="Ready"?false:Authn.actions.disable.download} aria-label="expand row" size="small" >
                <Download onClick={() => {
                  window.open(arr.upload, '_blank').focus();
                }}
                />
              </IconButton>}
              {(Authn.actions.hide.delete) ? null : <IconButton disabled={arr.scrubStatus==="Ready"?false:arr.scrubStatus==="Failed"?false:Authn.actions.disable.delete} aria-label="expand row" size="small" >
                <Bin onClick={() => {
                  setDelPosition(index)
                  setOpenDel(true)
                }}
                />
              </IconButton>}
              {(Authn.actions.hide.view) ? null : <IconButton disabled={Authn.actions.disable.view} aria-label="expand row" size="small" >
                <View onClick={() => {
                  setViewPosition(index)
                  setOpenView(true)
                }}
                />
              </IconButton>}
            </StyledTableCell> : null}
          </TableRow>
        </React.Fragment>
      );
    }
  }

  
  const closeEdit = () => {
    setOpen(() => (!(open)));
    setViewPosition(undefined);
    setEditPosition(undefined);
    setEditData(undefined);
    async function settingData() {
      return await APIGET(apiPath + `/all`).then(data => {
        if (data) {
          setDataSource(data.data);
        }
      })
    }
    settingData();
  }
  const closeDelete = () => {
    setOpenDel(() => (!(openDel)));
    setViewPosition(undefined);
    setEditPosition(undefined);
    async function settingData(){
      return await APIGET(apiPath + `/all`).then(data => {
        if (data) {
          setDataSource(data.data);
        }
      })
          }
        settingData();
  }
  const closeQr = () => {
    setOpenQr(() => (!(openQr)));
  }
  const closeView = () => {
    setOpenView(() => (!(openView)));
    setViewPosition(undefined);
    setEditPosition(undefined);

    // async function settingData(){
    //       return setDataSource(await APIGET(apiPath+`/all`).then(data=>data.data))
    //       }
    //     settingData();

  }
  const closeApprove = () => {
    setApproveView(() => (!(openApprove)));
    setApprovePosition(undefined);
    // setEditPosition(undefined);
    async function settingData() {
      return await APIGET(apiPath + `/all`).then(data => {
        if (data) {
          setDataSource(data.data);
        }
      })
    }
    settingData();
  }


  const handleClose = () => {
    setErrorOpen(false);
  };

  const filterData = (e)=>{
    setValue(e.target.value) 
    if(e.target.value ===""){
      setDataSource(filteredData)
    }
  }
  
  function searchdata(){    
       if(value){
        const filteredResult = filteredData.filter((item) => {
          return Object.keys(item).some((key) =>
          String(item[key]).toLowerCase().includes(value.toLowerCase())
          );
          });
          setDataSource(filteredResult)
       }
  }
  

  return (
    <React.Fragment>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={errorOpen} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMsg}
        </Alert>
      </Snackbar>

      <Dialog className={classes.popupEdit}
        // fullScreen={fullScreen}
        open={open}
        onClose={closeEdit}
        aria-labelledby="responsive-dialog-title"
      >
        <EditForm index={editPosition} formFieldsJson={formFieldsJson} dataArr={dataSource} apiContextVar={obj.apiContextVar} apiPath={apiPath} close={() => (closeEdit())} contextJson={obj.createJson} contextHeading={obj.contextHeading} />
      </Dialog>

      <Dialog className={classes.poup}
        open={openDel}
        onClose={closeDelete}
        aria-labelledby="responsive-dialog-title"
      >
        <DeleteTableRow index={delPosition} dataArr={dataSource} close={() => (closeDelete())} apiContext={obj.apiContextVar} onDelete={deletingData} contextHeading={obj.contextHeading} policyLabelOnDelete={obj.policyLabelOnDelete} />
      </Dialog>

      <Dialog className={classes.popupEdit}
        open={openView}
        onClose={closeView}
        aria-labelledby="responsive-dialog-title"
      >
        <PreviewPolicy index={viewPosition} dataArr={dataSource[viewPosition]} close={() => (closeView())} contextJson={obj.createJson} contextHeading={obj.contextHeading} />
      </Dialog>
      <Dialog className={classes.popupEdit}
        open={openApprove}
        onClose={closeApprove}
        aria-labelledby="responsive-dialog-title"
      >
        <ApproveReject index={approvePosition} dataArr={dataSource[approvePosition]} close={() => (closeApprove())} contextJson={obj.createJson} apiContext={obj.apiContextVar} contextHeading={obj.contextHeading} />
      </Dialog>
      <Dialog className={classes.QrCodePopup}
        open={openQr}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <img src={qrLink} alt="Qr Code" width="200" height="200"></img> */}
        <QrCodePopup close={() => (closeQr())} qrLink={qrLink} contextHeading={obj.contextHeading} policyLabelOnDelete={obj.policyLabelOnDelete} />
      </Dialog>

      <div className={classes.filtersearch}>
        <input type="text" value={value} onChange={filterData} className={classes.searchInput} placeholder="Search" />
        <div className={classes.searchbtn} onClick={searchdata}><BsSearch /></div>
      </div>
      {(dataSource.length !== 0) ? <><TableContainer className={classes.table} sx={{ minWidth: 330 }}>
       <Table stickyHeader>
          <TableHead>
            <TableRow>
              {_columns.map((column) => (<StyledTableCell>{String(column.title)}</StyledTableCell>))}
              {(Authn.analytics.analytic) ? <StyledTableCell>QR FLOW ANALYTICS</StyledTableCell> : null}
              {(Authn.actions.action) ? <StyledTableCell>ACTION</StyledTableCell> : null}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((arr, index) => (RenderRow(index)))}
          </TableBody>
        </Table> 
      </TableContainer>
      <div className={classes.pagination}>
        <TablePagination
          component="div"
          labelRowsPerPage='Show row per page'
          count={filteredData.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div></>: <div className={classes.tableLoader}><img src={Loading} alt="Loading..." /></div>}
    </React.Fragment>
  );
}