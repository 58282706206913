import React from "react";

//icons
import { ReactComponent as PolicyManager } from "../images/svgicon/policy-manager.svg";
import { ReactComponent as TemplateManager } from "../images/svgicon/template-manager.svg";
import { ReactComponent as CollectionFlow } from "../images/svgicon/git-pull-request.svg";
import { ReactComponent as AudienceManager } from "../images/svgicon/audience-manager.svg";
import { ReactComponent as Customer360 } from "../images/svgicon/customer360.svg";
import { ReactComponent as Dashboard } from "../images/svgicon/dashboard.svg";
import { ReactComponent as Report } from "../images/svgicon/report.svg";
import { ReactComponent as BusinessObjects } from "../images/svgicon/business-objects.svg";
import { ReactComponent as MessagingObject } from "../images/svgicon/messaging-object.svg";
import { ReactComponent as UrlShortner } from "../images/svgicon/url-shortner.svg";
import { ReactComponent as Assets } from "../images/svgicon/assets.svg";
import { ReactComponent as MessagingBlockout } from "../images/svgicon/messaging-blockout.svg";
import { ReactComponent as Variables } from "../images/svgicon/variable.svg";
import { ReactComponent as Qrcode } from "../images/svgicon/qr-code-scan-9795.svg";

const SideMenuJson = {

  menus: [
    {
      MenuTitle: "",
      item: [
        {
          icon: <PolicyManager />,
          title: "Procurement",
          path: "/Procurement",
        },
        {
          icon: <AudienceManager />,
          title: "Quality Check",
          path: "/QualityCheck",
        },
        {
          icon: <TemplateManager />,
          title: "Lead",
          path: "/Lead",
        },
        {
          icon: <Report />,
          title: "Report",
          path: "/Report",
        },
        {
          icon: <CollectionFlow />,
          title: "Change Password",
          path: "/ChangePassword",
        },
        // {
        //   icon: <CollectionFlow />,
        //   title: "Flow ",
        //   path: "/CustomFlow",
        // },
        {
          icon: <Customer360 />,
          title: "Admin",
          path: "/Admin",
          items: [
            {
              title: "User Management",
              path: "/Admin/UserManagement",
            },
            {
              title: "Master Data",
              path: "/Admin/MasterData",
            },
          ]
        }
      ]
    }
  ]
};

export {
  SideMenuJson
}
