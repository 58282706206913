import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import  useStyles  from './FormControlsCss';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ButtonToggle(props) {
    const classes = useStyles();
    let { id,field_type, field_id,field_apipath,field_disabled, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    // const { handleChange } = useContext(FormContext)
    const [alignment, setAlignment] = React.useState('and');

    const handleChange = (event) => {
      setAlignment(event.target.value);
    };
  
    return (
      <div className={classes.btntogl}>
      <ToggleButtonGroup
        color="primary"
        value={alignment}
        exclusive
        // onChange={handleChange}
        onClick={(event) => handleChange(event)}
        aria-label="Platform"
      >
        <ToggleButton value="and">AND</ToggleButton>
        <ToggleButton value="or">OR</ToggleButton>
      </ToggleButtonGroup>
      </div>
    );
  }
  