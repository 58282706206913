import React from 'react';
import '../css/ProfileCss.css'
import {
  Routes,
  Route,
  useNavigate
} from "react-router-dom";
// import AVATAR from '../images/avatar01.jpg'
import AVATAR from '../images/decidd/Admin.webp'

function Profile (props){
  let navigate = useNavigate();
    // const [token, setToken]= React.useState;
    // const logoutUser = () => {
    //     window.localStorage.clear();
    //  }
    let userName = localStorage.getItem('userName');
    let name = localStorage.getItem('name');
     const removeToken = () => {
        window.localStorage.clear();
        window.location.reload(false);
    };
    const changePasswordRoute = () => {
      navigate("/ChangePassword");
  }

  function capitalizeFirstLetter(str) {
    if(str){
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  }
  function capitalizeFirstLetterOnly(str) {
    if(str){
      return str.charAt(0).toUpperCase();
    }
  }
  var userLeter = capitalizeFirstLetterOnly(name);
return(
<div className={`profileDrawrer ${props.inactive ? "inactive" : " "}`}>
  <div className="profile-drawrer-image">
    <div className='prfimg'>{userName==='admin'?<img src={AVATAR} alt="avatar" />:<h2>{userLeter}</h2>}</div>
    <div className="adminList">
      <ul>
        <li>{userName!=='admin'?capitalizeFirstLetter(name):capitalizeFirstLetter(userName)}</li>
        <li>{userName==='admin'?'Admin Manager':userName}</li>
      </ul>
    </div>
  </div>
  <div className="profile-drawrer-list">
    <ul className="">
      <li onClick={changePasswordRoute}>
        Change Password
      </li>
      <li onClick={removeToken}>
        Signin Another Account
      </li>
      {/* <li>
        <svg>
          <use href="#settings" />
        </svg>
        Settings
      </li> */}
    </ul>
  </div>
  <div className="profileLogout">
    <span onClick={removeToken}>
      Logout
    </span>
  </div>
  <div className="profilePrivacy">
    <li>Privacy Policy</li>
    <li>Terms of Service</li>
  </div>
</div>

);
}
export default Profile;