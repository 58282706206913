import React from "react";
import { Routes,  Route} from "react-router-dom";
import useStyles from "../Component/ComponentCss"; 
import Landingpage from "./Landingpage";
import Procurement from "./Procurement/Create";
import ProcurementView from "./Procurement/View";
import QualityCheckView from "./QualityCheck/View";
import Lead from "./Lead/View";
// import Report from "./Report/Create";
import ChangePassword from "./ChangePassword/Create";

import Admin from "./Admin/MainCreate";
import UserManagement from "./Admin/UserManagement/Create";
// import CreateUser from "./Admin/UserManagement/CreateUser/Create";
import ViewUser from "./Admin/UserManagement/CreateUser/View";
import UpdateUser from "./Admin/UserManagement/UpdateUser/Create";
import ChangeUserPassword from "./Admin/UserManagement/ChangeUserPassword/Create";
import UpdateUserStatus from "./Admin/UserManagement/UpdateUserStatus/Create";

import MasterData from "./Admin/MasterData/Create";
import Team from "./Admin/MasterData/Team/View";
import Region from "./Admin/MasterData/Region/View";
import Country from "./Admin/MasterData/Country/View";
import Network from "./Admin/MasterData/Network/View";
import TrafficType from "./Admin/MasterData/TrafficType/View";
import TrafficProfile from "./Admin/MasterData/TrafficProfile/View";
import Department from "./Admin/MasterData/Department/View";
import Carrier from "./Admin/MasterData/Carrier/View";
import Designation from "./Admin/MasterData/Designation/View";
import ReportingManager from "./Admin/MasterData/ReportingManager/View";
import AccountManager from "./Admin/MasterData/AccountManager/View";
import Customer from "./Admin/MasterData/Customer/View";
import PotentialVolume from "./Admin/MasterData/PotentialVolume/View";



import CustomFlow from "../Component/CustomFlow";

function MainSectionRoot() {
  return (
    <>
      <Routes>  
          <Route path ="/" element={<Landingpage/>}/>
          <Route path="/Procurement" element={<ProcurementView/>}/>
          <Route path="/AddProcurement" element={<Procurement/>}/>
          <Route path="/QualityCheck" element={<QualityCheckView/>}/>
          <Route path="/Lead" element={<Lead/>}/>
          {/* <Route path="/Report" element={<Report/>}/> */}
          <Route path="/ChangePassword" element={<ChangePassword/>}/> 
          <Route path="/CustomFlow" element={<CustomFlow/>}/> 
          
          <Route path="/Admin" element={<Admin/>}/>
          <Route path="/Admin/UserManagement" element={<UserManagement/>}/>
          {/* <Route path="/Admin/UserManagement/CreateUser" element={<CreateUser/>}/> */}
          <Route path="/Admin/UserManagement/ViewUser" element={<ViewUser/>}/>
          <Route path="/Admin/UserManagement/UpdateUser" element={<UpdateUser/>}/>
          <Route path="/Admin/UserManagement/ChangeUserPassword" element={<ChangeUserPassword/>}/>
          <Route path="/Admin/UserManagement/UpdateUserStatus" element={<UpdateUserStatus/>}/>
          <Route path="/Admin/MasterData" element={<MasterData/>}/>
          <Route path="/Admin/MasterData/CreateTeam" element={<Team/>}/>
          <Route path="/Admin/MasterData/CreateRegion" element={<Region/>}/>
          <Route path="/Admin/MasterData/CreateCountry" element={<Country/>}/>
          <Route path="/Admin/MasterData/CreateNetwork" element={<Network/>}/>
          {/* <Route path="/Admin/MasterData/CreateComments" element={<Comments/>}/> */}
          <Route path="/Admin/MasterData/TrafficType" element={<TrafficType/>}/>
          <Route path="/Admin/MasterData/TrafficProfile" element={<TrafficProfile/>}/>
          <Route path="/Admin/MasterData/Department" element={<Department/>}/>
          <Route path="/Admin/MasterData/Carrier" element={<Carrier/>}/>
          <Route path="/Admin/MasterData/Designation" element={<Designation/>}/>
          <Route path="/Admin/MasterData/ReportingManager" element={<ReportingManager/>}/>
          <Route path="/Admin/MasterData/AccountManager" element={<AccountManager/>}/>
          <Route path="/Admin/MasterData/Customer" element={<Customer/>}/>
          <Route path="/Admin/MasterData/PotentialVolume" element={<PotentialVolume/>}/>
  
      </Routes>
    </>
  );
}
function MainSection() {
  const classes=useStyles();
  return (
      <div className={classes.MainSection}>
        <MainSectionRoot />
      </div>
  );
}
export default MainSection;

