import React from 'react'
import useStyles from './FormControlsCss';

export default function FormBrack(props) {
 
    let {field_label} = props
    
    const classes = useStyles();

    return ( 
        <div className={classes.inputroot}>
        <div className={classes.brack}></div>
        <h3 className={classes.h3color}>{field_label}</h3>
        </div>
    )     
}
