import React, { useContext, useEffect, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
// import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import '../css/Drag.css'
import {APIUPLOAD}            from '../services/APIServices';
import FormHelperText from '@mui/material/FormHelperText';
import elementValidation from '../Component/validation.js';
import { AiOutlineCloudDownload } from "react-icons/ai";

const DragFileUpload = (props) => {
    let { id,field_type, field_id,field_apipath,field_disabled,preview,field_label,acceptfile, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [selectedfile, SetSelectedFile] = useState([]);
    const [uploadfile, SetUploadFile] = useState([]);
    const [files, SetFiles] = useState([]);
    const [uploadPercentage, setUploadPercentage] = useState();
    const apipath= "uploadFiles/uploadMultipleFiles";


    const elementField = {id,field_type,field_disabled, field_id,field_apipath, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory };
    function handleBlur(){
      elementValidation(elementField)
    }
   
useEffect(()=>{

if(field_value){
    const fileNamesArray = field_value
    .slice(1, -1)
    .split(', ') 
    console.log(fileNamesArray)

    const selectedFiles = fileNamesArray.map((fileName, i) => ({
        filename: fileName,
    }));

    SetSelectedFile(selectedFiles);
}
},[field_value])

    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function setFileData(data){
        // const { fileName,uploadFilesLabel,filePath } = data;
        let filesName=[];
        const fileUrl = data.map(item => item.uploadFilesLabel);

        if(field_value){
            const fileNamesArray = field_value
            .slice(1, -1)
            .split(', ') 
            filesName = [...fileNamesArray, ...fileUrl];
        }else{
            filesName = [...fileUrl];
        }
        const stringUrl =filesName.length>0?'[' + filesName.join(', ') + ']':"";
        handleChange(field_id,{},stringUrl)
      }

    const InputChange = async (e) => {
        // --For Multiple File Input
        // SetUploadFile
        let images = [];
        const formData = new FormData();
        for (let i = 0; i < e.target.files.length; i++) {
            images.push((e.target.files[i]));
            // SetUploadFile((preValue) => {
            //     return [
            //         ...preValue,
            //         ( e.target.files[i])]
            //  });
            formData.append(`files`,  e.target.files[i])
            console.log(images)
            console.log( e.target.files[i])
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile((preValue) => {
                    return [
                        ...preValue,
                        {
                            filename: e.target.files[i].name,
                            fileimage: reader.result,
                        }
                    ]
                });
            }
            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
        try {
            const response = await APIUPLOAD(apipath, formData,setUploadPercentage,setFileData);
            console.log('Upload successful!', response);
          } catch (error) {
            console.error('Error uploading file:', error);
          }
    }


    const DeleteSelectFile = (id, e) => {
        e.preventDefault();
        if(window.confirm("Are you sure you want to delete this Image?")){
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        }else{
            // alert('No');
        }
        
    }

    const FileUploadSubmit = async (e) => {
        e.preventDefault();
        // form reset on submit 
        // e.target.reset();
        if (uploadfile.length > 0) {
            const formData = new FormData();
            for (let index = 0; index < uploadfile.length; index++) {
                formData.append(`file${index+1}`, uploadfile[index])
                console.log(formData)
                console.log(uploadfile[index])
                try {
                    const response = await APIUPLOAD(apipath, formData);
                    console.log('Upload successful!', response);
                  } catch (error) {
                    console.error('Error uploading file:', error);
                  }
                SetFiles((preValue)=>{
                    return[
                        ...preValue,
                        selectedfile[index]
                    ]   
                })
            }
            SetSelectedFile([]);
            SetUploadFile([]);
        } else {
            alert('Please select file')
        }
    }

    const DeleteFile = async (id, e) => {
        setUploadPercentage()
        e.preventDefault();
        if(window.confirm("Are you sure you want to delete this Image/File?")){
            let fileNamesArray=[]
            if(field_value){
                fileNamesArray = field_value
                .slice(1, -1)
                .split(', ') }else{   
                }
        let result = selectedfile.filter((_, index) => index !== id);
        let valueReult = fileNamesArray.filter((_, index) => index !== id);
        SetSelectedFile(result)
        const stringUrl =valueReult.length>0?'[' + valueReult.join(', ') + ']':"";
        handleChange(field_id,{},stringUrl)
    }
    };
    
    return (


        <div className="inputroot">
            <div className="kb-data-box">
                <div className="kb-modal-data-title">
                    <div className="kb-data-title">
                        <h6>{field_label}</h6>
                    </div>
                </div>
                <div className="kb-file-upload">
                    <div className="file-upload-box">
                        <input type="file" id={field_id} className="file-upload-input" onChange={InputChange} multiple accept={acceptfile} />
                        <span>Drop files to attach or <span className="file-link">browse</span></span>
                    </div>
                    {/* <div className="file-upload-btn">
                        <input type="file"  id={field_id} className="file-upload-input" onChange={InputChange} multiple  accept={acceptfile}/>
                        <span>@ Attach<span className="file-link"></span></span>
                    </div> */}
                </div>
                {selectedfile.length > 0 ?
                    // <hr />
                    <div className="kb-attach-box">
                        {
                            selectedfile.map((data, index) => {
                                const { id, filename, fileimage} = data;
                                return (
                                    <div className="file-atc-box" key={index}>
                                        {
                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i)&&fileimage ?
                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                        }
                                         <button className="file-action-btn-2" onClick={(e) => DeleteFile(index,e)}>Delete</button>
                                        <a href={fileimage} className="file-action-btn" download={filename}><AiOutlineCloudDownload/></a>
                                        <div className="file-name"><h6>{filename}</h6></div>
                                        {/* <div className="file-detail">
                                            <h6>{filename}</h6>
                                            <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                            <div className="file-actions">
                                                <button className="file-action-btn" onClick={(e) => DeleteFile(id)}>Delete</button>
                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                            </div>
                                        </div> */}
                                    </div>
                                )
                            })
                        }
                    </div>
                    : ''}
            </div>
            {uploadPercentage && (<div className='progrs'>
          <div
            className='progressBar'
            role='progressbar'
            style={{ width: `${uploadPercentage}%` }}
          >
              {uploadPercentage}%
            </div>
          </div>)}
        </div>


    );
}

export default DragFileUpload;