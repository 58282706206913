
import React,{useState,useEffect} from 'react'
import { FormControl, MenuItem, FormHelperText,InputLabel } from '@material-ui/core';
import  useStyles  from './FormControlsCss';
import Selects from '@mui/material/Select';
import { FormContext } from '../Component/Forms/FormContext';
import elementValidate from '../Component/validation';
import  { useContext } from  'react';
import { APIGET } from '../services/APIServices';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
// import { isNullOrUndefined } from 'html5-qrcode/esm/core';
 
export default function Customer360Search(props) {
  const classes = useStyles();
    // var getOps = '';
    const {field_type, field_id, field_label,field_SearchValue, field_placeholder,field_disabled, field_value,field_option_src,field_option, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [fieldOptions,setFieldOptions] = useState(field_options);
    const [fieldType,setFieldType]= useState(field_SearchValue);
    const [placeholder,setPlaceholder]= useState('');
                
    useEffect(()=>{
     props.field_option_src.field_option_srh_context = fieldType;
     if(fieldType==="mobileNumber"){
      setPlaceholder('Enter Mobile Number')
     }
    else if(fieldType==="customerId"){
      setPlaceholder('Enter Customer Id')
     }
    },[fieldType]);
         
    function handleBlur(){ 
      elementValidate(field)
      if(field_value){
      // getOptions(field_option_src);
      }
    }

    // async function getOptions(props) {
    // try{
    // if(props.field_option_src_context){
    // if(props.field_option_check_local_storage){
    // //todo check in localstorage
    // console.log("check in localstorage for "+props.field_option_src_context);
    // }

    // let APIRES =await APIGET(props.field_option_src_context+`/`+props.field_option_src_critieria+fieldType+`=`+field_value).then(
    // (APIRES)=>{
    // console.log('hello api consent data',APIRES);
    // let responseArray =new Array();
    // let _i =0;

    // if(props.field_select_all_label){
    // let _obj = new Object();
    // _obj.option_label = props.field_select_all_label;
    // _obj.value = props.field_select_all_value;
    // responseArray[_i] = _obj;
    // _i++;  
    // }
    // Object.keys(APIRES.data).forEach( key => {
    // let _obj = new Object();
    // _obj.option_label = APIRES.data[key][getOps];
    // _obj.value = APIRES.data[key][props.field_option_value];
    // responseArray[_i] = _obj;
    // _i++; 
    // })
    // //fieldOptions = responseArray; 
    // setFieldOptions(responseArray);
    // }
    // );                           
    // }
    // }catch(err){/** TODO Do Nothing */}
    // }

    const field = {
        field_id:field_id,
        field_type:field_type,
        field_disabled:field_disabled,
        field_value:field_value,
        field_SearchValue:field_SearchValue,
        field_helperText:field_helperText,
        field_minlength:field_minlength,
        field_maxlength:field_maxlength,
        field_minvalue:field_minvalue,
        field_maxvalue:field_maxvalue,
        field_value_type:field_value_type,
        field_mandatory:field_mandatory,
        field_options: (fieldOptions) ? fieldOptions : field_options ,
        field_label:field_label,
        field_option_src:field_option_src
      }
      
      const handletype=(event)=>{
        const getvalue=event.target.value;
        handleChange(field_id,getvalue,"")
        setFieldType(getvalue);
      }
    return (
        <div className={classes.inputroot}>
        <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
        <FormControl variant="outlined" className={classes.mw} 
        // {...(error && {error:true})}>
        >
         <div className={classes.css}>
          <div className={classes.selectm}> 
            <Selects className={classes.input}
             
                // name={name}
                // id={field_id}_LEFT 
                value={field_SearchValue}
                displayEmpty
                // onChange={(event)=>{setFieldType((prev)=>{return (event.target.value)});}}
                onChange={(e)=>handletype(e)}
                placeholder={field_placeholder}
                disabled={field_disabled}
                onBlur={handleBlur}
                >
                {/* <MenuItem className={classes.select} value="">None</MenuItem> */}
                <MenuItem value="">{field_placeholder}</MenuItem>

                {
                  field_option.map(
                        (option,i) => ( 
                        (option.value)?<MenuItem key={i} value={option.value}>{option.option_label}</MenuItem>://comment this line when field_options[0].field_value is defined for every select field.
                          <MenuItem key={i} value={option.option_label}>{option.option_label}</MenuItem>
                        )
                    )
                }
            </Selects>
            </div>
            <div className={classes.flexDiv}>
            <OutlinedInput className={classes.input}
             id = {field_id}
             type={field_type}
            //  type="number"
             value={field_value}
            //  placeholder={field_placeholder ? field_placeholder : ''} 
             placeholder={placeholder?placeholder:"Enter...."} 
             onChange={event => handleChange(field_id,fieldType, event.target.value)}
            // onChange={(event) => (((event.target.value).length<=10)?handleChange(field_id,{},event.target.value):null)}
             error
             disabled={field_disabled}
             inputProps={{  min:field_minvalue, max:field_maxvalue, minLength:field_minlength, maxLength: field_maxlength}}
             onBlur={handleBlur}
             />
            {/* <Selects className={classes.input}
             
             // name={name}
             id={field_id} 
             value={field_value}
             displayEmpty
             onChange={onChange || (event => handleChange(field_id, event))}
             placeholder={field_placeholder}
             disabled={field_disabled}
             onBlur={handleBlur}
             >
           
             <MenuItem value="">{field_placeholder}</MenuItem>

             {
                 fieldOptions.map(
                     (options,i) => ( 
                     (options.value)?<MenuItem key={i} value={options.value}>{options.option_label}</MenuItem>://comment this line when field_options[0].field_value is defined for every select field.
                       <MenuItem key={i} value={options.option_label}>{options.option_label}</MenuItem>
                     )
                 )
             }
            </Selects> */}
            {/* <Autocomplete  className={classes.multiInput}
                disablePortal
                id="sp"
                options= {fieldOptions.map((option) =>option.option_label)}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField placeholder="Search..." {...params} />}
            /> */}
         </div>
         </div>
            <FormHelperText>{field_helperText}</FormHelperText>
        </FormControl>
        </div>
    )
}
