import React from "react";
import { useNavigate } from 'react-router-dom';
// import logo from "../images/logo-svg.svg";
import Procurement from '../images/decidd/Procurement.png';
import QuilityCheck from "../images/decidd/QuilityCheck.png";
import Report from '../images/decidd/Report.png';
import ChangePassword from '../images/decidd/ChangePassword.png';
import Admin from '../images/decidd/Admin.png';
// import { ReactComponent as Dashboard }        from "../images/svgicon/dashboard.svg";
// import { ReactComponent as Report }           from "../images/svgicon/report.svg";
// import { ReactComponent as BusinessObjects }  from "../images/svgicon/business-objects.svg";
// import { ReactComponent as MessagingObject }  from "../images/svgicon/messaging-object.svg";
// import { ReactComponent as UrlShortner }      from "../images/svgicon/url-shortner.svg";
// import { ReactComponent as Assets }           from "../images/svgicon/assets.svg";
// import { ReactComponent as MessagingBlockout }from "../images/svgicon/messaging-blockout.svg";
// import { ReactComponent as Variables }        from "../images/svgicon/variable.svg";

import '../css/style.css';

const LandingJson = [
  {
      "card":1,
      "card_Link":"/Procurement",
      "img_src":Procurement,
      "card_title":"Procurement"
  },
  {
      "card":2,
      "card_Link":"/QualityCheck",
      "img_src":QuilityCheck,
      "card_title":"Quality Check"
  },
  {   
      "card":3,
      "card_Link":"/Lead",
      "img_src":ChangePassword,
      "card_title":"Lead"
  },
  {
    "card":4,
    "card_Link":"/Report",
    "img_src":Report,
    "card_title":"Report"
  },
  {   
      "card":5,
      "card_Link":"/Admin",
      "img_src":Admin,
      "card_title":"Admin"
  }
]

const LandingJson2 = [
  // {
  //     "card":1,
  //     "card_Link":"/DashBoard",
  //     "img_src":<Dashboard/>,
  //     "card_title":"Dashboard"
  // }, 
  // {
  //     "card":2,
  //     "card_Link":"/Report",
  //     "img_src":<Report/>,
  //     "card_title":"Reports"
  // },
  // {
  //     "card":3,
  //     "card_Link":"/BussinessObjects",
  //     "img_src":<BusinessObjects/>,
  //     "card_title":"Business Objects"
  // },
  // {   "card":4,
  //     "card_Link":"/MessagingObjects",
  //     "img_src":<MessagingObject/>,
  //     "card_title":"Messaging Objects"
  // },
  // {   "card":5,
  //     "card_Link":"/URLShortner",
  //     "img_src":<UrlShortner/>,
  //     "card_title":"URL Shortner"
  // },
  // {   "card":6,
  //     "card_Link":"/Assets",
  //     "img_src":<Assets/>,
  //     "card_title":"Assests"
  // },
  // {   "card":7,
  //     "card_Link":"/MessagingBlackout",
  //     "img_src":<MessagingBlockout/>,
  //     "card_title":"Messaging Blockout"
  // },
  // {   "card":8,
  //     "card_Link":"/Variables",
  //     "img_src":<Variables/>,
  //     "card_title":"Variables"
  // }

]



function LandingCard(props) {
  let navigate = useNavigate()
  function handleClick(card,card_Link)  {
    console.log(card);
    if(card_Link && card_Link !== ''){
      navigate(card_Link);
    } 
  
  };
  return (
(props.dataJSON || props.dataJSON2)?    
<React.Fragment> 
 <div className="main container lpContainer">
  {/* <div className="logoMain">
    <img src={logo} alt="logo" className="logoAnimate" />
  </div> */}
  <article className="padingExtra">
    <div className="landpage">
    {
    props.dataJSON.map((data,index)=>{ 
      return(
      <React.Fragment>
      <div className="landingbox cp" onClick={()=>handleClick(data.card,data.card_Link)}>
        <a href={data.card_href} className="full-box-link">
        </a>
        <div className="hc jcc">
          <img src={data.img_src} alt="" />
        </div>
        <div className="policy-content">
          <h3>{data.card_title}</h3>
        </div>
      </div>
      </React.Fragment>);})
    }
    </div>
  </article>
  <article className="smallLink">
    <div className="df smallBox fww jcc">
    {
    props.dataJSON2.map((data,index)=>{ 
      return(
      <React.Fragment>
      <div className="dashboardLand" onClick={()=>handleClick(data.card,data.card_Link)}>
        <div className="dl">
          <div className="svgAllGrp">
            {data.img_src}
            {/* <svg className="all-svg">
              <use>{data.img_src}</use>
            
            </svg> */}
          </div>
          <p>{data.card_title}</p>
        </div>
      </div>
      </React.Fragment>);})
    }
    </div>
  </article>
</div>
</React.Fragment>
  :
  null);
 }

 function Landingpage() {
    return(
        <LandingCard dataJSON={LandingJson} dataJSON2={LandingJson2}/>
    );
  }
 export default Landingpage;
 