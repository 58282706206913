import React, { useContext,useEffect, useState,useRef } from "react";
import Controls from "../../FormsControls/Control";
import { FormContext } from '../../Component/Forms/FormContext';
import masterJson from './masterJson.json'
import '../../css/CustomFlow.css';
import { MasterContext } from '../../services/MasterContext';
// import ActivitySection from '../../FormsControls/ActivitySection';
import { handleChange } from "../../Component/Functions/fromFunction";


export default function QualityCheckCoustom(props) {
    const { Input, Select, DragFileUpload, MultilineText, DatePicker,ActivitySection } = Controls;
    const { masterData } = React.useContext(MasterContext);

    const [masterJsn, setMasterJson] = useState(masterJson);
    const leftFormRef = useRef(null);

    useEffect(() => {
        let valueStr=""
        Object.keys(masterJson).forEach((key,index) =>{
            if(key!=="xioFEConfig"){
                masterJson[key].fields.map((field)=>{
                //    alert(field.id)
                    handleChange(masterJsn, setMasterJson, field.field_id, field.field_value)
                  
            });
            }
        });
    }, []);

    const scrollToBottom = () => {
        if (leftFormRef.current) {
          const section = leftFormRef.current;
          const scrollHeight = section.scrollHeight;
          const clientHeight = section.clientHeight;
      
          const scrollDifference = scrollHeight - clientHeight;
          section.scrollTo({
            top: scrollDifference,
            behavior: 'smooth',
          });
        }
      };

    return (
       <>
            {/* <FormContext.Provider value={{ handleChange }}> */}
            <FormContext.Provider value={{ handleChange: (id, event, valueStr) => handleChange(masterJsn, setMasterJson, id, valueStr) }}>
            <div className="cntanr">
            <div className="status">
                            {/* <span className="status-lbl">Status</span> */}
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "status") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "priority") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                    {/* <div className="from-hdr">
                        <h1>Quality Check</h1>
                    </div> */}

                    <div className="divForm">
                        <div className="left-form" ref={leftFormRef}>
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "carrier") {
                                                return (
                                                    <Input
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_mandatory={field.field_mandatory}
                                                    field_placeholder={field.field_placeholder}
                                                    field_type={field.field_type}
                                                    field_value={field.field_value}
                                                    field_disabled_Edit={field.field_disabled_Edit}
                                                    field_disabled={field.field_disabled}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_helperText={field.field_helperText}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                    field_unique={field.field_unique}
                                                    field_apipath={field.field_apipath}
                                                    />

                                                );
                                            } else if (field.field_id === "destinationId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "accountManagerId") {
                                                return (
                                                    <Select
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_value={field.field_value}
                                                    field_default_value={field.field_default_value}
                                                    field_placeholder={field.field_placeholder}
                                                    field_mandatory={field.field_mandatory}
                                                    field_option_src={field.field_option_src}
                                                    field_options={field.field_options}
                                                    field_type={field.field_type}
                                                    field_helperText={field.field_helperText}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                />
                                                );
                                            } else if (field.field_id === "reportingManagerId") {
                                               return (
                                                    <Select
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_value={field.field_value}
                                                    field_default_value={field.field_default_value}
                                                    field_placeholder={field.field_placeholder}
                                                    field_mandatory={field.field_mandatory}
                                                    field_option_src={field.field_option_src}
                                                    field_options={field.field_options}
                                                    field_type={field.field_type}
                                                    field_helperText={field.field_helperText}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                />
                                               );
                                           
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            <div>
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "remark") {
                                                return (
                                                    <MultilineText
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_helperText={field.field_helperText}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }
                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            <div className="activity">
                            {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "activitySection"&&field.id) {
                                                return (
                                                    <ActivitySection
                                                        id={field.id}
                                                        scroll={scrollToBottom}
                                                        field_id={field.field_id}
                                                        // field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_flowid={field.field_flowid}
                                                        field_apiContextComments={field.field_apiContextComments}
                                                        field_apiContextHistory={field.field_apiContextHistory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                        <div className="right-form">
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "amRegionId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "destinationRegionId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "departmentId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else if (field.field_id === "updateDate") {
                                                return (
                                                    <DatePicker
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_pastDisable={field.field_pastDisable}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                  
                    {/* <div className="fromBtn">
                        <button className="btn-sub" type="submit">Save</button>
                        <button className="btn-cncl" onClick={handleCancel}>Cancel</button>
                    </div> */}
                </div>
            </FormContext.Provider>
       </>
    );

}