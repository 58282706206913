import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App/App';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes,  Route,  useNavigate,  useLocation,} from "react-router-dom";

// import reportWebVitals from './reportWebVitals';
// import Apps from "./Component/drop";


ReactDOM.render(<App />, document.getElementById('root'));

