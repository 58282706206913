import React, { useContext, useState } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import useStyles from "./FormControlsCss";
import Button from './Button'
import { v4 as uuid } from 'uuid';
import { APIPOST,APIGET,qrCodeDomain } from '../services/APIServices';
import { FormJSONCreateContext } from '../Component/FormJSONCreateContext';
import {InputLabel,TextField} from '@material-ui/core';
import FormHelperText from '@mui/material/FormHelperText';
import copy from "copy-to-clipboard";  
export default function QRCode(props) {
    const {contextJson} = React.useContext(FormJSONCreateContext);
    const classes = useStyles();
    const unique_id = uuid();
    const small_id = unique_id.slice(0,10)
    let { field_type,field_qrCodeDoamin, field_id, url,field_label,field_keyValue, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    const { handleChange } = useContext(FormContext)
    const [imageUrl, setImageUrl] = useState(field_value);
    let uniqueId = props.id;
    let data ={
            "qrCodeDoamin":field_qrCodeDoamin,
            "qrCodeKey":small_id,
        }
    
    async function  ImageUrl(jsonData){
        console.log("qrimg"+`/${jsonData.dataRecevied.data.qrCodeFile}`);
        if(jsonData.dataRecevied.data.qrCodeFile){
            setImageUrl((prev)=>{return("//"+qrCodeDomain+"/cerf/"+"qrimg"+`/${jsonData.dataRecevied.data.qrCodeFile}`)})
    }
        let x = await handleChange(field_id,String("http://"+data.qrCodeDoamin+"/"+data.qrCodeKey),String("//"+qrCodeDomain+"/cerf/"+"qrimg"+`/${jsonData.dataRecevied.data.qrCodeFile}`));
    }
    const  GenerateQr =(event)=>{
        event.preventDefault();
        if(data.qrCodeKey){
        APIPOST("qrcode/qrcode",data,contextJson,ImageUrl)
        }
    }
    const copyToClipboard = (event) => {
        event.preventDefault();
        if(field_value){
            copy(url);
            // alert(`You have copied "${field_value}"`);
        }
      }
  

   return (
    <React.Fragment>
       <div className={classes.fileroot}>
          <InputLabel className={classes.label}>Generate QR Code</InputLabel> 
          <div className={classes.QRcode}>
                <div className={classes.qrbtn}>
                    <Button ButtonName='Generate' onClick={(event) => GenerateQr(event)}/>
                    {(field_value !== "") &&<Button ButtonName='Copy' onClick={(event) => copyToClipboard(event)}/>}
                </div>
              <div className={classes.QRcodebox} id={field_id}>
                  {imageUrl ? (<img src={field_value} alt="img" value={field_value} />) : null}
              </div>
             
          </div>
          {<FormHelperText></FormHelperText>}
          {/* <InputLabel className={classes.label}>{(field_value)?url:null}</InputLabel> */}
       </div>
    </React.Fragment>
   );
}