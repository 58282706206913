import React from "react";
import success from "../images/success.svg";
import "../css/Success.css";
 function SuccessPage(props){
     const {policyName,flowName,actionName,createPolicyLink,viewPolicyLink,createButtonText,viewButtonText,paragraphText,close} = props;
    //  if(close==="Close"){
    //   const viewLink=close 
    //  }else{
    //   const viewLink=viewPolicyLink
    //  }
     return(
        <div className="content-area df">
            <div className="success-block">
                <h2 className="sub-head">SUCCESS</h2>
                <div className="success-svg">
                <img src={success} alt="success"/>
                </div>
                <div>
                <h3>{`${flowName} Has Been Successfully ${actionName}`}</h3>
                {/* <p>{`${paragraphText}`}</p> */}
                </div>
                {/* <div className="df jcc btn-margin-success">
                 <div className="btn-submit closebtn click-effect"
                    onClick={close}
                >
                    {`${viewButtonText}`}
                </div>
                <div className="btn-submit viewbtn click-effect"
                    onClick={viewPolicyLink}
                >
                    {`${viewButtonText}`}
                </div>
                <div className="btn-submit click-effect"
                    onClick={createPolicyLink}
                >
                {`${createButtonText}`}
                </div>
                
                </div> */}
            </div>
        </div>

     );
 }
 export default SuccessPage;