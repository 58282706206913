import React, { useContext, useEffect, useState } from "react";
import Controls from "../../../../FormsControls/Control";
import { FormContext } from '../../../../Component/Forms/FormContext';
import masterJson from './masterJson.json'
import '../../../../css/CustomFlow.css';
import { MasterContext } from '../../../../services/MasterContext';
import ActivitySection from '../../../../FormsControls/ActivitySection';
import { handleSubmit, handleChange } from "../../../../Component/Functions/fromFunction";



export default function ProcurementCoustom(props) {
    const { Input, Select, DragFileUpload, MultilineText,PasswordInput } = Controls;
    const { masterData } = React.useContext(MasterContext);


    const [masterJsn, setMasterJson] = useState(masterJson);
   const forceUpdate = React.useCallback((args) => setMasterJson(args), []);

    useEffect(() => {
        let valueStr=""
        Object.keys(masterJson).forEach((key,index) =>{
            if(key!=="xioFEConfig"){
                masterJson[key].fields.map((field)=>{
                    handleChange(masterJsn, setMasterJson, field.field_id, field.field_value)
            });
            }
        });
    }, []);

     useEffect(()=>{
        Object.keys(masterJsn).forEach((key,index) =>{
          if(key!=="xioFEConfig"){
          let _object = masterJsn[key];
          if(masterJsn[key].fields)
          masterJsn[key].fields.forEach(thisField => {
            console.log(thisField);
            if(thisField.field_runtime_ops){
              thisField.field_runtime_ops.forEach(field => {
                if(field.condition)
                  var resultEval ;
                    var stmt = "resultEval = thisField."+field.condition.field+" "+field.condition.operator+" \""+field.condition.checkVal+"\""
                  eval(stmt);
                  console.log(stmt);
                  console.log(resultEval);
                  if(resultEval){
                    console.log(eval(field.ops));
                    eval(field.ops);
                    setTimeout(forceUpdate(masterJson),0);
      
                  }
              });
            }
          })}
        })
      })

    return (
        <>
            {/* <FormContext.Provider value={{ handleChange }}> */}
            <FormContext.Provider value={{ handleChange: (id, event, valueStr) => handleChange(masterJsn, setMasterJson, id, valueStr) }}>
            <div className="cntanr">
            <div className="status">
                            {/* <span className="status-lbl">Status</span> */}
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "status") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                    <div className="divForm">
                        <div className="left-form">
                            {/* <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "title") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div> */}
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                            if (field.field_id === "teamId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else  if (field.field_id === "departmentId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else  if (field.field_id === "userType") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else  if (field.field_id === "reportingManagerId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else  if (field.field_id === "designationId") {
                                                return (
                                                    <Select
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_value={field.field_value}
                                                        field_default_value={field.field_default_value}
                                                        field_placeholder={field.field_placeholder}
                                                        field_mandatory={field.field_mandatory}
                                                        field_option_src={field.field_option_src}
                                                        field_options={field.field_options}
                                                        field_type={field.field_type}
                                                        field_helperText={field.field_helperText}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                    />

                                                );
                                            } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                            {/* <div className="activity">
                                <ActivitySection  apiContextHistory={apiContextHistory} apiContextComments={apiContextComments}/>
                            </div> */}
                        </div>
                        <div className="right-form">
                            <div className="divForm">
                                {Object.keys(masterJson).map((key, index) => {
                                    if (key !== "xioFEConfig") {
                                        return masterJson[key].fields.map((field) => {
                                        if (field.field_id === "title") {
                                            return (
                                                <Select
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_value={field.field_value}
                                                    field_default_value={field.field_default_value}
                                                    field_placeholder={field.field_placeholder}
                                                    field_mandatory={field.field_mandatory}
                                                    field_option_src={field.field_option_src}
                                                    field_options={field.field_options}
                                                    field_type={field.field_type}
                                                    field_helperText={field.field_helperText}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                />

                                            );
                                        } else if (field.field_id === "firstName") {
                                            return (
                                                <Input
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_mandatory={field.field_mandatory}
                                                    field_placeholder={field.field_placeholder}
                                                    field_type={field.field_type}
                                                    field_value={field.field_value}
                                                    field_disabled_Edit={field.field_disabled_Edit}
                                                    field_disabled={field.field_disabled}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_helperText={field.field_helperText}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                    field_unique={field.field_unique}
                                                    field_apipath={field.field_apipath}
                                                />
                                            );
                                        } else if (field.field_id === "lastName") {
                                            return (
                                                <Input
                                                    field_id={field.field_id}
                                                    field_label={field.field_label}
                                                    field_mandatory={field.field_mandatory}
                                                    field_placeholder={field.field_placeholder}
                                                    field_type={field.field_type}
                                                    field_value={field.field_value}
                                                    field_disabled_Edit={field.field_disabled_Edit}
                                                    field_disabled={field.field_disabled}
                                                    field_minlength={field.field_minlength}
                                                    field_maxlength={field.field_maxlength}
                                                    field_minvalue={field.field_minvalue}
                                                    field_maxvalue={field.field_maxvalue}
                                                    field_value_type={field.field_value_type}
                                                    field_on_blur={field.field_on_blur}
                                                    field_helperText={field.field_helperText}
                                                    field_error_msg_required={field.field_error_msg_required}
                                                    field_error_msg_minlength={field.field_error_msg_minlength}
                                                    field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                    field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                    field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                    field_error_msg_uq={field.field_error_msg_uq}
                                                    field_unique={field.field_unique}
                                                    field_apipath={field.field_apipath}
                                                />
                                            );
                                        } else if (field.field_id === "mobile") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_helperText={field.field_helperText}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else if (field.field_id === "emailId") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_helperText={field.field_helperText}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />
                                                );
                                            } else if (field.field_id === "userDetailsLabel") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_helperText={field.field_helperText}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />

                                                );
                                            } else if (field.field_id === "password") {
                                                return (
                                                    <Input
                                                        field_id={field.field_id}
                                                        field_label={field.field_label}
                                                        field_mandatory={field.field_mandatory}
                                                        field_placeholder={field.field_placeholder}
                                                        field_type={field.field_type}
                                                        field_value={field.field_value}
                                                        field_disabled_Edit={field.field_disabled_Edit}
                                                        field_disabled={field.field_disabled}
                                                        field_minlength={field.field_minlength}
                                                        field_maxlength={field.field_maxlength}
                                                        field_password_length={field.field_password_length}
                                                        field_password_pattern={field.field_password_pattern}
                                                        field_SpecialCharacter={field.field_SpecialCharacter}
                                                        field_minvalue={field.field_minvalue}
                                                        field_maxvalue={field.field_maxvalue}
                                                        field_value_type={field.field_value_type}
                                                        field_on_blur={field.field_on_blur}
                                                        field_helperText={field.field_helperText}
                                                        field_error_msg_required={field.field_error_msg_required}
                                                        field_error_msg_minlength={field.field_error_msg_minlength}
                                                        field_error_msg_maxlength={field.field_error_msg_maxlength}
                                                        field_error_msg_minvalue={field.field_error_msg_minvalue}
                                                        field_error_msg_maxvalue={field.field_error_msg_maxvalue}
                                                        field_error_msg_uq={field.field_error_msg_uq}
                                                        field_unique={field.field_unique}
                                                        field_apipath={field.field_apipath}
                                                    />

                                                );
                                                                                      } else {
                                                // Handle other field types
                                                return null;
                                            }

                                        });
                                    }
                                    return null;
                                })}
                            </div>
                        </div>
                    </div>
                    {/* <div className="fromBtn">
                        <button className="btn-sub" type="submit">Save</button>
                        <button className="btn-cncl" onClick={handleCancel}>Cancel</button>
                    </div> */}
                </div>
            </FormContext.Provider>
        </>
    );

}