import * as React from 'react';
import useStyles from './FormControlsCss';
import {useState} from 'react';

export default function TextContent(props){
    const classes = useStyles();
    const { field_type, field_id, field_label, field_placeholder, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,field_mandatory } = props;
    //for this compoent there is no need for validation just the field_value is required. 
    if(field_value&&field_value!==""){
        console.log(field_value)
    return(
        
    <div className={classes.timeInputGrp}>
        <h2>{String(field_value)}</h2>
    </div>
    )}else{
        return null
    }
}