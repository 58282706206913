import React from "react";
import TabPanel from "./TabsCreateForm";
import {Typography } from '@material-ui/core';
import useStyles from '../Component/ComponentCss';
import { FormJSONCreateContext } from "../Component/FormJSONCreateContext";
import CustomForm from '../Component/Functions/CustomForm'
//Tab Stepper 
import TabStepper from './TabStepper';

function FormSection(props) {
  const {contextJson} = React.useContext(FormJSONCreateContext);
  const classes = useStyles();
  return (
  <>
    { ( contextJson.xioFEConfig && contextJson.xioFEConfig.TabStyle &&  contextJson.xioFEConfig.TabStyle==="steps")?<TabStepper contextClass={props.contextClasses}/>:( contextJson.xioFEConfig && contextJson.xioFEConfig.TabStyle &&  contextJson.xioFEConfig.TabStyle==="CustomForm")?<CustomForm contextClass={props.contextClasses}/>:<TabPanel contextClass={props.contextClasses}/>}   
  </>
);
}

export default FormSection; 
