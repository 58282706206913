import React from "react";
import useStyles from "../../Component/ComponentCss";
// import                   '../../Component/card.css';
import masterData from "../../images/decidd/masterData.png";
import userManagement from "../../images/decidd/userManagement.png";
import pending from "../../images/card/pending.png";
import { useNavigate } from 'react-router-dom';
import { Typography } from '@material-ui/core';

function Create() {
  const classes = useStyles();
  let navigate = useNavigate()

  function handleClick(page, link) {
    console.log(page);
    if (link && link !== '') {
      navigate(link);
    }

  };

  function Boxes(props) {
    const page = props.page1;
    const link = props.link1;
    const icon1 = props.icon;
    const src1 = props.src1;
    const status = props.status;
    const newpolicydisplay = props.newpolicydisplay;
    const newpolicynumber = props.newpolicynumber;
    const newpolicybton = props.newpolicybton;
    const newpolicyimgsrc = props.newpolicyimgsrc;
    //const newpolicybton= "bton2";
    //const newpolicyimgsrc= assign;
    return (

      <div className="flex">
        <div className="recipe-card" onClick={() => handleClick(page, link)}>
          <div className="active">
            <div className={icon1} ><span></span>{status}</div></div>
          <aside>
            <img src={src1} alt="policy" />
            <div className="button" style={{ display: (newpolicydisplay) }} > <img src={newpolicyimgsrc} alt="policy" /> </div>
          </aside>
          <article>
            <h3>{props.label}</h3>
            <div></div>
            <p>{props.text}</p>
          </article>
        </div>
      </div>
    )
  }
  const createTemplate = [
    {
      label: 'User Management',
      text: "User Management",
      page1: 1,
      link: "/Admin/UserManagement",
      //  icon: "active-icon",
      src1: userManagement,
      //  status: "active",
      newpolicydisplay: "none",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    },
    {
      label: 'Master Data',
      text: "Master Data",
      page1: 2,
      link: "/Admin/MasterData",
      //  icon: "active-icon",
      src1: masterData,
      //  status: "active",
      newpolicydisplay: "none",
      newpolicynumber: ' ',
      newpolicybton: "bton",
      newpolicyimgsrc: pending
    }
  ]

  return (
    <div className="main">
      {createTemplate.map((data, key) => {
        return (
          <Boxes
            text={data.text}
            label={data.label}
            page1={data.page1}
            link1={data.link}
            icon={data.icon}
            src1={data.src1}
            status={data.status}
            newpolicydisplay={data.newpolicydisplay}
            newpolicynumber={data.newpolicynumber}
            newpolicybton={data.newpolicybton}
            newpolicyimgsrc={data.newpolicyimgsrc}
          />
        );
      })
      }

    </div>
  );
}

export default Create;
