
import React, { useContext, useEffect } from 'react'
import { FormContext } from '../Component/Forms/FormContext';
import OutlinedInput from '@mui/material/OutlinedInput';
import {InputLabel} from '@material-ui/core';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import elementValidate from '../Component/validation';
import  useStyles  from './FormControlsCss';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import moment from 'moment';



export default function DatePicker(props) {
  const classes = useStyles();
  const { field_type, field_id, field_label, field_pastDisable,field_placeholder,field_disabled, field_value, field_options,field_helperText,field_minlength,field_maxlength,field_maxvalue,field_value_type,field_minvalue,field_error_msg_required,field_error_msg_minlength,field_error_msg_maxlength,field_error_msg_minvalue,field_error_msg_maxvalue,field_error_msg_uq,onChange,field_mandatory } = props;
  const { handleChange } = useContext(FormContext)

  
  const [value, setValue] = React.useState();
  const [past, setPast] = React.useState();

useEffect(()=>{
  let getDate=moment(new Date()).format('DD-MM-YYYY');
  handleChange(field_id,{},getDate) 
},[])

  const field = {
    field_id:field_id,
    field_type:field_type,
    field_value:field_value,
    field_helperText:field_helperText,
    field_minlength:field_minlength,
    field_maxlength:field_maxlength,
    field_minvalue:field_minvalue,
    field_maxvalue:field_maxvalue,
    field_value_type:field_value_type,
    field_mandatory:field_mandatory,
    field_label:field_label,
    field_error_msg_minvalue:field_error_msg_minvalue
  }
    function handleBlur(){
      elementValidate(field)
      // setError(field['field_helperText'])
    }
   
    function formatDateToMMDDYYYY(dateStr) {
      let dateArr = dateStr.split("-");
      return dateArr[1] + "-" + dateArr[0] + "-" + dateArr[2];
    }

  const handleChangedate = (date) => {
    setValue(date);
    let getDate=moment(new Date(date)).format('DD-MM-YYYY');
    handleChange(field_id,{},getDate) 
  };

  React.useEffect(()=>{
    if(field_value){
      setValue(formatDateToMMDDYYYY(field_value))
    }
    if(field_minvalue){
      setPast(formatDateToMMDDYYYY(field_minvalue))
    }else{
      setPast(new Date())
    }
  },[field_minvalue,field_value]);
 
  return (

    <div className={classes.inputroot}>
     <div style={{display:"flex"}}>
    <InputLabel className={classes.label}>{field_label}</InputLabel>{field_mandatory==="yes"?<span 
    style={{fontSize: "18px",
    color: "#d71729",
    fontWeight: "400",
    lineHeight: "20px",
    transform: "translateY(-2px)"}}>*</span>:null}
    </div>
     <LocalizationProvider dateAdapter={AdapterDayjs} >
        <DesktopDatePicker className={classes.input}
          inputFormat="DD/MM/YYYY"
          value={value}
           onChange={(date) => {
            handleChangedate(date)
          }}
          
          disabled={field_disabled}
          minDate={past}
          disablePast={field_pastDisable}
          renderInput={(params) => <TextField id={field_id} {...params} onBlur={handleBlur}/>}
        />
    </LocalizationProvider>
      <FormHelperText>{field_helperText}</FormHelperText>
    </div>
  );
}
