import * as React from 'react';
import useStyles from '../ComponentCss';
import Button from '../../FormsControls/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog'; 
import {Form} from '@formio/react';
import ApproveRejectComment from './ApproveRejectComment'
import {APIGET,APIDEL} from '../../services/APIServices';

export default function ApproveReject(props){
    const classes = useStyles();
    const [open,setOpen] =React.useState(false);
    const [data,setData] = React.useState();
    const [path,setPath] = React.useState("");
    const contextHeading = props.contextHeading;
    const contextJson = props.contextJson;
    const apiPath = props.apiContext.toString();
    const close = props.close;
    let dataRow ={};
    const {index,dataArr} = props;
// console.log(dataArr.id);
console.log(index);
console.log(dataArr);

React.useEffect(()=>{
  let APIRES = APIGET(apiPath+`/${dataArr.id}`).then(res => {
    console.log(res.data);
    setData(res.data)
  })
},[])

    const getLabels = (_id)=>{ 
      Object.keys(contextJson).forEach((key)=>{
        if(key!=="xioFEConfig"){ 
        let _object = contextJson[key];
        _object.fields.forEach((field,index)=>{
          const {field_id,field_label} = field;
          if(field_id===_id){
            dataRow[field_id] = {label:field_label,value:dataArr[field_id]}
            return field_label;
          } 
        })
    }})
    }
   if(dataArr){(Object.keys(dataArr).forEach((_id)=>(getLabels(_id))))}
   const getFields = ()=>{

   }
  

   const CustomFieldPreview =(_field_value)=>{
    // return (_field_value.fields)?(_field_value.fields.map((cField,_i)=>(`Field ${_i+1}:-\n ${cField.field_label} : ${cField.field_value}`)).join(','+' ')):"";
    return (_field_value.fields)?(_field_value.fields.map((cField,_i)=>(`${cField.field_label} : ${cField.field_value}`)).join(','+'<br>')):"";
}

function ConsentFormPreview (obj){
  const value=obj.value
  let keyary;
  if(value){
    keyary=Object.keys(value);
  }
  console.log(keyary);
          // return (_field_value)?(_field_value.map((cField,_i)=>(`${cField}`)).join(','+'<br>')):"";
          return (
           <>
            {value ? <div className={classes.formData}>
              <ul>
                {keyary.map((key,i)=>{
                  return <li><span>{key}</span><span>{value[`${key}`]}</span></li>
                })}
              </ul>
            </div>:null}
           </>
          )}

   const closePopup=()=>{
    setOpen(()=>(!(open)));
  }

  function approvePolicy(){
    setOpen(true)
    setPath('/approve');
  }
  function rejectPolicy(){
    setOpen(true)
    setPath('/decline')
  }
  console.log(path)
    return(
      <div  className={classes.preview}>
      <div className={classes.close}>
      <CloseIcon onClick={props.close}/>
      <h4>Approve/Reject {contextHeading}</h4>
      </div>
      {open?<ApproveRejectComment contextJson={contextJson} path={path} apiPath={apiPath} dataArr={dataArr} contextHeading={contextHeading} close={()=>(closePopup())}/>
      :
         <div className={classes.prvw}>
            <ul>
            {/* {(dataRow)?Object.keys(dataRow).map((key)=>{
                    if(key!=="formJsonData"&&key!=="additionalFields"){return(<div><li><span>{dataRow[key].label}</span><span>{dataRow[key].value}</span></li></div>)}
                    else{return null}
              })
              :null}  */}
              {Object.keys(contextJson).map((key,index)=>{
                if(key!=="xioFEConfig"){
                return contextJson[key].fields.map((_field,_index)=>{
                  // visiblefield(field,_index);
                  if((_field.field_id==="additionalFields")&&dataRow[_field.field_id]){
                    function StrtoJSON(strObj){
                      if(typeof(strObj)!=="string"){
                        return {strObj}
                      }
                      else{
                          return StrtoJSON(JSON.parse(strObj));
                      }
                    }
                    let customFieldValue = StrtoJSON(dataRow[_field.field_id].value);
                    return (CustomFieldPreview(customFieldValue.strObj).length===0)?null:(<div><li><span>{dataRow[_field.field_id].label}</span><span dangerouslySetInnerHTML={{__html:CustomFieldPreview(customFieldValue.strObj)}}></span></li></div>)
                  }else if((_field.field_id==="genratedQrcode")&&dataRow[_field.field_id]){
                    return !(dataRow&&dataRow[_field.field_id]&&dataRow[_field.field_id])?null:(<div><li><span>{dataRow[_field.field_id].label}</span><span ><img src={dataRow[_field.field_id].value} width="130" height="130"/></span></li></div>)
                  }else if((_field.field_id==="formData")&&dataRow[_field.field_id]){
                    function StrtoJSON(strObj){
                      if(typeof(strObj)!=="string"){
                        return {strObj}
                      }
                      else{
                          return StrtoJSON(JSON.parse(strObj));
                      }
                    }
                    let customFieldValue = StrtoJSON(dataRow[_field.field_id].value);
                    // console.log(dataRow[_field.field_id].value)
                return !(dataRow&&dataRow[_field.field_id]&&dataRow[_field.field_id])?null:(<div><li><span>{dataRow[_field.field_id].label}</span><span><ConsentFormPreview value={customFieldValue.strObj}/></span></li></div>)
              }else if((_field.field_id==="formJsonData")&&dataRow[_field.field_id]){
                // console.log(dataRow[_field.field_id].value)
                // return <> <Form form={JSON.parse(dataRow[_field.field_id].value)}/></>
                return !(dataRow&&dataRow[_field.field_id]&&dataRow[_field.field_id])?<span>No Form</span>:(<div><li><span>Form Preview</span><span className={classes.previewBox}><Form form={JSON.parse(dataRow[_field.field_id].value)}/></span></li></div>)
              }else{
                    return !(dataRow&&dataRow[_field.field_id]&&dataRow[_field.field_id])?null:(<div><li><span>{dataRow[_field.field_id].label}</span><span dangerouslySetInnerHTML={{__html:dataRow[_field.field_id].value}}></span></li></div>)
                  }
              })}
              })}
              </ul>
            <div className={classes.ctaNxtPrv}> 
                <Button className={classes.btnRset} ButtonName="Close" onClick={props.close}/>
                <Button className={classes.btnRej} ButtonName="Reject" onClick={()=>{rejectPolicy()}}/>
                <Button className={classes.btnApp} ButtonName="Approve" onClick={()=>{approvePolicy()}}/>
          </div>
        </div>}
      </div>);
}


